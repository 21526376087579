import React, { useEffect, useState, useLayoutEffect } from "react";
import "assets/scss/modules/accountDetails/accountDetails.scss";
import {
  isArrayNotEmpty,
  isUserLoggedIn,
  isNullUndefined,
} from "helper/validations";

import PersonalInfo from "./sections/PersonalInfo";
import Cards from "./sections/Cards";
import RSVP from "./sections/RSVP";
import Wish from "./sections/Wish";
import Album from "./sections/Album";
import Security from "./sections/Security";

import { firestoreDB } from "dataflow/services/firebase";
import { getpersonalInfoObject_success } from "dataflow/slices/personalInfo.slice";
import { getuserOrderList_success } from "dataflow/slices/userOrder.slice";
import { getuserCardList_success } from "dataflow/slices/userCard.slice";
import { useDispatch } from "react-redux";
import { useCustomSelector } from "hooks";
import { useHistory, useLocation } from "react-router-dom";

function AccountDetails(props) {
  const history = useHistory();
  const location = useLocation();

  const [cards, setCards] = useState([]);

  const [currentSelection, setCurrentSelection] = useState(
    location.state ? location?.state?.selection : "personalInfo"
  );

  const { getuserOrderList_requesting, userOrderList } =
    useCustomSelector("userOrder");

  const { isProvider } = useCustomSelector("website");

  const dispatch = useDispatch();

  //need to use Effect twice for update Event List based on order
  useEffect(() => {
    if (!isUserLoggedIn()) {
      history.replace("/utama");
    }

    if (userOrderList) {
      userOrderList.map((order) => {
        let eventId = order.eventId;
        let eventRef = firestoreDB().collection("cards").doc(eventId);
        eventRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              setCards((cards) => [...cards, { id: doc.id, ...doc.data() }]);
            } else {
              console.log("No such document!");
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      });
    }
  }, [userOrderList]);

  useEffect(() => {
    dispatch(getuserCardList_success(cards));
  }, [cards]);

  // Selection list
  let selectionList = [
    {
      header: "Maklumat Diri",
      description: "Maklumat akaun EkadTepi anda",
      id: "personalInfo",
    },
    {
      header: "EkadTepi",
      description: "Info dan status EkadTepi anda",
      id: "card",
    },
    {
      header: "RSVP",
      description: "Senarai kehadiran mengikut RSVP",
      id: "rsvp",
    },
    {
      header: "Ucapan",
      description: "Senarai ucapan yang diterima",
      id: "wish",
    },
    {
      header: "Album",
      description: "Senarai media yang dimuat naik",
      id: "album",
    },
    (!isProvider ? {
      header: "Keselamatan",
      description: "Emel dan kata kunci log masuk",
      id: "security",
    } : null),
  ];

  let display = "";
  switch (currentSelection) {
    case "personalInfo":
      display = <PersonalInfo />;
      break;
    case "card":
      display = <Cards />;
      break;
    case "rsvp":
      display = <RSVP setCurrentSelection={setCurrentSelection} />;
      break;
    case "wish":
      display = <Wish setCurrentSelection={setCurrentSelection} />;
      break;
    case "album":
      display = <Album setCurrentSelection={setCurrentSelection} />;
      break;
    case "security":
      display = <Security />;
      break;
  }

  return (
    <div className={"account-details  d-flex flex-column align-items-center"}>
      {/* Page Title */}
      <div className={"account-details__page-title text-center"}>
        <span>
          Akaun <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* Content */}
      <div className={"account-details__content d-flex"}>
        {/* Selections */}
        <div className={"content__selections"}>
          {isArrayNotEmpty(selectionList) &&
            selectionList.map((info, index) => {
              if(isNullUndefined(info)){
                return;
              }
              let header = info?.header;
              let description = info?.description;
              let id = info?.id;
              let isSelected = currentSelection === id;

              return (
                <div
                  key={id}
                  className={"selections__item-wrapper text-center "}
                >
                  <div
                    className={
                      "selections__item " +
                      (isSelected ? "selection__item_selected " : "")
                    }
                    onClick={() => setCurrentSelection(id)}
                  >
                    {/* Header */}
                    <div className={"item__header"}>{header}</div>

                    {/* Description */}
                    <div className={"item__description"}>{description}</div>
                  </div>
                </div>
              );
            })}
        </div>

        {/* Display */}
        <div className="account_display">
          <div className={"content__display"}>{display}</div>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
