import React, { useEffect, useState } from 'react';
import { deepCloneArray, hexToFilterCSS, isArrayNotEmpty, validateFunction } from 'helper/validations';
import ContactDisplay from 'modules/ECard/sections/contact/ContactDisplay';
import InputContentExpander from 'components/contentExpander/InputContentExpander';
import { useCustomSelector } from 'hooks';
import MenuPageContainer from 'components/containers/MenuPageContainer';


export default function useContactStep({
    fontList, loadFont,

    isResetRequired, currentStep,
    content_pageTitleDetails,

    contact_init_contactInfoDetails,contact_init_contactInfoDetailsFull, contact_init_textDetails,
    contact_init_iconBorderDetails,

    contact_contactInfoDetails = {}, setContact_contactInfoDetails: setContact_contactInfoDetails_props,
    contact_contactInfoDetailsFull = {}, setContact_contactInfoDetailsFull: setContact_contactInfoDetailsFull_props,
    contact_textDetails = {}, setContact_textDetails: setContact_textDetails_props,
    contact_iconBorderDetails = {}, setContact_iconBorderDetails: setContact_iconBorderDetails_props,
}) {

    // Validations
    let setContact_contactInfoDetails = validateFunction(setContact_contactInfoDetails_props);
    let setContact_contactInfoDetailsFull = validateFunction(setContact_contactInfoDetailsFull_props);
    let setContact_textDetails = validateFunction(setContact_textDetails_props);
    let setContact_iconBorderDetails = validateFunction(setContact_iconBorderDetails_props);

    const [currentVisibleContentPos, setCurrentVisibleContentPos] = useState();
    const { ecardEditDetails } = useCustomSelector("app");

    console.log(ecardEditDetails)

    function reset() {
        setContact_contactInfoDetails(contact_init_contactInfoDetails);
        setContact_contactInfoDetailsFull(contact_init_contactInfoDetailsFull);
        setContact_textDetails(contact_init_textDetails);
        setContact_iconBorderDetails(contact_init_iconBorderDetails);
    }
    useEffect(() => {
        if (currentStep == "contact" && isResetRequired) reset();
    }, [isResetRequired]);

    

    let input_contactStep = (
        <div className={"inputs__contact-step"}>
            {/* Contact Info */}
            <InputContentExpander
                mainDisplayText={"Maklumat Hubungi"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 0) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(0);
                }}
                isExpanded={currentVisibleContentPos === 0}

                themeType={"darkGrey"}

                onContentReset={() => {
                    setContact_contactInfoDetails(contact_init_contactInfoDetails)
                }}

                contentInputs={(isArrayNotEmpty(contact_contactInfoDetails)? contact_contactInfoDetails:[""]).map((details, idx) => {
                    
                    return {
                        onSectionDelete: setContact_contactInfoDetails,
                        onSectionAdd: setContact_contactInfoDetails,
                        list: contact_contactInfoDetails,
                        isDeleteIconVisible: true,
                        isAddIconVisible: true,
                        inputs: [
                            {
                                header: "Maklumat Penjemput " + (idx + 1),
                                type: "text",
                                label: "Nama:",
                                value: details?.name,
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    let newList = deepCloneArray(contact_contactInfoDetails);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        name: value
                                    }
                                    setContact_contactInfoDetails(newList);
                                }
                            },
                            {
                                type: "telInput",
                                label: "Hp No:",
                                value: contact_contactInfoDetailsFull[idx]?.phoneNo,
                                isBottomInputRelated: true,
                                onChange: (newNum, fullNum) => {
                                    let newList = deepCloneArray(contact_contactInfoDetails);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        phoneNo: fullNum
                                    }
                                    let newListFull = deepCloneArray(contact_contactInfoDetailsFull);
                                    newListFull[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        phoneNo: newNum
                                    }
                                    console.log(fullNum)
                                    console.log(fullNum.length)
                                    setContact_contactInfoDetailsFull(newListFull);
                                    setContact_contactInfoDetails(newList);
                                },
                            },
                            {
                                type: "text",
                                label: "Hubungan:",
                                value: details?.relationship,
                                onChange: (value) => {
                                    let newList = deepCloneArray(contact_contactInfoDetails);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        relationship: value
                                    }
                                    setContact_contactInfoDetails(newList);
                                }
                            },
                        ]
                    };
                })}
            />

            <InputContentExpander
                mainDisplayText={"Tulisan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 1) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(1);
                }}
                isExpanded={currentVisibleContentPos === 1}

                themeType={"blue"}

                otherProps={{
                    expanderContentStyle: { overflow: "visible" }
                }}

                onContentReset={() => setContact_textDetails(contact_init_textDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: contact_textDetails?.font },
                                value: contact_textDetails?.font,
                                onSelect: (value) => {
                                    setContact_textDetails({
                                        ...contact_textDetails,
                                        font: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: contact_textDetails?.fontColor,
                                value: contact_textDetails?.fontColor,
                                onChange: (color) => {
                                    setContact_textDetails({
                                        ...contact_textDetails,
                                        fontColor: color
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />

            <InputContentExpander
                mainDisplayText={"Sempadan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 2) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(2);
                }}
                isExpanded={currentVisibleContentPos === 2}

                themeType={"blue"}

                onContentReset={() => setContact_iconBorderDetails(contact_init_iconBorderDetails)}
               
                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "colorPicker",
                                label: "Warna Sempadan:",
                                defaultValue: contact_iconBorderDetails?.borderColor,
                                value: contact_iconBorderDetails?.borderColor,
                                onChange: (color) => {
                                    setContact_iconBorderDetails({
                                        ...contact_iconBorderDetails,
                                        borderColor: color
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />
        </div>
    );

    let preview_contactStep = (
        <MenuPageContainer ecardEditDetails={ecardEditDetails}>
            <ContactDisplay
                bgOverlayStyle={{
                    background: ecardEditDetails?.themes?.backgroundColor2,
                    borderColor: ecardEditDetails?.themes?.border && ecardEditDetails?.themes?.contentColor1
                }}

                titleStyle={{
                    fontFamily: content_pageTitleDetails?.font,
                    color: content_pageTitleDetails?.fontColor
                }}

                contacts={contact_contactInfoDetails}

                nameStyle={{
                    fontFamily: contact_textDetails?.font,
                    color: contact_textDetails?.fontColor
                }}
                relationshipStyle={{
                    fontFamily: contact_textDetails?.font,
                    color: contact_textDetails?.fontColor
                }}
                
                borderStyle={{
                    border: `solid ${contact_iconBorderDetails?.borderColor}`,
                }}
            />
        </MenuPageContainer>
    );

    return {
        input_contactStep, preview_contactStep
    }
}