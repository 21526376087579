import React from "react";

import money from "assets/images/money.svg";
import pantas from "assets/images/pantas.svg";
import mudah from "assets/images/mudah.svg";
import infinity from "assets/images/infinity.svg";

export function Attraction(props) {
  return (
    <div className={"home__attraction container"}>
      {/* Header */}
      <div className={"attraction__header text-center"}>
        <span>
          Kelebihan <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* Sub-Header */}
      <div className={"attraction__sub-header d-flex justify-content-center"}>
        <p className="attraction_text text-center">
          Sebagai sebuah platform penciptaan kad digital terbaik, kami
          menawarkan pelbagai kelebihan kepada pelanggan.
        </p>
      </div>

      {/* Components */}
      <div className="attraction_list d-flex row justify-content-center">
        <div className="attraction_list-price attraction_list-box d-flex flex-column justify-content-center align-items-center">
          <div className="attraction-circle d-flex justify-content-center align-items-center">
            <img className={"logo-money"} src={money} />
          </div>
          <div className="attraction-price-title text-center ">Murah</div>
          <div className="attraction-price-description text-center">
            Termurah di pasaran dengan pelbagai fungsi
          </div>
        </div>

        <div className="attraction_list-fast attraction_list-box d-flex flex-column justify-content-center align-items-center">
          <div className="attraction-circle d-flex justify-content-center align-items-center">
            <img className={"logo-money"} src={pantas} />
          </div>
          <div className="attraction-fast-title text-center">Pantas</div>
          <div className="attraction-fast-description text-center">
            Boleh disiapkan dalam masa yang singkat
          </div>
        </div>

        <div className="attraction_list-easy attraction_list-box d-flex flex-column justify-content-center align-items-center">
          <div className="attraction-circle d-flex justify-content-center align-items-center">
            <img className={"logo-money"} src={mudah} />
          </div>
          <div className="attraction-easy-title text-center">Mudah</div>
          <div className="attraction-easy-description text-center">
            Senang untuk dihasilkan dan digunakan
          </div>
        </div>

        <div className="attraction_list-unlimited attraction_list-box d-flex flex-column justify-content-center align-items-center">
          <div className="attraction-circle d-flex justify-content-center align-items-center">
            <img className={"logo-money"} src={infinity} />
          </div>
          <div className="attraction-unlimited-title text-center">
            Unlimited
          </div>
          <div className="attraction-unlimited-description text-center">
            Dapat dihantar kepada ramai kenalan
          </div>
        </div>
      </div>
    </div>
  );
}
