import React from 'react';

import { Form, InputGroup } from "react-bootstrap";
import "assets/scss/components/inputs/input.scss";

import { isFunction, isNotNullUndefined, isStringTrimNotEmpty, generateUniqueId, isArrayNotEmpty } from 'helper/validations';



export function Input(props) {
    const {
        className,
        
        controlId, 
        labelClassName, label,
        placeholder,

        inputClassName, inputType,
        inputId,
        elementType,
        value,
        maxLength,
        optionList,
        isDisabled, isDigitOnly,

        isInvalid, 
        errorMsg,

        isLabelAtTheSide, isMarginBottomOptional
    } = props;

    // Validate Props
    let controlId_validated = isStringTrimNotEmpty(controlId)? controlId: "input";
    let placeholder_validated = isStringTrimNotEmpty(placeholder)? placeholder: "";
    let elementType_validated = isStringTrimNotEmpty(elementType)? elementType: "input";
    let inputType_validated = isStringTrimNotEmpty(inputType)? inputType: "text";
    let value_validated = isNotNullUndefined(value)? value: "";
    let maxLength_validated = isStringTrimNotEmpty(maxLength)? maxLength : "";
    let optionList_validated = isArrayNotEmpty(optionList)? optionList: [];

    function handleOnChange(value) {
        if (isNotNullUndefined(value) &&
            isFunction(props.onChange)) {
            props.onChange(value);
        }
    }

    return (
        <Form.Group 
            className={"component-input " +
                (className? className + " ":"") +
                (isMarginBottomOptional? "mb-0 ":"") +
                (isLabelAtTheSide? "d-flex align-items-center ":"")}
            controlId={generateUniqueId(controlId_validated)}
        >
            {/* Label */}
            {label? (
                <Form.Label
                    className={"component-input__label " +
                        (labelClassName? labelClassName + " ":"") +
                        (isLabelAtTheSide? "pr-3 ":"")}
                >
                    {label}
                </Form.Label>
            ):""}

            {/* Input */}
            <InputGroup hasValidation>
                <Form.Control id={inputId} type={inputType_validated} as={elementType_validated}
                    className={"component-input__input " + 
                        (inputClassName? inputClassName + " ":"")}
                    placeholder={placeholder_validated} 
                    onChange={(event) => {
                        const { value } = event.target;

                        let value_formatted = value;
                        let keyedValued = parseInt(value.charAt(value.length - 1));
                        if (isDigitOnly && isNaN(keyedValued)) {
                            // Don't allow non-digit input
                            value_formatted = value.substring(0, value.length - 1);
                        }

                        if (isNotNullUndefined(value_formatted)) {
                            handleOnChange(value_formatted);
                        }
                    }}
                    value={value_validated}
                    maxLength={maxLength_validated}

                    isInvalid={isInvalid}
                    required
                    disabled={isDisabled}
                >
                    {/* Options */}
                    {(elementType_validated === "select") && isArrayNotEmpty(optionList_validated)? optionList_validated.map((option, idx) => (
                        <option value={option}>{option}</option>
                    )):null}
                </Form.Control>
                

                {/* Error Messages */}
                <Form.Control.Feedback type={"invalid"}
                    className={"component-input__error-msg"}
                >
                {errorMsg}
                </Form.Control.Feedback>
              </InputGroup>
        </Form.Group>
    );
}