import moment from "moment";
import { isNotNullUndefined } from ".";
import { hexToCSSFilter } from 'hex-to-css-filter';



export const validateString = {
    isString,
	isStringValid,
	isStringValidNumber,
	isStringEmpty,
	isStringNotEmpty,
	isStringTrimEmpty,
	isStringTrimNotEmpty,
	getLowerCaseString,
	getTrimmedString,
	getLowerCaseTrimmedString,
	isStringEqual_caseSensitive,
	isStringEqual_caseInsensitive,
	generateUniqueId,

	hexToFilterCSS
}

export function isString(val) {
    return (typeof val) === "string";
}

export function isStringValid(val) {
	return (isNotNullUndefined(val) && isString(val));
}

export function isStringValidNumber(val) {
	return isStringValid(val) && (!isNaN(parseFloat(val)));
}

export function isStringEmpty(val) {
	return (isStringValid(val) && val === "");
}

export function isStringNotEmpty(val) {
	return (isStringValid(val) && val !== "");
}

export function isStringTrimEmpty(val) {
    return (isStringValid(val) && val.trim() === "");
}

export function isStringTrimNotEmpty(val) {
    return (isStringNotEmpty(val) && val.trim() !== "");
}

export function getLowerCaseString(val) {
	if (isStringValid(val)) return val.toLowerCase();
	return undefined;
}

export function getTrimmedString(val, defaultValue) {
	if (isStringValid(val)) return val.trim();
	if (isNotNullUndefined(defaultValue)) return defaultValue;
	return undefined;
}

export function getLowerCaseTrimmedString(val) {
	if (isStringValid(val)) {
		let trimmedString = getTrimmedString(val);
		return getLowerCaseString(trimmedString);
	}
	return undefined;
}

export function isStringEqual_caseSensitive(val1, val2) {
	if (isStringValid(val1) && isStringValid(val2)) {
		return val1 === val2;
	}
	return false;
}

export function isStringEqual_caseInsensitive(val1, val2) {
	if (isStringValid(val1) && isStringValid(val2)) {
		return (getLowerCaseTrimmedString(val1) === getLowerCaseTrimmedString(val2));
	}
	return false;
}

export function generateUniqueId(name) {
	let randomNumber = Math.floor(moment().valueOf() * Math.random());
	if (isStringTrimNotEmpty(name)) return name + randomNumber;
	return "id" + randomNumber;
}

export function hexToFilterCSS(hex) {
	if (isStringTrimNotEmpty(hex) && hex.charAt(0) === '#') {
		let filterObj = hexToCSSFilter(hex, {
			forceFilterRecalculation: true,
			acceptanceLossPercentage: 1,
		});
		return filterObj?.filter?.replace(";","");
	}
	return hex;
}