import React from 'react'

import "assets/scss/components/ecard/closeButton.scss";
import { useCustomSelector } from 'hooks';

function CloseButton({setIsMenuOpen}) {

  const { appInfo, ecardEditDetails } = useCustomSelector("app");
  const rootURL = appInfo?.rootURL;
  const theme = appInfo?.themes ? appInfo?.themes : ecardEditDetails?.themes;

  const iconStyle1 = {
    color: theme?.contentColor1,
  };

  return (
    <div className={"closeButton"}>
      <i
        className="fas fa-times icons"
        onClick={() => {setIsMenuOpen(false)}}
        style={iconStyle1}
      ></i>
</div>
  )
}

export default CloseButton