import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useCustomSelector } from "hooks";
import Slider from "react-slick";

import {
  isNumberValid,
  getObjectPropertyValue,
  isStringTrimEmpty,
  isArrayNotEmpty,
} from "helper/validations";
import { Button } from "components/buttons/Button";

import ic_arrow from "assets/images/angle-right-solid.svg";
import useHomeServices from "dataflow/services/home.services";
import { ImgWithFallback } from "components";
import { useDispatch } from "react-redux";
import { getcardDesignPreviewList_success } from "dataflow/slices/home.slice";

function CardPreview(props) {
  const { cardDesignPreviewList } =
    useCustomSelector("home");

  const { getcardDesignPreviewList } = useHomeServices();
  const history = useHistory();
  const dispatch = useDispatch()

  const [slider, setSlider] = useState();

  // Get data for the card design
  useEffect(() => {
    dispatch(getcardDesignPreviewList_success())
    // getcardDesignPreviewList();
  }, []); 

  // Create List Display
  let cardItems = [];
  if (isArrayNotEmpty(cardDesignPreviewList)) {
    cardItems = cardDesignPreviewList.map((cardDetails, idx) => {
      const cardId = getObjectPropertyValue(cardDetails, "id");
      const theme = getObjectPropertyValue(cardDetails, "theme");
      // const name = getObjectPropertyValue(cardDetails, "title");
      let cardType = getObjectPropertyValue(cardDetails, "type");
      const cardTypeId = getObjectPropertyValue(cardDetails, "typeId");

      let imageUrl = getObjectPropertyValue(theme, "thumbnail");
      let imageSrc = getObjectPropertyValue(theme, "background");
      let OptimizedImageSrc = getObjectPropertyValue(theme, "backgroundOptimized");
      

      const style1 = {
        color: getObjectPropertyValue(theme, "homeColor1"),
        fontFamily: getObjectPropertyValue(theme, "homeFontFamily1"),
      };

      const style2 = {
        color: getObjectPropertyValue(theme, "homeColor2"),
        fontFamily: getObjectPropertyValue(theme, "homeFontFamily2"),
      };

      const style3 = {
        color: getObjectPropertyValue(theme, "homeColor3"),
        fontFamily: getObjectPropertyValue(theme, "homeFontFamily3"),
      };

      const style4 = {
        color: getObjectPropertyValue(theme, "homeColor4"),
        fontFamily: getObjectPropertyValue(theme, "homeFontFamily4"),
      };

      const style5 = {
        color: getObjectPropertyValue(theme, "homeColor5"),
        fontFamily: getObjectPropertyValue(theme, "homeFontFamily5"),
      };

      const background1 = {
        background: getObjectPropertyValue(theme, "backgroundColor1"),
      };

      if (
        (isStringTrimEmpty(cardId) || isNumberValid(cardId)) &&
        isStringTrimEmpty(imageUrl) &&
        isStringTrimEmpty(cardType)
      )
        return "";

      return (
        <div
          key={idx}
          className={
            "card-preview__item d-flex flex-column align-items-center justify-content-center "
          }
        >
          <div className={"item__wrapper"}>
            {/* Background Image */}
            <ImgWithFallback
              className={"item__background"}
              alt={"Background Card Design"}
              src={OptimizedImageSrc}
              fallback={imageSrc}
            /> 
            {/* <img
              className={"item__background"}
              src={imageUrl}
              alt={"Background Card Design"}
            /> */}

            {/* {Homepage preview} */}
            <div className={"card-preview__homepage"} style={background1}>
              <div className="homepage-title">
                <h3 className="home__text3" style={style1}>
                  Walimatul Urus
                </h3>
              </div>

              <div className="hompage-name">
                <h1 className="home__text1" style={style2}>
                  {"Adam"}
                </h1>
                <h1 className="home__text1" style={style3}>
                  &
                </h1>
                <h1 className="home__text1" style={style4}>
                  {"Hawa"}
                </h1>
              </div>

              <div className="hompage-date">
                <h3 className="home__text3" style={style5}>
                  {"23 OCT 2021"}
                </h3>
              </div>
            </div>

            {/* Image Type Header */}
            <div className={"item__card-type text-center text-ellipses"}>
              {cardType}
            </div>

            {/* Action */}
            <div
              className={
                "item__action-wrapper d-flex align-items-center justify-content-center"
              }
            >
              <Button
                className={"item__action"}
                content={"Lihat"}
                onClick={() => history.push(`/contoh-tema?tema=${cardTypeId}`)}
                decoration_isSecondary
              />
            </div>
          </div>
        </div>
      );
    });
  }

  var settings = {
    infinite: true,
    arrows: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "slide_div",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={"home__card-preview container"}>
      {/* Header */}
      <div className={"card-preview__header text-center"}>
        <span>
          Tema-Tema <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* Sub-Header */}
      <div className={"card-preview__sub-header d-flex justify-content-center"}>
        <p className="card-preview_text text-center">
          EkadTepi menawarkan pelbagai tema yang menarik kepada para pelanggan.
        </p>
      </div>

      {/* Slideshow */}
      <div
        className={
          "row card-preview-wrapper align-items-center justify-content-center"
        }
      >
        <div className={"col-1 text-right"} onClick={slider?.slickPrev}>
          <img className={"card-preview__prev"} src={ic_arrow} alt={"previous"}/>
        </div>

        <div className={"col-10"}>
          <Slider ref={(slider) => setSlider(slider)} {...settings}>
            {cardItems}
          </Slider>
        </div>

        <div className={"col-1"} onClick={slider?.slickNext}>
          <img className={"card-preview__next"} src={ic_arrow} alt={"next"}/>
        </div>
      </div>

      {/* Action */}
      <div
        className={
          "card-preview__action d-flex justify-content-center align-items-center "
        }
      >
        <div className={"action__wrapper d-flex justify-content-end"}>
          <Button
            className={"action__button"}
            onClick={() => history.push("/contoh-tema")}
            content={"Tema EkadTepi"}
            decoration_isBrightInversePrimary
          />
        </div>
      </div>
    </div>
  );
}
export default CardPreview;
