import React, { useLayoutEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import { firestoreDB, storage } from "dataflow/services/firebase";
import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore
import { useCustomSelector } from "hooks";
import { resetcameraMedia_success } from "dataflow/slices/camera.slice";

function Preview() {
  
  const {
    cameraMedia
  } = useCustomSelector("camera");

  const {
		appInfo
	} = useCustomSelector("app");
  const rootURL = appInfo.rootURL;
  const eventId = appInfo.eventId;

  const {
    booking
  } = useCustomSelector("booking");

  let history = useHistory();
  const dispatch = useDispatch();

  const [selection, setSelection] = useState(true);
  const [caption, setCaption] = useState(null);

  useLayoutEffect(() => {
    !cameraMedia && history.replace(`${rootURL}/camera`);
    return () => {};
  }, [cameraMedia, history]);

  const closePreview = () => {
    dispatch(resetcameraMedia_success());
  };

  const sendPost = () => {
    if (caption) {
      const id = uuid();
      const uploadTask = storage()
        .ref(`albums/${eventId}/${id}`)
        .putString(cameraMedia, "data_url");

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          //Error handling
          console.log(error);
        },
        () => {
          //On complete upload
          storage()
            .ref(`albums/${eventId}`)
            .child(id)
            .getDownloadURL()
            .then((url) => {
              firestoreDB().collection("albums").add({
                mediaId: id,
                eventId: eventId,
                mediaURL: url,
                user: booking.displayName,
                message: caption,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                favorites: 0,
              });
            });
          history.replace(`${rootURL}/gallery`);
        }
      );
    }
  };

  return (
    <div className="preview">
      <div className="preview__header">
        <i className="fas fa-arrow-left" onClick={closePreview}>
          <span>Back</span>
        </i>
        <div className="preview__headerSend" onClick={sendPost}>
          <span>Post Now</span>
          <i className="fas fa-chevron-right"></i>
        </div>
      </div>

      <img src={cameraMedia} alt="" className="preview__image" />

      <div className="preview__footer">
        <div className="preview__footerTop">
          {selection ? (
            <textarea
              className="caption"
              placeholder="Taipkan ucapan anda di sini..."
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              maxLength="200"
              required
            />
          ) : (
            <div className="filters"> </div>
          )}
        </div>

        <div className="preview__footerBottom">
          <div className="preview__footerCaption">
            <p>Caption</p>
          </div>
          {/* <div className="preview__footerFilter" onClick={() => setSelection(false)}>
                        <p>filter</p>
                    </div> */}
        </div>
      </div>
    </div>
  );
}

export default Preview;
