import React, { useEffect, useState } from "react";

import Media from "components/Media";
import { useCustomSelector } from "hooks";

import { useRouteMatch, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Card } from "components/Card";
import { Button } from "components/buttons/Button";
import { isArrayNotEmpty } from "helper/validations";

function Album({ setCurrentSelection }) {
  let { path, url } = useRouteMatch();

  const { userCardList } =
    useCustomSelector("userCard");

  const [cards, setCards] = useState([]);

  useEffect(() => {
    let events = userCardList?.filter(
      (card, index) => card.eventStatus == "publish"
    );
    setCards(events);
  }, []);

  return (
    <div className={"display__album-info"}>
      {/* Page Title */}
      <div className="album-info__header d-flex align-items-center justify-content-start">
        <p className={"header__title"}>1. Pilih Ekadtepi</p>
      </div>

      {/* Page Content */}
      <div className="album-info__content d-flex flex-column justify-content-center align-items-center w-100">
        {/* List of Ecards */}
        {isArrayNotEmpty(cards) ? (
          <div className="album-info__content-ecard d-flex overflow-auto">
            {cards?.map((card) => (
              <div key={card.id} className={"content-ecard__wrapper "}>
                <Link to={`${url}/${card.id}`}>
                  <Card
                    key={card.id}
                    eCardUrl={`${url}/${card.id}`}
                    height={"244px"}
                    width={"180px"}
                    userOrder
                    eventId={card.id}
                    theme={card.themes}
                    homeData={card.home}
                  />
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className={"missing-ecard"}>
            <span className={"missing-ecard__msg"}>
              Tiada 'Live EkadTepi' dijumpa. Sila teruskan pembayaran dalam
              'Draf EkadTepi' anda.
            </span>
            <Button
              className={"missing-ecard__action"}
              content={"Draf EkadTepi"}
              onClick={() => setCurrentSelection("card")}
              decoration_isBrightPrimary
            />
          </div>
        )}

        {/* Media viewer */}
        <div className="content-ecard__header d-flex flex-column align-items-center justify-content-start">
          <p className={"header__title"}>Senarai Media</p>
          <p className={"media-list-total"}>Jumlah media dimuatnaik:</p>
        </div>

        <div className="content-ecard__album overflow-auto d-flex flex-column justify-content-start">
          <Switch>
            <Route exact path={path}>
              <div className="content-ecard__album-notification d-flex justify-content-center align-items-center">
                <h4>Sila pilih EkadTepi</h4>
              </div>
            </Route>
            <Route path={`${path}/:eventId`}>
              <Media />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Album;
