export const musics = [
  {
    tag: "tiada",
    label: "Tiada",
    value: "Tiada",
    url: "",
  },
  // {
  //   tag: "Ekad-Music-1",
  //   label: "Love Story",
  //   value: "Love Story",
  //   url: "https://firebasestorage.googleapis.com/v0/b/e-card-77cee.appspot.com/o/audio%2FEkad-Music-1.mp3?alt=media&token=cc41426c-3343-4344-8385-35092cb4c9c0",
  // },
  // {
  //   tag: "Ekad-Music-2",
  //   label: "Beautiful Memories",
  //   value: "Beautiful Memories",
  //   url: "https://firebasestorage.googleapis.com/v0/b/e-card-77cee.appspot.com/o/audio%2FEkad-Music-2.mp3?alt=media&token=d5f8a314-a732-4570-8f04-b23f01bee5e7",
  // },
  // {
  //   tag: "Ekad-Music-3",
  //   label: "Classical Piano",
  //   value: "Classical Piano",
  //   url: "https://firebasestorage.googleapis.com/v0/b/e-card-77cee.appspot.com/o/audio%2FEkad-Music-3.mp3?alt=media&token=dc9ff1e2-cc3f-43ea-9103-468f019341cc",
  // },
  // {
  //   tag: "Ekad-Music-4",
  //   label: "Moment",
  //   value: "Moment",
  //   url: "https://firebasestorage.googleapis.com/v0/b/e-card-77cee.appspot.com/o/audio%2FEkad-Music-4.mp3?alt=media&token=dea015c6-db46-438f-b336-02609c66185f",
  // },
  // {
  //   tag: "Ekad-Music-5",
  //   label: "Always With Me",
  //   value: "Always With Me",
  //   url: "https://firebasestorage.googleapis.com/v0/b/e-card-77cee.appspot.com/o/audio%2FEkad-Music-4.mp3?alt=media&token=dea015c6-db46-438f-b336-02609c66185f",
  // },
  // {
  //   tag: "Ekad-Music-6",
  //   label: "Wedding Love",
  //   value: "Wedding Love",
  //   url: "https://firebasestorage.googleapis.com/v0/b/e-card-77cee.appspot.com/o/audio%2FEkad-Music-6.mp3?alt=media&token=45ebbcad-cd4d-4224-a4bc-1fad2123962d",
  // },
  {
    tag: "Ekad-Music-7",
    label: "Alunan Kompang",
    value: "Alunan Kompang",
    url: "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/audio%2FAlunan-Kompang-Sound%20(compress).mp3?alt=media&token=ad26480a-a5f9-4a20-9b1d-447ca6794e37",
  },
  {
    tag: "Ekad-Music-8",
    label: "Sea Waves",
    value: "Sea Waves",
    url: "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/audio%2FSea-Waves-Sound%20(compress).mp3?alt=media&token=83c4a8da-cc23-49a3-b5cd-9bb19c1d3a59",
  },
  {
    tag: "Ekad-Music-8",
    label: "Chirping Nature",
    value: "Chirping Nature",
    url: "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/audio%2FBird-Chirping-Sound%20(compress).mp3?alt=media&token=67209041-19b1-4db4-aa75-d374fc2dd958",
  },
];
