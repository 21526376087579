import { isArrayNotEmpty, isFunction, isNumberValid } from 'helper/validations';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown as DropdownBootstrap } from "react-bootstrap";
import ic_triangle from "assets/images/ic_triangle.png";
import "assets/scss/components/dropdown.scss";

export function Dropdown({ 
    className, wrapperClassName,
    toggleClassName,

    label, labelClassName,
    isLabelOnLeftSide,

    toggleText,
    options,

    themeType,
    isArrowVisible,

    // Function
    onLoadMenuItemData = () => {},
}) {
    // Themes
    let themeClassName = "";
    switch (themeType) {
        case "light":
            themeClassName = "component-dropdown_light-theme";
            break;
    }

    return (
        <div className={"component-dropdown " +
                (className? className + " ":"") +
                (isLabelOnLeftSide? "d-flex align-items-center ":"") +
                (themeClassName? themeClassName + " ":"")}>
            {/* Label */}
            {label? (
                <div
                    className={"component-input__label " +
                        (labelClassName? labelClassName + " ":"")}
                >
                    {label}
                </div>
            ):""}

            {/* Dropdown */}
            <DropdownBootstrap className={"component-dropdown__wrapper " + 
                        (wrapperClassName? wrapperClassName + " ":"")}>
                <DropdownBootstrap.Toggle 
                    className={"wrapper__toggle " +
                        (toggleClassName? toggleClassName + " ":"") +
                        (isArrowVisible? "d-flex justify-content-between align-items-center ":"")} 
                        id="dropdown-basic"
                >
                    <>
                        {toggleText}
                        {isArrowVisible && (
                            <>
                                <div/>
                                <img
                                    className={"toggle__icon"}
                                    src={ic_triangle}
                                    alt={"Dropdown arrow"}
                                />
                            </>
                            
                        )}
                    </>
                </DropdownBootstrap.Toggle>

                <DropdownBootstrap.Menu
                    className={"wrapper__menu"}
                    onScroll={(e) => {
                        let item = document.getElementById("component-dropdown-options0");
                        let itemHeight = item.getBoundingClientRect().height;

                        let menuEle = e.target;
                        let menuEle_scrollTop = menuEle.scrollTop;
                        let lastVisibleItemIndex = Math.floor((menuEle_scrollTop + menuEle.clientHeight) / itemHeight) - 1;
                        onLoadMenuItemData(lastVisibleItemIndex);
                    }}
                >
                    {isArrayNotEmpty(options) && options.map((item, idx) => {
                        let label = item?.label;
                        let value = item?.value;
                        let style = item?.style;
                        let onSelect = item?.onClick;

                        return (
                            <DropdownBootstrap.Item id={"component-dropdown-options" + idx} key={idx} style={style} onClick={() => {
                                onSelect(value);
                            }}>
                                {label}
                            </DropdownBootstrap.Item>
                        )
                    })}
                </DropdownBootstrap.Menu>
            </DropdownBootstrap>
        </div>
    );
}