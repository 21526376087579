import React, { useState } from "react";
import { isObjectNotEmpty, validateFunction } from "helper/validations"
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "assets/scss/components/inputs/datePicker.scss";
import { convertToDate } from "helper/validations/date";
import { useEffect } from "react";


export function DatePicker({
    className, labelClassName, inputClassName,

    label, inputProps: inputProps_props,
    dateFromParent,

    // Functions
    onChange: onChange_props,
}) {
    let onChange = validateFunction(onChange_props);

    let inputProps = isObjectNotEmpty(inputProps_props)? inputProps_props:{};
    
    const [date, setDate] = useState();

    useEffect(() => {
        setDate(convertToDate(dateFromParent));
    }, [dateFromParent]);
    
    return (
        <div className={"component-date-picker d-flex " +
                (className? className + " ":"")}
        >
            {/* Label */}
            <div className={"component-date-picker__label " +
                    (labelClassName? labelClassName + " ":"")}>
                {label}
            </div>

            {/* Input */}
            <ReactDatePicker 
                {...inputProps}
                selected={date}
                onChange={(date) => {
                    onChange(date);
                    setDate(date)
                }}
                className={"component-date-picker__input " + 
                    (inputClassName? inputClassName + " ":"")}
            />
        </div>
    )
}