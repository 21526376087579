import React, { useState } from "react";
import { useCustomSelector } from "hooks";
import HomeDisplay from "./HomeDisplay";

function Home({ isPhase, callback, setIsLoaded, setIsMuted }) {
  const { appInfo } = useCustomSelector("app");
  const home = appInfo.home;
  const theme = appInfo.themes;

  const [play1, setPlay1] = useState(true)
  const [play2, setPlay2] = useState(false)
  const [play3, setPlay3] = useState(false)
  const [play4, setPlay4] = useState(false)
  const [play5, setPlay5] = useState(false)

  const style1 = {
    color: theme.homeColor1,
    fontFamily: theme.homeFontFamily1,
  };

  const style2 = {
    color: theme.homeColor2,
    fontFamily: theme.homeFontFamily2,
  };

  const style3 = {
    color: theme.homeColor3,
    fontFamily: theme.homeFontFamily3,
  };

  const style4 = {
    color: theme.homeColor4,
    fontFamily: theme.homeFontFamily4,
  };

  const style5 = {
    color: theme.homeColor5,
    fontFamily: theme.homeFontFamily5,
  };

  const background1 = {
    background: theme.backgroundColor1,
  };

  const transitionDuration = 500;

  const transitionDelay = 100;

  const defaultOpacityStyle = {
    transition: `opacity ${transitionDuration}ms cubic-bezier(0.4, 0, 1, 1) ${transitionDelay}ms`,
    opacity: 1,
    }

    const opacityStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: theme.homeBgOpacity },
    exiting:  { opacity: theme.homeBgOpacity },
    exited:  { opacity: theme.homeBgOpacity }
    };

  return (
    <HomeDisplay
      bgImg={theme.backgroundOptimized}
      fallbackImg={theme.background}
      bgOverlayStyle={background1}
      opacityStyles={opacityStyles}
      defaultOpacityStyle={defaultOpacityStyle}
      transitionDuration={transitionDuration}

      titleTxt={home.event}
      titleStyle={style1}

      bridegroomStyle={style2}
      andSymbolStyle={style3}
      brideStyle={style4}

      bridegroomName={home.bridegroomName}
      brideName={home.brideName}

      dateStyle={style5}
      dateTxt={home?.date? home.date: appInfo?.details?.date1}

      isPhase={isPhase}
      callback={callback}

      play1={play1}
      play2={play2}
      play3={play3}
      play4={play4}
      play5={play5}

      setPlay1={setPlay1}
      setPlay2={setPlay2}
      setPlay3={setPlay3}
      setPlay4={setPlay4}
      setPlay5={setPlay5}

      setIsLoaded={setIsLoaded}
      setIsMuted={setIsMuted}
    />
  );
}

export default Home;