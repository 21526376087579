import React from 'react'

import "assets/scss/components/skeletons/shimmer.scss";

export function Shimmer() {
    return (
        <div className={"shimmer-wrapper"}>
            <div className={'shimmer'}>

            </div>
        </div>
    )
}

