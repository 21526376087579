import React from 'react';
import Swal from 'sweetalert2';

export default function AlertModal({
    title,
    text,
    icon, 
    confirmButtonText,
    showCancelButton,
    cancelButtonText
}) {
    return Swal.fire({
        title,
        text,
        icon, 
        confirmButtonText,
        showCancelButton,
        cancelButtonText
    })
}