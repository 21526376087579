import React, { useEffect, useState } from 'react';
import { deepCloneArray, hexToFilterCSS, isArrayNotEmpty, validateFunction } from 'helper/validations';
import LocationDisplay from '../../ECard/sections/location/LocationDisplay';
import InputContentExpander from 'components/contentExpander/InputContentExpander';
import { useCustomSelector } from 'hooks';
import MenuPageContainer from 'components/containers/MenuPageContainer';


export default function useLocationStep({
    fontList, loadFont,

    isResetRequired, currentStep,
    cardThemeBgImage, content_pageTitleDetails,

    event_init_details, location_locationDetails = {}, setLocation_locationDetails: setLocation_locationDetails_props,
    location_init_iconDetails, location_iconDetails = {}, setLocation_iconDetails: setLocation_iconDetails_props,
    location_init_borderDetails, location_borderDetails = {}, setLocation_borderDetails: setLocation_borderDetails_props,

    event_details, setEvent_details: setEvent_details_props
}) {
    // Validations
    let setLocation_locationDetails = validateFunction(setLocation_locationDetails_props);
    let setLocation_iconDetails = validateFunction(setLocation_iconDetails_props);
    let setLocation_borderDetails = validateFunction(setLocation_borderDetails_props);
    let setEvent_details = validateFunction(setEvent_details_props);

    const [currentVisibleContentPos, setCurrentVisibleContentPos] = useState();
    const { ecardEditDetails } = useCustomSelector("app");

    console.log(ecardEditDetails)

    function reset() {
        setLocation_locationDetails(event_init_details);
        setLocation_iconDetails(location_init_iconDetails);
        setLocation_borderDetails(location_init_borderDetails);
    }
    useEffect(() => {
        if (currentStep == "location" && isResetRequired) reset();
    }, [isResetRequired]);

    let input_locationStep = (
        <div className={"inputs__location-step"}>
            {/* Data */}
            <InputContentExpander
                mainDisplayText={"Maklumat Lokasi"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 0) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(0);
                }}
                isExpanded={currentVisibleContentPos === 0}

                themeType={"darkGrey"}

                onContentReset={() => setLocation_locationDetails(event_init_details)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "text",
                                label: "Alamat Majlis:",
                                value: location_locationDetails?.eventLocation,
                                onChange: (value) => {
                                    setLocation_locationDetails({
                                        ...location_locationDetails,
                                        eventLocation: value
                                    });

                                    // auto fill in event step
                                    // setEvent_details({
                                    //     ...event_details,
                                    //     eventLocation: value
                                    // });
                                },
                            },
                        ]
                    }
                ]}
            />

            {/* Icon Style */}
            <InputContentExpander
                mainDisplayText={"Ikon"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 1) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(1);
                }}
                isExpanded={currentVisibleContentPos === 1}

                themeType={"blue"}

                onContentReset={() => setLocation_iconDetails(location_init_iconDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "colorPicker",
                                label: "Warna Ikon:",
                                defaultValue: location_iconDetails?.iconColor,
                                value: location_iconDetails?.iconColor,
                                onChange: (color) => {
                                    setLocation_iconDetails({
                                        ...location_iconDetails,
                                        iconColor: color
                                    });
                                }
                            },

                            {
                                type: "dropdown",
                                label: "Fon Ikon:",
                                valueStyle: { fontFamily: location_iconDetails?.fontIcon },
                                value: location_iconDetails?.fontIcon,
                                onSelect: (value) => {
                                    setLocation_iconDetails({
                                        ...location_iconDetails,
                                        fontIcon: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon Ikon:",
                                defaultValue: location_iconDetails?.iconFontColor,
                                value: location_iconDetails?.iconFontColor,
                                onChange: (color) => {
                                    setLocation_iconDetails({
                                        ...location_iconDetails,
                                        iconFontColor: color
                                    });
                                }
                            }
                        ]
                    }
                ]}
            />

            {/* Border Styles */}
            <InputContentExpander
                mainDisplayText={"Sempadan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 2) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(2);
                }}
                isExpanded={currentVisibleContentPos === 2}

                themeType={"blue"}

                onContentReset={() => setLocation_borderDetails(location_init_borderDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "colorPicker",
                                label: "Warna Sempadan:",
                                defaultValue: location_borderDetails?.color,
                                value: location_borderDetails?.color,
                                onChange: (color) => {
                                    setLocation_borderDetails({
                                        ...location_borderDetails,
                                        color
                                    });
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    );

    let preview_locationStep = (
        <MenuPageContainer ecardEditDetails={ecardEditDetails}>
            <LocationDisplay

                titleStyle={{
                    fontFamily: content_pageTitleDetails?.font,
                    color: content_pageTitleDetails?.fontColor
                }}

                mapContainerStyle={{
                    borderColor: location_borderDetails?.color
                }}
                mapInfo={{
                    // coordinate: {
                    //     _latitude: ,
                    //     _longitude: ,
                    // },
                    place: location_locationDetails?.eventLocation
                }}

                iconFontStyles={{
                    fontFamily: location_iconDetails?.fontIcon,
                    color: location_iconDetails?.iconFontColor,
                }}
                iconStyles={{
                    filter: hexToFilterCSS(location_iconDetails?.iconColor)
                }}
            />
        </MenuPageContainer>
    );

    return {
        input_locationStep, preview_locationStep
    }
}