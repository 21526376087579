import React from "react";
import { useState, useEffect } from "react";
import { useCustomSelector } from "hooks";
import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore
import { firestoreDB } from "dataflow/services/firebase";
import { Popover } from "react-bootstrap";

import WishDisplay from "./WishDisplay";

function Wish({ colId }) {
  const [check, setCheck] = useState(false);
  const [input, setInput] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [messages, setMessages] = useState([]);
  const [tooltip, setTooltip] = useState(true);

  const { appInfo } = useCustomSelector("app");
  const eventId = appInfo.eventId;
  const theme = appInfo.themes;

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1,
  };

  const fontStyle1 = {
    color: theme.primaryColor,
    fontFamily: theme.fontFamily1,
  };

  //fetch user rsvp status
  const { booking } = useCustomSelector("booking");

  const readMessage = (e) => {
    setInput(e.target.value);
  };

  const checkBox = (e) => {
    setCheck(e.target.checked);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (booking) {
      //send message to database
      firestoreDB()
        .collection(colId)
        .doc(eventId)
        .collection("wishes")
        .add({
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          wish: input,
          user: booking.displayName,
          relationship: booking.relationship,
        })
        .then(() => console.log("You successfully send message"))
        .catch((error) => console.log("Failed to send: ", error));
    } else {
      setModalShow(true);
    }
    setInput("");
    setCheck(false);
  };

  //update the wish timeline in real time
  useEffect(() => {
    firestoreDB()
      .collection(colId)
      .doc(eventId)
      .collection("wishes")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) =>
        setMessages(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    console.log(messages);
  }, []);

  const renderPopover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">JADILAH YANG PERTAMA</Popover.Title>
      <Popover.Content>
        Hantar ucapan anda ke pasangan <strong>DISINI</strong>
      </Popover.Content>
    </Popover>
  );

  return (
    <WishDisplay
      titleStyle={titleStyle}
      messages={messages}
      renderPopover={renderPopover}
      isTooltipVisible={tooltip}
      input={input}
      sendMessage={sendMessage}
      readMessage={readMessage}
      check={check}
      checkBox={checkBox}
      setModalShow={setModalShow}
      setTooltip={setTooltip}
      isModalVisible={modalShow}
    />
  );
}

export default Wish;
