import React from 'react';

import { Input } from './Input';


export function TextAreaInput(props) {
    const {
        className, inputClassName, labelClassName,
        value, label, placeholder,
        onChange,
        isInvalid
    } = props;

    return (
        <Input
            className={className}
            controlId={"textareaInput"}
            labelClassName={labelClassName}
            label={label}
            placeholder={placeholder}
            elementType={"textarea"}
            
            inputClassName={inputClassName}
            value={value}
            onChange={onChange}
    
            isInvalid={!value && isInvalid}
            errorMsg={"Invalid Input"}
        />
    );
}