import { LiveModel } from "components/LiveModel";
import React from "react";

export function LiveExamples(props) {

  return (
    <div className={"home__live-examples container"}>
      {/* Header */}
      <div className={"live-examples__header text-center"}>
        <span>
          Contoh-Contoh <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* Sub-Header */}
      <div
        className={"live-examples__sub-header d-flex justify-content-center"}
      >
        <p className="live-example_text text-center">
          EkadTepi berusaha untuk menghasilkan kad jemputan yang lain daripada
          yang lain dan semestinya untuk memudahkan para pengguna.
        </p>
      </div>

      {/* Models */}
      <div
        className={
          "live-examples__models row d-flex align-items-center justify-content-around"
        }
      >
        {/* Left */}
        <div className={"models__left-side"}>
          {/* Title */}
          <div className={"left-side__title d-flex justify-content-center"}>
            <span className="majlis-1 text-center">Majlis Perkahwinan 1</span>
          </div>

          {/* Demo */}
          <div
            className={
              "left-side__demo d-flex align-items-center justify-content-center"
            }
          >
            <LiveModel
              url={
                "https://" + window?.location?.host + "/card/" + (process.env.REACT_APP_ENV=== "production"? "EpQyndaF2cRhp1gKkWFX":"PvocYc7syxQpX30OVp1a") 
              }
              isMobile
            />
          </div>
        </div>

        {/* Right */}
        <div className={"models__right-side"}>
          {/* Title */}
          <div className={"right-side__title d-flex justify-content-center"}>
            <span className="majlis-2 text-center">Majlis Perkahwinan 2</span>
          </div>

          {/* Demo */}
          <div
            className={
              "right-side__demo d-flex align-items-center justify-content-center"
            }
          >
            <LiveModel
              url={
                "https://" + window?.location?.host + "/card/" + (process.env.REACT_APP_ENV === "production"? "RaISJSOOrx8lGDqaGtdi":"LqOb8OEJjp6f93Dpcdjy")
              }
              isMobile
            />
          </div>
        </div>
      </div>

      {/* Instruction 
      <div className={"live-examples__instruction text-center"}>
        *Gunakan tetikus anda untuk gerakkan ekad ini.
      </div>*/}
    </div>
  );
}

