import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const personalInfoSlice = createSlice({
    name: 'personalInfo',
    initialState,
    reducers: {
        getpersonalInfoObject_requesting: (state) => {
            state.getpersonalInfoObject_requesting = true;
            state.getpersonalInfoObject_status = false;
            state.getpersonalInfoObject_errorMsg = undefined;
        },
        getpersonalInfoObject_success: (state, action) => {
            state.getpersonalInfoObject_requesting = false;
            state.getpersonalInfoObject_status = true;
            state.personalInfoObject = action.payload;
        },
          getpersonalInfoObject_failure: (state, action) => {
            state.getpersonalInfoObject_requesting = false;
            state.getpersonalInfoObject_errorMsg = action.payload;
        },
        resetpersonalInfoObject: (state) => initialState,
    },
})

export const {
    getpersonalInfoObject_requesting, 
    getpersonalInfoObject_success, 
    getpersonalInfoObject_failure,
    resetpersonalInfoObject 
} = personalInfoSlice.actions; 

export default personalInfoSlice.reducer;