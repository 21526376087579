import React from "react";
import { useCustomSelector } from "hooks";
import TentativeDisplay from "./TentativeDisplay";

function Tentative() {

  const { appInfo } = useCustomSelector("app");
  const tentatives = appInfo.tentative;
  const theme = appInfo.themes;

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1
  }

  const background1 = {
    background: theme.backgroundColor2,
    border:
      theme.border &&
      `solid 3px ${theme.contentColor1}`,
  };

  const timeStyle = {
    color: theme.tentativeColor1,
    fontFamily: theme.tentativeFontFamily1,
  };

  const activityStyle = {
    color: theme.tentativeColor2,
    fontFamily: theme.tentativeFontFamily2,
  };

  return (
    <TentativeDisplay
      bgOverlayStyle={background1}

      titleStyle={titleStyle}

      timeStyle={timeStyle}
      eventNameStyle={activityStyle}

      tentatives={tentatives}
    />
  );
}

export default Tentative;
