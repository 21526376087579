import React, { useState } from "react";
import ModalComp from "./ModalComp";

import CalendarModal from "./CalendarModal";
import { useCustomSelector } from "hooks";

import "assets/scss/components/ecard/rsvpSession.scss";
import { convertToDate, isDateValid } from "helper/validations/date";
import moment from "moment";

function RsvpSession({
  title,
  titleStyle,
  timeStyle,
  totalStyle,
  buttonStyle,

  isButtonDisabled,

  session,
  seat,
  id,
  start,
  end,
  total,
  unlimited,
  colId,
  cardId,
}) {
  const [attendance, setAttendance] = useState(); //number of seat left
  const [modalShow, setModalShow] = useState(false); //survey form
  const [modalShowForm, setModalShowForm] = useState(false); //rsvp form
  const [modalCalender, setModalCalender] = useState(false); //show add to calendar modal

  const { getbooking_requesting, booking } = useCustomSelector("booking");

  const show = (confirm) => {
    setAttendance(confirm);
    setModalShowForm(true);
  };

  const sendRsvp = (filled) => {
    setModalShowForm(false);

    //show calendar Modal after RSVP
    if (filled) {
      setModalCalender(true);
    }
  };

  return (
    <div className="rsvpsession">
      <div className="sesi m-auto">
        <div className="rsvptop__info">
          <div className="sesi__title">
            <div className="rsvp__sesi" style={titleStyle}>
              {title}
            </div>
            <div className="rsvp__sesiTime" style={timeStyle}>
              {isDateValid(start) && moment(convertToDate(start)).locale(navigator.language).format("hh:mm a ")}
              -
              {isDateValid(end) && moment(convertToDate(end)).locale(navigator.language).format(" hh:mm a")}
            </div>
          </div>

          {!unlimited && 
            <>
              <div className="rsvp-seat ">
                <div className="seat-empty" style={totalStyle}>
                  <div className="seat-empty-number">{seat}</div>
                  <div className="seat-empty-descp">
                    Bilangan<br></br>Kekosongan
                  </div>
                </div>

                <div className="seat-total" style={totalStyle}>
                  <div className="seat-total-number">{total}</div>
                  <div className="seat-total-descp">
                    Jumlah<br></br>Keseluruhan
                  </div>
                </div>
              </div>
            </>
          }
          
        </div>

        <div className="kehadiran">
          <button
            className="going"
            disabled={`${seat}` <= 0 || isButtonDisabled}
            onClick={() => {
              if (!isButtonDisabled) show(true)
            }}
            style={buttonStyle}
          >
            <div className="hadir">Hadir</div>
          </button>
        </div>
      </div>

      <ModalComp
        seat={seat}
        id={id}
        colId={colId}
        cardId={cardId}
        form={true}
        session={`Sesi ${session}`}
        start={start}
        end={end}
        attendance={attendance}
        unlimited={unlimited}
        show={modalShowForm}
        onHide={(filled) => sendRsvp(filled)}
      />

      {modalCalender && (
        <CalendarModal
          show={modalCalender}
          onHide={() => setModalCalender(false)}
        />
      )}
    </div>
  );
}

export default RsvpSession;
