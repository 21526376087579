import { getObjectPropertyValue, isStringEqual_caseInsensitive } from ".";
import { isArray, isArrayNotEmpty } from "./array";
import { isNotNullUndefined } from "./nullUndefined";
import { isNumberValid } from "./number";
import { isObjectNotEmpty } from "./object";
import { isStringTrimNotEmpty } from "./string";

export const validateUrl = {
    isUrlMatching,
    getQueries,
    concatenateQueriesFromObj,
    getReplacedPathQuery,
    getAddedPathQuery,
    getRemovedPathQuery,
}

export function isUrlMatching(props, expectedUrl) {
    return isStringEqual_caseInsensitive(props?.location?.pathname, expectedUrl);
}

export function getQueries(location) {
    let search = location?.search;
    
    if (isStringTrimNotEmpty(search)) {
        search = search.substring(1);

        let paramsArray = search.split("&");
        let obj = {};
        if (isArrayNotEmpty(paramsArray)) {
            paramsArray.map((param) => {
                let splittedArray = param.split("=");
                
                if (isArrayNotEmpty(splittedArray)) {
                    let paramName = splittedArray[0];
                    let paramValue = decodeURIComponent(splittedArray[1]);

                    // check if it has more than 1 value
                    if (paramValue.indexOf(",") != -1) {
                        paramValue = paramValue.split(",");
                    }
            
                    obj[paramName] = paramValue;
                }
            });
        
            return obj;
        }
    }
    return {};
}

export function concatenateQueriesFromObj(queryObj) {
    if (isObjectNotEmpty(queryObj)) {
        let result = "";
        for (const property in queryObj) {
            let value = queryObj[property];
            if (isNumberValid(value)) value += "";

            if (result != "") {
                result += "&";
            }

            if (isStringTrimNotEmpty(value)) {    
                result += property + "=" + encodeURIComponent(value);
            }
            else if (isArrayNotEmpty(value)) {
                let fullParam = "";
                value.map((name) => {
                    if (fullParam == "") {
                        fullParam = name;
                    }
                    else {
                        fullParam += "," + name;
                    }
                });
                fullParam = property + "=" + encodeURIComponent(fullParam);
                result += fullParam;
            }
        }

        return result;
    }
    return "";
}

export function getReplacedPathQuery(location, newParam_name, newParam_value) {
    let queryObj = getQueries(location);
    queryObj[newParam_name] = newParam_value;
    if (isStringTrimNotEmpty(location?.pathname)) {
        return location.pathname + "?" + concatenateQueriesFromObj(queryObj);
    }
    return "/";
}

export function getAddedPathQuery(location, newParam_name, newParam_value) {
    let queryObj = getQueries(location);

    if (queryObj[newParam_name]) {
        // Check if exists
        if (queryObj[newParam_name].indexOf(newParam_value) == -1) {
            if (isArray(queryObj[newParam_name])) {
                let newValue = "";
                queryObj[newParam_name].map((name, idx) => {
                    if (idx == 0) {
                        newValue = name;
                    }
                    else {
                        newValue += "," + name;
                    }
                });
                queryObj[newParam_name] = (newValue == "")? newParam_value: newValue + "," + newParam_value;
            }
            else {
                queryObj[newParam_name] = queryObj[newParam_name] + "," + newParam_value;
            }
        }
    }
    else queryObj[newParam_name] = newParam_value;

    if (isStringTrimNotEmpty(location?.pathname)) {
        return location.pathname + "?" + concatenateQueriesFromObj(queryObj);
    }
    return "/";
}

export function getRemovedPathQuery(location, newParam_name, newParam_value) {
    if (isNotNullUndefined(newParam_value)) {
        let newParam_value_stringified = newParam_value + "";
        let queryObj = getQueries(location);
        if (queryObj[newParam_name]) {
            // Check if exists
            if (queryObj[newParam_name].indexOf(newParam_value_stringified) != -1) {
                if (isArray(queryObj[newParam_name])) {
                    let removePos = queryObj[newParam_name].indexOf(newParam_value_stringified);
                    queryObj[newParam_name].splice(removePos, 1);

                    // Concate all names
                    let newValue = "";
                    queryObj[newParam_name].map((name) => {
                        if (newValue == "") newValue = name;
                        else newValue += "," + name;
                    });
                    queryObj[newParam_name] = newValue;
                }
                else {
                    // let removeStartPos = queryObj[newParam_name].indexOf(newParam_value_stringified);
                    // let removeEndPos = removeStartPos + newParam_value_stringified.length;
                    // queryObj[newParam_name] = queryObj[newParam_name].substring(0, removeStartPos + 1) + queryObj[newParam_name].substring(removeEndPos);
                    queryObj[newParam_name] = "";
                }
            }
        }
        else queryObj[newParam_name] = newParam_value_stringified;

        if (isStringTrimNotEmpty(location?.pathname)) {
            return location.pathname + "?" + concatenateQueriesFromObj(queryObj);
        }
    }
    return "/";
}