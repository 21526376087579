import React, { useState } from "react";
import DropdownMenu from "./DropdownMenu";
import { useCustomSelector } from "hooks";

import "assets/scss/components/ecard/customButton.scss";

function CustomButton({
  icon,
  input,
  sendMessage,
  readMessage,
  check,
  checkBox,
  setModalShow,
  setTooltip,
}) {
  const [open, setOpen] = useState(false);

  //fetch user rsvp status
  const { getbooking_requesting, booking } = useCustomSelector("booking");

  const { setappInfo_requesting, appInfo } = useCustomSelector("app");
  const theme = appInfo?.themes;

  const border1 = {
    // border: `solid ${primaryColor}`,
    border: `solid ${theme.primaryColor}`,
  };

  const iconStyle = {
    //backgroundColor1: theme.contentColor1,
    color: theme.contentColor1,
  };

  const openDropdown = () => {
    !booking && setModalShow(true);
    setOpen(!open);
    setTooltip(false);
  };

  return (
    <>
      <div className="custom__button" onClick={openDropdown} 
        // style={iconStyle}
        style={border1}
        >
        <i className="fas fa-plus-circle icons" style={iconStyle}></i>
      </div>

      {open && (
        <DropdownMenu
          input={input}
          sendMessage={sendMessage}
          readMessage={readMessage}
          check={check}
          checkBox={checkBox}
        ></DropdownMenu>
      )}
    </>
  );
}

export default CustomButton;
