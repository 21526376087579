import { EmailInput } from "components/formInputs/EmailInput";
import { Form } from "components/formInputs/Form";
import { PasswordInput } from "components/formInputs/PasswordInput";
import { Button } from "components/buttons/Button";
import React, { useState } from "react";
import {
  firestoreDB,
  getCurrentUser,
  updateUserEmail,
  updateUserPassword,
} from "dataflow/services";
import {
  getObjectPropertyValue,
  isEmailValid,
  isPasswordValid,
  isStringTrimNotEmpty,
} from "helper/validations";
import { useCustomSelector } from "hooks";
import { useDispatch } from "react-redux";
import { getpersonalInfoObject_success } from "dataflow/slices/personalInfo.slice";

import swal from "sweetalert";

function Security(props) {
  const { getpersonalInfoObject_requesting, personalInfoObject } =
    useCustomSelector("personalInfo");

  const dispatch = useDispatch();

  //get curent user id from firebase auth
  let userId = getObjectPropertyValue(getCurrentUser(), "uid", "");

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordVerification, setPasswordVerification] = useState("");

  const [isUpdateAttempted, setIsUpdateAttempted] = useState();
  const [isSubmissionAttempted, setIsSubmissionAttempted] = useState(false);

  const updatePasswword = () => {
    setIsSubmissionAttempted(true);
    if (
      isStringTrimNotEmpty(newPassword) &&
      isStringTrimNotEmpty(oldPassword) && isPasswordValid(newPassword)
    ) {
      updateUserPassword(oldPassword, newPassword)
        .then((status) => {
          swal({
            title: "Kata Laluan Telah Dikemas Kini",
            icon: "success",
            button: false,
            timer: 2000,
            className: "password-update__success",
          });

          setIsSubmissionAttempted(false);
          setOldPassword("");
          setNewPassword("");
        })
        .catch((error) => {
          swal({
            title: "Gagal Mengemaskini Kata Laluan",
            icon: "error",
            button: false,
            timer: 2000,
            className: "password-update__failed",
          });
        });
    }
  };

  const updateEmail = () => {
    setIsUpdateAttempted(true);
    if (isEmailValid(email) && isStringTrimNotEmpty(passwordVerification)) {

      updateUserEmail(passwordVerification, email)
        .then((status) => {
          setIsUpdateAttempted(false);
          setEmail("");
          setPasswordVerification("");
          console.log(status)
          swal({
            title: "Emel Telah Dikemas Kini",
            icon: "success",
            button: false,
            timer: 2000,
            className: "email-update__success",
          });

          //update save data to userInfo database
          let userInfoRef = firestoreDB().collection("users").doc(userId);

          userInfoRef
            .update({ email: email })
            .then(() => {
              dispatch(
                getpersonalInfoObject_success({
                  ...personalInfoObject,
                  email: email,
                })
              );
            })
            .catch((error) => {
              // The document probably doesn't exist.
              swal({
                title: "Gagal Mengemaskini Emel",
                icon: "error",
                button: false,
                timer: 2000,
                className: "email-update__failed",
              });

              console.error("Error updating document: ", error);
            });
        })
        .catch((error) => {
          // The document probably doesn't exist.
          swal({
            title: "Gagal Mengemaskini Emel",
            icon: "error",
            button: false,
            timer: 2000,
            className: "email-update__failed",
          });

          console.error("Error updating document: ", error);
        });

    }
  };

  return (
    <div className="display__security-info">
      <Form>
        {/* Tukar password */}
        <div className="security__change-password">
          <div className="security__change-password-title d-flex justify-content-center">
            Kata Laluan
          </div>
          {/* Old Password Input */}
          <PasswordInput
            className={
              "security__change-password-old d-flex flex-column justify-content-center"
            }
            customLabel={"Kata Laluan Semasa:"}
            value={oldPassword}
            onChange={setOldPassword}
            isLogin={false}
            isSubmissionAttempted={isSubmissionAttempted}
          />

          {/* New Password Input*/}
          <PasswordInput
            className={
              "security__change-password-new d-flex flex-column justify-content-center"
            }
            customLabel={"Kata Laluan Baru:"}
            value={newPassword}
            onChange={setNewPassword}
            isLogin={false}
            isSubmissionAttempted={isSubmissionAttempted}
          />

          {/* Save */}
          <div className={"w-100 d-flex justify-content-end"}>
            <Button
              className={"security__change-password-save"}
              content={"Simpan"}
              decoration_isBrightPrimary
              onClick={updatePasswword}
            />
          </div>
        </div>
      </Form>

      <Form>
        {/* Tukar email */}
        <div className="security__change-email">
          <div className="security__change-email-title d-flex justify-content-center">
            Email
          </div>
          {/* New email Input*/}
          <EmailInput
            className={
              "security__change-email-new d-flex flex-column justify-content-center"
            }
            labelClassName={"email__label"}
            label={"Alamat Emel Baru:"}
            inputClassName={"email__input"}
            value={email}
            onChange={setEmail}
            isSubmissionAttempted={isUpdateAttempted}
          />

          {/* Password Verification */}
          <PasswordInput
            className={
              "security__change-email-verification d-flex flex-column justify-content-center"
            }
            customLabel={"Kata Laluan Semasa:"}
            value={passwordVerification}
            onChange={setPasswordVerification}
            isLogin={false}
            isSubmissionAttempted={isUpdateAttempted}
          />

          {/* Save*/}
          <div className={"w-100 d-flex justify-content-end"}>
            <Button
              className={"security__change-email-save"}
              content={"Simpan"}
              onClick={updateEmail}
              decoration_isBrightPrimary
            />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Security;
