import React, {useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "components/buttons/Button";

import { isUserLoggedIn } from "helper/validations";
import { UserAuthenticationModal } from "components/modals/UserAuthenticationModal";
import { useDispatch } from "react-redux";
import { useCustomSelector } from "hooks";
import { setIsUserWebsiteLoggedIn } from "dataflow/slices/website.slice";

function Fail(props) {

  let history = useHistory();
  const dispatch = useDispatch();

  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const [isAuthLogin, setIsAuthLogin] = useState(true);

  const { isUserWebsiteLogin } = useCustomSelector("website");

  const handleClick = () => {
    isUserWebsiteLogin ? history.push({pathname: "/akaun-saya", state: {selection: "card"}}) : setIsAuthModalVisible(true);
  };

  return (
    <div className="fail">
      {/* Login/Sign Up Modals */}
      <UserAuthenticationModal
        isVisible={isAuthModalVisible}
        isLogin={isAuthLogin}
        setIsLogin={setIsAuthLogin}
        onHide={() => {
          setIsAuthModalVisible(false);
        }}
        onLoginSuccessful={() => {
          dispatch(setIsUserWebsiteLoggedIn(isUserLoggedIn()))
        }}
      />

      <div
        className={
          "fail__body d-flex flex-column align-items-center justify-content-space-evenly"
        }
      >
        <div className="fail-icons  d-flex flex-column align-items-center justify-content-center">
          <i className={"far fa-times-circle fail__body-icon icons"}></i>
          <h3 className={"fail__body-headers text-center"}>
            Pembayaran Tidak Berjaya!
          </h3>
        </div>

        <div className="fail-details">
          <div
            className={
              "fail__body-content  d-flex flex-column align-items-center justify-content-center"
            }
          >
            <div className={"fail__body-content-1 text-left"}>
              <div className="p-title">Hal ini mungkin disebabkan:</div>
              <div className="p-des">- Jumlah akaun yang tidak mencukupi</div>
              <div className="p-des">- Salah maklumat bank</div>
              <div className="p-des">- Bank menolak pembayaran</div>
            </div>

            {/* <div className={"fail__body-content-2 text-center"}>
              <p>Order Total: RM70.00</p>
              <p>Payment Method: Online Banking</p>
              <p>Email: support@ekadtepi.com</p>
            </div> */}
          </div>
        </div>

        {/* Go to account button */}
        <div className={"w-100 d-flex justify-content-center"}>
          <Button
            className={"receipt__account-button"}
            content={"Kembali ke akaun"}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

export default Fail;
