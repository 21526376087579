import useLoadFonts from "hooks/useLoadFonts";
import useLoadMusic from "hooks/useLoadMusic";
import React from "react";
import useCompleteStep from "../steps/useCompleteStep";
import useContactStep from "../steps/useContactStep";
import useContentStep from "../steps/useContentStep";
import useCoverStep from "../steps/useCoverStep";
import useEventStep from "../steps/useEventStep";
import useGalleryStep from "../steps/useGalleryStep";
import useLocationStep from "../steps/useLocationStep";
import usePresentStep from "../steps/usePresentStep";
import useRSVPStep from "../steps/useRSVPStep";
import useTentativeStep from "../steps/useTentativeStep";
import useWishStep from "../steps/useWishStep";

export default function useStepContent(props) {
  const { currentStep } = props;

  const fontsProps = useLoadFonts();
  const musicProps = useLoadMusic();

  const { input_coverStep, preview_coverStep } = useCoverStep({
    ...props,
    ...fontsProps,
    ...musicProps,
  });

  const { input_contentStep, preview_contentStep } = useContentStep({
    ...props,
    ...fontsProps,
  });

  const { input_contactStep, preview_contactStep } = useContactStep({
    ...props,
    ...fontsProps,
  });

  const { input_eventDetailsStep, preview_eventDetailsStep } = useEventStep({
    ...props,
    ...fontsProps,
  });

  const { input_tentativeStep, preview_tentativeStep } = useTentativeStep({
    ...props,
    ...fontsProps,
  });

  const { input_locationStep, preview_locationStep } = useLocationStep({
    ...props,
    ...fontsProps,
  });

  const { input_rsvpStep, preview_rsvpStep } = useRSVPStep({
    ...props,
    ...fontsProps,
  });

  const { input_wishStep, preview_wishStep } = useWishStep({
    ...props,
    ...fontsProps,
  });

  const { input_galleryStep, preview_galleryStep } = useGalleryStep({
    ...props,
    ...fontsProps,
  });

  const { input_presentStep, preview_presentStep } = usePresentStep({
    ...props,
    ...fontsProps,
  });

  const { preview: preview_complete, actions: actions_complete } =
    useCompleteStep({ ...props, ...fontsProps, ...musicProps });

  let inputs, preview;
  switch (currentStep) {
    case "cover":
      inputs = input_coverStep;
      preview = preview_coverStep;
      break;
    case "content":
      inputs = input_contentStep;
      preview = preview_contentStep;
      break;
    case "contact":
      inputs = input_contactStep;
      preview = preview_contactStep;
      break;
    case "eventDetails":
      inputs = input_eventDetailsStep;
      preview = preview_eventDetailsStep;
      break;
    case "tentative":
      inputs = input_tentativeStep;
      preview = preview_tentativeStep;
      break;
    case "location":
      inputs = input_locationStep;
      preview = preview_locationStep;
      break;
    case "rsvp":
      inputs = input_rsvpStep;
      preview = preview_rsvpStep;
      break;
    case "wish":
      inputs = input_wishStep;
      preview = preview_wishStep;
      break;
    case "gallery":
      inputs = input_galleryStep;
      preview = preview_galleryStep;
      break;
    case "present":
      inputs = input_presentStep;
      preview = preview_presentStep;
      break;
    case "complete":
      inputs = preview_complete;
      preview = actions_complete;
      break;
  }

  return {
    inputs,
    preview,
  };
}
