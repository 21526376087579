import React from 'react';

import { Input } from './Input';

import { isEmailInvalid } from "helper/validations"; 



export function EmailInput(props) {
    const {
        className, inputClassName, labelClassName,
        value, label,
        onChange,
        isSubmissionAttempted
    } = props;

    console.log(isEmailInvalid(value))

    return (
        <Input
            className={className}
            controlId={"emailInput"}
            labelClassName={labelClassName}
            label={label? label:"Email Address"}
            placeholder={"Ex: johndoe@gmail.com"}
            inputType={"email"}
            
            inputClassName={inputClassName}
            value={value}
            onChange={onChange}
    
            isInvalid={isEmailInvalid(value) && isSubmissionAttempted}
            errorMsg={"Invalid Email"}
        />
    );
}