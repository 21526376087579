import React, { useState } from "react";
import "assets/scss/modules/forgotPassword/forgotPassword.scss";
import { promiseInstance as Axios } from "dataflow/services/config";

import { Form } from "components/formInputs/Form";
import { EmailInput } from "components/formInputs/EmailInput";
import { Button } from "components/buttons/Button";

import { isEmailValid } from "helper/validations";

import swal from "sweetalert";
import { endpointUrls } from "helper/constants/endpointUrls";

function ForgotPassword(props) {

  const [email, setEmail] = useState();
  const [isSubmissionAttempted, setIsSubmissionAttempted] = useState();

  const onSubmit = () => {
    setIsSubmissionAttempted(true);

    if (isEmailValid(email)) {
      let body = { email };

      swal({
        title: "Penghantaran Email Dalam Proses",
        icon: "info",
        button: false,
        timer: 2000,
        className: "forgot-email__start"
      });

      Axios.post(endpointUrls.resetPassword, body)
        .then((response) => {
          swal.close()
          swal({
            title: "Berjaya Dihantar!",
            icon: "success",
            text: "Sila semak emel anda untuk tukar kata laluan yang baru.",
            button: false,
            timer: 2000,
            className: "forgot-email__success"
          });

          console.log(response.data);
          setEmail();
          setIsSubmissionAttempted(false);
        })
        .catch((error) => {

          swal({
            title: "Gagal Menghantar Emel!",
            icon: "error",
            button: false,
            timer: 2000,
            className: "forgot-email__failed"
          });

          console.log(error.message);
          setEmail();
          setIsSubmissionAttempted(false);
        });

    }
  };

  return (
    <div
      className={
        "forgot-password container d-flex flex-column align-items-center justify-content-center"
      }
    >
      {/* Page Title */}
      <div className={"forgot-password__page-title text-center"}>
        Reset Kata Laluan
      </div>

      <div className="forgot-password__note text-center">
        <p>- Sila masukkan emel akaun EkadTepi anda -</p>
      </div>

      <Form>
        {/* Email */}
        <EmailInput
          className={"forgot-password__email d-flex align-items-center"}
          labelClassName={"email__label"}
          label={"Emel:"}
          inputClassName={"email__input"}
          value={email}
          onChange={setEmail}
          isSubmissionAttempted={isSubmissionAttempted}
        />

        {/* Submit */}
        <div
          className={"forgot-password__button w-100 d-flex justify-content-end"}
        >
          <Button
            className={"forgot-password__submit"}
            content={"Hantar"}
            onClick={onSubmit}
            decoration_isBrightPrimary
          />
        </div>
      </Form>

      {/* <p>{status}</p> */}
    </div>
  );
}

export default ForgotPassword;
