import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCustomSelector } from "hooks";
import ModalComp from "components/ecard/ModalComp";
import { setcameraMedia_success } from "dataflow/slices/camera.slice";

function Camera() {
  const cameraRef = useRef(null);
  let history = useHistory();
  const dispatch = useDispatch();

  const { appInfo } = useCustomSelector("app");
  const rootURL = appInfo.rootURL;

  //fetch user rsvp status
  const { booking } = useCustomSelector("booking");

  const [modalShow, setModalShow] = useState(false);
  const [cameraMode, setCameraMode] = useState("user");
  const [mirror, setMirror] = useState(true);

  const videoConstraints = {
    width: 1080,
    height: 1080,
    facingMode: cameraMode,
  };

  let width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  let height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const capture = useCallback(() => {
    const imageSrc = cameraRef.current.getScreenshot();
    dispatch(setcameraMedia_success(imageSrc));
    history.push(`${rootURL}/preview`);
  }, [cameraRef]);

  const switchCamera = () => {
    if (cameraMode == "user") {
      setCameraMode("environment");
      setMirror(false);
    } else {
      setCameraMode("user");
      setMirror(true);
    }
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];
    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onloadend = () => {
      dispatch(setcameraMedia_success(reader.result));
      history.push(`${rootURL}/preview`);
    };
  };

  return (
    <div className="camera">
      <Webcam
        className="camera__webcam"
        audio={false}
        height={1080}
        width={1080}
        screenshotFormat="image/jpeg"
        ref={cameraRef}
        forceScreenshotSourceSize
        videoConstraints={videoConstraints}
        mirrored={mirror}
      />
      <div className="camera__header">
        <i
          className="fas fa-times close__icon"
          onClick={() => history.replace(`${rootURL}/gallery`)}
        ></i>

        <div>
          <label htmlFor="upload-button">
            <i className="fas fa-image"></i>
          </label>
          <input
            type="file"
            id="upload-button"
            name="img"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="camera__footer">
        <i className="far fa-circle capture__icon" onClick={capture}></i>
        <i className="fas fa-sync switch__camera" onClick={switchCamera}></i>
      </div>

      <ModalComp
        text={"Sila isikan maklumat anda:"}
        survey={false}
        show={!booking ? true : modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Camera;
