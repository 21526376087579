import React from "react";

export function WhyUs(props) {
  return (
    <div className={"home__why-us container"}>
      {/* Header */}
      <div className={"why-us__header text-center"}>
        <span>
          Mengapa <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* Sub-Header */}
      <div className={"why-us__sub-header d-flex justify-content-center"}>
        <p className="why-us_text text-center">
          Objektif utama EkadTepi dibangunkan adalah untuk memudahkan urusan dan
          perjalanan majlis anda.
        </p>
      </div>

      {/* Details */}
      <div className={"why-us__details row no-gutters"}>
        {/* RSVP */}
        <div className={"details__rsvp col-lg-4 col-sm-12"}>
          <div
            className={
              "rsvp__wrapper general-box d-flex flex-column align-items-center justify-content-center text-center"
            }
          >
            {/* Header */}
            <div className={"rsvp__header"}>
              <div className={"header__top"}>Fungsi RSVP</div>
              <div className={"header__bottom"}>Kehadiran Tetamu</div>
            </div>

            {/* Description */}
            <div className={"rsvp__description why_us-description"}>
              Dengan fungsi RSVP, anda dapat mengetahui jumlah keseluruhan
              tetamu yang akan hadir pada majlis istimewa anda.
            </div>
          </div>
        </div>

        <div className={"details__section-wrapper col-lg-8 col-sm-12"}>
          <div className={"row no-gutters"}>
            {/* SOP */}
            <div className={"section-wrapper__sop col-6"}>
              <div
                className={
                  "sop__wrapper general-box  d-flex flex-column align-items-center justify-content-center text-center"
                }
              >
                {/* Header */}
                <div className={"sop__header"}>
                  <div className={"header__top text-right"}>Patuh S.O.P</div>
                  <div className={"header__bottom"}>Kita Jaga Kita</div>
                </div>

                {/* Description */}
                <div className={"sop__description why_us-description"}>
                  Tetapkan sesi untuk kehadiran tetamu dan mengehadkan jumlah
                  tetamu mengikut sesi yang ditetapkan.
                </div>
              </div>
            </div>

            {/* Create Your Own */}
            <div className={"section-wrapper__create-your-own col-6"}>
              <div
                className={
                  "create-your-own__wrapper general-box  d-flex flex-column align-items-center justify-content-center text-center"
                }
              >
                {/* Header */}
                <div className={"create-your-own__header"}>
                  <div className={"header__top"}>Buat Sendiri?</div>
                  <div className={"header__bottom"}>
                    Mengikut Kreativiti Anda
                  </div>
                </div>

                {/* Description */}
                <div
                  className={"create-your-own__description why_us-description"}
                >
                  Anda boleh mengubah semua tulisan pada EkadTepi mengikut font
                  dan warna kesukaan anda.
                </div>
              </div>
            </div>
          </div>

          {/* Easy for User */}
          <div className={"section-wrapper__easy-for-user"}>
            <div
              className={
                "easy-for-user__wrapper general-box d-flex flex-column align-items-center justify-content-center  text-center"
              }
            >
              {/* Header */}
              <div className={"easy-for-user__header"}>
                <div className={"header__top"}>Memudahkan Anda </div>
                <div className={"header__bottom "}>Menghantar Jemputan</div>
              </div>

              {/* Description */}
              <div className={"easy-for-user__description why_us-description"}>
                Dengan menggunakan link URL, anda dapat menghantar kad jemputan
                kepada semua tetamu dengan lebih mudah dan pantas!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
