import React from "react";

import {
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { useCustomSelector } from "hooks";
import ContentDisplay from "./ContentDisplay";
import CloseButton from "components/ecard/CloseButton";

function Content({ setIsMenuOpen }) {
  const { appInfo } = useCustomSelector("app");
  const timestamp = appInfo.timestamp;
  const theme = appInfo.themes;

  let history = useHistory();
  let { path, url } = useRouteMatch();

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1,
  };

  const fontStyle1 = {
    color: theme.contentColor4,
    fontFamily: theme.contentFontFamily3,
  };

  const iconStyle1 = {
    color: theme.contentColor5,
  };

  const background1 = {
    background: theme.backgroundColor2,
    border:
      theme.border &&
      `solid 3px ${theme.contentColor1}`,
  };

  const timerStyle = {
    color: theme.contentColor2,
    fontFamily: theme.contentFontFamily2,
  };

  return (
    <>
      <CloseButton setIsMenuOpen={setIsMenuOpen}/>
      <ContentDisplay
        bgOverlayStyle={background1}

        titleStyle={titleStyle}

        countdownTextStyle={timerStyle}
        countdownBorderColor={theme?.contentColor3}
        timer={timestamp}

        iconStyle={iconStyle1}
        onIconClick={(pageType) => history.push(`${url}/` + pageType)}
        iconTextStyle={fontStyle1}
      />
    </> 
  );
}

export default Content;
