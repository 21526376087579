import React, { useEffect, useState } from "react";
import default_background from "assets/images/background.png";

export default function useHandleValues(props) {
  // App
  const [app_details, setApp_details] = useState();
  // Cover Page
  const [cover_details, setCover_details] = useState();
  const [cover_styles, setCover_styles] = useState({
    eventNameFont: "initial",
    eventNameFontColor: "initial",
    bridegroomNameFont: "initial",
    bridegroomNameFontColor: "initial",
    andSymbolFont: "initial",
    andSymbolFontColor: "initial",
    brideNameFont: "initial",
    brideNameFontColor: "initial",
    dateFont: "initial",
    dateFontColor: "initial",
    homeBgOpacity: "initial",
  });

  // Content Page
  const [content_pageTitleDetails, setContent_pageTitleDetails] = useState({
    font: "initial",
    fontColor: "initial",
  });
  const [content_countdownDetails, setContent_countdownDetails] = useState({
    font: "initial",
    fontColor: "initial",
    circleColor: "initial",
  });
  const [content_textIconDetails, setContent_textIconDetails] = useState({
    font: "initial",
    fontColor: "initial",
    iconColor: "initial",
  });

  // Contact Page
  const [contact_contactInfoDetails, setContact_contactInfoDetails] =
    useState();
  const [contact_contactInfoDetailsFull, setContact_contactInfoDetailsFull] =
    useState();
  const [contact_textDetails, setContact_textDetails] = useState({
    font: "initial",
    fontColor: "initial",
  });
  const [contact_iconBorderDetails, setContact_iconBorderDetails] = useState({
    borderColor: "initial",
  });

  // Event Page
  const [event_details, setEvent_details] = useState();
  const [event_textDetails, setEvent_textDetails] = useState({
    celebratorFont: "initial",
    celebratorFontColor: "initial",
    coupleFont: "initial",
    coupleFontColor: "initial",
    dateFont: "initial",
    dateFontColor: "initial",
    locationFont: "initial",
    locationFontColor: "initial",
    otherTextFont: "initial",
    otherTextFontColor: "initial",
    prayerFont: "initial",
    prayerFontColor: "initial",
    detailsBgOpacity: "initial",
  });

  // Tentative Page
  const [tentative_tentativeDetailsList, setTentative_tentativeDetailsList] =
    useState([{}]);
  const [tentative_textDetails, setTentative_textDetails] = useState({
    timeFont: "initial",
    timeColorFont: "initial",
    activityFont: "initial",
    activityFontColor: "initial",
  });
  const [tentative_borderBgDetails, setTentative_borderBgDetails] = useState({
    bgColor: "initial",
    borderColor: "initial",
  });

  // Location Page
  const [location_locationDetails, setLocation_locationDetails] = useState();
  const [location_iconDetails, setLocation_iconDetails] = useState({
    iconColor: "initial",
    fontIcon: "initial",
    iconFontColor: "initial",
  });
  const [location_borderDetails, setLocation_borderDetails] = useState({
    color: "initial",
  });

  // RSVP Page
  const [rsvp_rsvpDetailsList, setRsvp_rsvpDetailsList] = useState();
  const [rsvp_textDetails, setRsvp_textDetails] = useState({
    titleFont: "initial",
    titleColor: "initial",
    timeFont: "initial",
    timeColor: "initial",
    totalFont: "initial",
    totalColor: "initial",
  });

  return {
    app_details,
    setApp_details,
    cover_details,
    setCover_details,
    cover_styles,
    setCover_styles,

    content_pageTitleDetails,
    setContent_pageTitleDetails,
    content_countdownDetails,
    setContent_countdownDetails,
    content_textIconDetails,
    setContent_textIconDetails,

    contact_contactInfoDetails,
    setContact_contactInfoDetails,
    contact_contactInfoDetailsFull,
    setContact_contactInfoDetailsFull,
    contact_textDetails,
    setContact_textDetails,
    contact_iconBorderDetails,
    setContact_iconBorderDetails,

    event_details,
    setEvent_details,
    event_textDetails,
    setEvent_textDetails,

    tentative_tentativeDetailsList,
    setTentative_tentativeDetailsList,
    tentative_textDetails,
    setTentative_textDetails,
    tentative_borderBgDetails,
    setTentative_borderBgDetails,

    location_locationDetails,
    setLocation_locationDetails,
    location_iconDetails,
    setLocation_iconDetails,
    location_borderDetails,
    setLocation_borderDetails,

    rsvp_rsvpDetailsList,
    setRsvp_rsvpDetailsList,
    rsvp_textDetails,
    setRsvp_textDetails,
  };
}
