import {
  getcardDesignPreviewList_requesting,
  getcardDesignPreviewList_success,
  getcardDesignPreviewList_failure,
} from "dataflow/slices/home.slice";

import { useRequest } from "./config";
import { endpointUrls } from "helper/constants/endpointUrls";

export default function useHomeServices() {
  const { get } = useRequest();

  function getcardDesignPreviewList() {
    get({
      url: endpointUrls.getCardDesignPreviewList,
      requesting: getcardDesignPreviewList_requesting,
      success: getcardDesignPreviewList_success,
      failure: getcardDesignPreviewList_failure,
    });
  }

  return {
    getcardDesignPreviewList,
  };
}
