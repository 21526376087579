import React from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import Home from "modules/home/Home";
import AboutUs from "modules/aboutUs/AboutUs";
import Footer from "modules/footer/Footer";
import CardCatalog from "modules/cardCatalog/CardCatalog";
import AccountDetails from "modules/accountDetails/AccountDetails";
import ForgotPassword from "modules/forgotPassword/ForgotPassword";
import ECard from "modules/ECard/ECard";
import UpdateECard from "modules/updateECard/UpdateECard";
import TopNavigationBar from "modules/navigations/TopNavigationBar";
import Receipt from "modules/receipt/Receipt";
import Payment from "modules/payment/Payment";
import Loading from "modules/payment/Loading";

import "assets/scss/app.scss";



function App() {
  return (
    <div className={"app"}>
      <Router history={createBrowserHistory()}>
        {/* Navigation */}
        <Switch>
          <Route path={"/loading"}/>

          <Route path={"/card/:id"}/>
          <Route path={"/user/card/:id"}/>
           
          <Route
            path={"/"}
            render={(props) => <TopNavigationBar {...props} isPrimary />}
          />

        </Switch>

        {/* Pages */}
        <Switch>

          <Route
            path={"/reset-kata-laluan"}
            render={(props) => <ForgotPassword {...props} />}
          />

          <Route path={"/utama"} render={(props) => <Home {...props} />} />

          <Route path={"/loading"} render={(props) => <Loading {...props}/>} />

          <Route path={"/pembayaran/:id"} render={(props) => <Payment {...props}/>} />

          <Route
            path={"/contoh-tema"}
            render={(props) => <CardCatalog {...props} />}
          />
          <Route
            path={"/hubungi-kami"}
            render={(props) => <AboutUs {...props} />}
          />
          <Route
            path={"/akaun-saya"}
            render={(props) => <AccountDetails {...props} />}
          />
          <Route path={"/cart"} render={(props) => <AboutUs {...props} />} />
          <Route
            path={"/ubahsuai-ekadtepi/:orderId/:cardId"}
            render={(props) => <UpdateECard {...props} />}
          />

          <Route path={"/resit"} render={(props) => <Receipt {...props} />} />

          <Route path={"/card/:id"} render={(props) => <ECard {...props}/>}/> 
          <Route path={"/user/card/:id"} render={(props) => <Redirect to={`/card/${props.match.params.id}`} />}/>

          <Redirect to={"/utama"} />
        </Switch>

        {/* Footer */}
        <Switch>
          <Route path={"/loading"}/>

          <Route path={"/card/:id"}/>
          <Route path={"/user/card/:id"} />
          
          <Route path={"/"} render={(props) => <Footer {...props} />} />

        </Switch>
      </Router>
    </div>
  );
}
export default App;
