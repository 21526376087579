import React from "react";
import "assets/scss/modules/aboutUs/aboutUs.scss";
import SendEmail from "./sections/SendEmail";
import FAQ from "./sections/FAQ";

function AboutUs(props) {
  return (
    <div className={"about-us container d-flex flex-column align-items-center"}>
      {/* Page Title */}
      <div className={"about-us__page-title text-center"}>
        <span>
          Hubungi <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      <SendEmail />

      <div className={"about-us__horizontal-divider"} />

      <FAQ />
    </div>
  );
}

export default AboutUs;
