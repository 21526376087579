import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import * as firebaseui from "firebaseui";
import { getObjectPropertyValue, isFunction, isNotNullUndefined, isUserLoggedIn, setCookie } from 'helper/validations';
import store from 'dataflow/slices';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, 
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID, 
    appId: process.env.REACT_APP_FIREBASE_APP_ID, 
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const initFirebase = () => firebase.initializeApp(firebaseConfig);

export const firestoreDB = () => firebase.firestore();

export const storage = () => firebase.storage();

export function initFirebaseUI(pathname) {
    // Initialize the FirebaseUI Widget using Firebase.
    let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());

    var uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: async function(authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                // Set cookies
                let userInfo = getObjectPropertyValue(authResult, "user");
                let tokenResult = await userInfo.getIdTokenResult()
                let signInProvider = getObjectPropertyValue(tokenResult, "signInProvider");

                let userId = getObjectPropertyValue(userInfo, "uid");
                setCookie("uid", userId, { path: "/" });
                setCookie("provider", signInProvider, { path: "/" });
                // let name = getObjectPropertyValue(userInfo, "displayName");
                // let email = getObjectPropertyValue(userInfo, "email");
                // let isEmailVerified = getObjectPropertyValue(userInfo, "emailVerified");
                // let phoneNumber = getObjectPropertyValue(userInfo, "phoneNumber");
                // let profilePic = getObjectPropertyValue(userInfo, "photoURL");
                
                // let additionalUserInfo = getObjectPropertyValue(authResult, "additionalUserInfo");
                // let isNewUser = getObjectPropertyValue(additionalUserInfo, "isNewUser");

                store.dispatch({
                    type: 'website/setIsProviderWebsiteLoggedIn',
                    payload: isUserLoggedIn()
                })
               
                return true;
            },
            uiShown: function() {
                // The widget is rendered.
                // Hide the loader.
                document.getElementById('loader').style.display = 'none';
            }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: pathname,
        signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.GithubAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID
        ],
        // Terms of service url.
        tosUrl: '<your-tos-url>',
        // Privacy policy url.
        privacyPolicyUrl: '<your-privacy-policy-url>'
    };

    // The start method will wait until the DOM is loaded.
    ui.start('#firebaseui-auth-container', uiConfig);
}

export function handleAuthStateChange(setIsUserAuthenticated) {
    firebase.auth().onAuthStateChanged(function(user) {
        if (isFunction(setIsUserAuthenticated)) {
            if (user) setIsUserAuthenticated(true); // User is signed in.
            else setIsUserAuthenticated(false); // User is signed out.
        }
    });
}

export function handleUserSignUp(email, password, onSuccess, onFailure) {
    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((user) => {
        // email verification handle from backend. Hence disable this.
        // emailVerificationEmail(user);
        if (isFunction(onSuccess)) onSuccess(user);
    })
    .catch(function(error) {
        // Handle Errors here.
        if (isFunction(onFailure)) onFailure(error);
    });
}

export function handleUserSignIn(email, password, onSuccess, onFailure) {
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then((user) => {
        // Signed in 
        if (isFunction(onSuccess)) onSuccess(user);
    })
    .catch(function(error) {
        // Handle Errors here.
        if (isFunction(onFailure)) onFailure(error);
    });
}

export function getCurrentUser(){
    return firebase.auth().currentUser;
}

export function handleUserSignOut(){
    let user = getCurrentUser()

    if (isNotNullUndefined(user)) {
            firebase.auth().signOut()
            .then(() => {
                console.log('Signed Out');
            })
            .catch((error) => {
               console.error('Sign Out Error');
            });
    }
    
    return true;
}

export function updateUserPassword(oldPassword, newPassword){

    return new Promise(function(resolve, reject){
        reauthenticateUser(oldPassword)
            .then((userCredential) => {
                let user = userCredential.user;
                user.updatePassword(newPassword)
                    .then(() => {
                        console.log("succesfully update password")
                        resolve();
                    }).catch((error) => {
                        console.log("failed to update password")
                        reject(error);
                    });
            })
            .catch((error) => {
                console.log("failed to reauth user")
                reject(error);
            })
    })
}

export function updateUserEmail(oldPassword, newEmail){

    return new Promise(function(resolve, reject){

        reauthenticateUser(oldPassword)
            .then((userCredential) => {
                let user = userCredential.user;
                user.updateEmail(newEmail)
                    .then(() => {
                        console.log("succesfully update email")
                        resolve();
                    }).catch((error) => {
                        console.log("failed to update email")
                        reject(error);
                    });
            })
            .catch((error) => {
                console.log("failed to reauth user 1")
                reject(error);
            })
    })
}

export function reauthenticateUser(password){
    const user = getCurrentUser();

    //only for sign Up with email. Need to setup for other providers too (Google, FB, Twitter)
    const credential = firebase.auth.EmailAuthProvider.credential(
        user.email, 
        password
    );

    return user.reauthenticateWithCredential(credential)
}

export function passwordResetEmail(email){
    return firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
            // Password reset email sent!
            // ..
            return("E-mel tetapan semula kata laluan berjaya dihantar.");
        })
        .catch((error) => {
            // var errorCode = error.code;
            // var errorMessage = error.message;
            return("Tidak ada rekod pengguna. Pengguna mungkin telah dipadamkan.");
        });
}

//deprecated: use backend
export function emailVerificationEmail(userCredential){

    //get the user object and send email
    let user = userCredential.user;
    user.sendEmailVerification(); 
}

export function deleteStorageFile(eventId, fileName){
     // Create a reference to the file to delete
     let storageRef = storage().ref();
     var deleteRef = storageRef.child(`albums/${eventId}/${fileName}.jpg`);

     // Delete the file
     deleteRef
       .delete()
       .then(() => {
         // File deleted successfully
         console.log("File successfully deleted from storage!");
       })
       .catch((error) => {
         // Uh-oh, an error occurred!
         console.log("Failed to delete from storage!");
       });
}

export async function refreshIdToken(){
    let user = getCurrentUser()
    if(isNotNullUndefined(user)){
        let tokenResult = await user.getIdTokenResult()
        let idToken = getObjectPropertyValue(tokenResult, "token");
        return idToken;
    }else{

    }
    return "";
}


