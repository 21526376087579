export const fonts = [
  // {
  //     "label": "ABeeZee",
  //     "value": "ABeeZee",
  //     "url": "https://fonts.googleapis.com/css2?family=ABeeZee",
  //     "style": {
  //         "fontFamily": "ABeeZee"
  //     }
  // },
  //   {
  //     label: "Abel",
  //     value: "Abel",
  //     url: "https://fonts.googleapis.com/css2?family=Abel",
  //     style: {
  //       fontFamily: "Abel",
  //     },
  //   },
  //   {
  //     label: "Abhaya Libre",
  //     value: "Abhaya Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Abhaya Libre",
  //     style: {
  //       fontFamily: "Abhaya Libre",
  //     },
  //   },
  {
    label: "Abril Fatface",
    value: "Abril Fatface",
    url: "https://fonts.googleapis.com/css2?family=Abril Fatface",
    style: {
      fontFamily: "Abril Fatface",
    },
  },
  // {
  //     "label": "Abyssinica SIL",
  //     "value": "Abyssinica SIL",
  //     "url": "https://fonts.googleapis.com/css2?family=Abyssinica SIL",
  //     "style": {
  //         "fontFamily": "Abyssinica SIL"
  //     }
  // },
  {
    label: "Aclonica",
    value: "Aclonica",
    url: "https://fonts.googleapis.com/css2?family=Aclonica",
    style: {
      fontFamily: "Aclonica",
    },
  },
  {
    label: "Acme",
    value: "Acme",
    url: "https://fonts.googleapis.com/css2?family=Acme",
    style: {
      fontFamily: "Acme",
    },
  },
  //   {
  //     label: "Actor",
  //     value: "Actor",
  //     url: "https://fonts.googleapis.com/css2?family=Actor",
  //     style: {
  //       fontFamily: "Actor",
  //     },
  //   },
  //   {
  //     label: "Adamina",
  //     value: "Adamina",
  //     url: "https://fonts.googleapis.com/css2?family=Adamina",
  //     style: {
  //       fontFamily: "Adamina",
  //     },
  //   },
  // {
  //     "label": "Adobe Blank",
  //     "value": "Adobe Blank",
  //     "url": "https://fonts.googleapis.com/css2?family=Adobe Blank",
  //     "style": {
  //         "fontFamily": "Adobe Blank"
  //     }
  // },
  //   {
  //     label: "Advent Pro",
  //     value: "Advent Pro",
  //     url: "https://fonts.googleapis.com/css2?family=Advent Pro",
  //     style: {
  //       fontFamily: "Advent Pro",
  //     },
  //   },
  //   {
  //     label: "Aguafina Script",
  //     value: "Aguafina Script",
  //     url: "https://fonts.googleapis.com/css2?family=Aguafina Script",
  //     style: {
  //       fontFamily: "Aguafina Script",
  //     },
  //   },
  //   {
  //     label: "Akronim",
  //     value: "Akronim",
  //     url: "https://fonts.googleapis.com/css2?family=Akronim",
  //     style: {
  //       fontFamily: "Akronim",
  //     },
  //   },
  // {
  //     "label": "aksarabaligalang",
  //     "value": "aksarabaligalang",
  //     "url": "https://fonts.googleapis.com/css2?family=aksarabaligalang",
  //     "style": {
  //         "fontFamily": "aksarabaligalang"
  //     }
  // },
  {
    label: "Aladin",
    value: "Aladin",
    url: "https://fonts.googleapis.com/css2?family=Aladin",
    style: {
      fontFamily: "Aladin",
    },
  },
  //   {
  //     label: "Aldrich",
  //     value: "Aldrich",
  //     url: "https://fonts.googleapis.com/css2?family=Aldrich",
  //     style: {
  //       fontFamily: "Aldrich",
  //     },
  //   },
  //   {
  //     label: "Alef",
  //     value: "Alef",
  //     url: "https://fonts.googleapis.com/css2?family=Alef",
  //     style: {
  //       fontFamily: "Alef",
  //     },
  //   },
  // {
  //     "label": "alefhebrew",
  //     "value": "alefhebrew",
  //     "url": "https://fonts.googleapis.com/css2?family=alefhebrew",
  //     "style": {
  //         "fontFamily": "alefhebrew"
  //     }
  // },
  //   {
  //     label: "Alegreya",
  //     value: "Alegreya",
  //     url: "https://fonts.googleapis.com/css2?family=Alegreya",
  //     style: {
  //       fontFamily: "Alegreya",
  //     },
  //   },
  //   {
  //     label: "Alegreya Sans",
  //     value: "Alegreya Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Alegreya Sans",
  //     style: {
  //       fontFamily: "Alegreya Sans",
  //     },
  //   },
  //   {
  //     label: "Alegreya Sans SC",
  //     value: "Alegreya Sans SC",
  //     url: "https://fonts.googleapis.com/css2?family=Alegreya Sans SC",
  //     style: {
  //       fontFamily: "Alegreya Sans SC",
  //     },
  //   },
  {
    label: "Alegreya SC",
    value: "Alegreya SC",
    url: "https://fonts.googleapis.com/css2?family=Alegreya SC",
    style: {
      fontFamily: "Alegreya SC",
    },
  },
  //   {
  //     label: "Aleo",
  //     value: "Aleo",
  //     url: "https://fonts.googleapis.com/css2?family=Aleo",
  //     style: {
  //       fontFamily: "Aleo",
  //     },
  //   },
  //   {
  //     label: "Alex Brush",
  //     value: "Alex Brush",
  //     url: "https://fonts.googleapis.com/css2?family=Alex Brush",
  //     style: {
  //       fontFamily: "Alex Brush",
  //     },
  //   },
  //   {
  //     label: "Alfa Slab One",
  //     value: "Alfa Slab One",
  //     url: "https://fonts.googleapis.com/css2?family=Alfa Slab One",
  //     style: {
  //       fontFamily: "Alfa Slab One",
  //     },
  //   },
  //   {
  //     label: "Alice",
  //     value: "Alice",
  //     url: "https://fonts.googleapis.com/css2?family=Alice",
  //     style: {
  //       fontFamily: "Alice",
  //     },
  //   },
  //   {
  //     label: "Alike",
  //     value: "Alike",
  //     url: "https://fonts.googleapis.com/css2?family=Alike",
  //     style: {
  //       fontFamily: "Alike",
  //     },
  //   },
  //   {
  //     label: "Alike Angular",
  //     value: "Alike Angular",
  //     url: "https://fonts.googleapis.com/css2?family=Alike Angular",
  //     style: {
  //       fontFamily: "Alike Angular",
  //     },
  //   },
  //   {
  //     label: "Allan",
  //     value: "Allan",
  //     url: "https://fonts.googleapis.com/css2?family=Allan",
  //     style: {
  //       fontFamily: "Allan",
  //     },
  //   },
  //   {
  //     label: "Allerta",
  //     value: "Allerta",
  //     url: "https://fonts.googleapis.com/css2?family=Allerta",
  //     style: {
  //       fontFamily: "Allerta",
  //     },
  //   },
  //   {
  //     label: "Allerta Stencil",
  //     value: "Allerta Stencil",
  //     url: "https://fonts.googleapis.com/css2?family=Allerta Stencil",
  //     style: {
  //       fontFamily: "Allerta Stencil",
  //     },
  //   },
  //   {
  //     label: "Allura",
  //     value: "Allura",
  //     url: "https://fonts.googleapis.com/css2?family=Allura",
  //     style: {
  //       fontFamily: "Allura",
  //     },
  //   },
  {
    label: "Almendra",
    value: "Almendra",
    url: "https://fonts.googleapis.com/css2?family=Almendra",
    style: {
      fontFamily: "Almendra",
    },
  },
  //   {
  //     label: "Almendra Display",
  //     value: "Almendra Display",
  //     url: "https://fonts.googleapis.com/css2?family=Almendra Display",
  //     style: {
  //       fontFamily: "Almendra Display",
  //     },
  //   },
  //   {
  //     label: "Almendra SC",
  //     value: "Almendra SC",
  //     url: "https://fonts.googleapis.com/css2?family=Almendra SC",
  //     style: {
  //       fontFamily: "Almendra SC",
  //     },
  //   },
  {
    label: "Amarante",
    value: "Amarante",
    url: "https://fonts.googleapis.com/css2?family=Amarante",
    style: {
      fontFamily: "Amarante",
    },
  },
  {
    label: "Amaranth",
    value: "Amaranth",
    url: "https://fonts.googleapis.com/css2?family=Amaranth",
    style: {
      fontFamily: "Amaranth",
    },
  },
  //   {
  //     label: "Amatic SC",
  //     value: "Amatic SC",
  //     url: "https://fonts.googleapis.com/css2?family=Amatic SC",
  //     style: {
  //       fontFamily: "Amatic SC",
  //     },
  //   },
  //   {
  //     label: "Amatica SC",
  //     value: "Amatica SC",
  //     url: "https://fonts.googleapis.com/css2?family=Amatica SC",
  //     style: {
  //       fontFamily: "Amatica SC",
  //     },
  //   },
  //   {
  //     label: "Amethysta",
  //     value: "Amethysta",
  //     url: "https://fonts.googleapis.com/css2?family=Amethysta",
  //     style: {
  //       fontFamily: "Amethysta",
  //     },
  //   },
  //   {
  //     label: "Amiko",
  //     value: "Amiko",
  //     url: "https://fonts.googleapis.com/css2?family=Amiko",
  //     style: {
  //       fontFamily: "Amiko",
  //     },
  //   },
  //   {
  //     label: "Amiri",
  //     value: "Amiri",
  //     url: "https://fonts.googleapis.com/css2?family=Amiri",
  //     style: {
  //       fontFamily: "Amiri",
  //     },
  //   },
  {
    label: "Amita",
    value: "Amita",
    url: "https://fonts.googleapis.com/css2?family=Amita",
    style: {
      fontFamily: "Amita",
    },
  },
  // {
  //     "label": "amstelvaralpha",
  //     "value": "amstelvaralpha",
  //     "url": "https://fonts.googleapis.com/css2?family=amstelvaralpha",
  //     "style": {
  //         "fontFamily": "amstelvaralpha"
  //     }
  // },
  //   {
  //     label: "Anaheim",
  //     value: "Anaheim",
  //     url: "https://fonts.googleapis.com/css2?family=Anaheim",
  //     style: {
  //       fontFamily: "Anaheim",
  //     },
  //   },
  //   {
  //     label: "Andada",
  //     value: "Andada",
  //     url: "https://fonts.googleapis.com/css2?family=Andada",
  //     style: {
  //       fontFamily: "Andada",
  //     },
  //   },
  // {
  //     "label": "Andada SC",
  //     "value": "Andada SC",
  //     "url": "https://fonts.googleapis.com/css2?family=Andada SC",
  //     "style": {
  //         "fontFamily": "Andada SC"
  //     }
  // },
  //   {
  //     label: "Andika",
  //     value: "Andika",
  //     url: "https://fonts.googleapis.com/css2?family=Andika",
  //     style: {
  //       fontFamily: "Andika",
  //     },
  //   },
  //   {
  //     label: "Angkor",
  //     value: "Angkor",
  //     url: "https://fonts.googleapis.com/css2?family=Angkor",
  //     style: {
  //       fontFamily: "Angkor",
  //     },
  //   },
  //   {
  //     label: "Annie Use Your Telescope",
  //     value: "Annie Use Your Telescope",
  //     url: "https://fonts.googleapis.com/css2?family=Annie Use Your Telescope",
  //     style: {
  //       fontFamily: "Annie Use Your Telescope",
  //     },
  //   },
  //   {
  //     label: "Anonymous Pro",
  //     value: "Anonymous Pro",
  //     url: "https://fonts.googleapis.com/css2?family=Anonymous Pro",
  //     style: {
  //       fontFamily: "Anonymous Pro",
  //     },
  //   },
  //   {
  //     label: "Antic",
  //     value: "Antic",
  //     url: "https://fonts.googleapis.com/css2?family=Antic",
  //     style: {
  //       fontFamily: "Antic",
  //     },
  //   },
  //   {
  //     label: "Antic Didone",
  //     value: "Antic Didone",
  //     url: "https://fonts.googleapis.com/css2?family=Antic Didone",
  //     style: {
  //       fontFamily: "Antic Didone",
  //     },
  //   },
  //   {
  //     label: "Antic Slab",
  //     value: "Antic Slab",
  //     url: "https://fonts.googleapis.com/css2?family=Antic Slab",
  //     style: {
  //       fontFamily: "Antic Slab",
  //     },
  //   },
  //   {
  //     label: "Anton",
  //     value: "Anton",
  //     url: "https://fonts.googleapis.com/css2?family=Anton",
  //     style: {
  //       fontFamily: "Anton",
  //     },
  //   },
  //   {
  //     label: "Antonio",
  //     value: "Antonio",
  //     url: "https://fonts.googleapis.com/css2?family=Antonio",
  //     style: {
  //       fontFamily: "Antonio",
  //     },
  //   },
  //   {
  //     label: "Arapey",
  //     value: "Arapey",
  //     url: "https://fonts.googleapis.com/css2?family=Arapey",
  //     style: {
  //       fontFamily: "Arapey",
  //     },
  //   },
  //   {
  //     label: "Arbutus",
  //     value: "Arbutus",
  //     url: "https://fonts.googleapis.com/css2?family=Arbutus",
  //     style: {
  //       fontFamily: "Arbutus",
  //     },
  //   },
  //   {
  //     label: "Arbutus Slab",
  //     value: "Arbutus Slab",
  //     url: "https://fonts.googleapis.com/css2?family=Arbutus Slab",
  //     style: {
  //       fontFamily: "Arbutus Slab",
  //     },
  //   },
  //   {
  //     label: "Architects Daughter",
  //     value: "Architects Daughter",
  //     url: "https://fonts.googleapis.com/css2?family=Architects Daughter",
  //     style: {
  //       fontFamily: "Architects Daughter",
  //     },
  //   },
  //   {
  //     label: "Archivo",
  //     value: "Archivo",
  //     url: "https://fonts.googleapis.com/css2?family=Archivo",
  //     style: {
  //       fontFamily: "Archivo",
  //     },
  //   },
  //   {
  //     label: "Archivo Black",
  //     value: "Archivo Black",
  //     url: "https://fonts.googleapis.com/css2?family=Archivo Black",
  //     style: {
  //       fontFamily: "Archivo Black",
  //     },
  //   },
  //   {
  //     label: "Archivo Narrow",
  //     value: "Archivo Narrow",
  //     url: "https://fonts.googleapis.com/css2?family=Archivo Narrow",
  //     style: {
  //       fontFamily: "Archivo Narrow",
  //     },
  //   },
  // {
  //     "label": "archivovfbeta",
  //     "value": "archivovfbeta",
  //     "url": "https://fonts.googleapis.com/css2?family=archivovfbeta",
  //     "style": {
  //         "fontFamily": "archivovfbeta"
  //     }
  // },
  //   {
  //     label: "Aref Ruqaa",
  //     value: "Aref Ruqaa",
  //     url: "https://fonts.googleapis.com/css2?family=Aref Ruqaa",
  //     style: {
  //       fontFamily: "Aref Ruqaa",
  //     },
  //   },
  //   {
  //     label: "Arima Madurai",
  //     value: "Arima Madurai",
  //     url: "https://fonts.googleapis.com/css2?family=Arima Madurai",
  //     style: {
  //       fontFamily: "Arima Madurai",
  //     },
  //   },
  //   {
  //     label: "Arimo",
  //     value: "Arimo",
  //     url: "https://fonts.googleapis.com/css2?family=Arimo",
  //     style: {
  //       fontFamily: "Arimo",
  //     },
  //   },
  //   {
  //     label: "Arizonia",
  //     value: "Arizonia",
  //     url: "https://fonts.googleapis.com/css2?family=Arizonia",
  //     style: {
  //       fontFamily: "Arizonia",
  //     },
  //   },
  //   {
  //     label: "Armata",
  //     value: "Armata",
  //     url: "https://fonts.googleapis.com/css2?family=Armata",
  //     style: {
  //       fontFamily: "Armata",
  //     },
  //   },
  //   {
  //     label: "Arsenal",
  //     value: "Arsenal",
  //     url: "https://fonts.googleapis.com/css2?family=Arsenal",
  //     style: {
  //       fontFamily: "Arsenal",
  //     },
  //   },
  //   {
  //     label: "Artifika",
  //     value: "Artifika",
  //     url: "https://fonts.googleapis.com/css2?family=Artifika",
  //     style: {
  //       fontFamily: "Artifika",
  //     },
  //   },
  //   {
  //     label: "Arvo",
  //     value: "Arvo",
  //     url: "https://fonts.googleapis.com/css2?family=Arvo",
  //     style: {
  //       fontFamily: "Arvo",
  //     },
  //   },
  {
    label: "Arya",
    value: "Arya",
    url: "https://fonts.googleapis.com/css2?family=Arya",
    style: {
      fontFamily: "Arya",
    },
  },
  //   {
  //     label: "Asap",
  //     value: "Asap",
  //     url: "https://fonts.googleapis.com/css2?family=Asap",
  //     style: {
  //       fontFamily: "Asap",
  //     },
  //   },
  //   {
  //     label: "Asap Condensed",
  //     value: "Asap Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Asap Condensed",
  //     style: {
  //       fontFamily: "Asap Condensed",
  //     },
  //   },
  // {
  //     "label": "asapvfbeta",
  //     "value": "asapvfbeta",
  //     "url": "https://fonts.googleapis.com/css2?family=asapvfbeta",
  //     "style": {
  //         "fontFamily": "asapvfbeta"
  //     }
  // },
  //   {
  //     label: "Asar",
  //     value: "Asar",
  //     url: "https://fonts.googleapis.com/css2?family=Asar",
  //     style: {
  //       fontFamily: "Asar",
  //     },
  //   },
  //   {
  //     label: "Asset",
  //     value: "Asset",
  //     url: "https://fonts.googleapis.com/css2?family=Asset",
  //     style: {
  //       fontFamily: "Asset",
  //     },
  //   },
  //   {
  //     label: "Assistant",
  //     value: "Assistant",
  //     url: "https://fonts.googleapis.com/css2?family=Assistant",
  //     style: {
  //       fontFamily: "Assistant",
  //     },
  //   },
  //   {
  //     label: "Astloch",
  //     value: "Astloch",
  //     url: "https://fonts.googleapis.com/css2?family=Astloch",
  //     style: {
  //       fontFamily: "Astloch",
  //     },
  //   },
  //   {
  //     label: "Asul",
  //     value: "Asul",
  //     url: "https://fonts.googleapis.com/css2?family=Asul",
  //     style: {
  //       fontFamily: "Asul",
  //     },
  //   },
  //   {
  //     label: "Athiti",
  //     value: "Athiti",
  //     url: "https://fonts.googleapis.com/css2?family=Athiti",
  //     style: {
  //       fontFamily: "Athiti",
  //     },
  //   },
  //   {
  //     label: "Atomic Age",
  //     value: "Atomic Age",
  //     url: "https://fonts.googleapis.com/css2?family=Atomic Age",
  //     style: {
  //       fontFamily: "Atomic Age",
  //     },
  //   },
  //   {
  //     label: "Aubrey",
  //     value: "Aubrey",
  //     url: "https://fonts.googleapis.com/css2?family=Aubrey",
  //     style: {
  //       fontFamily: "Aubrey",
  //     },
  //   },
  //   {
  //     label: "Audiowide",
  //     value: "Audiowide",
  //     url: "https://fonts.googleapis.com/css2?family=Audiowide",
  //     style: {
  //       fontFamily: "Audiowide",
  //     },
  //   },
  //   {
  //     label: "Autour One",
  //     value: "Autour One",
  //     url: "https://fonts.googleapis.com/css2?family=Autour One",
  //     style: {
  //       fontFamily: "Autour One",
  //     },
  //   },
  //   {
  //     label: "Average",
  //     value: "Average",
  //     url: "https://fonts.googleapis.com/css2?family=Average",
  //     style: {
  //       fontFamily: "Average",
  //     },
  //   },
  //   {
  //     label: "Average Sans",
  //     value: "Average Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Average Sans",
  //     style: {
  //       fontFamily: "Average Sans",
  //     },
  //   },
  //   {
  //     label: "Averia Gruesa Libre",
  //     value: "Averia Gruesa Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Averia Gruesa Libre",
  //     style: {
  //       fontFamily: "Averia Gruesa Libre",
  //     },
  //   },
  //   {
  //     label: "Averia Libre",
  //     value: "Averia Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Averia Libre",
  //     style: {
  //       fontFamily: "Averia Libre",
  //     },
  //   },
  //   {
  //     label: "Averia Sans Libre",
  //     value: "Averia Sans Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Averia Sans Libre",
  //     style: {
  //       fontFamily: "Averia Sans Libre",
  //     },
  //   },
  //   {
  //     label: "Averia Serif Libre",
  //     value: "Averia Serif Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Averia Serif Libre",
  //     style: {
  //       fontFamily: "Averia Serif Libre",
  //     },
  //   },
  //   {
  //     label: "B612",
  //     value: "B612",
  //     url: "https://fonts.googleapis.com/css2?family=B612",
  //     style: {
  //       fontFamily: "B612",
  //     },
  //   },
  //   {
  //     label: "B612 Mono",
  //     value: "B612 Mono",
  //     url: "https://fonts.googleapis.com/css2?family=B612 Mono",
  //     style: {
  //       fontFamily: "B612 Mono",
  //     },
  //   },
  //   {
  //     label: "Bad Script",
  //     value: "Bad Script",
  //     url: "https://fonts.googleapis.com/css2?family=Bad Script",
  //     style: {
  //       fontFamily: "Bad Script",
  //     },
  //   },
  //   {
  //     label: "Bahiana",
  //     value: "Bahiana",
  //     url: "https://fonts.googleapis.com/css2?family=Bahiana",
  //     style: {
  //       fontFamily: "Bahiana",
  //     },
  //   },
  //   {
  //     label: "Bahianita",
  //     value: "Bahianita",
  //     url: "https://fonts.googleapis.com/css2?family=Bahianita",
  //     style: {
  //       fontFamily: "Bahianita",
  //     },
  //   },
  //   {
  //     label: "Bai Jamjuree",
  //     value: "Bai Jamjuree",
  //     url: "https://fonts.googleapis.com/css2?family=Bai Jamjuree",
  //     style: {
  //       fontFamily: "Bai Jamjuree",
  //     },
  //   },
  //   {
  //     label: "Baloo",
  //     value: "Baloo",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo",
  //     style: {
  //       fontFamily: "Baloo",
  //     },
  //   },
  //   {
  //     label: "Baloo Bhai",
  //     value: "Baloo Bhai",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Bhai",
  //     style: {
  //       fontFamily: "Baloo Bhai",
  //     },
  //   },
  //   {
  //     label: "Baloo Bhaijaan",
  //     value: "Baloo Bhaijaan",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Bhaijaan",
  //     style: {
  //       fontFamily: "Baloo Bhaijaan",
  //     },
  //   },
  //   {
  //     label: "Baloo Bhaina",
  //     value: "Baloo Bhaina",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Bhaina",
  //     style: {
  //       fontFamily: "Baloo Bhaina",
  //     },
  //   },
  //   {
  //     label: "Baloo Chettan",
  //     value: "Baloo Chettan",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Chettan",
  //     style: {
  //       fontFamily: "Baloo Chettan",
  //     },
  //   },
  //   {
  //     label: "Baloo Da",
  //     value: "Baloo Da",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Da",
  //     style: {
  //       fontFamily: "Baloo Da",
  //     },
  //   },
  //   {
  //     label: "Baloo Paaji",
  //     value: "Baloo Paaji",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Paaji",
  //     style: {
  //       fontFamily: "Baloo Paaji",
  //     },
  //   },
  //   {
  //     label: "Baloo Tamma",
  //     value: "Baloo Tamma",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Tamma",
  //     style: {
  //       fontFamily: "Baloo Tamma",
  //     },
  //   },
  //   {
  //     label: "Baloo Tammudu",
  //     value: "Baloo Tammudu",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Tammudu",
  //     style: {
  //       fontFamily: "Baloo Tammudu",
  //     },
  //   },
  //   {
  //     label: "Baloo Thambi",
  //     value: "Baloo Thambi",
  //     url: "https://fonts.googleapis.com/css2?family=Baloo Thambi",
  //     style: {
  //       fontFamily: "Baloo Thambi",
  //     },
  //   },
  //   {
  //     label: "Balthazar",
  //     value: "Balthazar",
  //     url: "https://fonts.googleapis.com/css2?family=Balthazar",
  //     style: {
  //       fontFamily: "Balthazar",
  //     },
  //   },
  //   {
  //     label: "Bangers",
  //     value: "Bangers",
  //     url: "https://fonts.googleapis.com/css2?family=Bangers",
  //     style: {
  //       fontFamily: "Bangers",
  //     },
  //   },
  //   {
  //     label: "Barlow",
  //     value: "Barlow",
  //     url: "https://fonts.googleapis.com/css2?family=Barlow",
  //     style: {
  //       fontFamily: "Barlow",
  //     },
  //   },
  //   {
  //     label: "Barlow Condensed",
  //     value: "Barlow Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Barlow Condensed",
  //     style: {
  //       fontFamily: "Barlow Condensed",
  //     },
  //   },
  //   {
  //     label: "Barlow Semi Condensed",
  //     value: "Barlow Semi Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Barlow Semi Condensed",
  //     style: {
  //       fontFamily: "Barlow Semi Condensed",
  //     },
  //   },
  //   {
  //     label: "Barriecito",
  //     value: "Barriecito",
  //     url: "https://fonts.googleapis.com/css2?family=Barriecito",
  //     style: {
  //       fontFamily: "Barriecito",
  //     },
  //   },
  //   {
  //     label: "Barrio",
  //     value: "Barrio",
  //     url: "https://fonts.googleapis.com/css2?family=Barrio",
  //     style: {
  //       fontFamily: "Barrio",
  //     },
  //   },
  //   {
  //     label: "Basic",
  //     value: "Basic",
  //     url: "https://fonts.googleapis.com/css2?family=Basic",
  //     style: {
  //       fontFamily: "Basic",
  //     },
  //   },
  //   {
  //     label: "Battambang",
  //     value: "Battambang",
  //     url: "https://fonts.googleapis.com/css2?family=Battambang",
  //     style: {
  //       fontFamily: "Battambang",
  //     },
  //   },
  //   {
  //     label: "Baumans",
  //     value: "Baumans",
  //     url: "https://fonts.googleapis.com/css2?family=Baumans",
  //     style: {
  //       fontFamily: "Baumans",
  //     },
  //   },
  //   {
  //     label: "Bayon",
  //     value: "Bayon",
  //     url: "https://fonts.googleapis.com/css2?family=Bayon",
  //     style: {
  //       fontFamily: "Bayon",
  //     },
  //   },
  //   {
  //     label: "Belgrano",
  //     value: "Belgrano",
  //     url: "https://fonts.googleapis.com/css2?family=Belgrano",
  //     style: {
  //       fontFamily: "Belgrano",
  //     },
  //   },
  //   {
  //     label: "Bellefair",
  //     value: "Bellefair",
  //     url: "https://fonts.googleapis.com/css2?family=Bellefair",
  //     style: {
  //       fontFamily: "Bellefair",
  //     },
  //   },
  //   {
  //     label: "Belleza",
  //     value: "Belleza",
  //     url: "https://fonts.googleapis.com/css2?family=Belleza",
  //     style: {
  //       fontFamily: "Belleza",
  //     },
  //   },
  //   {
  //     label: "BenchNine",
  //     value: "BenchNine",
  //     url: "https://fonts.googleapis.com/css2?family=BenchNine",
  //     style: {
  //       fontFamily: "BenchNine",
  //     },
  //   },
  //   {
  //     label: "Bentham",
  //     value: "Bentham",
  //     url: "https://fonts.googleapis.com/css2?family=Bentham",
  //     style: {
  //       fontFamily: "Bentham",
  //     },
  //   },
  {
    label: "Berkshire Swash",
    value: "Berkshire Swash",
    url: "https://fonts.googleapis.com/css2?family=Berkshire Swash",
    style: {
      fontFamily: "Berkshire Swash",
    },
  },
  //   {
  //     label: "Beth Ellen",
  //     value: "Beth Ellen",
  //     url: "https://fonts.googleapis.com/css2?family=Beth Ellen",
  //     style: {
  //       fontFamily: "Beth Ellen",
  //     },
  //   },
  //   {
  //     label: "Bevan",
  //     value: "Bevan",
  //     url: "https://fonts.googleapis.com/css2?family=Bevan",
  //     style: {
  //       fontFamily: "Bevan",
  //     },
  //   },
  // {
  //     "label": "Bhavuka",
  //     "value": "Bhavuka",
  //     "url": "https://fonts.googleapis.com/css2?family=Bhavuka",
  //     "style": {
  //         "fontFamily": "Bhavuka"
  //     }
  // },
  //   {
  //     label: "Bigelow Rules",
  //     value: "Bigelow Rules",
  //     url: "https://fonts.googleapis.com/css2?family=Bigelow Rules",
  //     style: {
  //       fontFamily: "Bigelow Rules",
  //     },
  //   },
  //   {
  //     label: "Bigshot One",
  //     value: "Bigshot One",
  //     url: "https://fonts.googleapis.com/css2?family=Bigshot One",
  //     style: {
  //       fontFamily: "Bigshot One",
  //     },
  //   },
  //   {
  //     label: "Bilbo",
  //     value: "Bilbo",
  //     url: "https://fonts.googleapis.com/css2?family=Bilbo",
  //     style: {
  //       fontFamily: "Bilbo",
  //     },
  //   },
  //   {
  //     label: "Bilbo Swash Caps",
  //     value: "Bilbo Swash Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Bilbo Swash Caps",
  //     style: {
  //       fontFamily: "Bilbo Swash Caps",
  //     },
  //   },
  // {
  //     "label": "Bio Rhyme",
  //     "value": "Bio Rhyme",
  //     "url": "https://fonts.googleapis.com/css2?family=Bio Rhyme",
  //     "style": {
  //         "fontFamily": "Bio Rhyme"
  //     }
  // },
  // {
  //     "label": "Bio Rhyme Expanded",
  //     "value": "Bio Rhyme Expanded",
  //     "url": "https://fonts.googleapis.com/css2?family=Bio Rhyme Expanded",
  //     "style": {
  //         "fontFamily": "Bio Rhyme Expanded"
  //     }
  // },
  //   {
  //     label: "Biryani",
  //     value: "Biryani",
  //     url: "https://fonts.googleapis.com/css2?family=Biryani",
  //     style: {
  //       fontFamily: "Biryani",
  //     },
  //   },
  //   {
  //     label: "Bitter",
  //     value: "Bitter",
  //     url: "https://fonts.googleapis.com/css2?family=Bitter",
  //     style: {
  //       fontFamily: "Bitter",
  //     },
  //   },
  //   {
  //     label: "Black And White Picture",
  //     value: "Black And White Picture",
  //     url: "https://fonts.googleapis.com/css2?family=Black And White Picture",
  //     style: {
  //       fontFamily: "Black And White Picture",
  //     },
  //   },
  //   {
  //     label: "Black Han Sans",
  //     value: "Black Han Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Black Han Sans",
  //     style: {
  //       fontFamily: "Black Han Sans",
  //     },
  //   },
  //   {
  //     label: "Black Ops One",
  //     value: "Black Ops One",
  //     url: "https://fonts.googleapis.com/css2?family=Black Ops One",
  //     style: {
  //       fontFamily: "Black Ops One",
  //     },
  //   },
  {
    label: "Bokor",
    value: "Bokor",
    url: "https://fonts.googleapis.com/css2?family=Bokor",
    style: {
      fontFamily: "Bokor",
    },
  },
  //   {
  //     label: "Bonbon",
  //     value: "Bonbon",
  //     url: "https://fonts.googleapis.com/css2?family=Bonbon",
  //     style: {
  //       fontFamily: "Bonbon",
  //     },
  //   },
  //   {
  //     label: "Boogaloo",
  //     value: "Boogaloo",
  //     url: "https://fonts.googleapis.com/css2?family=Boogaloo",
  //     style: {
  //       fontFamily: "Boogaloo",
  //     },
  //   },
  //   {
  //     label: "Bowlby One",
  //     value: "Bowlby One",
  //     url: "https://fonts.googleapis.com/css2?family=Bowlby One",
  //     style: {
  //       fontFamily: "Bowlby One",
  //     },
  //   },
  //   {
  //     label: "Bowlby One SC",
  //     value: "Bowlby One SC",
  //     url: "https://fonts.googleapis.com/css2?family=Bowlby One SC",
  //     style: {
  //       fontFamily: "Bowlby One SC",
  //     },
  //   },
  //   {
  //     label: "Brawler",
  //     value: "Brawler",
  //     url: "https://fonts.googleapis.com/css2?family=Brawler",
  //     style: {
  //       fontFamily: "Brawler",
  //     },
  //   },
  //   {
  //     label: "Bree Serif",
  //     value: "Bree Serif",
  //     url: "https://fonts.googleapis.com/css2?family=Bree Serif",
  //     style: {
  //       fontFamily: "Bree Serif",
  //     },
  //   },
  // {
  //     "label": "Bruno Ace",
  //     "value": "Bruno Ace",
  //     "url": "https://fonts.googleapis.com/css2?family=Bruno Ace",
  //     "style": {
  //         "fontFamily": "Bruno Ace"
  //     }
  // },
  // {
  //     "label": "Bruno Ace SC",
  //     "value": "Bruno Ace SC",
  //     "url": "https://fonts.googleapis.com/css2?family=Bruno Ace SC",
  //     "style": {
  //         "fontFamily": "Bruno Ace SC"
  //     }
  // },
  //   {
  //     label: "Bubblegum Sans",
  //     value: "Bubblegum Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Bubblegum Sans",
  //     style: {
  //       fontFamily: "Bubblegum Sans",
  //     },
  //   },
  //   {
  //     label: "Bubbler One",
  //     value: "Bubbler One",
  //     url: "https://fonts.googleapis.com/css2?family=Bubbler One",
  //     style: {
  //       fontFamily: "Bubbler One",
  //     },
  //   },
  // {
  //     "label": "Buda",
  //     "value": "Buda",
  //     "url": "https://fonts.googleapis.com/css2?family=Buda",
  //     "style": {
  //         "fontFamily": "Buda"
  //     }
  // },
  //   {
  //     label: "Buenard",
  //     value: "Buenard",
  //     url: "https://fonts.googleapis.com/css2?family=Buenard",
  //     style: {
  //       fontFamily: "Buenard",
  //     },
  //   },
  //   {
  //     label: "Bungee",
  //     value: "Bungee",
  //     url: "https://fonts.googleapis.com/css2?family=Bungee",
  //     style: {
  //       fontFamily: "Bungee",
  //     },
  //   },
  //   {
  //     label: "Bungee Hairline",
  //     value: "Bungee Hairline",
  //     url: "https://fonts.googleapis.com/css2?family=Bungee Hairline",
  //     style: {
  //       fontFamily: "Bungee Hairline",
  //     },
  //   },
  //   {
  //     label: "Bungee Inline",
  //     value: "Bungee Inline",
  //     url: "https://fonts.googleapis.com/css2?family=Bungee Inline",
  //     style: {
  //       fontFamily: "Bungee Inline",
  //     },
  //   },
  //   {
  //     label: "Bungee Outline",
  //     value: "Bungee Outline",
  //     url: "https://fonts.googleapis.com/css2?family=Bungee Outline",
  //     style: {
  //       fontFamily: "Bungee Outline",
  //     },
  //   },
  //   {
  //     label: "Bungee Shade",
  //     value: "Bungee Shade",
  //     url: "https://fonts.googleapis.com/css2?family=Bungee Shade",
  //     style: {
  //       fontFamily: "Bungee Shade",
  //     },
  //   },
  //   {
  //     label: "Butcherman",
  //     value: "Butcherman",
  //     url: "https://fonts.googleapis.com/css2?family=Butcherman",
  //     style: {
  //       fontFamily: "Butcherman",
  //     },
  //   },
  //   {
  //     label: "Butcherman Caps",
  //     value: "Butcherman Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Butcherman Caps",
  //     style: {
  //       fontFamily: "Butcherman Caps",
  //     },
  //   },
  //   {
  //     label: "Butterfly Kids",
  //     value: "Butterfly Kids",
  //     url: "https://fonts.googleapis.com/css2?family=Butterfly Kids",
  //     style: {
  //       fontFamily: "Butterfly Kids",
  //     },
  //   },
  //   {
  //     label: "Cabin",
  //     value: "Cabin",
  //     url: "https://fonts.googleapis.com/css2?family=Cabin",
  //     style: {
  //       fontFamily: "Cabin",
  //     },
  //   },
  //   {
  //     label: "Cabin Condensed",
  //     value: "Cabin Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Cabin Condensed",
  //     style: {
  //       fontFamily: "Cabin Condensed",
  //     },
  //   },
  //   {
  //     label: "Cabin Sketch",
  //     value: "Cabin Sketch",
  //     url: "https://fonts.googleapis.com/css2?family=Cabin Sketch",
  //     style: {
  //       fontFamily: "Cabin Sketch",
  //     },
  //   },
  // {
  //     "label": "cabinvfbeta",
  //     "value": "cabinvfbeta",
  //     "url": "https://fonts.googleapis.com/css2?family=cabinvfbeta",
  //     "style": {
  //         "fontFamily": "cabinvfbeta"
  //     }
  // },
  //   {
  //     label: "Caesar Dressing",
  //     value: "Caesar Dressing",
  //     url: "https://fonts.googleapis.com/css2?family=Caesar Dressing",
  //     style: {
  //       fontFamily: "Caesar Dressing",
  //     },
  //   },
  //   {
  //     label: "Cagliostro",
  //     value: "Cagliostro",
  //     url: "https://fonts.googleapis.com/css2?family=Cagliostro",
  //     style: {
  //       fontFamily: "Cagliostro",
  //     },
  //   },
  //   {
  //     label: "Cairo",
  //     value: "Cairo",
  //     url: "https://fonts.googleapis.com/css2?family=Cairo",
  //     style: {
  //       fontFamily: "Cairo",
  //     },
  //   },
  //   {
  //     label: "Calligraffitti",
  //     value: "Calligraffitti",
  //     url: "https://fonts.googleapis.com/css2?family=Calligraffitti",
  //     style: {
  //       fontFamily: "Calligraffitti",
  //     },
  //   },
  //   {
  //     label: "Cambay",
  //     value: "Cambay",
  //     url: "https://fonts.googleapis.com/css2?family=Cambay",
  //     style: {
  //       fontFamily: "Cambay",
  //     },
  //   },
  //   {
  //     label: "Cambo",
  //     value: "Cambo",
  //     url: "https://fonts.googleapis.com/css2?family=Cambo",
  //     style: {
  //       fontFamily: "Cambo",
  //     },
  //   },
  //   {
  //     label: "Candal",
  //     value: "Candal",
  //     url: "https://fonts.googleapis.com/css2?family=Candal",
  //     style: {
  //       fontFamily: "Candal",
  //     },
  //   },
  //   {
  //     label: "Cantarell",
  //     value: "Cantarell",
  //     url: "https://fonts.googleapis.com/css2?family=Cantarell",
  //     style: {
  //       fontFamily: "Cantarell",
  //     },
  //   },
  //   {
  //     label: "Cantata One",
  //     value: "Cantata One",
  //     url: "https://fonts.googleapis.com/css2?family=Cantata One",
  //     style: {
  //       fontFamily: "Cantata One",
  //     },
  //   },
  //   {
  //     label: "Cantora One",
  //     value: "Cantora One",
  //     url: "https://fonts.googleapis.com/css2?family=Cantora One",
  //     style: {
  //       fontFamily: "Cantora One",
  //     },
  //   },
  //   {
  //     label: "Capriola",
  //     value: "Capriola",
  //     url: "https://fonts.googleapis.com/css2?family=Capriola",
  //     style: {
  //       fontFamily: "Capriola",
  //     },
  //   },
  //   {
  //     label: "Cardo",
  //     value: "Cardo",
  //     url: "https://fonts.googleapis.com/css2?family=Cardo",
  //     style: {
  //       fontFamily: "Cardo",
  //     },
  //   },
  //   {
  //     label: "Carme",
  //     value: "Carme",
  //     url: "https://fonts.googleapis.com/css2?family=Carme",
  //     style: {
  //       fontFamily: "Carme",
  //     },
  //   },
  //   {
  //     label: "Carrois Gothic",
  //     value: "Carrois Gothic",
  //     url: "https://fonts.googleapis.com/css2?family=Carrois Gothic",
  //     style: {
  //       fontFamily: "Carrois Gothic",
  //     },
  //   },
  //   {
  //     label: "Carrois Gothic SC",
  //     value: "Carrois Gothic SC",
  //     url: "https://fonts.googleapis.com/css2?family=Carrois Gothic SC",
  //     style: {
  //       fontFamily: "Carrois Gothic SC",
  //     },
  //   },
  //   {
  //     label: "Carter One",
  //     value: "Carter One",
  //     url: "https://fonts.googleapis.com/css2?family=Carter One",
  //     style: {
  //       fontFamily: "Carter One",
  //     },
  //   },
  //   {
  //     label: "Catamaran",
  //     value: "Catamaran",
  //     url: "https://fonts.googleapis.com/css2?family=Catamaran",
  //     style: {
  //       fontFamily: "Catamaran",
  //     },
  //   },
  //   {
  //     label: "Caudex",
  //     value: "Caudex",
  //     url: "https://fonts.googleapis.com/css2?family=Caudex",
  //     style: {
  //       fontFamily: "Caudex",
  //     },
  //   },
  //   {
  //     label: "Caveat",
  //     value: "Caveat",
  //     url: "https://fonts.googleapis.com/css2?family=Caveat",
  //     style: {
  //       fontFamily: "Caveat",
  //     },
  //   },
  {
    label: "Caveat Brush",
    value: "Caveat Brush",
    url: "https://fonts.googleapis.com/css2?family=Caveat Brush",
    style: {
      fontFamily: "Caveat Brush",
    },
  },
  //   {
  //     label: "Cedarville Cursive",
  //     value: "Cedarville Cursive",
  //     url: "https://fonts.googleapis.com/css2?family=Cedarville Cursive",
  //     style: {
  //       fontFamily: "Cedarville Cursive",
  //     },
  //   },
  //   {
  //     label: "Ceviche One",
  //     value: "Ceviche One",
  //     url: "https://fonts.googleapis.com/css2?family=Ceviche One",
  //     style: {
  //       fontFamily: "Ceviche One",
  //     },
  //   },
  //   {
  //     label: "Chakra Petch",
  //     value: "Chakra Petch",
  //     url: "https://fonts.googleapis.com/css2?family=Chakra Petch",
  //     style: {
  //       fontFamily: "Chakra Petch",
  //     },
  //   },
  //   {
  //     label: "Changa",
  //     value: "Changa",
  //     url: "https://fonts.googleapis.com/css2?family=Changa",
  //     style: {
  //       fontFamily: "Changa",
  //     },
  //   },
  //   {
  //     label: "Changa One",
  //     value: "Changa One",
  //     url: "https://fonts.googleapis.com/css2?family=Changa One",
  //     style: {
  //       fontFamily: "Changa One",
  //     },
  //   },
  //   {
  //     label: "Chango",
  //     value: "Chango",
  //     url: "https://fonts.googleapis.com/css2?family=Chango",
  //     style: {
  //       fontFamily: "Chango",
  //     },
  //   },
  {
    label: "Charm",
    value: "Charm",
    url: "https://fonts.googleapis.com/css2?family=Charm",
    style: {
      fontFamily: "Charm",
    },
  },
  //   {
  //     label: "Charmonman",
  //     value: "Charmonman",
  //     url: "https://fonts.googleapis.com/css2?family=Charmonman",
  //     style: {
  //       fontFamily: "Charmonman",
  //     },
  //   },
  //   {
  //     label: "Chau Philomene One",
  //     value: "Chau Philomene One",
  //     url: "https://fonts.googleapis.com/css2?family=Chau Philomene One",
  //     style: {
  //       fontFamily: "Chau Philomene One",
  //     },
  //   },
  //   {
  //     label: "Chela One",
  //     value: "Chela One",
  //     url: "https://fonts.googleapis.com/css2?family=Chela One",
  //     style: {
  //       fontFamily: "Chela One",
  //     },
  //   },
  //   {
  //     label: "Chelsea Market",
  //     value: "Chelsea Market",
  //     url: "https://fonts.googleapis.com/css2?family=Chelsea Market",
  //     style: {
  //       fontFamily: "Chelsea Market",
  //     },
  //   },
  //   {
  //     label: "Chenla",
  //     value: "Chenla",
  //     url: "https://fonts.googleapis.com/css2?family=Chenla",
  //     style: {
  //       fontFamily: "Chenla",
  //     },
  //   },
  //   {
  //     label: "Cherry Cream Soda",
  //     value: "Cherry Cream Soda",
  //     url: "https://fonts.googleapis.com/css2?family=Cherry Cream Soda",
  //     style: {
  //       fontFamily: "Cherry Cream Soda",
  //     },
  //   },
  //   {
  //     label: "Cherry Swash",
  //     value: "Cherry Swash",
  //     url: "https://fonts.googleapis.com/css2?family=Cherry Swash",
  //     style: {
  //       fontFamily: "Cherry Swash",
  //     },
  //   },
  //   {
  //     label: "Chewy",
  //     value: "Chewy",
  //     url: "https://fonts.googleapis.com/css2?family=Chewy",
  //     style: {
  //       fontFamily: "Chewy",
  //     },
  //   },
  {
    label: "Chicle",
    value: "Chicle",
    url: "https://fonts.googleapis.com/css2?family=Chicle",
    style: {
      fontFamily: "Chicle",
    },
  },
  //   {
  //     label: "Chilanka",
  //     value: "Chilanka",
  //     url: "https://fonts.googleapis.com/css2?family=Chilanka",
  //     style: {
  //       fontFamily: "Chilanka",
  //     },
  //   },
  //   {
  //     label: "Chivo",
  //     value: "Chivo",
  //     url: "https://fonts.googleapis.com/css2?family=Chivo",
  //     style: {
  //       fontFamily: "Chivo",
  //     },
  //   },
  //   {
  //     label: "Chonburi",
  //     value: "Chonburi",
  //     url: "https://fonts.googleapis.com/css2?family=Chonburi",
  //     style: {
  //       fontFamily: "Chonburi",
  //     },
  //   },
  //   {
  //     label: "Cinzel",
  //     value: "Cinzel",
  //     url: "https://fonts.googleapis.com/css2?family=Cinzel",
  //     style: {
  //       fontFamily: "Cinzel",
  //     },
  //   },
  //   {
  //     label: "Cinzel Decorative",
  //     value: "Cinzel Decorative",
  //     url: "https://fonts.googleapis.com/css2?family=Cinzel Decorative",
  //     style: {
  //       fontFamily: "Cinzel Decorative",
  //     },
  //   },
  //   {
  //     label: "Clicker Script",
  //     value: "Clicker Script",
  //     url: "https://fonts.googleapis.com/css2?family=Clicker Script",
  //     style: {
  //       fontFamily: "Clicker Script",
  //     },
  //   },
  //   {
  //     label: "Coda",
  //     value: "Coda",
  //     url: "https://fonts.googleapis.com/css2?family=Coda",
  //     style: {
  //       fontFamily: "Coda",
  //     },
  //   },
  // {
  //     "label": "Coda Caption",
  //     "value": "Coda Caption",
  //     "url": "https://fonts.googleapis.com/css2?family=Coda Caption",
  //     "style": {
  //         "fontFamily": "Coda Caption"
  //     }
  // },
  //   {
  //     label: "Codystar",
  //     value: "Codystar",
  //     url: "https://fonts.googleapis.com/css2?family=Codystar",
  //     style: {
  //       fontFamily: "Codystar",
  //     },
  //   },
  //   {
  //     label: "Coiny",
  //     value: "Coiny",
  //     url: "https://fonts.googleapis.com/css2?family=Coiny",
  //     style: {
  //       fontFamily: "Coiny",
  //     },
  //   },
  //   {
  //     label: "Combo",
  //     value: "Combo",
  //     url: "https://fonts.googleapis.com/css2?family=Combo",
  //     style: {
  //       fontFamily: "Combo",
  //     },
  //   },
  //   {
  //     label: "Comfortaa",
  //     value: "Comfortaa",
  //     url: "https://fonts.googleapis.com/css2?family=Comfortaa",
  //     style: {
  //       fontFamily: "Comfortaa",
  //     },
  //   },
  //   {
  //     label: "Coming Soon",
  //     value: "Coming Soon",
  //     url: "https://fonts.googleapis.com/css2?family=Coming Soon",
  //     style: {
  //       fontFamily: "Coming Soon",
  //     },
  //   },
  {
    label: "Concert One",
    value: "Concert One",
    url: "https://fonts.googleapis.com/css2?family=Concert One",
    style: {
      fontFamily: "Concert One",
    },
  },
  //   {
  //     label: "Condiment",
  //     value: "Condiment",
  //     url: "https://fonts.googleapis.com/css2?family=Condiment",
  //     style: {
  //       fontFamily: "Condiment",
  //     },
  //   },
  //   {
  //     label: "Content",
  //     value: "Content",
  //     url: "https://fonts.googleapis.com/css2?family=Content",
  //     style: {
  //       fontFamily: "Content",
  //     },
  //   },
  //   {
  //     label: "Contrail One",
  //     value: "Contrail One",
  //     url: "https://fonts.googleapis.com/css2?family=Contrail One",
  //     style: {
  //       fontFamily: "Contrail One",
  //     },
  //   },
  //   {
  //     label: "Convergence",
  //     value: "Convergence",
  //     url: "https://fonts.googleapis.com/css2?family=Convergence",
  //     style: {
  //       fontFamily: "Convergence",
  //     },
  //   },
  {
    label: "Cookie",
    value: "Cookie",
    url: "https://fonts.googleapis.com/css2?family=Cookie",
    style: {
      fontFamily: "Cookie",
    },
  },
  //   {
  //     label: "Copse",
  //     value: "Copse",
  //     url: "https://fonts.googleapis.com/css2?family=Copse",
  //     style: {
  //       fontFamily: "Copse",
  //     },
  //   },
  //   {
  //     label: "Corben",
  //     value: "Corben",
  //     url: "https://fonts.googleapis.com/css2?family=Corben",
  //     style: {
  //       fontFamily: "Corben",
  //     },
  //   },
  //   {
  //     label: "Cormorant",
  //     value: "Cormorant",
  //     url: "https://fonts.googleapis.com/css2?family=Cormorant",
  //     style: {
  //       fontFamily: "Cormorant",
  //     },
  //   },
  //   {
  //     label: "Cormorant Garamond",
  //     value: "Cormorant Garamond",
  //     url: "https://fonts.googleapis.com/css2?family=Cormorant Garamond",
  //     style: {
  //       fontFamily: "Cormorant Garamond",
  //     },
  //   },
  //   {
  //     label: "Cormorant Infant",
  //     value: "Cormorant Infant",
  //     url: "https://fonts.googleapis.com/css2?family=Cormorant Infant",
  //     style: {
  //       fontFamily: "Cormorant Infant",
  //     },
  //   },
  //   {
  //     label: "Cormorant SC",
  //     value: "Cormorant SC",
  //     url: "https://fonts.googleapis.com/css2?family=Cormorant SC",
  //     style: {
  //       fontFamily: "Cormorant SC",
  //     },
  //   },
  //   {
  //     label: "Cormorant Unicase",
  //     value: "Cormorant Unicase",
  //     url: "https://fonts.googleapis.com/css2?family=Cormorant Unicase",
  //     style: {
  //       fontFamily: "Cormorant Unicase",
  //     },
  //   },
  //   {
  //     label: "Cormorant Upright",
  //     value: "Cormorant Upright",
  //     url: "https://fonts.googleapis.com/css2?family=Cormorant Upright",
  //     style: {
  //       fontFamily: "Cormorant Upright",
  //     },
  //   },
  {
    label: "Courgette",
    value: "Courgette",
    url: "https://fonts.googleapis.com/css2?family=Courgette",
    style: {
      fontFamily: "Courgette",
    },
  },
  //   {
  //     label: "Cousine",
  //     value: "Cousine",
  //     url: "https://fonts.googleapis.com/css2?family=Cousine",
  //     style: {
  //       fontFamily: "Cousine",
  //     },
  //   },
  //   {
  //     label: "Coustard",
  //     value: "Coustard",
  //     url: "https://fonts.googleapis.com/css2?family=Coustard",
  //     style: {
  //       fontFamily: "Coustard",
  //     },
  //   },
  //   {
  //     label: "Covered By Your Grace",
  //     value: "Covered By Your Grace",
  //     url: "https://fonts.googleapis.com/css2?family=Covered By Your Grace",
  //     style: {
  //       fontFamily: "Covered By Your Grace",
  //     },
  //   },
  //   {
  //     label: "Crafty Girls",
  //     value: "Crafty Girls",
  //     url: "https://fonts.googleapis.com/css2?family=Crafty Girls",
  //     style: {
  //       fontFamily: "Crafty Girls",
  //     },
  //   },
  //   {
  //     label: "Creepster",
  //     value: "Creepster",
  //     url: "https://fonts.googleapis.com/css2?family=Creepster",
  //     style: {
  //       fontFamily: "Creepster",
  //     },
  //   },
  //   {
  //     label: "Creepster Caps",
  //     value: "Creepster Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Creepster Caps",
  //     style: {
  //       fontFamily: "Creepster Caps",
  //     },
  //   },
  //   {
  //     label: "Crete Round",
  //     value: "Crete Round",
  //     url: "https://fonts.googleapis.com/css2?family=Crete Round",
  //     style: {
  //       fontFamily: "Crete Round",
  //     },
  //   },
  //   {
  //     label: "Crimson Pro",
  //     value: "Crimson Pro",
  //     url: "https://fonts.googleapis.com/css2?family=Crimson Pro",
  //     style: {
  //       fontFamily: "Crimson Pro",
  //     },
  //   },
  //   {
  //     label: "Crimson Text",
  //     value: "Crimson Text",
  //     url: "https://fonts.googleapis.com/css2?family=Crimson Text",
  //     style: {
  //       fontFamily: "Crimson Text",
  //     },
  //   },
  //   {
  //     label: "Croissant One",
  //     value: "Croissant One",
  //     url: "https://fonts.googleapis.com/css2?family=Croissant One",
  //     style: {
  //       fontFamily: "Croissant One",
  //     },
  //   },
  //   {
  //     label: "Crushed",
  //     value: "Crushed",
  //     url: "https://fonts.googleapis.com/css2?family=Crushed",
  //     style: {
  //       fontFamily: "Crushed",
  //     },
  //   },
  //   {
  //     label: "Cuprum",
  //     value: "Cuprum",
  //     url: "https://fonts.googleapis.com/css2?family=Cuprum",
  //     style: {
  //       fontFamily: "Cuprum",
  //     },
  //   },
  //   {
  //     label: "Cute Font",
  //     value: "Cute Font",
  //     url: "https://fonts.googleapis.com/css2?family=Cute Font",
  //     style: {
  //       fontFamily: "Cute Font",
  //     },
  //   },
  //   {
  //     label: "Cutive",
  //     value: "Cutive",
  //     url: "https://fonts.googleapis.com/css2?family=Cutive",
  //     style: {
  //       fontFamily: "Cutive",
  //     },
  //   },
  //   {
  //     label: "Cutive Mono",
  //     value: "Cutive Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Cutive Mono",
  //     style: {
  //       fontFamily: "Cutive Mono",
  //     },
  //   },
  //   {
  //     label: "Damion",
  //     value: "Damion",
  //     url: "https://fonts.googleapis.com/css2?family=Damion",
  //     style: {
  //       fontFamily: "Damion",
  //     },
  //   },
  {
    label: "Dancing Script",
    value: "Dancing Script",
    url: "https://fonts.googleapis.com/css2?family=Dancing Script",
    style: {
      fontFamily: "Dancing Script",
    },
  },
  //   {
  //     label: "Dangrek",
  //     value: "Dangrek",
  //     url: "https://fonts.googleapis.com/css2?family=Dangrek",
  //     style: {
  //       fontFamily: "Dangrek",
  //     },
  //   },
  //   {
  //     label: "Darker Grotesque",
  //     value: "Darker Grotesque",
  //     url: "https://fonts.googleapis.com/css2?family=Darker Grotesque",
  //     style: {
  //       fontFamily: "Darker Grotesque",
  //     },
  //   },
  //   {
  //     label: "Dawning of a New Day",
  //     value: "Dawning of a New Day",
  //     url: "https://fonts.googleapis.com/css2?family=Dawning of a New Day",
  //     style: {
  //       fontFamily: "Dawning of a New Day",
  //     },
  //   },
  //   {
  //     label: "Days One",
  //     value: "Days One",
  //     url: "https://fonts.googleapis.com/css2?family=Days One",
  //     style: {
  //       fontFamily: "Days One",
  //     },
  //   },
  // {
  //     "label": "decovaralpha",
  //     "value": "decovaralpha",
  //     "url": "https://fonts.googleapis.com/css2?family=decovaralpha",
  //     "style": {
  //         "fontFamily": "decovaralpha"
  //     }
  // },
  //   {
  //     label: "Dekko",
  //     value: "Dekko",
  //     url: "https://fonts.googleapis.com/css2?family=Dekko",
  //     style: {
  //       fontFamily: "Dekko",
  //     },
  //   },
  //   {
  //     label: "Delius",
  //     value: "Delius",
  //     url: "https://fonts.googleapis.com/css2?family=Delius",
  //     style: {
  //       fontFamily: "Delius",
  //     },
  //   },
  {
    label: "Delius Swash Caps",
    value: "Delius Swash Caps",
    url: "https://fonts.googleapis.com/css2?family=Delius Swash Caps",
    style: {
      fontFamily: "Delius Swash Caps",
    },
  },
  //   {
  //     label: "Delius Unicase",
  //     value: "Delius Unicase",
  //     url: "https://fonts.googleapis.com/css2?family=Delius Unicase",
  //     style: {
  //       fontFamily: "Delius Unicase",
  //     },
  //   },
  //   {
  //     label: "Della Respira",
  //     value: "Della Respira",
  //     url: "https://fonts.googleapis.com/css2?family=Della Respira",
  //     style: {
  //       fontFamily: "Della Respira",
  //     },
  //   },
  //   {
  //     label: "Denk One",
  //     value: "Denk One",
  //     url: "https://fonts.googleapis.com/css2?family=Denk One",
  //     style: {
  //       fontFamily: "Denk One",
  //     },
  //   },
  //   {
  //     label: "Devonshire",
  //     value: "Devonshire",
  //     url: "https://fonts.googleapis.com/css2?family=Devonshire",
  //     style: {
  //       fontFamily: "Devonshire",
  //     },
  //   },
  //   {
  //     label: "Dhurjati",
  //     value: "Dhurjati",
  //     url: "https://fonts.googleapis.com/css2?family=Dhurjati",
  //     style: {
  //       fontFamily: "Dhurjati",
  //     },
  //   },
  //   {
  //     label: "Dhyana",
  //     value: "Dhyana",
  //     url: "https://fonts.googleapis.com/css2?family=Dhyana",
  //     style: {
  //       fontFamily: "Dhyana",
  //     },
  //   },
  //   {
  //     label: "Didact Gothic",
  //     value: "Didact Gothic",
  //     url: "https://fonts.googleapis.com/css2?family=Didact Gothic",
  //     style: {
  //       fontFamily: "Didact Gothic",
  //     },
  //   },
  // {
  //     "label": "Digital Numbers",
  //     "value": "Digital Numbers",
  //     "url": "https://fonts.googleapis.com/css2?family=Digital Numbers",
  //     "style": {
  //         "fontFamily": "Digital Numbers"
  //     }
  // },
  //   {
  //     label: "Diplomata",
  //     value: "Diplomata",
  //     url: "https://fonts.googleapis.com/css2?family=Diplomata",
  //     style: {
  //       fontFamily: "Diplomata",
  //     },
  //   },
  //   {
  //     label: "Diplomata SC",
  //     value: "Diplomata SC",
  //     url: "https://fonts.googleapis.com/css2?family=Diplomata SC",
  //     style: {
  //       fontFamily: "Diplomata SC",
  //     },
  //   },
  //   {
  //     label: "DM Sans",
  //     value: "DM Sans",
  //     url: "https://fonts.googleapis.com/css2?family=DM Sans",
  //     style: {
  //       fontFamily: "DM Sans",
  //     },
  //   },
  //   {
  //     label: "DM Serif Display",
  //     value: "DM Serif Display",
  //     url: "https://fonts.googleapis.com/css2?family=DM Serif Display",
  //     style: {
  //       fontFamily: "DM Serif Display",
  //     },
  //   },
  //   {
  //     label: "DM Serif Text",
  //     value: "DM Serif Text",
  //     url: "https://fonts.googleapis.com/css2?family=DM Serif Text",
  //     style: {
  //       fontFamily: "DM Serif Text",
  //     },
  //   },
  //   {
  //     label: "Do Hyeon",
  //     value: "Do Hyeon",
  //     url: "https://fonts.googleapis.com/css2?family=Do Hyeon",
  //     style: {
  //       fontFamily: "Do Hyeon",
  //     },
  //   },
  //   {
  //     label: "Dokdo",
  //     value: "Dokdo",
  //     url: "https://fonts.googleapis.com/css2?family=Dokdo",
  //     style: {
  //       fontFamily: "Dokdo",
  //     },
  //   },
  //   {
  //     label: "Domine",
  //     value: "Domine",
  //     url: "https://fonts.googleapis.com/css2?family=Domine",
  //     style: {
  //       fontFamily: "Domine",
  //     },
  //   },
  //   {
  //     label: "Donegal One",
  //     value: "Donegal One",
  //     url: "https://fonts.googleapis.com/css2?family=Donegal One",
  //     style: {
  //       fontFamily: "Donegal One",
  //     },
  //   },
  //   {
  //     label: "Doppio One",
  //     value: "Doppio One",
  //     url: "https://fonts.googleapis.com/css2?family=Doppio One",
  //     style: {
  //       fontFamily: "Doppio One",
  //     },
  //   },
  //   {
  //     label: "Dorsa",
  //     value: "Dorsa",
  //     url: "https://fonts.googleapis.com/css2?family=Dorsa",
  //     style: {
  //       fontFamily: "Dorsa",
  //     },
  //   },
  //   {
  //     label: "Dosis",
  //     value: "Dosis",
  //     url: "https://fonts.googleapis.com/css2?family=Dosis",
  //     style: {
  //       fontFamily: "Dosis",
  //     },
  //   },
  //   {
  //     label: "Dr Sugiyama",
  //     value: "Dr Sugiyama",
  //     url: "https://fonts.googleapis.com/css2?family=Dr Sugiyama",
  //     style: {
  //       fontFamily: "Dr Sugiyama",
  //     },
  //   },
  //   {
  //     label: "Duru Sans",
  //     value: "Duru Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Duru Sans",
  //     style: {
  //       fontFamily: "Duru Sans",
  //     },
  //   },
  {
    label: "Dynalight",
    value: "Dynalight",
    url: "https://fonts.googleapis.com/css2?family=Dynalight",
    style: {
      fontFamily: "Dynalight",
    },
  },
  //   {
  //     label: "Eagle Lake",
  //     value: "Eagle Lake",
  //     url: "https://fonts.googleapis.com/css2?family=Eagle Lake",
  //     style: {
  //       fontFamily: "Eagle Lake",
  //     },
  //   },
  //   {
  //     label: "East Sea Dokdo",
  //     value: "East Sea Dokdo",
  //     url: "https://fonts.googleapis.com/css2?family=East Sea Dokdo",
  //     style: {
  //       fontFamily: "East Sea Dokdo",
  //     },
  //   },
  //   {
  //     label: "Eater",
  //     value: "Eater",
  //     url: "https://fonts.googleapis.com/css2?family=Eater",
  //     style: {
  //       fontFamily: "Eater",
  //     },
  //   },
  //   {
  //     label: "Eater Caps",
  //     value: "Eater Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Eater Caps",
  //     style: {
  //       fontFamily: "Eater Caps",
  //     },
  //   },
  //   {
  //     label: "EB Garamond",
  //     value: "EB Garamond",
  //     url: "https://fonts.googleapis.com/css2?family=EB Garamond",
  //     style: {
  //       fontFamily: "EB Garamond",
  //     },
  //   },
  //   {
  //     label: "Economica",
  //     value: "Economica",
  //     url: "https://fonts.googleapis.com/css2?family=Economica",
  //     style: {
  //       fontFamily: "Economica",
  //     },
  //   },
  //   {
  //     label: "Eczar",
  //     value: "Eczar",
  //     url: "https://fonts.googleapis.com/css2?family=Eczar",
  //     style: {
  //       fontFamily: "Eczar",
  //     },
  //   },
  //   {
  //     label: "Ek Mukta",
  //     value: "Ek Mukta",
  //     url: "https://fonts.googleapis.com/css2?family=Ek Mukta",
  //     style: {
  //       fontFamily: "Ek Mukta",
  //     },
  //   },
  //   {
  //     label: "El Messiri",
  //     value: "El Messiri",
  //     url: "https://fonts.googleapis.com/css2?family=El Messiri",
  //     style: {
  //       fontFamily: "El Messiri",
  //     },
  //   },
  //   {
  //     label: "Electrolize",
  //     value: "Electrolize",
  //     url: "https://fonts.googleapis.com/css2?family=Electrolize",
  //     style: {
  //       fontFamily: "Electrolize",
  //     },
  //   },
  //   {
  //     label: "Elsie",
  //     value: "Elsie",
  //     url: "https://fonts.googleapis.com/css2?family=Elsie",
  //     style: {
  //       fontFamily: "Elsie",
  //     },
  //   },
  //   {
  //     label: "Elsie Swash Caps",
  //     value: "Elsie Swash Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Elsie Swash Caps",
  //     style: {
  //       fontFamily: "Elsie Swash Caps",
  //     },
  //   },
  //   {
  //     label: "Emblema One",
  //     value: "Emblema One",
  //     url: "https://fonts.googleapis.com/css2?family=Emblema One",
  //     style: {
  //       fontFamily: "Emblema One",
  //     },
  //   },
  {
    label: "Emilys Candy",
    value: "Emilys Candy",
    url: "https://fonts.googleapis.com/css2?family=Emilys Candy",
    style: {
      fontFamily: "Emilys Candy",
    },
  },
  //   {
  //     label: "Encode Sans",
  //     value: "Encode Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Encode Sans",
  //     style: {
  //       fontFamily: "Encode Sans",
  //     },
  //   },
  //   {
  //     label: "Encode Sans Condensed",
  //     value: "Encode Sans Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Encode Sans Condensed",
  //     style: {
  //       fontFamily: "Encode Sans Condensed",
  //     },
  //   },
  //   {
  //     label: "Encode Sans Expanded",
  //     value: "Encode Sans Expanded",
  //     url: "https://fonts.googleapis.com/css2?family=Encode Sans Expanded",
  //     style: {
  //       fontFamily: "Encode Sans Expanded",
  //     },
  //   },
  //   {
  //     label: "Encode Sans Semi Condensed",
  //     value: "Encode Sans Semi Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Encode Sans Semi Condensed",
  //     style: {
  //       fontFamily: "Encode Sans Semi Condensed",
  //     },
  //   },
  //   {
  //     label: "Encode Sans Semi Expanded",
  //     value: "Encode Sans Semi Expanded",
  //     url: "https://fonts.googleapis.com/css2?family=Encode Sans Semi Expanded",
  //     style: {
  //       fontFamily: "Encode Sans Semi Expanded",
  //     },
  //   },
  //   {
  //     label: "Engagement",
  //     value: "Engagement",
  //     url: "https://fonts.googleapis.com/css2?family=Engagement",
  //     style: {
  //       fontFamily: "Engagement",
  //     },
  //   },
  //   {
  //     label: "Englebert",
  //     value: "Englebert",
  //     url: "https://fonts.googleapis.com/css2?family=Englebert",
  //     style: {
  //       fontFamily: "Englebert",
  //     },
  //   },
  //   {
  //     label: "Enriqueta",
  //     value: "Enriqueta",
  //     url: "https://fonts.googleapis.com/css2?family=Enriqueta",
  //     style: {
  //       fontFamily: "Enriqueta",
  //     },
  //   },
  //   {
  //     label: "Erica One",
  //     value: "Erica One",
  //     url: "https://fonts.googleapis.com/css2?family=Erica One",
  //     style: {
  //       fontFamily: "Erica One",
  //     },
  //   },
  //   {
  //     label: "Esteban",
  //     value: "Esteban",
  //     url: "https://fonts.googleapis.com/css2?family=Esteban",
  //     style: {
  //       fontFamily: "Esteban",
  //     },
  //   },
  {
    label: "Euphoria Script",
    value: "Euphoria Script",
    url: "https://fonts.googleapis.com/css2?family=Euphoria Script",
    style: {
      fontFamily: "Euphoria Script",
    },
  },
  //   {
  //     label: "Ewert",
  //     value: "Ewert",
  //     url: "https://fonts.googleapis.com/css2?family=Ewert",
  //     style: {
  //       fontFamily: "Ewert",
  //     },
  //   },
  //   {
  //     label: "Exo",
  //     value: "Exo",
  //     url: "https://fonts.googleapis.com/css2?family=Exo",
  //     style: {
  //       fontFamily: "Exo",
  //     },
  //   },
  //   {
  //     label: "Exo 2",
  //     value: "Exo 2",
  //     url: "https://fonts.googleapis.com/css2?family=Exo 2",
  //     style: {
  //       fontFamily: "Exo 2",
  //     },
  //   },
  //   {
  //     label: "Expletus Sans",
  //     value: "Expletus Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Expletus Sans",
  //     style: {
  //       fontFamily: "Expletus Sans",
  //     },
  //   },
  //   {
  //     label: "Fahkwang",
  //     value: "Fahkwang",
  //     url: "https://fonts.googleapis.com/css2?family=Fahkwang",
  //     style: {
  //       fontFamily: "Fahkwang",
  //     },
  //   },
  //   {
  //     label: "Fanwood Text",
  //     value: "Fanwood Text",
  //     url: "https://fonts.googleapis.com/css2?family=Fanwood Text",
  //     style: {
  //       fontFamily: "Fanwood Text",
  //     },
  //   },
  //   {
  //     label: "Farro",
  //     value: "Farro",
  //     url: "https://fonts.googleapis.com/css2?family=Farro",
  //     style: {
  //       fontFamily: "Farro",
  //     },
  //   },
  //   {
  //     label: "Farsan",
  //     value: "Farsan",
  //     url: "https://fonts.googleapis.com/css2?family=Farsan",
  //     style: {
  //       fontFamily: "Farsan",
  //     },
  //   },
  //   {
  //     label: "Fascinate",
  //     value: "Fascinate",
  //     url: "https://fonts.googleapis.com/css2?family=Fascinate",
  //     style: {
  //       fontFamily: "Fascinate",
  //     },
  //   },
  //   {
  //     label: "Fascinate Inline",
  //     value: "Fascinate Inline",
  //     url: "https://fonts.googleapis.com/css2?family=Fascinate Inline",
  //     style: {
  //       fontFamily: "Fascinate Inline",
  //     },
  //   },
  //   {
  //     label: "Faster One",
  //     value: "Faster One",
  //     url: "https://fonts.googleapis.com/css2?family=Faster One",
  //     style: {
  //       fontFamily: "Faster One",
  //     },
  //   },
  //   {
  //     label: "Fasthand",
  //     value: "Fasthand",
  //     url: "https://fonts.googleapis.com/css2?family=Fasthand",
  //     style: {
  //       fontFamily: "Fasthand",
  //     },
  //   },
  //   {
  //     label: "Fauna One",
  //     value: "Fauna One",
  //     url: "https://fonts.googleapis.com/css2?family=Fauna One",
  //     style: {
  //       fontFamily: "Fauna One",
  //     },
  //   },
  //   {
  //     label: "Faustina",
  //     value: "Faustina",
  //     url: "https://fonts.googleapis.com/css2?family=Faustina",
  //     style: {
  //       fontFamily: "Faustina",
  //     },
  //   },
  // {
  //     "label": "faustinavfbeta",
  //     "value": "faustinavfbeta",
  //     "url": "https://fonts.googleapis.com/css2?family=faustinavfbeta",
  //     "style": {
  //         "fontFamily": "faustinavfbeta"
  //     }
  // },
  //   {
  //     label: "Federant",
  //     value: "Federant",
  //     url: "https://fonts.googleapis.com/css2?family=Federant",
  //     style: {
  //       fontFamily: "Federant",
  //     },
  //   },
  //   {
  //     label: "Federo",
  //     value: "Federo",
  //     url: "https://fonts.googleapis.com/css2?family=Federo",
  //     style: {
  //       fontFamily: "Federo",
  //     },
  //   },
  //   {
  //     label: "Felipa",
  //     value: "Felipa",
  //     url: "https://fonts.googleapis.com/css2?family=Felipa",
  //     style: {
  //       fontFamily: "Felipa",
  //     },
  //   },
  //   {
  //     label: "Fenix",
  //     value: "Fenix",
  //     url: "https://fonts.googleapis.com/css2?family=Fenix",
  //     style: {
  //       fontFamily: "Fenix",
  //     },
  //   },
  //   {
  //     label: "Finger Paint",
  //     value: "Finger Paint",
  //     url: "https://fonts.googleapis.com/css2?family=Finger Paint",
  //     style: {
  //       fontFamily: "Finger Paint",
  //     },
  //   },
  //   {
  //     label: "Fira Code",
  //     value: "Fira Code",
  //     url: "https://fonts.googleapis.com/css2?family=Fira Code",
  //     style: {
  //       fontFamily: "Fira Code",
  //     },
  //   },
  //   {
  //     label: "Fira Mono",
  //     value: "Fira Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Fira Mono",
  //     style: {
  //       fontFamily: "Fira Mono",
  //     },
  //   },
  //   {
  //     label: "Fira Sans",
  //     value: "Fira Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Fira Sans",
  //     style: {
  //       fontFamily: "Fira Sans",
  //     },
  //   },
  //   {
  //     label: "Fira Sans Condensed",
  //     value: "Fira Sans Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Fira Sans Condensed",
  //     style: {
  //       fontFamily: "Fira Sans Condensed",
  //     },
  //   },
  //   {
  //     label: "Fira Sans Extra Condensed",
  //     value: "Fira Sans Extra Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Fira Sans Extra Condensed",
  //     style: {
  //       fontFamily: "Fira Sans Extra Condensed",
  //     },
  //   },
  //   {
  //     label: "Fjalla One",
  //     value: "Fjalla One",
  //     url: "https://fonts.googleapis.com/css2?family=Fjalla One",
  //     style: {
  //       fontFamily: "Fjalla One",
  //     },
  //   },
  //   {
  //     label: "Fjord One",
  //     value: "Fjord One",
  //     url: "https://fonts.googleapis.com/css2?family=Fjord One",
  //     style: {
  //       fontFamily: "Fjord One",
  //     },
  //   },
  //   {
  //     label: "Flamenco",
  //     value: "Flamenco",
  //     url: "https://fonts.googleapis.com/css2?family=Flamenco",
  //     style: {
  //       fontFamily: "Flamenco",
  //     },
  //   },
  //   {
  //     label: "Flavors",
  //     value: "Flavors",
  //     url: "https://fonts.googleapis.com/css2?family=Flavors",
  //     style: {
  //       fontFamily: "Flavors",
  //     },
  //   },
  //   {
  //     label: "Fondamento",
  //     value: "Fondamento",
  //     url: "https://fonts.googleapis.com/css2?family=Fondamento",
  //     style: {
  //       fontFamily: "Fondamento",
  //     },
  //   },
  //   {
  //     label: "Fontdiner Swanky",
  //     value: "Fontdiner Swanky",
  //     url: "https://fonts.googleapis.com/css2?family=Fontdiner Swanky",
  //     style: {
  //       fontFamily: "Fontdiner Swanky",
  //     },
  //   },
  //   {
  //     label: "Forum",
  //     value: "Forum",
  //     url: "https://fonts.googleapis.com/css2?family=Forum",
  //     style: {
  //       fontFamily: "Forum",
  //     },
  //   },
  //   {
  //     label: "Francois One",
  //     value: "Francois One",
  //     url: "https://fonts.googleapis.com/css2?family=Francois One",
  //     style: {
  //       fontFamily: "Francois One",
  //     },
  //   },
  //   {
  //     label: "Frank Ruhl Libre",
  //     value: "Frank Ruhl Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Frank Ruhl Libre",
  //     style: {
  //       fontFamily: "Frank Ruhl Libre",
  //     },
  //   },
  //   {
  //     label: "Freckle Face",
  //     value: "Freckle Face",
  //     url: "https://fonts.googleapis.com/css2?family=Freckle Face",
  //     style: {
  //       fontFamily: "Freckle Face",
  //     },
  //   },
  //   {
  //     label: "Fredericka the Great",
  //     value: "Fredericka the Great",
  //     url: "https://fonts.googleapis.com/css2?family=Fredericka the Great",
  //     style: {
  //       fontFamily: "Fredericka the Great",
  //     },
  //   },
  //   {
  //     label: "Fredoka One",
  //     value: "Fredoka One",
  //     url: "https://fonts.googleapis.com/css2?family=Fredoka One",
  //     style: {
  //       fontFamily: "Fredoka One",
  //     },
  //   },
  //   {
  //     label: "Freehand",
  //     value: "Freehand",
  //     url: "https://fonts.googleapis.com/css2?family=Freehand",
  //     style: {
  //       fontFamily: "Freehand",
  //     },
  //   },
  //   {
  //     label: "Fresca",
  //     value: "Fresca",
  //     url: "https://fonts.googleapis.com/css2?family=Fresca",
  //     style: {
  //       fontFamily: "Fresca",
  //     },
  //   },
  //   {
  //     label: "Frijole",
  //     value: "Frijole",
  //     url: "https://fonts.googleapis.com/css2?family=Frijole",
  //     style: {
  //       fontFamily: "Frijole",
  //     },
  //   },
  //   {
  //     label: "Fruktur",
  //     value: "Fruktur",
  //     url: "https://fonts.googleapis.com/css2?family=Fruktur",
  //     style: {
  //       fontFamily: "Fruktur",
  //     },
  //   },
  //   {
  //     label: "Fugaz One",
  //     value: "Fugaz One",
  //     url: "https://fonts.googleapis.com/css2?family=Fugaz One",
  //     style: {
  //       fontFamily: "Fugaz One",
  //     },
  //   },
  //   {
  //     label: "Gabriela",
  //     value: "Gabriela",
  //     url: "https://fonts.googleapis.com/css2?family=Gabriela",
  //     style: {
  //       fontFamily: "Gabriela",
  //     },
  //   },
  //   {
  //     label: "Gaegu",
  //     value: "Gaegu",
  //     url: "https://fonts.googleapis.com/css2?family=Gaegu",
  //     style: {
  //       fontFamily: "Gaegu",
  //     },
  //   },
  //   {
  //     label: "Gafata",
  //     value: "Gafata",
  //     url: "https://fonts.googleapis.com/css2?family=Gafata",
  //     style: {
  //       fontFamily: "Gafata",
  //     },
  //   },
  //   {
  //     label: "Galada",
  //     value: "Galada",
  //     url: "https://fonts.googleapis.com/css2?family=Galada",
  //     style: {
  //       fontFamily: "Galada",
  //     },
  //   },
  //   {
  //     label: "Galdeano",
  //     value: "Galdeano",
  //     url: "https://fonts.googleapis.com/css2?family=Galdeano",
  //     style: {
  //       fontFamily: "Galdeano",
  //     },
  //   },
  //   {
  //     label: "Galindo",
  //     value: "Galindo",
  //     url: "https://fonts.googleapis.com/css2?family=Galindo",
  //     style: {
  //       fontFamily: "Galindo",
  //     },
  //   },
  //   {
  //     label: "Gamja Flower",
  //     value: "Gamja Flower",
  //     url: "https://fonts.googleapis.com/css2?family=Gamja Flower",
  //     style: {
  //       fontFamily: "Gamja Flower",
  //     },
  //   },
  //   {
  //     label: "Gayathri",
  //     value: "Gayathri",
  //     url: "https://fonts.googleapis.com/css2?family=Gayathri",
  //     style: {
  //       fontFamily: "Gayathri",
  //     },
  //   },
  // {
  //     "label": "Gemunu Libre",
  //     "value": "Gemunu Libre",
  //     "url": "https://fonts.googleapis.com/css2?family=Gemunu Libre",
  //     "style": {
  //         "fontFamily": "Gemunu Libre"
  //     }
  // },
  //   {
  //     label: "Gentium Basic",
  //     value: "Gentium Basic",
  //     url: "https://fonts.googleapis.com/css2?family=Gentium Basic",
  //     style: {
  //       fontFamily: "Gentium Basic",
  //     },
  //   },
  //   {
  //     label: "Gentium Book Basic",
  //     value: "Gentium Book Basic",
  //     url: "https://fonts.googleapis.com/css2?family=Gentium Book Basic",
  //     style: {
  //       fontFamily: "Gentium Book Basic",
  //     },
  //   },
  //   {
  //     label: "Geo",
  //     value: "Geo",
  //     url: "https://fonts.googleapis.com/css2?family=Geo",
  //     style: {
  //       fontFamily: "Geo",
  //     },
  //   },
  //   {
  //     label: "Geostar",
  //     value: "Geostar",
  //     url: "https://fonts.googleapis.com/css2?family=Geostar",
  //     style: {
  //       fontFamily: "Geostar",
  //     },
  //   },
  //   {
  //     label: "Geostar Fill",
  //     value: "Geostar Fill",
  //     url: "https://fonts.googleapis.com/css2?family=Geostar Fill",
  //     style: {
  //       fontFamily: "Geostar Fill",
  //     },
  //   },
  {
    label: "Germania One",
    value: "Germania One",
    url: "https://fonts.googleapis.com/css2?family=Germania One",
    style: {
      fontFamily: "Germania One",
    },
  },
  //   {
  //     label: "GFS Didot",
  //     value: "GFS Didot",
  //     url: "https://fonts.googleapis.com/css2?family=GFS Didot",
  //     style: {
  //       fontFamily: "GFS Didot",
  //     },
  //   },
  //   {
  //     label: "GFS Neohellenic",
  //     value: "GFS Neohellenic",
  //     url: "https://fonts.googleapis.com/css2?family=GFS Neohellenic",
  //     style: {
  //       fontFamily: "GFS Neohellenic",
  //     },
  //   },
  //   {
  //     label: "Gidugu",
  //     value: "Gidugu",
  //     url: "https://fonts.googleapis.com/css2?family=Gidugu",
  //     style: {
  //       fontFamily: "Gidugu",
  //     },
  //   },
  //   {
  //     label: "Gilda Display",
  //     value: "Gilda Display",
  //     url: "https://fonts.googleapis.com/css2?family=Gilda Display",
  //     style: {
  //       fontFamily: "Gilda Display",
  //     },
  //   },
  //   {
  //     label: "Give You Glory",
  //     value: "Give You Glory",
  //     url: "https://fonts.googleapis.com/css2?family=Give You Glory",
  //     style: {
  //       fontFamily: "Give You Glory",
  //     },
  //   },
  //   {
  //     label: "Glass Antiqua",
  //     value: "Glass Antiqua",
  //     url: "https://fonts.googleapis.com/css2?family=Glass Antiqua",
  //     style: {
  //       fontFamily: "Glass Antiqua",
  //     },
  //   },
  //   {
  //     label: "Glegoo",
  //     value: "Glegoo",
  //     url: "https://fonts.googleapis.com/css2?family=Glegoo",
  //     style: {
  //       fontFamily: "Glegoo",
  //     },
  //   },
  //   {
  //     label: "Gloria Hallelujah",
  //     value: "Gloria Hallelujah",
  //     url: "https://fonts.googleapis.com/css2?family=Gloria Hallelujah",
  //     style: {
  //       fontFamily: "Gloria Hallelujah",
  //     },
  //   },
  //   {
  //     label: "Goblin One",
  //     value: "Goblin One",
  //     url: "https://fonts.googleapis.com/css2?family=Goblin One",
  //     style: {
  //       fontFamily: "Goblin One",
  //     },
  //   },
  //   {
  //     label: "Gochi Hand",
  //     value: "Gochi Hand",
  //     url: "https://fonts.googleapis.com/css2?family=Gochi Hand",
  //     style: {
  //       fontFamily: "Gochi Hand",
  //     },
  //   },
  //   {
  //     label: "Gorditas",
  //     value: "Gorditas",
  //     url: "https://fonts.googleapis.com/css2?family=Gorditas",
  //     style: {
  //       fontFamily: "Gorditas",
  //     },
  //   },
  //   {
  //     label: "Gothic A1",
  //     value: "Gothic A1",
  //     url: "https://fonts.googleapis.com/css2?family=Gothic A1",
  //     style: {
  //       fontFamily: "Gothic A1",
  //     },
  //   },
  //   {
  //     label: "Goudy Bookletter 1911",
  //     value: "Goudy Bookletter 1911",
  //     url: "https://fonts.googleapis.com/css2?family=Goudy Bookletter 1911",
  //     style: {
  //       fontFamily: "Goudy Bookletter 1911",
  //     },
  //   },
  //   {
  //     label: "Graduate",
  //     value: "Graduate",
  //     url: "https://fonts.googleapis.com/css2?family=Graduate",
  //     style: {
  //       fontFamily: "Graduate",
  //     },
  //   },
  {
    label: "Grand Hotel",
    value: "Grand Hotel",
    url: "https://fonts.googleapis.com/css2?family=Grand Hotel",
    style: {
      fontFamily: "Grand Hotel",
    },
  },
  //   {
  //     label: "Gravitas One",
  //     value: "Gravitas One",
  //     url: "https://fonts.googleapis.com/css2?family=Gravitas One",
  //     style: {
  //       fontFamily: "Gravitas One",
  //     },
  //   },
  {
    label: "Great Vibes",
    value: "Great Vibes",
    url: "https://fonts.googleapis.com/css2?family=Great Vibes",
    style: {
      fontFamily: "Great Vibes",
    },
  },
  //   {
  //     label: "Grenze",
  //     value: "Grenze",
  //     url: "https://fonts.googleapis.com/css2?family=Grenze",
  //     style: {
  //       fontFamily: "Grenze",
  //     },
  //   },
  //   {
  //     label: "Griffy",
  //     value: "Griffy",
  //     url: "https://fonts.googleapis.com/css2?family=Griffy",
  //     style: {
  //       fontFamily: "Griffy",
  //     },
  //   },
  //   {
  //     label: "Gruppo",
  //     value: "Gruppo",
  //     url: "https://fonts.googleapis.com/css2?family=Gruppo",
  //     style: {
  //       fontFamily: "Gruppo",
  //     },
  //   },
  //   {
  //     label: "Gudea",
  //     value: "Gudea",
  //     url: "https://fonts.googleapis.com/css2?family=Gudea",
  //     style: {
  //       fontFamily: "Gudea",
  //     },
  //   },
  //   {
  //     label: "Gugi",
  //     value: "Gugi",
  //     url: "https://fonts.googleapis.com/css2?family=Gugi",
  //     style: {
  //       fontFamily: "Gugi",
  //     },
  //   },
  //   {
  //     label: "Gurajada",
  //     value: "Gurajada",
  //     url: "https://fonts.googleapis.com/css2?family=Gurajada",
  //     style: {
  //       fontFamily: "Gurajada",
  //     },
  //   },
  //   {
  //     label: "Habibi",
  //     value: "Habibi",
  //     url: "https://fonts.googleapis.com/css2?family=Habibi",
  //     style: {
  //       fontFamily: "Habibi",
  //     },
  //   },
  //   {
  //     label: "Halant",
  //     value: "Halant",
  //     url: "https://fonts.googleapis.com/css2?family=Halant",
  //     style: {
  //       fontFamily: "Halant",
  //     },
  //   },
  //   {
  //     label: "Hammersmith One",
  //     value: "Hammersmith One",
  //     url: "https://fonts.googleapis.com/css2?family=Hammersmith One",
  //     style: {
  //       fontFamily: "Hammersmith One",
  //     },
  //   },
  //   {
  //     label: "Hanalei",
  //     value: "Hanalei",
  //     url: "https://fonts.googleapis.com/css2?family=Hanalei",
  //     style: {
  //       fontFamily: "Hanalei",
  //     },
  //   },
  //   {
  //     label: "Hanalei Fill",
  //     value: "Hanalei Fill",
  //     url: "https://fonts.googleapis.com/css2?family=Hanalei Fill",
  //     style: {
  //       fontFamily: "Hanalei Fill",
  //     },
  //   },
  //   {
  //     label: "Handlee",
  //     value: "Handlee",
  //     url: "https://fonts.googleapis.com/css2?family=Handlee",
  //     style: {
  //       fontFamily: "Handlee",
  //     },
  //   },
  // {
  //     "label": "hanna",
  //     "value": "hanna",
  //     "url": "https://fonts.googleapis.com/css2?family=hanna",
  //     "style": {
  //         "fontFamily": "hanna"
  //     }
  // },
  // {
  //     "label": "hannari",
  //     "value": "hannari",
  //     "url": "https://fonts.googleapis.com/css2?family=hannari",
  //     "style": {
  //         "fontFamily": "hannari"
  //     }
  // },
  //   {
  //     label: "Hanuman",
  //     value: "Hanuman",
  //     url: "https://fonts.googleapis.com/css2?family=Hanuman",
  //     style: {
  //       fontFamily: "Hanuman",
  //     },
  //   },
  //   {
  //     label: "Happy Monkey",
  //     value: "Happy Monkey",
  //     url: "https://fonts.googleapis.com/css2?family=Happy Monkey",
  //     style: {
  //       fontFamily: "Happy Monkey",
  //     },
  //   },
  //   {
  //     label: "Harmattan",
  //     value: "Harmattan",
  //     url: "https://fonts.googleapis.com/css2?family=Harmattan",
  //     style: {
  //       fontFamily: "Harmattan",
  //     },
  //   },
  //   {
  //     label: "Headland One",
  //     value: "Headland One",
  //     url: "https://fonts.googleapis.com/css2?family=Headland One",
  //     style: {
  //       fontFamily: "Headland One",
  //     },
  //   },
  //   {
  //     label: "Heebo",
  //     value: "Heebo",
  //     url: "https://fonts.googleapis.com/css2?family=Heebo",
  //     style: {
  //       fontFamily: "Heebo",
  //     },
  //   },
  //   {
  //     label: "Henny Penny",
  //     value: "Henny Penny",
  //     url: "https://fonts.googleapis.com/css2?family=Henny Penny",
  //     style: {
  //       fontFamily: "Henny Penny",
  //     },
  //   },
  // {
  //     "label": "Hermeneus One",
  //     "value": "Hermeneus One",
  //     "url": "https://fonts.googleapis.com/css2?family=Hermeneus One",
  //     "style": {
  //         "fontFamily": "Hermeneus One"
  //     }
  // },
  //   {
  //     label: "Herr Von Muellerhoff",
  //     value: "Herr Von Muellerhoff",
  //     url: "https://fonts.googleapis.com/css2?family=Herr Von Muellerhoff",
  //     style: {
  //       fontFamily: "Herr Von Muellerhoff",
  //     },
  //   },
  //   {
  //     label: "Hi Melody",
  //     value: "Hi Melody",
  //     url: "https://fonts.googleapis.com/css2?family=Hi Melody",
  //     style: {
  //       fontFamily: "Hi Melody",
  //     },
  //   },
  //   {
  //     label: "Hind",
  //     value: "Hind",
  //     url: "https://fonts.googleapis.com/css2?family=Hind",
  //     style: {
  //       fontFamily: "Hind",
  //     },
  //   },
  // {
  //     "label": "Hind Colombo",
  //     "value": "Hind Colombo",
  //     "url": "https://fonts.googleapis.com/css2?family=Hind Colombo",
  //     "style": {
  //         "fontFamily": "Hind Colombo"
  //     }
  // },
  //   {
  //     label: "Hind Guntur",
  //     value: "Hind Guntur",
  //     url: "https://fonts.googleapis.com/css2?family=Hind Guntur",
  //     style: {
  //       fontFamily: "Hind Guntur",
  //     },
  //   },
  // {
  //     "label": "Hind Jalandhar",
  //     "value": "Hind Jalandhar",
  //     "url": "https://fonts.googleapis.com/css2?family=Hind Jalandhar",
  //     "style": {
  //         "fontFamily": "Hind Jalandhar"
  //     }
  // },
  // {
  //     "label": "Hind Kochi",
  //     "value": "Hind Kochi",
  //     "url": "https://fonts.googleapis.com/css2?family=Hind Kochi",
  //     "style": {
  //         "fontFamily": "Hind Kochi"
  //     }
  // },
  //   {
  //     label: "Hind Madurai",
  //     value: "Hind Madurai",
  //     url: "https://fonts.googleapis.com/css2?family=Hind Madurai",
  //     style: {
  //       fontFamily: "Hind Madurai",
  //     },
  //   },
  // {
  //     "label": "Hind Mysuru",
  //     "value": "Hind Mysuru",
  //     "url": "https://fonts.googleapis.com/css2?family=Hind Mysuru",
  //     "style": {
  //         "fontFamily": "Hind Mysuru"
  //     }
  // },
  //   {
  //     label: "Hind Siliguri",
  //     value: "Hind Siliguri",
  //     url: "https://fonts.googleapis.com/css2?family=Hind Siliguri",
  //     style: {
  //       fontFamily: "Hind Siliguri",
  //     },
  //   },
  //   {
  //     label: "Hind Vadodara",
  //     value: "Hind Vadodara",
  //     url: "https://fonts.googleapis.com/css2?family=Hind Vadodara",
  //     style: {
  //       fontFamily: "Hind Vadodara",
  //     },
  //   },
  //   {
  //     label: "Holtwood One SC",
  //     value: "Holtwood One SC",
  //     url: "https://fonts.googleapis.com/css2?family=Holtwood One SC",
  //     style: {
  //       fontFamily: "Holtwood One SC",
  //     },
  //   },
  //   {
  //     label: "Homemade Apple",
  //     value: "Homemade Apple",
  //     url: "https://fonts.googleapis.com/css2?family=Homemade Apple",
  //     style: {
  //       fontFamily: "Homemade Apple",
  //     },
  //   },
  //   {
  //     label: "Homenaje",
  //     value: "Homenaje",
  //     url: "https://fonts.googleapis.com/css2?family=Homenaje",
  //     style: {
  //       fontFamily: "Homenaje",
  //     },
  //   },
  //   {
  //     label: "IBM Plex Mono",
  //     value: "IBM Plex Mono",
  //     url: "https://fonts.googleapis.com/css2?family=IBM Plex Mono",
  //     style: {
  //       fontFamily: "IBM Plex Mono",
  //     },
  //   },
  //   {
  //     label: "IBM Plex Sans",
  //     value: "IBM Plex Sans",
  //     url: "https://fonts.googleapis.com/css2?family=IBM Plex Sans",
  //     style: {
  //       fontFamily: "IBM Plex Sans",
  //     },
  //   },
  //   {
  //     label: "IBM Plex Sans Condensed",
  //     value: "IBM Plex Sans Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=IBM Plex Sans Condensed",
  //     style: {
  //       fontFamily: "IBM Plex Sans Condensed",
  //     },
  //   },
  //   {
  //     label: "IBM Plex Serif",
  //     value: "IBM Plex Serif",
  //     url: "https://fonts.googleapis.com/css2?family=IBM Plex Serif",
  //     style: {
  //       fontFamily: "IBM Plex Serif",
  //     },
  //   },
  //   {
  //     label: "Iceberg",
  //     value: "Iceberg",
  //     url: "https://fonts.googleapis.com/css2?family=Iceberg",
  //     style: {
  //       fontFamily: "Iceberg",
  //     },
  //   },
  //   {
  //     label: "Iceland",
  //     value: "Iceland",
  //     url: "https://fonts.googleapis.com/css2?family=Iceland",
  //     style: {
  //       fontFamily: "Iceland",
  //     },
  //   },
  //   {
  //     label: "IM Fell Double Pica",
  //     value: "IM Fell Double Pica",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell Double Pica",
  //     style: {
  //       fontFamily: "IM Fell Double Pica",
  //     },
  //   },
  //   {
  //     label: "IM Fell Double Pica SC",
  //     value: "IM Fell Double Pica SC",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell Double Pica SC",
  //     style: {
  //       fontFamily: "IM Fell Double Pica SC",
  //     },
  //   },
  //   {
  //     label: "IM Fell DW Pica",
  //     value: "IM Fell DW Pica",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell DW Pica",
  //     style: {
  //       fontFamily: "IM Fell DW Pica",
  //     },
  //   },
  //   {
  //     label: "IM Fell DW Pica SC",
  //     value: "IM Fell DW Pica SC",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell DW Pica SC",
  //     style: {
  //       fontFamily: "IM Fell DW Pica SC",
  //     },
  //   },
  //   {
  //     label: "IM Fell English",
  //     value: "IM Fell English",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell English",
  //     style: {
  //       fontFamily: "IM Fell English",
  //     },
  //   },
  //   {
  //     label: "IM Fell English SC",
  //     value: "IM Fell English SC",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell English SC",
  //     style: {
  //       fontFamily: "IM Fell English SC",
  //     },
  //   },
  //   {
  //     label: "IM Fell French Canon",
  //     value: "IM Fell French Canon",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell French Canon",
  //     style: {
  //       fontFamily: "IM Fell French Canon",
  //     },
  //   },
  //   {
  //     label: "IM Fell French Canon SC",
  //     value: "IM Fell French Canon SC",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell French Canon SC",
  //     style: {
  //       fontFamily: "IM Fell French Canon SC",
  //     },
  //   },
  //   {
  //     label: "IM Fell Great Primer",
  //     value: "IM Fell Great Primer",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell Great Primer",
  //     style: {
  //       fontFamily: "IM Fell Great Primer",
  //     },
  //   },
  //   {
  //     label: "IM Fell Great Primer SC",
  //     value: "IM Fell Great Primer SC",
  //     url: "https://fonts.googleapis.com/css2?family=IM Fell Great Primer SC",
  //     style: {
  //       fontFamily: "IM Fell Great Primer SC",
  //     },
  //   },
  //   {
  //     label: "Imprima",
  //     value: "Imprima",
  //     url: "https://fonts.googleapis.com/css2?family=Imprima",
  //     style: {
  //       fontFamily: "Imprima",
  //     },
  //   },
  //   {
  //     label: "Inconsolata",
  //     value: "Inconsolata",
  //     url: "https://fonts.googleapis.com/css2?family=Inconsolata",
  //     style: {
  //       fontFamily: "Inconsolata",
  //     },
  //   },
  //   {
  //     label: "Inder",
  //     value: "Inder",
  //     url: "https://fonts.googleapis.com/css2?family=Inder",
  //     style: {
  //       fontFamily: "Inder",
  //     },
  //   },
  //   {
  //     label: "Indie Flower",
  //     value: "Indie Flower",
  //     url: "https://fonts.googleapis.com/css2?family=Indie Flower",
  //     style: {
  //       fontFamily: "Indie Flower",
  //     },
  //   },
  //   {
  //     label: "Inika",
  //     value: "Inika",
  //     url: "https://fonts.googleapis.com/css2?family=Inika",
  //     style: {
  //       fontFamily: "Inika",
  //     },
  //   },
  //   {
  //     label: "Inknut Antiqua",
  //     value: "Inknut Antiqua",
  //     url: "https://fonts.googleapis.com/css2?family=Inknut Antiqua",
  //     style: {
  //       fontFamily: "Inknut Antiqua",
  //     },
  //   },
  //   {
  //     label: "Irish Grover",
  //     value: "Irish Grover",
  //     url: "https://fonts.googleapis.com/css2?family=Irish Grover",
  //     style: {
  //       fontFamily: "Irish Grover",
  //     },
  //   },
  //   {
  //     label: "Istok Web",
  //     value: "Istok Web",
  //     url: "https://fonts.googleapis.com/css2?family=Istok Web",
  //     style: {
  //       fontFamily: "Istok Web",
  //     },
  //   },
  //   {
  //     label: "Italiana",
  //     value: "Italiana",
  //     url: "https://fonts.googleapis.com/css2?family=Italiana",
  //     style: {
  //       fontFamily: "Italiana",
  //     },
  //   },
  //   {
  //     label: "Italianno",
  //     value: "Italianno",
  //     url: "https://fonts.googleapis.com/css2?family=Italianno",
  //     style: {
  //       fontFamily: "Italianno",
  //     },
  //   },
  //   {
  //     label: "Itim",
  //     value: "Itim",
  //     url: "https://fonts.googleapis.com/css2?family=Itim",
  //     style: {
  //       fontFamily: "Itim",
  //     },
  //   },
  //   {
  //     label: "Jacques Francois",
  //     value: "Jacques Francois",
  //     url: "https://fonts.googleapis.com/css2?family=Jacques Francois",
  //     style: {
  //       fontFamily: "Jacques Francois",
  //     },
  //   },
  //   {
  //     label: "Jacques Francois Shadow",
  //     value: "Jacques Francois Shadow",
  //     url: "https://fonts.googleapis.com/css2?family=Jacques Francois Shadow",
  //     style: {
  //       fontFamily: "Jacques Francois Shadow",
  //     },
  //   },
  //   {
  //     label: "Jaldi",
  //     value: "Jaldi",
  //     url: "https://fonts.googleapis.com/css2?family=Jaldi",
  //     style: {
  //       fontFamily: "Jaldi",
  //     },
  //   },
  // {
  //     "label": "jejugothic",
  //     "value": "jejugothic",
  //     "url": "https://fonts.googleapis.com/css2?family=jejugothic",
  //     "style": {
  //         "fontFamily": "jejugothic"
  //     }
  // },
  // {
  //     "label": "jejuhallasan",
  //     "value": "jejuhallasan",
  //     "url": "https://fonts.googleapis.com/css2?family=jejuhallasan",
  //     "style": {
  //         "fontFamily": "jejuhallasan"
  //     }
  // },
  // {
  //     "label": "jejumyeongjo",
  //     "value": "jejumyeongjo",
  //     "url": "https://fonts.googleapis.com/css2?family=jejumyeongjo",
  //     "style": {
  //         "fontFamily": "jejumyeongjo"
  //     }
  // },
  //   {
  //     label: "Jim Nightshade",
  //     value: "Jim Nightshade",
  //     url: "https://fonts.googleapis.com/css2?family=Jim Nightshade",
  //     style: {
  //       fontFamily: "Jim Nightshade",
  //     },
  //   },
  //   {
  //     label: "Jockey One",
  //     value: "Jockey One",
  //     url: "https://fonts.googleapis.com/css2?family=Jockey One",
  //     style: {
  //       fontFamily: "Jockey One",
  //     },
  //   },
  //   {
  //     label: "Jolly Lodger",
  //     value: "Jolly Lodger",
  //     url: "https://fonts.googleapis.com/css2?family=Jolly Lodger",
  //     style: {
  //       fontFamily: "Jolly Lodger",
  //     },
  //   },
  //   {
  //     label: "Jomhuria",
  //     value: "Jomhuria",
  //     url: "https://fonts.googleapis.com/css2?family=Jomhuria",
  //     style: {
  //       fontFamily: "Jomhuria",
  //     },
  //   },
  // {
  //     "label": "jomolhari",
  //     "value": "jomolhari",
  //     "url": "https://fonts.googleapis.com/css2?family=jomolhari",
  //     "style": {
  //         "fontFamily": "jomolhari"
  //     }
  // },
  //   {
  //     label: "Josefin Sans",
  //     value: "Josefin Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Josefin Sans",
  //     style: {
  //       fontFamily: "Josefin Sans",
  //     },
  //   },
  //   {
  //     label: "Josefin Sans Std Light",
  //     value: "Josefin Sans Std Light",
  //     url: "https://fonts.googleapis.com/css2?family=Josefin Sans Std Light",
  //     style: {
  //       fontFamily: "Josefin Sans Std Light",
  //     },
  //   },
  //   {
  //     label: "Josefin Slab",
  //     value: "Josefin Slab",
  //     url: "https://fonts.googleapis.com/css2?family=Josefin Slab",
  //     style: {
  //       fontFamily: "Josefin Slab",
  //     },
  //   },
  //   {
  //     label: "Joti One",
  //     value: "Joti One",
  //     url: "https://fonts.googleapis.com/css2?family=Joti One",
  //     style: {
  //       fontFamily: "Joti One",
  //     },
  //   },
  //   {
  //     label: "Jua",
  //     value: "Jua",
  //     url: "https://fonts.googleapis.com/css2?family=Jua",
  //     style: {
  //       fontFamily: "Jua",
  //     },
  //   },
  //   {
  //     label: "Judson",
  //     value: "Judson",
  //     url: "https://fonts.googleapis.com/css2?family=Judson",
  //     style: {
  //       fontFamily: "Judson",
  //     },
  //   },
  //   {
  //     label: "Julee",
  //     value: "Julee",
  //     url: "https://fonts.googleapis.com/css2?family=Julee",
  //     style: {
  //       fontFamily: "Julee",
  //     },
  //   },
  //   {
  //     label: "Julius Sans One",
  //     value: "Julius Sans One",
  //     url: "https://fonts.googleapis.com/css2?family=Julius Sans One",
  //     style: {
  //       fontFamily: "Julius Sans One",
  //     },
  //   },
  //   {
  //     label: "Junge",
  //     value: "Junge",
  //     url: "https://fonts.googleapis.com/css2?family=Junge",
  //     style: {
  //       fontFamily: "Junge",
  //     },
  //   },
  //   {
  //     label: "Jura",
  //     value: "Jura",
  //     url: "https://fonts.googleapis.com/css2?family=Jura",
  //     style: {
  //       fontFamily: "Jura",
  //     },
  //   },
  //   {
  //     label: "Just Another Hand",
  //     value: "Just Another Hand",
  //     url: "https://fonts.googleapis.com/css2?family=Just Another Hand",
  //     style: {
  //       fontFamily: "Just Another Hand",
  //     },
  //   },
  //   {
  //     label: "Just Me Again Down Here",
  //     value: "Just Me Again Down Here",
  //     url: "https://fonts.googleapis.com/css2?family=Just Me Again Down Here",
  //     style: {
  //       fontFamily: "Just Me Again Down Here",
  //     },
  //   },
  //   {
  //     label: "K2D",
  //     value: "K2D",
  //     url: "https://fonts.googleapis.com/css2?family=K2D",
  //     style: {
  //       fontFamily: "K2D",
  //     },
  //   },
  //   {
  //     label: "Kadwa",
  //     value: "Kadwa",
  //     url: "https://fonts.googleapis.com/css2?family=Kadwa",
  //     style: {
  //       fontFamily: "Kadwa",
  //     },
  //   },
  //   {
  //     label: "Kalam",
  //     value: "Kalam",
  //     url: "https://fonts.googleapis.com/css2?family=Kalam",
  //     style: {
  //       fontFamily: "Kalam",
  //     },
  //   },
  //   {
  //     label: "Kameron",
  //     value: "Kameron",
  //     url: "https://fonts.googleapis.com/css2?family=Kameron",
  //     style: {
  //       fontFamily: "Kameron",
  //     },
  //   },
  //   {
  //     label: "Kanit",
  //     value: "Kanit",
  //     url: "https://fonts.googleapis.com/css2?family=Kanit",
  //     style: {
  //       fontFamily: "Kanit",
  //     },
  //   },
  //   {
  //     label: "Kantumruy",
  //     value: "Kantumruy",
  //     url: "https://fonts.googleapis.com/css2?family=Kantumruy",
  //     style: {
  //       fontFamily: "Kantumruy",
  //     },
  //   },
  //   {
  //     label: "Karla",
  //     value: "Karla",
  //     url: "https://fonts.googleapis.com/css2?family=Karla",
  //     style: {
  //       fontFamily: "Karla",
  //     },
  //   },
  // {
  //     "label": "Karla Tamil Inclined",
  //     "value": "Karla Tamil Inclined",
  //     "url": "https://fonts.googleapis.com/css2?family=Karla Tamil Inclined",
  //     "style": {
  //         "fontFamily": "Karla Tamil Inclined"
  //     }
  // },
  // {
  //     "label": "Karla Tamil Upright",
  //     "value": "Karla Tamil Upright",
  //     "url": "https://fonts.googleapis.com/css2?family=Karla Tamil Upright",
  //     "style": {
  //         "fontFamily": "Karla Tamil Upright"
  //     }
  // },
  //   {
  //     label: "Karma",
  //     value: "Karma",
  //     url: "https://fonts.googleapis.com/css2?family=Karma",
  //     style: {
  //       fontFamily: "Karma",
  //     },
  //   },
  //   {
  //     label: "Katibeh",
  //     value: "Katibeh",
  //     url: "https://fonts.googleapis.com/css2?family=Katibeh",
  //     style: {
  //       fontFamily: "Katibeh",
  //     },
  //   },
  {
    label: "Kaushan Script",
    value: "Kaushan Script",
    url: "https://fonts.googleapis.com/css2?family=Kaushan Script",
    style: {
      fontFamily: "Kaushan Script",
    },
  },
  //   {
  //     label: "Kavivanar",
  //     value: "Kavivanar",
  //     url: "https://fonts.googleapis.com/css2?family=Kavivanar",
  //     style: {
  //       fontFamily: "Kavivanar",
  //     },
  //   },
  //   {
  //     label: "Kavoon",
  //     value: "Kavoon",
  //     url: "https://fonts.googleapis.com/css2?family=Kavoon",
  //     style: {
  //       fontFamily: "Kavoon",
  //     },
  //   },
  //   {
  //     label: "Kdam Thmor",
  //     value: "Kdam Thmor",
  //     url: "https://fonts.googleapis.com/css2?family=Kdam Thmor",
  //     style: {
  //       fontFamily: "Kdam Thmor",
  //     },
  //   },
  //   {
  //     label: "Keania One",
  //     value: "Keania One",
  //     url: "https://fonts.googleapis.com/css2?family=Keania One",
  //     style: {
  //       fontFamily: "Keania One",
  //     },
  //   },
  //   {
  //     label: "Kelly Slab",
  //     value: "Kelly Slab",
  //     url: "https://fonts.googleapis.com/css2?family=Kelly Slab",
  //     style: {
  //       fontFamily: "Kelly Slab",
  //     },
  //   },
  //   {
  //     label: "Kenia",
  //     value: "Kenia",
  //     url: "https://fonts.googleapis.com/css2?family=Kenia",
  //     style: {
  //       fontFamily: "Kenia",
  //     },
  //   },
  //   {
  //     label: "Khand",
  //     value: "Khand",
  //     url: "https://fonts.googleapis.com/css2?family=Khand",
  //     style: {
  //       fontFamily: "Khand",
  //     },
  //   },
  //   {
  //     label: "Khmer",
  //     value: "Khmer",
  //     url: "https://fonts.googleapis.com/css2?family=Khmer",
  //     style: {
  //       fontFamily: "Khmer",
  //     },
  //   },
  //   {
  //     label: "Khula",
  //     value: "Khula",
  //     url: "https://fonts.googleapis.com/css2?family=Khula",
  //     style: {
  //       fontFamily: "Khula",
  //     },
  //   },
  // {
  //     "label": "khyay",
  //     "value": "khyay",
  //     "url": "https://fonts.googleapis.com/css2?family=khyay",
  //     "style": {
  //         "fontFamily": "khyay"
  //     }
  // },
  //   {
  //     label: "Kirang Haerang",
  //     value: "Kirang Haerang",
  //     url: "https://fonts.googleapis.com/css2?family=Kirang Haerang",
  //     style: {
  //       fontFamily: "Kirang Haerang",
  //     },
  //   },
  //   {
  //     label: "Kite One",
  //     value: "Kite One",
  //     url: "https://fonts.googleapis.com/css2?family=Kite One",
  //     style: {
  //       fontFamily: "Kite One",
  //     },
  //   },
  //   {
  //     label: "Knewave",
  //     value: "Knewave",
  //     url: "https://fonts.googleapis.com/css2?family=Knewave",
  //     style: {
  //       fontFamily: "Knewave",
  //     },
  //   },
  //   {
  //     label: "Kodchasan",
  //     value: "Kodchasan",
  //     url: "https://fonts.googleapis.com/css2?family=Kodchasan",
  //     style: {
  //       fontFamily: "Kodchasan",
  //     },
  //   },
  //   {
  //     label: "KoHo",
  //     value: "KoHo",
  //     url: "https://fonts.googleapis.com/css2?family=KoHo",
  //     style: {
  //       fontFamily: "KoHo",
  //     },
  //   },
  // {
  //     "label": "kokoro",
  //     "value": "kokoro",
  //     "url": "https://fonts.googleapis.com/css2?family=kokoro",
  //     "style": {
  //         "fontFamily": "kokoro"
  //     }
  // },
  // {
  //     "label": "kopubbatang",
  //     "value": "kopubbatang",
  //     "url": "https://fonts.googleapis.com/css2?family=kopubbatang",
  //     "style": {
  //         "fontFamily": "kopubbatang"
  //     }
  // },
  //   {
  //     label: "Kotta One",
  //     value: "Kotta One",
  //     url: "https://fonts.googleapis.com/css2?family=Kotta One",
  //     style: {
  //       fontFamily: "Kotta One",
  //     },
  //   },
  //   {
  //     label: "Koulen",
  //     value: "Koulen",
  //     url: "https://fonts.googleapis.com/css2?family=Koulen",
  //     style: {
  //       fontFamily: "Koulen",
  //     },
  //   },
  //   {
  //     label: "Kranky",
  //     value: "Kranky",
  //     url: "https://fonts.googleapis.com/css2?family=Kranky",
  //     style: {
  //       fontFamily: "Kranky",
  //     },
  //   },
  //   {
  //     label: "Kreon",
  //     value: "Kreon",
  //     url: "https://fonts.googleapis.com/css2?family=Kreon",
  //     style: {
  //       fontFamily: "Kreon",
  //     },
  //   },
  //   {
  //     label: "Kristi",
  //     value: "Kristi",
  //     url: "https://fonts.googleapis.com/css2?family=Kristi",
  //     style: {
  //       fontFamily: "Kristi",
  //     },
  //   },
  //   {
  //     label: "Krona One",
  //     value: "Krona One",
  //     url: "https://fonts.googleapis.com/css2?family=Krona One",
  //     style: {
  //       fontFamily: "Krona One",
  //     },
  //   },
  //   {
  //     label: "Krub",
  //     value: "Krub",
  //     url: "https://fonts.googleapis.com/css2?family=Krub",
  //     style: {
  //       fontFamily: "Krub",
  //     },
  //   },
  //   {
  //     label: "Kumar One",
  //     value: "Kumar One",
  //     url: "https://fonts.googleapis.com/css2?family=Kumar One",
  //     style: {
  //       fontFamily: "Kumar One",
  //     },
  //   },
  //   {
  //     label: "Kurale",
  //     value: "Kurale",
  //     url: "https://fonts.googleapis.com/css2?family=Kurale",
  //     style: {
  //       fontFamily: "Kurale",
  //     },
  //   },
  //   {
  //     label: "La Belle Aurore",
  //     value: "La Belle Aurore",
  //     url: "https://fonts.googleapis.com/css2?family=La Belle Aurore",
  //     style: {
  //       fontFamily: "La Belle Aurore",
  //     },
  //   },
  //   {
  //     label: "Lacquer",
  //     value: "Lacquer",
  //     url: "https://fonts.googleapis.com/css2?family=Lacquer",
  //     style: {
  //       fontFamily: "Lacquer",
  //     },
  //   },
  //   {
  //     label: "Laila",
  //     value: "Laila",
  //     url: "https://fonts.googleapis.com/css2?family=Laila",
  //     style: {
  //       fontFamily: "Laila",
  //     },
  //   },
  //   {
  //     label: "Lakki Reddy",
  //     value: "Lakki Reddy",
  //     url: "https://fonts.googleapis.com/css2?family=Lakki Reddy",
  //     style: {
  //       fontFamily: "Lakki Reddy",
  //     },
  //   },
  //   {
  //     label: "Lalezar",
  //     value: "Lalezar",
  //     url: "https://fonts.googleapis.com/css2?family=Lalezar",
  //     style: {
  //       fontFamily: "Lalezar",
  //     },
  //   },
  {
    label: "Lancelot",
    value: "Lancelot",
    url: "https://fonts.googleapis.com/css2?family=Lancelot",
    style: {
      fontFamily: "Lancelot",
    },
  },
  // {
  //     "label": "laomuangdon",
  //     "value": "laomuangdon",
  //     "url": "https://fonts.googleapis.com/css2?family=laomuangdon",
  //     "style": {
  //         "fontFamily": "laomuangdon"
  //     }
  // },
  // {
  //     "label": "laomuangkhong",
  //     "value": "laomuangkhong",
  //     "url": "https://fonts.googleapis.com/css2?family=laomuangkhong",
  //     "style": {
  //         "fontFamily": "laomuangkhong"
  //     }
  // },
  // {
  //     "label": "laosanspro",
  //     "value": "laosanspro",
  //     "url": "https://fonts.googleapis.com/css2?family=laosanspro",
  //     "style": {
  //         "fontFamily": "laosanspro"
  //     }
  // },
  //   {
  //     label: "Lateef",
  //     value: "Lateef",
  //     url: "https://fonts.googleapis.com/css2?family=Lateef",
  //     style: {
  //       fontFamily: "Lateef",
  //     },
  //   },
  //   {
  //     label: "Lato",
  //     value: "Lato",
  //     url: "https://fonts.googleapis.com/css2?family=Lato",
  //     style: {
  //       fontFamily: "Lato",
  //     },
  //   },
  //   {
  //     label: "League Script",
  //     value: "League Script",
  //     url: "https://fonts.googleapis.com/css2?family=League Script",
  //     style: {
  //       fontFamily: "League Script",
  //     },
  //   },
  //   {
  //     label: "Leckerli One",
  //     value: "Leckerli One",
  //     url: "https://fonts.googleapis.com/css2?family=Leckerli One",
  //     style: {
  //       fontFamily: "Leckerli One",
  //     },
  //   },
  //   {
  //     label: "Ledger",
  //     value: "Ledger",
  //     url: "https://fonts.googleapis.com/css2?family=Ledger",
  //     style: {
  //       fontFamily: "Ledger",
  //     },
  //   },
  //   {
  //     label: "Lekton",
  //     value: "Lekton",
  //     url: "https://fonts.googleapis.com/css2?family=Lekton",
  //     style: {
  //       fontFamily: "Lekton",
  //     },
  //   },
  //   {
  //     label: "Lemon",
  //     value: "Lemon",
  //     url: "https://fonts.googleapis.com/css2?family=Lemon",
  //     style: {
  //       fontFamily: "Lemon",
  //     },
  //   },
  //   {
  //     label: "Lemonada",
  //     value: "Lemonada",
  //     url: "https://fonts.googleapis.com/css2?family=Lemonada",
  //     style: {
  //       fontFamily: "Lemonada",
  //     },
  //   },
  //   {
  //     label: "Libre Barcode 128",
  //     value: "Libre Barcode 128",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Barcode 128",
  //     style: {
  //       fontFamily: "Libre Barcode 128",
  //     },
  //   },
  //   {
  //     label: "Libre Barcode 128 Text",
  //     value: "Libre Barcode 128 Text",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Barcode 128 Text",
  //     style: {
  //       fontFamily: "Libre Barcode 128 Text",
  //     },
  //   },
  //   {
  //     label: "Libre Barcode 39",
  //     value: "Libre Barcode 39",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Barcode 39",
  //     style: {
  //       fontFamily: "Libre Barcode 39",
  //     },
  //   },
  //   {
  //     label: "Libre Barcode 39 Extended",
  //     value: "Libre Barcode 39 Extended",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Barcode 39 Extended",
  //     style: {
  //       fontFamily: "Libre Barcode 39 Extended",
  //     },
  //   },
  //   {
  //     label: "Libre Barcode 39 Extended Text",
  //     value: "Libre Barcode 39 Extended Text",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Barcode 39 Extended Text",
  //     style: {
  //       fontFamily: "Libre Barcode 39 Extended Text",
  //     },
  //   },
  //   {
  //     label: "Libre Barcode 39 Text",
  //     value: "Libre Barcode 39 Text",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Barcode 39 Text",
  //     style: {
  //       fontFamily: "Libre Barcode 39 Text",
  //     },
  //   },
  //   {
  //     label: "Libre Baskerville",
  //     value: "Libre Baskerville",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Baskerville",
  //     style: {
  //       fontFamily: "Libre Baskerville",
  //     },
  //   },
  //   {
  //     label: "Libre Caslon Display",
  //     value: "Libre Caslon Display",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Caslon Display",
  //     style: {
  //       fontFamily: "Libre Caslon Display",
  //     },
  //   },
  //   {
  //     label: "Libre Caslon Text",
  //     value: "Libre Caslon Text",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Caslon Text",
  //     style: {
  //       fontFamily: "Libre Caslon Text",
  //     },
  //   },
  //   {
  //     label: "Libre Franklin",
  //     value: "Libre Franklin",
  //     url: "https://fonts.googleapis.com/css2?family=Libre Franklin",
  //     style: {
  //       fontFamily: "Libre Franklin",
  //     },
  //   },
  //   {
  //     label: "Life Savers",
  //     value: "Life Savers",
  //     url: "https://fonts.googleapis.com/css2?family=Life Savers",
  //     style: {
  //       fontFamily: "Life Savers",
  //     },
  //   },
  //   {
  //     label: "Lilita One",
  //     value: "Lilita One",
  //     url: "https://fonts.googleapis.com/css2?family=Lilita One",
  //     style: {
  //       fontFamily: "Lilita One",
  //     },
  //   },
  //   {
  //     label: "Lily Script One",
  //     value: "Lily Script One",
  //     url: "https://fonts.googleapis.com/css2?family=Lily Script One",
  //     style: {
  //       fontFamily: "Lily Script One",
  //     },
  //   },
  {
    label: "Limelight",
    value: "Limelight",
    url: "https://fonts.googleapis.com/css2?family=Limelight",
    style: {
      fontFamily: "Limelight",
    },
  },
  //   {
  //     label: "Linden Hill",
  //     value: "Linden Hill",
  //     url: "https://fonts.googleapis.com/css2?family=Linden Hill",
  //     style: {
  //       fontFamily: "Linden Hill",
  //     },
  //   },
  //   {
  //     label: "Literata",
  //     value: "Literata",
  //     url: "https://fonts.googleapis.com/css2?family=Literata",
  //     style: {
  //       fontFamily: "Literata",
  //     },
  //   },
  //   {
  //     label: "Liu Jian Mao Cao",
  //     value: "Liu Jian Mao Cao",
  //     url: "https://fonts.googleapis.com/css2?family=Liu Jian Mao Cao",
  //     style: {
  //       fontFamily: "Liu Jian Mao Cao",
  //     },
  //   },
  // {
  //     "label": "LiuJianMaoCao",
  //     "value": "LiuJianMaoCao",
  //     "url": "https://fonts.googleapis.com/css2?family=LiuJianMaoCao",
  //     "style": {
  //         "fontFamily": "LiuJianMaoCao"
  //     }
  // },
  //   {
  //     label: "Livvic",
  //     value: "Livvic",
  //     url: "https://fonts.googleapis.com/css2?family=Livvic",
  //     style: {
  //       fontFamily: "Livvic",
  //     },
  //   },
  //   {
  //     label: "Lobster",
  //     value: "Lobster",
  //     url: "https://fonts.googleapis.com/css2?family=Lobster",
  //     style: {
  //       fontFamily: "Lobster",
  //     },
  //   },
  {
    label: "Lobster Two",
    value: "Lobster Two",
    url: "https://fonts.googleapis.com/css2?family=Lobster Two",
    style: {
      fontFamily: "Lobster Two",
    },
  },
  //   {
  //     label: "Lohit Bengali",
  //     value: "Lohit Bengali",
  //     url: "https://fonts.googleapis.com/css2?family=Lohit Bengali",
  //     style: {
  //       fontFamily: "Lohit Bengali",
  //     },
  //   },
  //   {
  //     label: "Lohit Tamil",
  //     value: "Lohit Tamil",
  //     url: "https://fonts.googleapis.com/css2?family=Lohit Tamil",
  //     style: {
  //       fontFamily: "Lohit Tamil",
  //     },
  //   },
  // {
  //     "label": "lohitdevanagari",
  //     "value": "lohitdevanagari",
  //     "url": "https://fonts.googleapis.com/css2?family=lohitdevanagari",
  //     "style": {
  //         "fontFamily": "lohitdevanagari"
  //     }
  // },
  //   {
  //     label: "Londrina Outline",
  //     value: "Londrina Outline",
  //     url: "https://fonts.googleapis.com/css2?family=Londrina Outline",
  //     style: {
  //       fontFamily: "Londrina Outline",
  //     },
  //   },
  //   {
  //     label: "Londrina Shadow",
  //     value: "Londrina Shadow",
  //     url: "https://fonts.googleapis.com/css2?family=Londrina Shadow",
  //     style: {
  //       fontFamily: "Londrina Shadow",
  //     },
  //   },
  //   {
  //     label: "Londrina Sketch",
  //     value: "Londrina Sketch",
  //     url: "https://fonts.googleapis.com/css2?family=Londrina Sketch",
  //     style: {
  //       fontFamily: "Londrina Sketch",
  //     },
  //   },
  //   {
  //     label: "Londrina Solid",
  //     value: "Londrina Solid",
  //     url: "https://fonts.googleapis.com/css2?family=Londrina Solid",
  //     style: {
  //       fontFamily: "Londrina Solid",
  //     },
  //   },
  //   {
  //     label: "Long Cang",
  //     value: "Long Cang",
  //     url: "https://fonts.googleapis.com/css2?family=Long Cang",
  //     style: {
  //       fontFamily: "Long Cang",
  //     },
  //   },
  // {
  //     "label": "LongCang",
  //     "value": "LongCang",
  //     "url": "https://fonts.googleapis.com/css2?family=LongCang",
  //     "style": {
  //         "fontFamily": "LongCang"
  //     }
  // },
  //   {
  //     label: "Lora",
  //     value: "Lora",
  //     url: "https://fonts.googleapis.com/css2?family=Lora",
  //     style: {
  //       fontFamily: "Lora",
  //     },
  //   },
  //   {
  //     label: "Love Ya Like A Sister",
  //     value: "Love Ya Like A Sister",
  //     url: "https://fonts.googleapis.com/css2?family=Love Ya Like A Sister",
  //     style: {
  //       fontFamily: "Love Ya Like A Sister",
  //     },
  //   },
  //   {
  //     label: "Loved by the King",
  //     value: "Loved by the King",
  //     url: "https://fonts.googleapis.com/css2?family=Loved by the King",
  //     style: {
  //       fontFamily: "Loved by the King",
  //     },
  //   },
  //   {
  //     label: "Lovers Quarrel",
  //     value: "Lovers Quarrel",
  //     url: "https://fonts.googleapis.com/css2?family=Lovers Quarrel",
  //     style: {
  //       fontFamily: "Lovers Quarrel",
  //     },
  //   },
  //   {
  //     label: "Luckiest Guy",
  //     value: "Luckiest Guy",
  //     url: "https://fonts.googleapis.com/css2?family=Luckiest Guy",
  //     style: {
  //       fontFamily: "Luckiest Guy",
  //     },
  //   },
  //   {
  //     label: "Lusitana",
  //     value: "Lusitana",
  //     url: "https://fonts.googleapis.com/css2?family=Lusitana",
  //     style: {
  //       fontFamily: "Lusitana",
  //     },
  //   },
  //   {
  //     label: "Lustria",
  //     value: "Lustria",
  //     url: "https://fonts.googleapis.com/css2?family=Lustria",
  //     style: {
  //       fontFamily: "Lustria",
  //     },
  //   },
  //   {
  //     label: "Ma Shan Zheng",
  //     value: "Ma Shan Zheng",
  //     url: "https://fonts.googleapis.com/css2?family=Ma Shan Zheng",
  //     style: {
  //       fontFamily: "Ma Shan Zheng",
  //     },
  //   },
  //   {
  //     label: "Macondo",
  //     value: "Macondo",
  //     url: "https://fonts.googleapis.com/css2?family=Macondo",
  //     style: {
  //       fontFamily: "Macondo",
  //     },
  //   },
  //   {
  //     label: "Macondo Swash Caps",
  //     value: "Macondo Swash Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Macondo Swash Caps",
  //     style: {
  //       fontFamily: "Macondo Swash Caps",
  //     },
  //   },
  //   {
  //     label: "Mada",
  //     value: "Mada",
  //     url: "https://fonts.googleapis.com/css2?family=Mada",
  //     style: {
  //       fontFamily: "Mada",
  //     },
  //   },
  //   {
  //     label: "Magra",
  //     value: "Magra",
  //     url: "https://fonts.googleapis.com/css2?family=Magra",
  //     style: {
  //       fontFamily: "Magra",
  //     },
  //   },
  //   {
  //     label: "Maiden Orange",
  //     value: "Maiden Orange",
  //     url: "https://fonts.googleapis.com/css2?family=Maiden Orange",
  //     style: {
  //       fontFamily: "Maiden Orange",
  //     },
  //   },
  //   {
  //     label: "Maitree",
  //     value: "Maitree",
  //     url: "https://fonts.googleapis.com/css2?family=Maitree",
  //     style: {
  //       fontFamily: "Maitree",
  //     },
  //   },
  //   {
  //     label: "Major Mono Display",
  //     value: "Major Mono Display",
  //     url: "https://fonts.googleapis.com/css2?family=Major Mono Display",
  //     style: {
  //       fontFamily: "Major Mono Display",
  //     },
  //   },
  //   {
  //     label: "Mako",
  //     value: "Mako",
  //     url: "https://fonts.googleapis.com/css2?family=Mako",
  //     style: {
  //       fontFamily: "Mako",
  //     },
  //   },
  //   {
  //     label: "Mali",
  //     value: "Mali",
  //     url: "https://fonts.googleapis.com/css2?family=Mali",
  //     style: {
  //       fontFamily: "Mali",
  //     },
  //   },
  //   {
  //     label: "Mallanna",
  //     value: "Mallanna",
  //     url: "https://fonts.googleapis.com/css2?family=Mallanna",
  //     style: {
  //       fontFamily: "Mallanna",
  //     },
  //   },
  //   {
  //     label: "Mandali",
  //     value: "Mandali",
  //     url: "https://fonts.googleapis.com/css2?family=Mandali",
  //     style: {
  //       fontFamily: "Mandali",
  //     },
  //   },
  //   {
  //     label: "Manjari",
  //     value: "Manjari",
  //     url: "https://fonts.googleapis.com/css2?family=Manjari",
  //     style: {
  //       fontFamily: "Manjari",
  //     },
  //   },
  //   {
  //     label: "Manuale",
  //     value: "Manuale",
  //     url: "https://fonts.googleapis.com/css2?family=Manuale",
  //     style: {
  //       fontFamily: "Manuale",
  //     },
  //   },
  //   {
  //     label: "Marcellus",
  //     value: "Marcellus",
  //     url: "https://fonts.googleapis.com/css2?family=Marcellus",
  //     style: {
  //       fontFamily: "Marcellus",
  //     },
  //   },
  //   {
  //     label: "Marcellus SC",
  //     value: "Marcellus SC",
  //     url: "https://fonts.googleapis.com/css2?family=Marcellus SC",
  //     style: {
  //       fontFamily: "Marcellus SC",
  //     },
  //   },
  //   {
  //     label: "Marck Script",
  //     value: "Marck Script",
  //     url: "https://fonts.googleapis.com/css2?family=Marck Script",
  //     style: {
  //       fontFamily: "Marck Script",
  //     },
  //   },
  //   {
  //     label: "Margarine",
  //     value: "Margarine",
  //     url: "https://fonts.googleapis.com/css2?family=Margarine",
  //     style: {
  //       fontFamily: "Margarine",
  //     },
  //   },
  //   {
  //     label: "Marko One",
  //     value: "Marko One",
  //     url: "https://fonts.googleapis.com/css2?family=Marko One",
  //     style: {
  //       fontFamily: "Marko One",
  //     },
  //   },
  //   {
  //     label: "Marmelad",
  //     value: "Marmelad",
  //     url: "https://fonts.googleapis.com/css2?family=Marmelad",
  //     style: {
  //       fontFamily: "Marmelad",
  //     },
  //   },
  //   {
  //     label: "Martel",
  //     value: "Martel",
  //     url: "https://fonts.googleapis.com/css2?family=Martel",
  //     style: {
  //       fontFamily: "Martel",
  //     },
  //   },
  //   {
  //     label: "Martel Sans",
  //     value: "Martel Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Martel Sans",
  //     style: {
  //       fontFamily: "Martel Sans",
  //     },
  //   },
  //   {
  //     label: "Marvel",
  //     value: "Marvel",
  //     url: "https://fonts.googleapis.com/css2?family=Marvel",
  //     style: {
  //       fontFamily: "Marvel",
  //     },
  //   },
  // {
  //     "label": "MaShanZheng",
  //     "value": "MaShanZheng",
  //     "url": "https://fonts.googleapis.com/css2?family=MaShanZheng",
  //     "style": {
  //         "fontFamily": "MaShanZheng"
  //     }
  // },
  //   {
  //     label: "Mate",
  //     value: "Mate",
  //     url: "https://fonts.googleapis.com/css2?family=Mate",
  //     style: {
  //       fontFamily: "Mate",
  //     },
  //   },
  //   {
  //     label: "Mate SC",
  //     value: "Mate SC",
  //     url: "https://fonts.googleapis.com/css2?family=Mate SC",
  //     style: {
  //       fontFamily: "Mate SC",
  //     },
  //   },
  //   {
  //     label: "Maven Pro",
  //     value: "Maven Pro",
  //     url: "https://fonts.googleapis.com/css2?family=Maven Pro",
  //     style: {
  //       fontFamily: "Maven Pro",
  //     },
  //   },
  // {
  //     "label": "mavenprovfbeta",
  //     "value": "mavenprovfbeta",
  //     "url": "https://fonts.googleapis.com/css2?family=mavenprovfbeta",
  //     "style": {
  //         "fontFamily": "mavenprovfbeta"
  //     }
  // },
  //   {
  //     label: "McLaren",
  //     value: "McLaren",
  //     url: "https://fonts.googleapis.com/css2?family=McLaren",
  //     style: {
  //       fontFamily: "McLaren",
  //     },
  //   },
  //   {
  //     label: "Meddon",
  //     value: "Meddon",
  //     url: "https://fonts.googleapis.com/css2?family=Meddon",
  //     style: {
  //       fontFamily: "Meddon",
  //     },
  //   },
  //   {
  //     label: "MedievalSharp",
  //     value: "MedievalSharp",
  //     url: "https://fonts.googleapis.com/css2?family=MedievalSharp",
  //     style: {
  //       fontFamily: "MedievalSharp",
  //     },
  //   },
  //   {
  //     label: "Medula One",
  //     value: "Medula One",
  //     url: "https://fonts.googleapis.com/css2?family=Medula One",
  //     style: {
  //       fontFamily: "Medula One",
  //     },
  //   },
  //   {
  //     label: "Meera Inimai",
  //     value: "Meera Inimai",
  //     url: "https://fonts.googleapis.com/css2?family=Meera Inimai",
  //     style: {
  //       fontFamily: "Meera Inimai",
  //     },
  //   },
  //   {
  //     label: "Megrim",
  //     value: "Megrim",
  //     url: "https://fonts.googleapis.com/css2?family=Megrim",
  //     style: {
  //       fontFamily: "Megrim",
  //     },
  //   },
  //   {
  //     label: "Meie Script",
  //     value: "Meie Script",
  //     url: "https://fonts.googleapis.com/css2?family=Meie Script",
  //     style: {
  //       fontFamily: "Meie Script",
  //     },
  //   },
  // {
  //     "label": "Merge One",
  //     "value": "Merge One",
  //     "url": "https://fonts.googleapis.com/css2?family=Merge One",
  //     "style": {
  //         "fontFamily": "Merge One"
  //     }
  // },
  //   {
  //     label: "Merienda",
  //     value: "Merienda",
  //     url: "https://fonts.googleapis.com/css2?family=Merienda",
  //     style: {
  //       fontFamily: "Merienda",
  //     },
  //   },
  //   {
  //     label: "Merienda One",
  //     value: "Merienda One",
  //     url: "https://fonts.googleapis.com/css2?family=Merienda One",
  //     style: {
  //       fontFamily: "Merienda One",
  //     },
  //   },
  //   {
  //     label: "Merriweather",
  //     value: "Merriweather",
  //     url: "https://fonts.googleapis.com/css2?family=Merriweather",
  //     style: {
  //       fontFamily: "Merriweather",
  //     },
  //   },
  //   {
  //     label: "Merriweather Sans",
  //     value: "Merriweather Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Merriweather Sans",
  //     style: {
  //       fontFamily: "Merriweather Sans",
  //     },
  //   },
  // {
  //     "label": "Mervale Script",
  //     "value": "Mervale Script",
  //     "url": "https://fonts.googleapis.com/css2?family=Mervale Script",
  //     "style": {
  //         "fontFamily": "Mervale Script"
  //     }
  // },
  //   {
  //     label: "Metal",
  //     value: "Metal",
  //     url: "https://fonts.googleapis.com/css2?family=Metal",
  //     style: {
  //       fontFamily: "Metal",
  //     },
  //   },
  //   {
  //     label: "Metal Mania",
  //     value: "Metal Mania",
  //     url: "https://fonts.googleapis.com/css2?family=Metal Mania",
  //     style: {
  //       fontFamily: "Metal Mania",
  //     },
  //   },
  //   {
  //     label: "Metamorphous",
  //     value: "Metamorphous",
  //     url: "https://fonts.googleapis.com/css2?family=Metamorphous",
  //     style: {
  //       fontFamily: "Metamorphous",
  //     },
  //   },
  //   {
  //     label: "Metrophobic",
  //     value: "Metrophobic",
  //     url: "https://fonts.googleapis.com/css2?family=Metrophobic",
  //     style: {
  //       fontFamily: "Metrophobic",
  //     },
  //   },
  // {
  //     "label": "Miama",
  //     "value": "Miama",
  //     "url": "https://fonts.googleapis.com/css2?family=Miama",
  //     "style": {
  //         "fontFamily": "Miama"
  //     }
  // },
  //   {
  //     label: "Michroma",
  //     value: "Michroma",
  //     url: "https://fonts.googleapis.com/css2?family=Michroma",
  //     style: {
  //       fontFamily: "Michroma",
  //     },
  //   },
  //   {
  //     label: "Milonga",
  //     value: "Milonga",
  //     url: "https://fonts.googleapis.com/css2?family=Milonga",
  //     style: {
  //       fontFamily: "Milonga",
  //     },
  //   },
  //   {
  //     label: "Miltonian",
  //     value: "Miltonian",
  //     url: "https://fonts.googleapis.com/css2?family=Miltonian",
  //     style: {
  //       fontFamily: "Miltonian",
  //     },
  //   },
  //   {
  //     label: "Miltonian Tattoo",
  //     value: "Miltonian Tattoo",
  //     url: "https://fonts.googleapis.com/css2?family=Miltonian Tattoo",
  //     style: {
  //       fontFamily: "Miltonian Tattoo",
  //     },
  //   },
  //   {
  //     label: "Mina",
  //     value: "Mina",
  //     url: "https://fonts.googleapis.com/css2?family=Mina",
  //     style: {
  //       fontFamily: "Mina",
  //     },
  //   },
  //   {
  //     label: "Miniver",
  //     value: "Miniver",
  //     url: "https://fonts.googleapis.com/css2?family=Miniver",
  //     style: {
  //       fontFamily: "Miniver",
  //     },
  //   },
  //   {
  //     label: "Miriam Libre",
  //     value: "Miriam Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Miriam Libre",
  //     style: {
  //       fontFamily: "Miriam Libre",
  //     },
  //   },
  //   {
  //     label: "Miss Fajardose",
  //     value: "Miss Fajardose",
  //     url: "https://fonts.googleapis.com/css2?family=Miss Fajardose",
  //     style: {
  //       fontFamily: "Miss Fajardose",
  //     },
  //   },
  //   {
  //     label: "Miss Saint Delafield",
  //     value: "Miss Saint Delafield",
  //     url: "https://fonts.googleapis.com/css2?family=Miss Saint Delafield",
  //     style: {
  //       fontFamily: "Miss Saint Delafield",
  //     },
  //   },
  //   {
  //     label: "Modak",
  //     value: "Modak",
  //     url: "https://fonts.googleapis.com/css2?family=Modak",
  //     style: {
  //       fontFamily: "Modak",
  //     },
  //   },
  //   {
  //     label: "Modern Antiqua",
  //     value: "Modern Antiqua",
  //     url: "https://fonts.googleapis.com/css2?family=Modern Antiqua",
  //     style: {
  //       fontFamily: "Modern Antiqua",
  //     },
  //   },
  //   {
  //     label: "Molengo",
  //     value: "Molengo",
  //     url: "https://fonts.googleapis.com/css2?family=Molengo",
  //     style: {
  //       fontFamily: "Molengo",
  //     },
  //   },
  // {
  //     "label": "Molle",
  //     "value": "Molle",
  //     "url": "https://fonts.googleapis.com/css2?family=Molle",
  //     "style": {
  //         "fontFamily": "Molle"
  //     }
  // },
  //   {
  //     label: "Monda",
  //     value: "Monda",
  //     url: "https://fonts.googleapis.com/css2?family=Monda",
  //     style: {
  //       fontFamily: "Monda",
  //     },
  //   },
  //   {
  //     label: "Monofett",
  //     value: "Monofett",
  //     url: "https://fonts.googleapis.com/css2?family=Monofett",
  //     style: {
  //       fontFamily: "Monofett",
  //     },
  //   },
  //   {
  //     label: "Monoton",
  //     value: "Monoton",
  //     url: "https://fonts.googleapis.com/css2?family=Monoton",
  //     style: {
  //       fontFamily: "Monoton",
  //     },
  //   },
  //   {
  //     label: "Monsieur La Doulaise",
  //     value: "Monsieur La Doulaise",
  //     url: "https://fonts.googleapis.com/css2?family=Monsieur La Doulaise",
  //     style: {
  //       fontFamily: "Monsieur La Doulaise",
  //     },
  //   },
  //   {
  //     label: "Montaga",
  //     value: "Montaga",
  //     url: "https://fonts.googleapis.com/css2?family=Montaga",
  //     style: {
  //       fontFamily: "Montaga",
  //     },
  //   },
  //   {
  //     label: "Montez",
  //     value: "Montez",
  //     url: "https://fonts.googleapis.com/css2?family=Montez",
  //     style: {
  //       fontFamily: "Montez",
  //     },
  //   },
  {
    label: "Montserrat",
    value: "Montserrat",
    url: "https://fonts.googleapis.com/css2?family=Montserrat",
    style: {
      fontFamily: "Montserrat",
    },
  },
  //   {
  //     label: "Montserrat Alternates",
  //     value: "Montserrat Alternates",
  //     url: "https://fonts.googleapis.com/css2?family=Montserrat Alternates",
  //     style: {
  //       fontFamily: "Montserrat Alternates",
  //     },
  //   },
  //   {
  //     label: "Montserrat Subrayada",
  //     value: "Montserrat Subrayada",
  //     url: "https://fonts.googleapis.com/css2?family=Montserrat Subrayada",
  //     style: {
  //       fontFamily: "Montserrat Subrayada",
  //     },
  //   },
  //   {
  //     label: "Moul",
  //     value: "Moul",
  //     url: "https://fonts.googleapis.com/css2?family=Moul",
  //     style: {
  //       fontFamily: "Moul",
  //     },
  //   },
  //   {
  //     label: "Moulpali",
  //     value: "Moulpali",
  //     url: "https://fonts.googleapis.com/css2?family=Moulpali",
  //     style: {
  //       fontFamily: "Moulpali",
  //     },
  //   },
  //   {
  //     label: "Mountains of Christmas",
  //     value: "Mountains of Christmas",
  //     url: "https://fonts.googleapis.com/css2?family=Mountains of Christmas",
  //     style: {
  //       fontFamily: "Mountains of Christmas",
  //     },
  //   },
  //   {
  //     label: "Mouse Memoirs",
  //     value: "Mouse Memoirs",
  //     url: "https://fonts.googleapis.com/css2?family=Mouse Memoirs",
  //     style: {
  //       fontFamily: "Mouse Memoirs",
  //     },
  //   },
  // {
  //     "label": "mplus1p",
  //     "value": "mplus1p",
  //     "url": "https://fonts.googleapis.com/css2?family=mplus1p",
  //     "style": {
  //         "fontFamily": "mplus1p"
  //     }
  // },
  //   {
  //     label: "Mr Bedford",
  //     value: "Mr Bedford",
  //     url: "https://fonts.googleapis.com/css2?family=Mr Bedford",
  //     style: {
  //       fontFamily: "Mr Bedford",
  //     },
  //   },
  //   {
  //     label: "Mr Bedfort",
  //     value: "Mr Bedfort",
  //     url: "https://fonts.googleapis.com/css2?family=Mr Bedfort",
  //     style: {
  //       fontFamily: "Mr Bedfort",
  //     },
  //   },
  //   {
  //     label: "Mr Dafoe",
  //     value: "Mr Dafoe",
  //     url: "https://fonts.googleapis.com/css2?family=Mr Dafoe",
  //     style: {
  //       fontFamily: "Mr Dafoe",
  //     },
  //   },
  //   {
  //     label: "Mr De Haviland",
  //     value: "Mr De Haviland",
  //     url: "https://fonts.googleapis.com/css2?family=Mr De Haviland",
  //     style: {
  //       fontFamily: "Mr De Haviland",
  //     },
  //   },
  //   {
  //     label: "Mrs Saint Delafield",
  //     value: "Mrs Saint Delafield",
  //     url: "https://fonts.googleapis.com/css2?family=Mrs Saint Delafield",
  //     style: {
  //       fontFamily: "Mrs Saint Delafield",
  //     },
  //   },
  //   {
  //     label: "Mrs Sheppards",
  //     value: "Mrs Sheppards",
  //     url: "https://fonts.googleapis.com/css2?family=Mrs Sheppards",
  //     style: {
  //       fontFamily: "Mrs Sheppards",
  //     },
  //   },
  //   {
  //     label: "Mukta",
  //     value: "Mukta",
  //     url: "https://fonts.googleapis.com/css2?family=Mukta",
  //     style: {
  //       fontFamily: "Mukta",
  //     },
  //   },
  //   {
  //     label: "Mukta Mahee",
  //     value: "Mukta Mahee",
  //     url: "https://fonts.googleapis.com/css2?family=Mukta Mahee",
  //     style: {
  //       fontFamily: "Mukta Mahee",
  //     },
  //   },
  //   {
  //     label: "Mukta Malar",
  //     value: "Mukta Malar",
  //     url: "https://fonts.googleapis.com/css2?family=Mukta Malar",
  //     style: {
  //       fontFamily: "Mukta Malar",
  //     },
  //   },
  //   {
  //     label: "Mukta Vaani",
  //     value: "Mukta Vaani",
  //     url: "https://fonts.googleapis.com/css2?family=Mukta Vaani",
  //     style: {
  //       fontFamily: "Mukta Vaani",
  //     },
  //   },
  //   {
  //     label: "Muli",
  //     value: "Muli",
  //     url: "https://fonts.googleapis.com/css2?family=Muli",
  //     style: {
  //       fontFamily: "Muli",
  //     },
  //   },
  // {
  //     "label": "myanmarsanspro",
  //     "value": "myanmarsanspro",
  //     "url": "https://fonts.googleapis.com/css2?family=myanmarsanspro",
  //     "style": {
  //         "fontFamily": "myanmarsanspro"
  //     }
  // },
  //   {
  //     label: "Mystery Quest",
  //     value: "Mystery Quest",
  //     url: "https://fonts.googleapis.com/css2?family=Mystery Quest",
  //     style: {
  //       fontFamily: "Mystery Quest",
  //     },
  //   },
  //   {
  //     label: "Nanum Brush Script",
  //     value: "Nanum Brush Script",
  //     url: "https://fonts.googleapis.com/css2?family=Nanum Brush Script",
  //     style: {
  //       fontFamily: "Nanum Brush Script",
  //     },
  //   },
  //   {
  //     label: "Nanum Gothic",
  //     value: "Nanum Gothic",
  //     url: "https://fonts.googleapis.com/css2?family=Nanum Gothic",
  //     style: {
  //       fontFamily: "Nanum Gothic",
  //     },
  //   },
  //   {
  //     label: "Nanum Gothic Coding",
  //     value: "Nanum Gothic Coding",
  //     url: "https://fonts.googleapis.com/css2?family=Nanum Gothic Coding",
  //     style: {
  //       fontFamily: "Nanum Gothic Coding",
  //     },
  //   },
  //   {
  //     label: "Nanum Myeongjo",
  //     value: "Nanum Myeongjo",
  //     url: "https://fonts.googleapis.com/css2?family=Nanum Myeongjo",
  //     style: {
  //       fontFamily: "Nanum Myeongjo",
  //     },
  //   },
  //   {
  //     label: "Nanum Pen Script",
  //     value: "Nanum Pen Script",
  //     url: "https://fonts.googleapis.com/css2?family=Nanum Pen Script",
  //     style: {
  //       fontFamily: "Nanum Pen Script",
  //     },
  //   },
  // {
  //     "label": "NATS",
  //     "value": "NATS",
  //     "url": "https://fonts.googleapis.com/css2?family=NATS",
  //     "style": {
  //         "fontFamily": "NATS"
  //     }
  // },
  //   {
  //     label: "Neucha",
  //     value: "Neucha",
  //     url: "https://fonts.googleapis.com/css2?family=Neucha",
  //     style: {
  //       fontFamily: "Neucha",
  //     },
  //   },
  //   {
  //     label: "Neuton",
  //     value: "Neuton",
  //     url: "https://fonts.googleapis.com/css2?family=Neuton",
  //     style: {
  //       fontFamily: "Neuton",
  //     },
  //   },
  //   {
  //     label: "New Rocker",
  //     value: "New Rocker",
  //     url: "https://fonts.googleapis.com/css2?family=New Rocker",
  //     style: {
  //       fontFamily: "New Rocker",
  //     },
  //   },
  //   {
  //     label: "News Cycle",
  //     value: "News Cycle",
  //     url: "https://fonts.googleapis.com/css2?family=News Cycle",
  //     style: {
  //       fontFamily: "News Cycle",
  //     },
  //   },
  // {
  //     "label": "nicomoji",
  //     "value": "nicomoji",
  //     "url": "https://fonts.googleapis.com/css2?family=nicomoji",
  //     "style": {
  //         "fontFamily": "nicomoji"
  //     }
  // },
  {
    label: "Niconne",
    value: "Niconne",
    url: "https://fonts.googleapis.com/css2?family=Niconne",
    style: {
      fontFamily: "Niconne",
    },
  },
  // {
  //     "label": "nikukyu",
  //     "value": "nikukyu",
  //     "url": "https://fonts.googleapis.com/css2?family=nikukyu",
  //     "style": {
  //         "fontFamily": "nikukyu"
  //     }
  // },
  //   {
  //     label: "Niramit",
  //     value: "Niramit",
  //     url: "https://fonts.googleapis.com/css2?family=Niramit",
  //     style: {
  //       fontFamily: "Niramit",
  //     },
  //   },
  //   {
  //     label: "Nixie One",
  //     value: "Nixie One",
  //     url: "https://fonts.googleapis.com/css2?family=Nixie One",
  //     style: {
  //       fontFamily: "Nixie One",
  //     },
  //   },
  //   {
  //     label: "Nobile",
  //     value: "Nobile",
  //     url: "https://fonts.googleapis.com/css2?family=Nobile",
  //     style: {
  //       fontFamily: "Nobile",
  //     },
  //   },
  //   {
  //     label: "Nokora",
  //     value: "Nokora",
  //     url: "https://fonts.googleapis.com/css2?family=Nokora",
  //     style: {
  //       fontFamily: "Nokora",
  //     },
  //   },
  //   {
  //     label: "Norican",
  //     value: "Norican",
  //     url: "https://fonts.googleapis.com/css2?family=Norican",
  //     style: {
  //       fontFamily: "Norican",
  //     },
  //   },
  //   {
  //     label: "Nosifer",
  //     value: "Nosifer",
  //     url: "https://fonts.googleapis.com/css2?family=Nosifer",
  //     style: {
  //       fontFamily: "Nosifer",
  //     },
  //   },
  //   {
  //     label: "Nosifer Caps",
  //     value: "Nosifer Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Nosifer Caps",
  //     style: {
  //       fontFamily: "Nosifer Caps",
  //     },
  //   },
  //   {
  //     label: "Notable",
  //     value: "Notable",
  //     url: "https://fonts.googleapis.com/css2?family=Notable",
  //     style: {
  //       fontFamily: "Notable",
  //     },
  //   },
  //   {
  //     label: "Nothing You Could Do",
  //     value: "Nothing You Could Do",
  //     url: "https://fonts.googleapis.com/css2?family=Nothing You Could Do",
  //     style: {
  //       fontFamily: "Nothing You Could Do",
  //     },
  //   },
  //   {
  //     label: "Noticia Text",
  //     value: "Noticia Text",
  //     url: "https://fonts.googleapis.com/css2?family=Noticia Text",
  //     style: {
  //       fontFamily: "Noticia Text",
  //     },
  //   },
  //   {
  //     label: "Noto Sans",
  //     value: "Noto Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Noto Sans",
  //     style: {
  //       fontFamily: "Noto Sans",
  //     },
  //   },
  //   {
  //     label: "Noto Serif",
  //     value: "Noto Serif",
  //     url: "https://fonts.googleapis.com/css2?family=Noto Serif",
  //     style: {
  //       fontFamily: "Noto Serif",
  //     },
  //   },
  // {
  //     "label": "notosanstamil",
  //     "value": "notosanstamil",
  //     "url": "https://fonts.googleapis.com/css2?family=notosanstamil",
  //     "style": {
  //         "fontFamily": "notosanstamil"
  //     }
  // },
  //   {
  //     label: "Nova Cut",
  //     value: "Nova Cut",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Cut",
  //     style: {
  //       fontFamily: "Nova Cut",
  //     },
  //   },
  //   {
  //     label: "Nova Flat",
  //     value: "Nova Flat",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Flat",
  //     style: {
  //       fontFamily: "Nova Flat",
  //     },
  //   },
  //   {
  //     label: "Nova Mono",
  //     value: "Nova Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Mono",
  //     style: {
  //       fontFamily: "Nova Mono",
  //     },
  //   },
  //   {
  //     label: "Nova Oval",
  //     value: "Nova Oval",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Oval",
  //     style: {
  //       fontFamily: "Nova Oval",
  //     },
  //   },
  //   {
  //     label: "Nova Round",
  //     value: "Nova Round",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Round",
  //     style: {
  //       fontFamily: "Nova Round",
  //     },
  //   },
  //   {
  //     label: "Nova Script",
  //     value: "Nova Script",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Script",
  //     style: {
  //       fontFamily: "Nova Script",
  //     },
  //   },
  //   {
  //     label: "Nova Slim",
  //     value: "Nova Slim",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Slim",
  //     style: {
  //       fontFamily: "Nova Slim",
  //     },
  //   },
  //   {
  //     label: "Nova Square",
  //     value: "Nova Square",
  //     url: "https://fonts.googleapis.com/css2?family=Nova Square",
  //     style: {
  //       fontFamily: "Nova Square",
  //     },
  //   },
  //   {
  //     label: "NTR",
  //     value: "NTR",
  //     url: "https://fonts.googleapis.com/css2?family=NTR",
  //     style: {
  //       fontFamily: "NTR",
  //     },
  //   },
  //   {
  //     label: "Numans",
  //     value: "Numans",
  //     url: "https://fonts.googleapis.com/css2?family=Numans",
  //     style: {
  //       fontFamily: "Numans",
  //     },
  //   },
  //   {
  //     label: "Nunito",
  //     value: "Nunito",
  //     url: "https://fonts.googleapis.com/css2?family=Nunito",
  //     style: {
  //       fontFamily: "Nunito",
  //     },
  //   },
  //   {
  //     label: "Nunito Sans",
  //     value: "Nunito Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Nunito Sans",
  //     style: {
  //       fontFamily: "Nunito Sans",
  //     },
  //   },
  //   {
  //     label: "Odor Mean Chey",
  //     value: "Odor Mean Chey",
  //     url: "https://fonts.googleapis.com/css2?family=Odor Mean Chey",
  //     style: {
  //       fontFamily: "Odor Mean Chey",
  //     },
  //   },
  //   {
  //     label: "Offside",
  //     value: "Offside",
  //     url: "https://fonts.googleapis.com/css2?family=Offside",
  //     style: {
  //       fontFamily: "Offside",
  //     },
  //   },
  //   {
  //     label: "OFL Sorts Mill Goudy TT",
  //     value: "OFL Sorts Mill Goudy TT",
  //     url: "https://fonts.googleapis.com/css2?family=OFL Sorts Mill Goudy TT",
  //     style: {
  //       fontFamily: "OFL Sorts Mill Goudy TT",
  //     },
  //   },
  //   {
  //     label: "Old Standard TT",
  //     value: "Old Standard TT",
  //     url: "https://fonts.googleapis.com/css2?family=Old Standard TT",
  //     style: {
  //       fontFamily: "Old Standard TT",
  //     },
  //   },
  //   {
  //     label: "Oldenburg",
  //     value: "Oldenburg",
  //     url: "https://fonts.googleapis.com/css2?family=Oldenburg",
  //     style: {
  //       fontFamily: "Oldenburg",
  //     },
  //   },
  {
    label: "Oleo Script",
    value: "Oleo Script",
    url: "https://fonts.googleapis.com/css2?family=Oleo Script",
    style: {
      fontFamily: "Oleo Script",
    },
  },
  //   {
  //     label: "Oleo Script Swash Caps",
  //     value: "Oleo Script Swash Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Oleo Script Swash Caps",
  //     style: {
  //       fontFamily: "Oleo Script Swash Caps",
  //     },
  //   },
  {
    label: "Open Sans",
    value: "Open Sans",
    url: "https://fonts.googleapis.com/css2?family=Open Sans",
    style: {
      fontFamily: "Open Sans",
    },
  },
  // {
  //     "label": "Open Sans Condensed",
  //     "value": "Open Sans Condensed",
  //     "url": "https://fonts.googleapis.com/css2?family=Open Sans Condensed",
  //     "style": {
  //         "fontFamily": "Open Sans Condensed"
  //     }
  // },
  // {
  //     "label": "opensanshebrew",
  //     "value": "opensanshebrew",
  //     "url": "https://fonts.googleapis.com/css2?family=opensanshebrew",
  //     "style": {
  //         "fontFamily": "opensanshebrew"
  //     }
  // },
  // {
  //     "label": "opensanshebrewcondensed",
  //     "value": "opensanshebrewcondensed",
  //     "url": "https://fonts.googleapis.com/css2?family=opensanshebrewcondensed",
  //     "style": {
  //         "fontFamily": "opensanshebrewcondensed"
  //     }
  // },
  //   {
  //     label: "Oranienbaum",
  //     value: "Oranienbaum",
  //     url: "https://fonts.googleapis.com/css2?family=Oranienbaum",
  //     style: {
  //       fontFamily: "Oranienbaum",
  //     },
  //   },
  //   {
  //     label: "Orbitron",
  //     value: "Orbitron",
  //     url: "https://fonts.googleapis.com/css2?family=Orbitron",
  //     style: {
  //       fontFamily: "Orbitron",
  //     },
  //   },
  {
    label: "Oregano",
    value: "Oregano",
    url: "https://fonts.googleapis.com/css2?family=Oregano",
    style: {
      fontFamily: "Oregano",
    },
  },
  //   {
  //     label: "Orienta",
  //     value: "Orienta",
  //     url: "https://fonts.googleapis.com/css2?family=Orienta",
  //     style: {
  //       fontFamily: "Orienta",
  //     },
  //   },
  //   {
  //     label: "Original Surfer",
  //     value: "Original Surfer",
  //     url: "https://fonts.googleapis.com/css2?family=Original Surfer",
  //     style: {
  //       fontFamily: "Original Surfer",
  //     },
  //   },
  //   {
  //     label: "Oswald",
  //     value: "Oswald",
  //     url: "https://fonts.googleapis.com/css2?family=Oswald",
  //     style: {
  //       fontFamily: "Oswald",
  //     },
  //   },
  //   {
  //     label: "Over the Rainbow",
  //     value: "Over the Rainbow",
  //     url: "https://fonts.googleapis.com/css2?family=Over the Rainbow",
  //     style: {
  //       fontFamily: "Over the Rainbow",
  //     },
  //   },
  //   {
  //     label: "Overlock",
  //     value: "Overlock",
  //     url: "https://fonts.googleapis.com/css2?family=Overlock",
  //     style: {
  //       fontFamily: "Overlock",
  //     },
  //   },
  //   {
  //     label: "Overlock SC",
  //     value: "Overlock SC",
  //     url: "https://fonts.googleapis.com/css2?family=Overlock SC",
  //     style: {
  //       fontFamily: "Overlock SC",
  //     },
  //   },
  //   {
  //     label: "Overpass",
  //     value: "Overpass",
  //     url: "https://fonts.googleapis.com/css2?family=Overpass",
  //     style: {
  //       fontFamily: "Overpass",
  //     },
  //   },
  //   {
  //     label: "Overpass Mono",
  //     value: "Overpass Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Overpass Mono",
  //     style: {
  //       fontFamily: "Overpass Mono",
  //     },
  //   },
  //   {
  //     label: "Ovo",
  //     value: "Ovo",
  //     url: "https://fonts.googleapis.com/css2?family=Ovo",
  //     style: {
  //       fontFamily: "Ovo",
  //     },
  //   },
  //   {
  //     label: "Oxygen",
  //     value: "Oxygen",
  //     url: "https://fonts.googleapis.com/css2?family=Oxygen",
  //     style: {
  //       fontFamily: "Oxygen",
  //     },
  //   },
  //   {
  //     label: "Oxygen Mono",
  //     value: "Oxygen Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Oxygen Mono",
  //     style: {
  //       fontFamily: "Oxygen Mono",
  //     },
  //   },
  {
    label: "Pacifico",
    value: "Pacifico",
    url: "https://fonts.googleapis.com/css2?family=Pacifico",
    style: {
      fontFamily: "Pacifico",
    },
  },
  //   {
  //     label: "Padauk",
  //     value: "Padauk",
  //     url: "https://fonts.googleapis.com/css2?family=Padauk",
  //     style: {
  //       fontFamily: "Padauk",
  //     },
  //   },
  //   {
  //     label: "Palanquin",
  //     value: "Palanquin",
  //     url: "https://fonts.googleapis.com/css2?family=Palanquin",
  //     style: {
  //       fontFamily: "Palanquin",
  //     },
  //   },
  //   {
  //     label: "Palanquin Dark",
  //     value: "Palanquin Dark",
  //     url: "https://fonts.googleapis.com/css2?family=Palanquin Dark",
  //     style: {
  //       fontFamily: "Palanquin Dark",
  //     },
  //   },
  //   {
  //     label: "Pangolin",
  //     value: "Pangolin",
  //     url: "https://fonts.googleapis.com/css2?family=Pangolin",
  //     style: {
  //       fontFamily: "Pangolin",
  //     },
  //   },
  //   {
  //     label: "Paprika",
  //     value: "Paprika",
  //     url: "https://fonts.googleapis.com/css2?family=Paprika",
  //     style: {
  //       fontFamily: "Paprika",
  //     },
  //   },
  //   {
  //     label: "Parisienne",
  //     value: "Parisienne",
  //     url: "https://fonts.googleapis.com/css2?family=Parisienne",
  //     style: {
  //       fontFamily: "Parisienne",
  //     },
  //   },
  //   {
  //     label: "Passero One",
  //     value: "Passero One",
  //     url: "https://fonts.googleapis.com/css2?family=Passero One",
  //     style: {
  //       fontFamily: "Passero One",
  //     },
  //   },
  //   {
  //     label: "Passion One",
  //     value: "Passion One",
  //     url: "https://fonts.googleapis.com/css2?family=Passion One",
  //     style: {
  //       fontFamily: "Passion One",
  //     },
  //   },
  //   {
  //     label: "Pathway Gothic One",
  //     value: "Pathway Gothic One",
  //     url: "https://fonts.googleapis.com/css2?family=Pathway Gothic One",
  //     style: {
  //       fontFamily: "Pathway Gothic One",
  //     },
  //   },
  //   {
  //     label: "Patrick Hand",
  //     value: "Patrick Hand",
  //     url: "https://fonts.googleapis.com/css2?family=Patrick Hand",
  //     style: {
  //       fontFamily: "Patrick Hand",
  //     },
  //   },
  //   {
  //     label: "Patrick Hand SC",
  //     value: "Patrick Hand SC",
  //     url: "https://fonts.googleapis.com/css2?family=Patrick Hand SC",
  //     style: {
  //       fontFamily: "Patrick Hand SC",
  //     },
  //   },
  {
    label: "Pattaya",
    value: "Pattaya",
    url: "https://fonts.googleapis.com/css2?family=Pattaya",
    style: {
      fontFamily: "Pattaya",
    },
  },
  //   {
  //     label: "Patua One",
  //     value: "Patua One",
  //     url: "https://fonts.googleapis.com/css2?family=Patua One",
  //     style: {
  //       fontFamily: "Patua One",
  //     },
  //   },
  //   {
  //     label: "Pavanam",
  //     value: "Pavanam",
  //     url: "https://fonts.googleapis.com/css2?family=Pavanam",
  //     style: {
  //       fontFamily: "Pavanam",
  //     },
  //   },
  //   {
  //     label: "Paytone One",
  //     value: "Paytone One",
  //     url: "https://fonts.googleapis.com/css2?family=Paytone One",
  //     style: {
  //       fontFamily: "Paytone One",
  //     },
  //   },
  // {
  //     "label": "Pecita",
  //     "value": "Pecita",
  //     "url": "https://fonts.googleapis.com/css2?family=Pecita",
  //     "style": {
  //         "fontFamily": "Pecita"
  //     }
  // },
  //   {
  //     label: "Peddana",
  //     value: "Peddana",
  //     url: "https://fonts.googleapis.com/css2?family=Peddana",
  //     style: {
  //       fontFamily: "Peddana",
  //     },
  //   },
  //   {
  //     label: "Peralta",
  //     value: "Peralta",
  //     url: "https://fonts.googleapis.com/css2?family=Peralta",
  //     style: {
  //       fontFamily: "Peralta",
  //     },
  //   },
  //   {
  //     label: "Permanent Marker",
  //     value: "Permanent Marker",
  //     url: "https://fonts.googleapis.com/css2?family=Permanent Marker",
  //     style: {
  //       fontFamily: "Permanent Marker",
  //     },
  //   },
  //   {
  //     label: "Petit Formal Script",
  //     value: "Petit Formal Script",
  //     url: "https://fonts.googleapis.com/css2?family=Petit Formal Script",
  //     style: {
  //       fontFamily: "Petit Formal Script",
  //     },
  //   },
  //   {
  //     label: "Petrona",
  //     value: "Petrona",
  //     url: "https://fonts.googleapis.com/css2?family=Petrona",
  //     style: {
  //       fontFamily: "Petrona",
  //     },
  //   },
  // {
  //     "label": "Phetsarath",
  //     "value": "Phetsarath",
  //     "url": "https://fonts.googleapis.com/css2?family=Phetsarath",
  //     "style": {
  //         "fontFamily": "Phetsarath"
  //     }
  // },
  //   {
  //     label: "Philosopher",
  //     value: "Philosopher",
  //     url: "https://fonts.googleapis.com/css2?family=Philosopher",
  //     style: {
  //       fontFamily: "Philosopher",
  //     },
  //   },
  //   {
  //     label: "Piedra",
  //     value: "Piedra",
  //     url: "https://fonts.googleapis.com/css2?family=Piedra",
  //     style: {
  //       fontFamily: "Piedra",
  //     },
  //   },
  //   {
  //     label: "Pinyon Script",
  //     value: "Pinyon Script",
  //     url: "https://fonts.googleapis.com/css2?family=Pinyon Script",
  //     style: {
  //       fontFamily: "Pinyon Script",
  //     },
  //   },
  {
    label: "Pirata One",
    value: "Pirata One",
    url: "https://fonts.googleapis.com/css2?family=Pirata One",
    style: {
      fontFamily: "Pirata One",
    },
  },
  //   {
  //     label: "Plaster",
  //     value: "Plaster",
  //     url: "https://fonts.googleapis.com/css2?family=Plaster",
  //     style: {
  //       fontFamily: "Plaster",
  //     },
  //   },
  //   {
  //     label: "Play",
  //     value: "Play",
  //     url: "https://fonts.googleapis.com/css2?family=Play",
  //     style: {
  //       fontFamily: "Play",
  //     },
  //   },
  {
    label: "Playball",
    value: "Playball",
    url: "https://fonts.googleapis.com/css2?family=Playball",
    style: {
      fontFamily: "Playball",
    },
  },
  //   {
  //     label: "Playfair Display",
  //     value: "Playfair Display",
  //     url: "https://fonts.googleapis.com/css2?family=Playfair Display",
  //     style: {
  //       fontFamily: "Playfair Display",
  //     },
  //   },
  //   {
  //     label: "Playfair Display SC",
  //     value: "Playfair Display SC",
  //     url: "https://fonts.googleapis.com/css2?family=Playfair Display SC",
  //     style: {
  //       fontFamily: "Playfair Display SC",
  //     },
  //   },
  //   {
  //     label: "Podkova",
  //     value: "Podkova",
  //     url: "https://fonts.googleapis.com/css2?family=Podkova",
  //     style: {
  //       fontFamily: "Podkova",
  //     },
  //   },
  // {
  //     "label": "podkovavfbeta",
  //     "value": "podkovavfbeta",
  //     "url": "https://fonts.googleapis.com/css2?family=podkovavfbeta",
  //     "style": {
  //         "fontFamily": "podkovavfbeta"
  //     }
  // },
  // {
  //     "label": "Poetsen One",
  //     "value": "Poetsen One",
  //     "url": "https://fonts.googleapis.com/css2?family=Poetsen One",
  //     "style": {
  //         "fontFamily": "Poetsen One"
  //     }
  // },
  {
    label: "Poiret One",
    value: "Poiret One",
    url: "https://fonts.googleapis.com/css2?family=Poiret One",
    style: {
      fontFamily: "Poiret One",
    },
  },
  //   {
  //     label: "Poller One",
  //     value: "Poller One",
  //     url: "https://fonts.googleapis.com/css2?family=Poller One",
  //     style: {
  //       fontFamily: "Poller One",
  //     },
  //   },
  //   {
  //     label: "Poly",
  //     value: "Poly",
  //     url: "https://fonts.googleapis.com/css2?family=Poly",
  //     style: {
  //       fontFamily: "Poly",
  //     },
  //   },
  //   {
  //     label: "Pompiere",
  //     value: "Pompiere",
  //     url: "https://fonts.googleapis.com/css2?family=Pompiere",
  //     style: {
  //       fontFamily: "Pompiere",
  //     },
  //   },
  // {
  //     "label": "Ponnala",
  //     "value": "Ponnala",
  //     "url": "https://fonts.googleapis.com/css2?family=Ponnala",
  //     "style": {
  //         "fontFamily": "Ponnala"
  //     }
  // },
  {
    label: "Pontano Sans",
    value: "Pontano Sans",
    url: "https://fonts.googleapis.com/css2?family=Pontano Sans",
    style: {
      fontFamily: "Pontano Sans",
    },
  },
  //   {
  //     label: "Poor Story",
  //     value: "Poor Story",
  //     url: "https://fonts.googleapis.com/css2?family=Poor Story",
  //     style: {
  //       fontFamily: "Poor Story",
  //     },
  //   },
  {
    label: "Poppins",
    value: "Poppins",
    url: "https://fonts.googleapis.com/css2?family=Poppins",
    style: {
      fontFamily: "Poppins",
    },
  },
  //   {
  //     label: "Port Lligat Sans",
  //     value: "Port Lligat Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Port Lligat Sans",
  //     style: {
  //       fontFamily: "Port Lligat Sans",
  //     },
  //   },
  //   {
  //     label: "Port Lligat Slab",
  //     value: "Port Lligat Slab",
  //     url: "https://fonts.googleapis.com/css2?family=Port Lligat Slab",
  //     style: {
  //       fontFamily: "Port Lligat Slab",
  //     },
  //   },
  // {
  //     "label": "Porter Sans Block",
  //     "value": "Porter Sans Block",
  //     "url": "https://fonts.googleapis.com/css2?family=Porter Sans Block",
  //     "style": {
  //         "fontFamily": "Porter Sans Block"
  //     }
  // },
  // {
  //     "label": "Post No Bills Colombo",
  //     "value": "Post No Bills Colombo",
  //     "url": "https://fonts.googleapis.com/css2?family=Post No Bills Colombo",
  //     "style": {
  //         "fontFamily": "Post No Bills Colombo"
  //     }
  // },
  // {
  //     "label": "Post No Bills Jaffna",
  //     "value": "Post No Bills Jaffna",
  //     "url": "https://fonts.googleapis.com/css2?family=Post No Bills Jaffna",
  //     "style": {
  //         "fontFamily": "Post No Bills Jaffna"
  //     }
  // },
  //   {
  //     label: "Pragati Narrow",
  //     value: "Pragati Narrow",
  //     url: "https://fonts.googleapis.com/css2?family=Pragati Narrow",
  //     style: {
  //       fontFamily: "Pragati Narrow",
  //     },
  //   },
  //   {
  //     label: "Preahvihear",
  //     value: "Preahvihear",
  //     url: "https://fonts.googleapis.com/css2?family=Preahvihear",
  //     style: {
  //       fontFamily: "Preahvihear",
  //     },
  //   },
  //   {
  //     label: "Press Start 2P",
  //     value: "Press Start 2P",
  //     url: "https://fonts.googleapis.com/css2?family=Press Start 2P",
  //     style: {
  //       fontFamily: "Press Start 2P",
  //     },
  //   },
  //   {
  //     label: "Pridi",
  //     value: "Pridi",
  //     url: "https://fonts.googleapis.com/css2?family=Pridi",
  //     style: {
  //       fontFamily: "Pridi",
  //     },
  //   },
  {
    label: "Princess Sofia",
    value: "Princess Sofia",
    url: "https://fonts.googleapis.com/css2?family=Princess Sofia",
    style: {
      fontFamily: "Princess Sofia",
    },
  },
  //   {
  //     label: "Prociono",
  //     value: "Prociono",
  //     url: "https://fonts.googleapis.com/css2?family=Prociono",
  //     style: {
  //       fontFamily: "Prociono",
  //     },
  //   },
  //   {
  //     label: "Prompt",
  //     value: "Prompt",
  //     url: "https://fonts.googleapis.com/css2?family=Prompt",
  //     style: {
  //       fontFamily: "Prompt",
  //     },
  //   },
  //   {
  //     label: "Prosto One",
  //     value: "Prosto One",
  //     url: "https://fonts.googleapis.com/css2?family=Prosto One",
  //     style: {
  //       fontFamily: "Prosto One",
  //     },
  //   },
  //   {
  //     label: "Proza Libre",
  //     value: "Proza Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Proza Libre",
  //     style: {
  //       fontFamily: "Proza Libre",
  //     },
  //   },
  //   {
  //     label: "PT Mono",
  //     value: "PT Mono",
  //     url: "https://fonts.googleapis.com/css2?family=PT Mono",
  //     style: {
  //       fontFamily: "PT Mono",
  //     },
  //   },
  //   {
  //     label: "PT Sans",
  //     value: "PT Sans",
  //     url: "https://fonts.googleapis.com/css2?family=PT Sans",
  //     style: {
  //       fontFamily: "PT Sans",
  //     },
  //   },
  //   {
  //     label: "PT Sans Caption",
  //     value: "PT Sans Caption",
  //     url: "https://fonts.googleapis.com/css2?family=PT Sans Caption",
  //     style: {
  //       fontFamily: "PT Sans Caption",
  //     },
  //   },
  //   {
  //     label: "PT Sans Narrow",
  //     value: "PT Sans Narrow",
  //     url: "https://fonts.googleapis.com/css2?family=PT Sans Narrow",
  //     style: {
  //       fontFamily: "PT Sans Narrow",
  //     },
  //   },
  //   {
  //     label: "PT Serif",
  //     value: "PT Serif",
  //     url: "https://fonts.googleapis.com/css2?family=PT Serif",
  //     style: {
  //       fontFamily: "PT Serif",
  //     },
  //   },
  //   {
  //     label: "PT Serif Caption",
  //     value: "PT Serif Caption",
  //     url: "https://fonts.googleapis.com/css2?family=PT Serif Caption",
  //     style: {
  //       fontFamily: "PT Serif Caption",
  //     },
  //   },
  //   {
  //     label: "Puritan",
  //     value: "Puritan",
  //     url: "https://fonts.googleapis.com/css2?family=Puritan",
  //     style: {
  //       fontFamily: "Puritan",
  //     },
  //   },
  //   {
  //     label: "Purple Purse",
  //     value: "Purple Purse",
  //     url: "https://fonts.googleapis.com/css2?family=Purple Purse",
  //     style: {
  //       fontFamily: "Purple Purse",
  //     },
  //   },
  // {
  //     "label": "Pushster",
  //     "value": "Pushster",
  //     "url": "https://fonts.googleapis.com/css2?family=Pushster",
  //     "style": {
  //         "fontFamily": "Pushster"
  //     }
  // },
  //   {
  //     label: "Quando",
  //     value: "Quando",
  //     url: "https://fonts.googleapis.com/css2?family=Quando",
  //     style: {
  //       fontFamily: "Quando",
  //     },
  //   },
  //   {
  //     label: "Quantico",
  //     value: "Quantico",
  //     url: "https://fonts.googleapis.com/css2?family=Quantico",
  //     style: {
  //       fontFamily: "Quantico",
  //     },
  //   },
  //   {
  //     label: "Quattrocento",
  //     value: "Quattrocento",
  //     url: "https://fonts.googleapis.com/css2?family=Quattrocento",
  //     style: {
  //       fontFamily: "Quattrocento",
  //     },
  //   },
  //   {
  //     label: "Quattrocento Sans",
  //     value: "Quattrocento Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Quattrocento Sans",
  //     style: {
  //       fontFamily: "Quattrocento Sans",
  //     },
  //   },
  //   {
  //     label: "Questrial",
  //     value: "Questrial",
  //     url: "https://fonts.googleapis.com/css2?family=Questrial",
  //     style: {
  //       fontFamily: "Questrial",
  //     },
  //   },
  {
    label: "Quicksand",
    value: "Quicksand",
    url: "https://fonts.googleapis.com/css2?family=Quicksand",
    style: {
      fontFamily: "Quicksand",
    },
  },
  {
    label: "Quintessential",
    value: "Quintessential",
    url: "https://fonts.googleapis.com/css2?family=Quintessential",
    style: {
      fontFamily: "Quintessential",
    },
  },
  //   {
  //     label: "Qwigley",
  //     value: "Qwigley",
  //     url: "https://fonts.googleapis.com/css2?family=Qwigley",
  //     style: {
  //       fontFamily: "Qwigley",
  //     },
  //   },
  //   {
  //     label: "Racing Sans One",
  //     value: "Racing Sans One",
  //     url: "https://fonts.googleapis.com/css2?family=Racing Sans One",
  //     style: {
  //       fontFamily: "Racing Sans One",
  //     },
  //   },
  //   {
  //     label: "Radley",
  //     value: "Radley",
  //     url: "https://fonts.googleapis.com/css2?family=Radley",
  //     style: {
  //       fontFamily: "Radley",
  //     },
  //   },
  //   {
  //     label: "Rajdhani",
  //     value: "Rajdhani",
  //     url: "https://fonts.googleapis.com/css2?family=Rajdhani",
  //     style: {
  //       fontFamily: "Rajdhani",
  //     },
  //   },
  //   {
  //     label: "Rakkas",
  //     value: "Rakkas",
  //     url: "https://fonts.googleapis.com/css2?family=Rakkas",
  //     style: {
  //       fontFamily: "Rakkas",
  //     },
  //   },
  //   {
  //     label: "Raleway",
  //     value: "Raleway",
  //     url: "https://fonts.googleapis.com/css2?family=Raleway",
  //     style: {
  //       fontFamily: "Raleway",
  //     },
  //   },
  //   {
  //     label: "Raleway Dots",
  //     value: "Raleway Dots",
  //     url: "https://fonts.googleapis.com/css2?family=Raleway Dots",
  //     style: {
  //       fontFamily: "Raleway Dots",
  //     },
  //   },
  //   {
  //     label: "Ramabhadra",
  //     value: "Ramabhadra",
  //     url: "https://fonts.googleapis.com/css2?family=Ramabhadra",
  //     style: {
  //       fontFamily: "Ramabhadra",
  //     },
  //   },
  {
    label: "Ramaraja",
    value: "Ramaraja",
    url: "https://fonts.googleapis.com/css2?family=Ramaraja",
    style: {
      fontFamily: "Ramaraja",
    },
  },
  //   {
  //     label: "Rambla",
  //     value: "Rambla",
  //     url: "https://fonts.googleapis.com/css2?family=Rambla",
  //     style: {
  //       fontFamily: "Rambla",
  //     },
  //   },
  //   {
  //     label: "Rammetto One",
  //     value: "Rammetto One",
  //     url: "https://fonts.googleapis.com/css2?family=Rammetto One",
  //     style: {
  //       fontFamily: "Rammetto One",
  //     },
  //   },
  //   {
  //     label: "Ranchers",
  //     value: "Ranchers",
  //     url: "https://fonts.googleapis.com/css2?family=Ranchers",
  //     style: {
  //       fontFamily: "Ranchers",
  //     },
  //   },
  {
    label: "Rancho",
    value: "Rancho",
    url: "https://fonts.googleapis.com/css2?family=Rancho",
    style: {
      fontFamily: "Rancho",
    },
  },
  //   {
  //     label: "Ranga",
  //     value: "Ranga",
  //     url: "https://fonts.googleapis.com/css2?family=Ranga",
  //     style: {
  //       fontFamily: "Ranga",
  //     },
  //   },
  //   {
  //     label: "Rasa",
  //     value: "Rasa",
  //     url: "https://fonts.googleapis.com/css2?family=Rasa",
  //     style: {
  //       fontFamily: "Rasa",
  //     },
  //   },
  //   {
  //     label: "Rationale",
  //     value: "Rationale",
  //     url: "https://fonts.googleapis.com/css2?family=Rationale",
  //     style: {
  //       fontFamily: "Rationale",
  //     },
  //   },
  //   {
  //     label: "Ravi Prakash",
  //     value: "Ravi Prakash",
  //     url: "https://fonts.googleapis.com/css2?family=Ravi Prakash",
  //     style: {
  //       fontFamily: "Ravi Prakash",
  //     },
  //   },
  //   {
  //     label: "Red Hat Display",
  //     value: "Red Hat Display",
  //     url: "https://fonts.googleapis.com/css2?family=Red Hat Display",
  //     style: {
  //       fontFamily: "Red Hat Display",
  //     },
  //   },
  //   {
  //     label: "Red Hat Text",
  //     value: "Red Hat Text",
  //     url: "https://fonts.googleapis.com/css2?family=Red Hat Text",
  //     style: {
  //       fontFamily: "Red Hat Text",
  //     },
  //   },
  // {
  //     "label": "Redacted",
  //     "value": "Redacted",
  //     "url": "https://fonts.googleapis.com/css2?family=Redacted",
  //     "style": {
  //         "fontFamily": "Redacted"
  //     }
  // },
  // {
  //     "label": "Redacted Script",
  //     "value": "Redacted Script",
  //     "url": "https://fonts.googleapis.com/css2?family=Redacted Script",
  //     "style": {
  //         "fontFamily": "Redacted Script"
  //     }
  // },
  {
    label: "Redressed",
    value: "Redressed",
    url: "https://fonts.googleapis.com/css2?family=Redressed",
    style: {
      fontFamily: "Redressed",
    },
  },
  //   {
  //     label: "Reem Kufi",
  //     value: "Reem Kufi",
  //     url: "https://fonts.googleapis.com/css2?family=Reem Kufi",
  //     style: {
  //       fontFamily: "Reem Kufi",
  //     },
  //   },
  //   {
  //     label: "Reenie Beanie",
  //     value: "Reenie Beanie",
  //     url: "https://fonts.googleapis.com/css2?family=Reenie Beanie",
  //     style: {
  //       fontFamily: "Reenie Beanie",
  //     },
  //   },
  //   {
  //     label: "Revalia",
  //     value: "Revalia",
  //     url: "https://fonts.googleapis.com/css2?family=Revalia",
  //     style: {
  //       fontFamily: "Revalia",
  //     },
  //   },
  //   {
  //     label: "Rhodium Libre",
  //     value: "Rhodium Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Rhodium Libre",
  //     style: {
  //       fontFamily: "Rhodium Libre",
  //     },
  //   },
  //   {
  //     label: "Ribeye",
  //     value: "Ribeye",
  //     url: "https://fonts.googleapis.com/css2?family=Ribeye",
  //     style: {
  //       fontFamily: "Ribeye",
  //     },
  //   },
  //   {
  //     label: "Ribeye Marrow",
  //     value: "Ribeye Marrow",
  //     url: "https://fonts.googleapis.com/css2?family=Ribeye Marrow",
  //     style: {
  //       fontFamily: "Ribeye Marrow",
  //     },
  //   },
  //   {
  //     label: "Righteous",
  //     value: "Righteous",
  //     url: "https://fonts.googleapis.com/css2?family=Righteous",
  //     style: {
  //       fontFamily: "Righteous",
  //     },
  //   },
  {
    label: "Risque",
    value: "Risque",
    url: "https://fonts.googleapis.com/css2?family=Risque",
    style: {
      fontFamily: "Risque",
    },
  },
  {
    label: "Roboto",
    value: "Roboto",
    url: "https://fonts.googleapis.com/css2?family=Roboto",
    style: {
      fontFamily: "Roboto",
    },
  },
  //   {
  //     label: "Roboto Condensed",
  //     value: "Roboto Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Roboto Condensed",
  //     style: {
  //       fontFamily: "Roboto Condensed",
  //     },
  //   },
  //   {
  //     label: "Roboto Mono",
  //     value: "Roboto Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Roboto Mono",
  //     style: {
  //       fontFamily: "Roboto Mono",
  //     },
  //   },
  //   {
  //     label: "Roboto Slab",
  //     value: "Roboto Slab",
  //     url: "https://fonts.googleapis.com/css2?family=Roboto Slab",
  //     style: {
  //       fontFamily: "Roboto Slab",
  //     },
  //   },
  {
    label: "Rochester",
    value: "Rochester",
    url: "https://fonts.googleapis.com/css2?family=Rochester",
    style: {
      fontFamily: "Rochester",
    },
  },
  //   {
  //     label: "Rock Salt",
  //     value: "Rock Salt",
  //     url: "https://fonts.googleapis.com/css2?family=Rock Salt",
  //     style: {
  //       fontFamily: "Rock Salt",
  //     },
  //   },
  {
    label: "Rokkitt",
    value: "Rokkitt",
    url: "https://fonts.googleapis.com/css2?family=Rokkitt",
    style: {
      fontFamily: "Rokkitt",
    },
  },
  //   {
  //     label: "Romanesco",
  //     value: "Romanesco",
  //     url: "https://fonts.googleapis.com/css2?family=Romanesco",
  //     style: {
  //       fontFamily: "Romanesco",
  //     },
  //   },
  {
    label: "Ropa Sans",
    value: "Ropa Sans",
    url: "https://fonts.googleapis.com/css2?family=Ropa Sans",
    style: {
      fontFamily: "Ropa Sans",
    },
  },
  //   {
  //     label: "Rosario",
  //     value: "Rosario",
  //     url: "https://fonts.googleapis.com/css2?family=Rosario",
  //     style: {
  //       fontFamily: "Rosario",
  //     },
  //   },
  //   {
  //     label: "Rosarivo",
  //     value: "Rosarivo",
  //     url: "https://fonts.googleapis.com/css2?family=Rosarivo",
  //     style: {
  //       fontFamily: "Rosarivo",
  //     },
  //   },
  {
    label: "Rouge Script",
    value: "Rouge Script",
    url: "https://fonts.googleapis.com/css2?family=Rouge Script",
    style: {
      fontFamily: "Rouge Script",
    },
  },
  // {
  //     "label": "roundedmplus1c",
  //     "value": "roundedmplus1c",
  //     "url": "https://fonts.googleapis.com/css2?family=roundedmplus1c",
  //     "style": {
  //         "fontFamily": "roundedmplus1c"
  //     }
  // },
  //   {
  //     label: "Rozha One",
  //     value: "Rozha One",
  //     url: "https://fonts.googleapis.com/css2?family=Rozha One",
  //     style: {
  //       fontFamily: "Rozha One",
  //     },
  //   },
  //   {
  //     label: "Rubik",
  //     value: "Rubik",
  //     url: "https://fonts.googleapis.com/css2?family=Rubik",
  //     style: {
  //       fontFamily: "Rubik",
  //     },
  //   },
  //   {
  //     label: "Rubik Mono One",
  //     value: "Rubik Mono One",
  //     url: "https://fonts.googleapis.com/css2?family=Rubik Mono One",
  //     style: {
  //       fontFamily: "Rubik Mono One",
  //     },
  //   },
  //   {
  //     label: "Rubik One",
  //     value: "Rubik One",
  //     url: "https://fonts.googleapis.com/css2?family=Rubik One",
  //     style: {
  //       fontFamily: "Rubik One",
  //     },
  //   },
  //   {
  //     label: "Ruda",
  //     value: "Ruda",
  //     url: "https://fonts.googleapis.com/css2?family=Ruda",
  //     style: {
  //       fontFamily: "Ruda",
  //     },
  //   },
  //   {
  //     label: "Rufina",
  //     value: "Rufina",
  //     url: "https://fonts.googleapis.com/css2?family=Rufina",
  //     style: {
  //       fontFamily: "Rufina",
  //     },
  //   },
  //   {
  //     label: "Ruge Boogie",
  //     value: "Ruge Boogie",
  //     url: "https://fonts.googleapis.com/css2?family=Ruge Boogie",
  //     style: {
  //       fontFamily: "Ruge Boogie",
  //     },
  //   },
  //   {
  //     label: "Ruluko",
  //     value: "Ruluko",
  //     url: "https://fonts.googleapis.com/css2?family=Ruluko",
  //     style: {
  //       fontFamily: "Ruluko",
  //     },
  //   },
  //   {
  //     label: "Rum Raisin",
  //     value: "Rum Raisin",
  //     url: "https://fonts.googleapis.com/css2?family=Rum Raisin",
  //     style: {
  //       fontFamily: "Rum Raisin",
  //     },
  //   },
  //   {
  //     label: "Ruslan Display",
  //     value: "Ruslan Display",
  //     url: "https://fonts.googleapis.com/css2?family=Ruslan Display",
  //     style: {
  //       fontFamily: "Ruslan Display",
  //     },
  //   },
  //   {
  //     label: "Russo One",
  //     value: "Russo One",
  //     url: "https://fonts.googleapis.com/css2?family=Russo One",
  //     style: {
  //       fontFamily: "Russo One",
  //     },
  //   },
  //   {
  //     label: "Ruthie",
  //     value: "Ruthie",
  //     url: "https://fonts.googleapis.com/css2?family=Ruthie",
  //     style: {
  //       fontFamily: "Ruthie",
  //     },
  //   },
  //   {
  //     label: "Rye",
  //     value: "Rye",
  //     url: "https://fonts.googleapis.com/css2?family=Rye",
  //     style: {
  //       fontFamily: "Rye",
  //     },
  //   },
  //   {
  //     label: "Sacramento",
  //     value: "Sacramento",
  //     url: "https://fonts.googleapis.com/css2?family=Sacramento",
  //     style: {
  //       fontFamily: "Sacramento",
  //     },
  //   },
  //   {
  //     label: "Sahitya",
  //     value: "Sahitya",
  //     url: "https://fonts.googleapis.com/css2?family=Sahitya",
  //     style: {
  //       fontFamily: "Sahitya",
  //     },
  //   },
  {
    label: "Sail",
    value: "Sail",
    url: "https://fonts.googleapis.com/css2?family=Sail",
    style: {
      fontFamily: "Sail",
    },
  },
  //   {
  //     label: "Saira",
  //     value: "Saira",
  //     url: "https://fonts.googleapis.com/css2?family=Saira",
  //     style: {
  //       fontFamily: "Saira",
  //     },
  //   },
  //   {
  //     label: "Saira Condensed",
  //     value: "Saira Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Saira Condensed",
  //     style: {
  //       fontFamily: "Saira Condensed",
  //     },
  //   },
  //   {
  //     label: "Saira Extra Condensed",
  //     value: "Saira Extra Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Saira Extra Condensed",
  //     style: {
  //       fontFamily: "Saira Extra Condensed",
  //     },
  //   },
  //   {
  //     label: "Saira Semi Condensed",
  //     value: "Saira Semi Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Saira Semi Condensed",
  //     style: {
  //       fontFamily: "Saira Semi Condensed",
  //     },
  //   },
  // {
  //     "label": "Saira Stencil",
  //     "value": "Saira Stencil",
  //     "url": "https://fonts.googleapis.com/css2?family=Saira Stencil",
  //     "style": {
  //         "fontFamily": "Saira Stencil"
  //     }
  // },
  //   {
  //     label: "Saira Stencil One",
  //     value: "Saira Stencil One",
  //     url: "https://fonts.googleapis.com/css2?family=Saira Stencil One",
  //     style: {
  //       fontFamily: "Saira Stencil One",
  //     },
  //   },
  //   {
  //     label: "Salsa",
  //     value: "Salsa",
  //     url: "https://fonts.googleapis.com/css2?family=Salsa",
  //     style: {
  //       fontFamily: "Salsa",
  //     },
  //   },
  //   {
  //     label: "Sanchez",
  //     value: "Sanchez",
  //     url: "https://fonts.googleapis.com/css2?family=Sanchez",
  //     style: {
  //       fontFamily: "Sanchez",
  //     },
  //   },
  //   {
  //     label: "Sancreek",
  //     value: "Sancreek",
  //     url: "https://fonts.googleapis.com/css2?family=Sancreek",
  //     style: {
  //       fontFamily: "Sancreek",
  //     },
  //   },
  // {
  //     "label": "Sansation",
  //     "value": "Sansation",
  //     "url": "https://fonts.googleapis.com/css2?family=Sansation",
  //     "style": {
  //         "fontFamily": "Sansation"
  //     }
  // },
  //   {
  //     label: "Sansita",
  //     value: "Sansita",
  //     url: "https://fonts.googleapis.com/css2?family=Sansita",
  //     style: {
  //       fontFamily: "Sansita",
  //     },
  //   },
  //   {
  //     label: "Sansita One",
  //     value: "Sansita One",
  //     url: "https://fonts.googleapis.com/css2?family=Sansita One",
  //     style: {
  //       fontFamily: "Sansita One",
  //     },
  //   },
  //   {
  //     label: "Sarabun",
  //     value: "Sarabun",
  //     url: "https://fonts.googleapis.com/css2?family=Sarabun",
  //     style: {
  //       fontFamily: "Sarabun",
  //     },
  //   },
  //   {
  //     label: "Sarala",
  //     value: "Sarala",
  //     url: "https://fonts.googleapis.com/css2?family=Sarala",
  //     style: {
  //       fontFamily: "Sarala",
  //     },
  //   },
  //   {
  //     label: "Sarina",
  //     value: "Sarina",
  //     url: "https://fonts.googleapis.com/css2?family=Sarina",
  //     style: {
  //       fontFamily: "Sarina",
  //     },
  //   },
  //   {
  //     label: "Sarpanch",
  //     value: "Sarpanch",
  //     url: "https://fonts.googleapis.com/css2?family=Sarpanch",
  //     style: {
  //       fontFamily: "Sarpanch",
  //     },
  //   },
  //   {
  //     label: "Satisfy",
  //     value: "Satisfy",
  //     url: "https://fonts.googleapis.com/css2?family=Satisfy",
  //     style: {
  //       fontFamily: "Satisfy",
  //     },
  //   },
  // {
  //     "label": "sawarabigothic",
  //     "value": "sawarabigothic",
  //     "url": "https://fonts.googleapis.com/css2?family=sawarabigothic",
  //     "style": {
  //         "fontFamily": "sawarabigothic"
  //     }
  // },
  // {
  //     "label": "sawarabimincho",
  //     "value": "sawarabimincho",
  //     "url": "https://fonts.googleapis.com/css2?family=sawarabimincho",
  //     "style": {
  //         "fontFamily": "sawarabimincho"
  //     }
  // },
  //   {
  //     label: "Scada",
  //     value: "Scada",
  //     url: "https://fonts.googleapis.com/css2?family=Scada",
  //     style: {
  //       fontFamily: "Scada",
  //     },
  //   },
  //   {
  //     label: "Scheherazade",
  //     value: "Scheherazade",
  //     url: "https://fonts.googleapis.com/css2?family=Scheherazade",
  //     style: {
  //       fontFamily: "Scheherazade",
  //     },
  //   },
  //   {
  //     label: "Schoolbell",
  //     value: "Schoolbell",
  //     url: "https://fonts.googleapis.com/css2?family=Schoolbell",
  //     style: {
  //       fontFamily: "Schoolbell",
  //     },
  //   },
  //   {
  //     label: "Scope One",
  //     value: "Scope One",
  //     url: "https://fonts.googleapis.com/css2?family=Scope One",
  //     style: {
  //       fontFamily: "Scope One",
  //     },
  //   },
  //   {
  //     label: "Seaweed Script",
  //     value: "Seaweed Script",
  //     url: "https://fonts.googleapis.com/css2?family=Seaweed Script",
  //     style: {
  //       fontFamily: "Seaweed Script",
  //     },
  //   },
  //   {
  //     label: "Secular One",
  //     value: "Secular One",
  //     url: "https://fonts.googleapis.com/css2?family=Secular One",
  //     style: {
  //       fontFamily: "Secular One",
  //     },
  //   },
  // {
  //     "label": "Sedan",
  //     "value": "Sedan",
  //     "url": "https://fonts.googleapis.com/css2?family=Sedan",
  //     "style": {
  //         "fontFamily": "Sedan"
  //     }
  // },
  // {
  //     "label": "Sedan SC",
  //     "value": "Sedan SC",
  //     "url": "https://fonts.googleapis.com/css2?family=Sedan SC",
  //     "style": {
  //         "fontFamily": "Sedan SC"
  //     }
  // },
  //   {
  //     label: "Sedgwick Ave",
  //     value: "Sedgwick Ave",
  //     url: "https://fonts.googleapis.com/css2?family=Sedgwick Ave",
  //     style: {
  //       fontFamily: "Sedgwick Ave",
  //     },
  //   },
  //   {
  //     label: "Sedgwick Ave Display",
  //     value: "Sedgwick Ave Display",
  //     url: "https://fonts.googleapis.com/css2?family=Sedgwick Ave Display",
  //     style: {
  //       fontFamily: "Sedgwick Ave Display",
  //     },
  //   },
  // {
  //     "label": "seoulhangang",
  //     "value": "seoulhangang",
  //     "url": "https://fonts.googleapis.com/css2?family=seoulhangang",
  //     "style": {
  //         "fontFamily": "seoulhangang"
  //     }
  // },
  // {
  //     "label": "seoulhangangcondensed",
  //     "value": "seoulhangangcondensed",
  //     "url": "https://fonts.googleapis.com/css2?family=seoulhangangcondensed",
  //     "style": {
  //         "fontFamily": "seoulhangangcondensed"
  //     }
  // },
  // {
  //     "label": "seoulnamsan",
  //     "value": "seoulnamsan",
  //     "url": "https://fonts.googleapis.com/css2?family=seoulnamsan",
  //     "style": {
  //         "fontFamily": "seoulnamsan"
  //     }
  // },
  // {
  //     "label": "seoulnamsancondensed",
  //     "value": "seoulnamsancondensed",
  //     "url": "https://fonts.googleapis.com/css2?family=seoulnamsancondensed",
  //     "style": {
  //         "fontFamily": "seoulnamsancondensed"
  //     }
  // },
  // {
  //     "label": "seoulnamsanvertical",
  //     "value": "seoulnamsanvertical",
  //     "url": "https://fonts.googleapis.com/css2?family=seoulnamsanvertical",
  //     "style": {
  //         "fontFamily": "seoulnamsanvertical"
  //     }
  // },
  {
    label: "Sevillana",
    value: "Sevillana",
    url: "https://fonts.googleapis.com/css2?family=Sevillana",
    style: {
      fontFamily: "Sevillana",
    },
  },
  //   {
  //     label: "Seymour One",
  //     value: "Seymour One",
  //     url: "https://fonts.googleapis.com/css2?family=Seymour One",
  //     style: {
  //       fontFamily: "Seymour One",
  //     },
  //   },
  //   {
  //     label: "Shadows Into Light",
  //     value: "Shadows Into Light",
  //     url: "https://fonts.googleapis.com/css2?family=Shadows Into Light",
  //     style: {
  //       fontFamily: "Shadows Into Light",
  //     },
  //   },
  //   {
  //     label: "Shadows Into Light Two",
  //     value: "Shadows Into Light Two",
  //     url: "https://fonts.googleapis.com/css2?family=Shadows Into Light Two",
  //     style: {
  //       fontFamily: "Shadows Into Light Two",
  //     },
  //   },
  //   {
  //     label: "Shanti",
  //     value: "Shanti",
  //     url: "https://fonts.googleapis.com/css2?family=Shanti",
  //     style: {
  //       fontFamily: "Shanti",
  //     },
  //   },
  //   {
  //     label: "Share",
  //     value: "Share",
  //     url: "https://fonts.googleapis.com/css2?family=Share",
  //     style: {
  //       fontFamily: "Share",
  //     },
  //   },
  //   {
  //     label: "Share Tech",
  //     value: "Share Tech",
  //     url: "https://fonts.googleapis.com/css2?family=Share Tech",
  //     style: {
  //       fontFamily: "Share Tech",
  //     },
  //   },
  //   {
  //     label: "Share Tech Mono",
  //     value: "Share Tech Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Share Tech Mono",
  //     style: {
  //       fontFamily: "Share Tech Mono",
  //     },
  //   },
  //   {
  //     label: "Shojumaru",
  //     value: "Shojumaru",
  //     url: "https://fonts.googleapis.com/css2?family=Shojumaru",
  //     style: {
  //       fontFamily: "Shojumaru",
  //     },
  //   },
  //   {
  //     label: "Short Stack",
  //     value: "Short Stack",
  //     url: "https://fonts.googleapis.com/css2?family=Short Stack",
  //     style: {
  //       fontFamily: "Short Stack",
  //     },
  //   },
  {
    label: "Shrikhand",
    value: "Shrikhand",
    url: "https://fonts.googleapis.com/css2?family=Shrikhand",
    style: {
      fontFamily: "Shrikhand",
    },
  },
  //   {
  //     label: "Siamreap",
  //     value: "Siamreap",
  //     url: "https://fonts.googleapis.com/css2?family=Siamreap",
  //     style: {
  //       fontFamily: "Siamreap",
  //     },
  //   },
  //   {
  //     label: "Siemreap",
  //     value: "Siemreap",
  //     url: "https://fonts.googleapis.com/css2?family=Siemreap",
  //     style: {
  //       fontFamily: "Siemreap",
  //     },
  //   },
  //   {
  //     label: "Sigmar One",
  //     value: "Sigmar One",
  //     url: "https://fonts.googleapis.com/css2?family=Sigmar One",
  //     style: {
  //       fontFamily: "Sigmar One",
  //     },
  //   },
  {
    label: "Signika",
    value: "Signika",
    url: "https://fonts.googleapis.com/css2?family=Signika",
    style: {
      fontFamily: "Signika",
    },
  },
  //   {
  //     label: "Signika Negative",
  //     value: "Signika Negative",
  //     url: "https://fonts.googleapis.com/css2?family=Signika Negative",
  //     style: {
  //       fontFamily: "Signika Negative",
  //     },
  //   },
  //   {
  //     label: "Simonetta",
  //     value: "Simonetta",
  //     url: "https://fonts.googleapis.com/css2?family=Simonetta",
  //     style: {
  //       fontFamily: "Simonetta",
  //     },
  //   },
  //   {
  //     label: "Single Day",
  //     value: "Single Day",
  //     url: "https://fonts.googleapis.com/css2?family=Single Day",
  //     style: {
  //       fontFamily: "Single Day",
  //     },
  //   },
  //   {
  //     label: "Sintony",
  //     value: "Sintony",
  //     url: "https://fonts.googleapis.com/css2?family=Sintony",
  //     style: {
  //       fontFamily: "Sintony",
  //     },
  //   },
  //   {
  //     label: "Sirin Stencil",
  //     value: "Sirin Stencil",
  //     url: "https://fonts.googleapis.com/css2?family=Sirin Stencil",
  //     style: {
  //       fontFamily: "Sirin Stencil",
  //     },
  //   },
  // {
  //     "label": "Sitara",
  //     "value": "Sitara",
  //     "url": "https://fonts.googleapis.com/css2?family=Sitara",
  //     "style": {
  //         "fontFamily": "Sitara"
  //     }
  // },
  //   {
  //     label: "Six Caps",
  //     value: "Six Caps",
  //     url: "https://fonts.googleapis.com/css2?family=Six Caps",
  //     style: {
  //       fontFamily: "Six Caps",
  //     },
  //   },
  //   {
  //     label: "Skranji",
  //     value: "Skranji",
  //     url: "https://fonts.googleapis.com/css2?family=Skranji",
  //     style: {
  //       fontFamily: "Skranji",
  //     },
  //   },
  //   {
  //     label: "Slabo 13px",
  //     value: "Slabo 13px",
  //     url: "https://fonts.googleapis.com/css2?family=Slabo 13px",
  //     style: {
  //       fontFamily: "Slabo 13px",
  //     },
  //   },
  //   {
  //     label: "Slabo 27px",
  //     value: "Slabo 27px",
  //     url: "https://fonts.googleapis.com/css2?family=Slabo 27px",
  //     style: {
  //       fontFamily: "Slabo 27px",
  //     },
  //   },
  //   {
  //     label: "Slackey",
  //     value: "Slackey",
  //     url: "https://fonts.googleapis.com/css2?family=Slackey",
  //     style: {
  //       fontFamily: "Slackey",
  //     },
  //   },
  //   {
  //     label: "Smokum",
  //     value: "Smokum",
  //     url: "https://fonts.googleapis.com/css2?family=Smokum",
  //     style: {
  //       fontFamily: "Smokum",
  //     },
  //   },
  //   {
  //     label: "Smythe",
  //     value: "Smythe",
  //     url: "https://fonts.googleapis.com/css2?family=Smythe",
  //     style: {
  //       fontFamily: "Smythe",
  //     },
  //   },
  //   {
  //     label: "Sniglet",
  //     value: "Sniglet",
  //     url: "https://fonts.googleapis.com/css2?family=Sniglet",
  //     style: {
  //       fontFamily: "Sniglet",
  //     },
  //   },
  //   {
  //     label: "Snippet",
  //     value: "Snippet",
  //     url: "https://fonts.googleapis.com/css2?family=Snippet",
  //     style: {
  //       fontFamily: "Snippet",
  //     },
  //   },
  //   {
  //     label: "Snowburst One",
  //     value: "Snowburst One",
  //     url: "https://fonts.googleapis.com/css2?family=Snowburst One",
  //     style: {
  //       fontFamily: "Snowburst One",
  //     },
  //   },
  //   {
  //     label: "Sofadi One",
  //     value: "Sofadi One",
  //     url: "https://fonts.googleapis.com/css2?family=Sofadi One",
  //     style: {
  //       fontFamily: "Sofadi One",
  //     },
  //   },
  //   {
  //     label: "Sofia",
  //     value: "Sofia",
  //     url: "https://fonts.googleapis.com/css2?family=Sofia",
  //     style: {
  //       fontFamily: "Sofia",
  //     },
  //   },
  //   {
  //     label: "Solway",
  //     value: "Solway",
  //     url: "https://fonts.googleapis.com/css2?family=Solway",
  //     style: {
  //       fontFamily: "Solway",
  //     },
  //   },
  //   {
  //     label: "Song Myung",
  //     value: "Song Myung",
  //     url: "https://fonts.googleapis.com/css2?family=Song Myung",
  //     style: {
  //       fontFamily: "Song Myung",
  //     },
  //   },
  //   {
  //     label: "Sonsie One",
  //     value: "Sonsie One",
  //     url: "https://fonts.googleapis.com/css2?family=Sonsie One",
  //     style: {
  //       fontFamily: "Sonsie One",
  //     },
  //   },
  //   {
  //     label: "Sorts Mill Goudy",
  //     value: "Sorts Mill Goudy",
  //     url: "https://fonts.googleapis.com/css2?family=Sorts Mill Goudy",
  //     style: {
  //       fontFamily: "Sorts Mill Goudy",
  //     },
  //   },
  // {
  //     "label": "souliyo",
  //     "value": "souliyo",
  //     "url": "https://fonts.googleapis.com/css2?family=souliyo",
  //     "style": {
  //         "fontFamily": "souliyo"
  //     }
  // },
  //   {
  //     label: "Source Code Pro",
  //     value: "Source Code Pro",
  //     url: "https://fonts.googleapis.com/css2?family=Source Code Pro",
  //     style: {
  //       fontFamily: "Source Code Pro",
  //     },
  //   },
  //   {
  //     label: "Source Sans Pro",
  //     value: "Source Sans Pro",
  //     url: "https://fonts.googleapis.com/css2?family=Source Sans Pro",
  //     style: {
  //       fontFamily: "Source Sans Pro",
  //     },
  //   },
  //   {
  //     label: "Source Serif Pro",
  //     value: "Source Serif Pro",
  //     url: "https://fonts.googleapis.com/css2?family=Source Serif Pro",
  //     style: {
  //       fontFamily: "Source Serif Pro",
  //     },
  //   },
  //   {
  //     label: "Space Mono",
  //     value: "Space Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Space Mono",
  //     style: {
  //       fontFamily: "Space Mono",
  //     },
  //   },
  //   {
  //     label: "Special Elite",
  //     value: "Special Elite",
  //     url: "https://fonts.googleapis.com/css2?family=Special Elite",
  //     style: {
  //       fontFamily: "Special Elite",
  //     },
  //   },
  //   {
  //     label: "Spectral",
  //     value: "Spectral",
  //     url: "https://fonts.googleapis.com/css2?family=Spectral",
  //     style: {
  //       fontFamily: "Spectral",
  //     },
  //   },
  //   {
  //     label: "Spicy Rice",
  //     value: "Spicy Rice",
  //     url: "https://fonts.googleapis.com/css2?family=Spicy Rice",
  //     style: {
  //       fontFamily: "Spicy Rice",
  //     },
  //   },
  //   {
  //     label: "Spinnaker",
  //     value: "Spinnaker",
  //     url: "https://fonts.googleapis.com/css2?family=Spinnaker",
  //     style: {
  //       fontFamily: "Spinnaker",
  //     },
  //   },
  {
    label: "Spirax",
    value: "Spirax",
    url: "https://fonts.googleapis.com/css2?family=Spirax",
    style: {
      fontFamily: "Spirax",
    },
  },
  //   {
  //     label: "Squada One",
  //     value: "Squada One",
  //     url: "https://fonts.googleapis.com/css2?family=Squada One",
  //     style: {
  //       fontFamily: "Squada One",
  //     },
  //   },
  //   {
  //     label: "Sree Krushnadevaraya",
  //     value: "Sree Krushnadevaraya",
  //     url: "https://fonts.googleapis.com/css2?family=Sree Krushnadevaraya",
  //     style: {
  //       fontFamily: "Sree Krushnadevaraya",
  //     },
  //   },
  //   {
  //     label: "Sriracha",
  //     value: "Sriracha",
  //     url: "https://fonts.googleapis.com/css2?family=Sriracha",
  //     style: {
  //       fontFamily: "Sriracha",
  //     },
  //   },
  {
    label: "Srisakdi",
    value: "Srisakdi",
    url: "https://fonts.googleapis.com/css2?family=Srisakdi",
    style: {
      fontFamily: "Srisakdi",
    },
  },
  //   {
  //     label: "Staatliches",
  //     value: "Staatliches",
  //     url: "https://fonts.googleapis.com/css2?family=Staatliches",
  //     style: {
  //       fontFamily: "Staatliches",
  //     },
  //   },
  //   {
  //     label: "Stalemate",
  //     value: "Stalemate",
  //     url: "https://fonts.googleapis.com/css2?family=Stalemate",
  //     style: {
  //       fontFamily: "Stalemate",
  //     },
  //   },
  //   {
  //     label: "Stalin One",
  //     value: "Stalin One",
  //     url: "https://fonts.googleapis.com/css2?family=Stalin One",
  //     style: {
  //       fontFamily: "Stalin One",
  //     },
  //   },
  //   {
  //     label: "Stalinist One",
  //     value: "Stalinist One",
  //     url: "https://fonts.googleapis.com/css2?family=Stalinist One",
  //     style: {
  //       fontFamily: "Stalinist One",
  //     },
  //   },
  //   {
  //     label: "Stardos Stencil",
  //     value: "Stardos Stencil",
  //     url: "https://fonts.googleapis.com/css2?family=Stardos Stencil",
  //     style: {
  //       fontFamily: "Stardos Stencil",
  //     },
  //   },
  //   {
  //     label: "Stint Ultra Condensed",
  //     value: "Stint Ultra Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Stint Ultra Condensed",
  //     style: {
  //       fontFamily: "Stint Ultra Condensed",
  //     },
  //   },
  //   {
  //     label: "Stint Ultra Expanded",
  //     value: "Stint Ultra Expanded",
  //     url: "https://fonts.googleapis.com/css2?family=Stint Ultra Expanded",
  //     style: {
  //       fontFamily: "Stint Ultra Expanded",
  //     },
  //   },
  //   {
  //     label: "Stoke",
  //     value: "Stoke",
  //     url: "https://fonts.googleapis.com/css2?family=Stoke",
  //     style: {
  //       fontFamily: "Stoke",
  //     },
  //   },
  //   {
  //     label: "Strait",
  //     value: "Strait",
  //     url: "https://fonts.googleapis.com/css2?family=Strait",
  //     style: {
  //       fontFamily: "Strait",
  //     },
  //   },
  // {
  //     "label": "Strong",
  //     "value": "Strong",
  //     "url": "https://fonts.googleapis.com/css2?family=Strong",
  //     "style": {
  //         "fontFamily": "Strong"
  //     }
  // },
  //   {
  //     label: "Stylish",
  //     value: "Stylish",
  //     url: "https://fonts.googleapis.com/css2?family=Stylish",
  //     style: {
  //       fontFamily: "Stylish",
  //     },
  //   },
  //   {
  //     label: "Sue Ellen Francisco",
  //     value: "Sue Ellen Francisco",
  //     url: "https://fonts.googleapis.com/css2?family=Sue Ellen Francisco",
  //     style: {
  //       fontFamily: "Sue Ellen Francisco",
  //     },
  //   },
  //   {
  //     label: "Suez One",
  //     value: "Suez One",
  //     url: "https://fonts.googleapis.com/css2?family=Suez One",
  //     style: {
  //       fontFamily: "Suez One",
  //     },
  //   },
  //   {
  //     label: "Sumana",
  //     value: "Sumana",
  //     url: "https://fonts.googleapis.com/css2?family=Sumana",
  //     style: {
  //       fontFamily: "Sumana",
  //     },
  //   },
  // {
  //     "label": "Sunflower",
  //     "value": "Sunflower",
  //     "url": "https://fonts.googleapis.com/css2?family=Sunflower",
  //     "style": {
  //         "fontFamily": "Sunflower"
  //     }
  // },
  //   {
  //     label: "Sunshiney",
  //     value: "Sunshiney",
  //     url: "https://fonts.googleapis.com/css2?family=Sunshiney",
  //     style: {
  //       fontFamily: "Sunshiney",
  //     },
  //   },
  //   {
  //     label: "Supermercado One",
  //     value: "Supermercado One",
  //     url: "https://fonts.googleapis.com/css2?family=Supermercado One",
  //     style: {
  //       fontFamily: "Supermercado One",
  //     },
  //   },
  //   {
  //     label: "Sura",
  //     value: "Sura",
  //     url: "https://fonts.googleapis.com/css2?family=Sura",
  //     style: {
  //       fontFamily: "Sura",
  //     },
  //   },
  //   {
  //     label: "Suranna",
  //     value: "Suranna",
  //     url: "https://fonts.googleapis.com/css2?family=Suranna",
  //     style: {
  //       fontFamily: "Suranna",
  //     },
  //   },
  //   {
  //     label: "Suravaram",
  //     value: "Suravaram",
  //     url: "https://fonts.googleapis.com/css2?family=Suravaram",
  //     style: {
  //       fontFamily: "Suravaram",
  //     },
  //   },
  //   {
  //     label: "Suwannaphum",
  //     value: "Suwannaphum",
  //     url: "https://fonts.googleapis.com/css2?family=Suwannaphum",
  //     style: {
  //       fontFamily: "Suwannaphum",
  //     },
  //   },
  //   {
  //     label: "Swanky and Moo Moo",
  //     value: "Swanky and Moo Moo",
  //     url: "https://fonts.googleapis.com/css2?family=Swanky and Moo Moo",
  //     style: {
  //       fontFamily: "Swanky and Moo Moo",
  //     },
  //   },
  //   {
  //     label: "Syncopate",
  //     value: "Syncopate",
  //     url: "https://fonts.googleapis.com/css2?family=Syncopate",
  //     style: {
  //       fontFamily: "Syncopate",
  //     },
  //   },
  //   {
  //     label: "Tajawal",
  //     value: "Tajawal",
  //     url: "https://fonts.googleapis.com/css2?family=Tajawal",
  //     style: {
  //       fontFamily: "Tajawal",
  //     },
  //   },
  //   {
  //     label: "Tangerine",
  //     value: "Tangerine",
  //     url: "https://fonts.googleapis.com/css2?family=Tangerine",
  //     style: {
  //       fontFamily: "Tangerine",
  //     },
  //   },
  //   {
  //     label: "Taprom",
  //     value: "Taprom",
  //     url: "https://fonts.googleapis.com/css2?family=Taprom",
  //     style: {
  //       fontFamily: "Taprom",
  //     },
  //   },
  //   {
  //     label: "Tauri",
  //     value: "Tauri",
  //     url: "https://fonts.googleapis.com/css2?family=Tauri",
  //     style: {
  //       fontFamily: "Tauri",
  //     },
  //   },
  //   {
  //     label: "Taviraj",
  //     value: "Taviraj",
  //     url: "https://fonts.googleapis.com/css2?family=Taviraj",
  //     style: {
  //       fontFamily: "Taviraj",
  //     },
  //   },
  //   {
  //     label: "Teko",
  //     value: "Teko",
  //     url: "https://fonts.googleapis.com/css2?family=Teko",
  //     style: {
  //       fontFamily: "Teko",
  //     },
  //   },
  //   {
  //     label: "Telex",
  //     value: "Telex",
  //     url: "https://fonts.googleapis.com/css2?family=Telex",
  //     style: {
  //       fontFamily: "Telex",
  //     },
  //   },
  //   {
  //     label: "Tenali Ramakrishna",
  //     value: "Tenali Ramakrishna",
  //     url: "https://fonts.googleapis.com/css2?family=Tenali Ramakrishna",
  //     style: {
  //       fontFamily: "Tenali Ramakrishna",
  //     },
  //   },
  //   {
  //     label: "Tenor Sans",
  //     value: "Tenor Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Tenor Sans",
  //     style: {
  //       fontFamily: "Tenor Sans",
  //     },
  //   },
  //   {
  //     label: "Terminal Dosis",
  //     value: "Terminal Dosis",
  //     url: "https://fonts.googleapis.com/css2?family=Terminal Dosis",
  //     style: {
  //       fontFamily: "Terminal Dosis",
  //     },
  //   },
  //   {
  //     label: "Terminal Dosis Light",
  //     value: "Terminal Dosis Light",
  //     url: "https://fonts.googleapis.com/css2?family=Terminal Dosis Light",
  //     style: {
  //       fontFamily: "Terminal Dosis Light",
  //     },
  //   },
  //   {
  //     label: "Text Me One",
  //     value: "Text Me One",
  //     url: "https://fonts.googleapis.com/css2?family=Text Me One",
  //     style: {
  //       fontFamily: "Text Me One",
  //     },
  //   },
  //   {
  //     label: "Thabit",
  //     value: "Thabit",
  //     url: "https://fonts.googleapis.com/css2?family=Thabit",
  //     style: {
  //       fontFamily: "Thabit",
  //     },
  //   },
  // {
  //     "label": "tharlon",
  //     "value": "tharlon",
  //     "url": "https://fonts.googleapis.com/css2?family=tharlon",
  //     "style": {
  //         "fontFamily": "tharlon"
  //     }
  // },
  //   {
  //     label: "Thasadith",
  //     value: "Thasadith",
  //     url: "https://fonts.googleapis.com/css2?family=Thasadith",
  //     style: {
  //       fontFamily: "Thasadith",
  //     },
  //   },
  //   {
  //     label: "The Girl Next Door",
  //     value: "The Girl Next Door",
  //     url: "https://fonts.googleapis.com/css2?family=The Girl Next Door",
  //     style: {
  //       fontFamily: "The Girl Next Door",
  //     },
  //   },
  //   {
  //     label: "Tienne",
  //     value: "Tienne",
  //     url: "https://fonts.googleapis.com/css2?family=Tienne",
  //     style: {
  //       fontFamily: "Tienne",
  //     },
  //   },
  //   {
  //     label: "Tillana",
  //     value: "Tillana",
  //     url: "https://fonts.googleapis.com/css2?family=Tillana",
  //     style: {
  //       fontFamily: "Tillana",
  //     },
  //   },
  //   {
  //     label: "Timmana",
  //     value: "Timmana",
  //     url: "https://fonts.googleapis.com/css2?family=Timmana",
  //     style: {
  //       fontFamily: "Timmana",
  //     },
  //   },
  //   {
  //     label: "Tinos",
  //     value: "Tinos",
  //     url: "https://fonts.googleapis.com/css2?family=Tinos",
  //     style: {
  //       fontFamily: "Tinos",
  //     },
  //   },
  //   {
  //     label: "Titan One",
  //     value: "Titan One",
  //     url: "https://fonts.googleapis.com/css2?family=Titan One",
  //     style: {
  //       fontFamily: "Titan One",
  //     },
  //   },
  //   {
  //     label: "Titillium Web",
  //     value: "Titillium Web",
  //     url: "https://fonts.googleapis.com/css2?family=Titillium Web",
  //     style: {
  //       fontFamily: "Titillium Web",
  //     },
  //   },
  //   {
  //     label: "Trade Winds",
  //     value: "Trade Winds",
  //     url: "https://fonts.googleapis.com/css2?family=Trade Winds",
  //     style: {
  //       fontFamily: "Trade Winds",
  //     },
  //   },
  //   {
  //     label: "Trirong",
  //     value: "Trirong",
  //     url: "https://fonts.googleapis.com/css2?family=Trirong",
  //     style: {
  //       fontFamily: "Trirong",
  //     },
  //   },
  //   {
  //     label: "Trocchi",
  //     value: "Trocchi",
  //     url: "https://fonts.googleapis.com/css2?family=Trocchi",
  //     style: {
  //       fontFamily: "Trocchi",
  //     },
  //   },
  //   {
  //     label: "Trochut",
  //     value: "Trochut",
  //     url: "https://fonts.googleapis.com/css2?family=Trochut",
  //     style: {
  //       fontFamily: "Trochut",
  //     },
  //   },
  //   {
  //     label: "Trykker",
  //     value: "Trykker",
  //     url: "https://fonts.googleapis.com/css2?family=Trykker",
  //     style: {
  //       fontFamily: "Trykker",
  //     },
  //   },
  // {
  //     "label": "Tuffy",
  //     "value": "Tuffy",
  //     "url": "https://fonts.googleapis.com/css2?family=Tuffy",
  //     "style": {
  //         "fontFamily": "Tuffy"
  //     }
  // },
  //   {
  //     label: "Tulpen One",
  //     value: "Tulpen One",
  //     url: "https://fonts.googleapis.com/css2?family=Tulpen One",
  //     style: {
  //       fontFamily: "Tulpen One",
  //     },
  //   },
  //   {
  //     label: "Ubuntu",
  //     value: "Ubuntu",
  //     url: "https://fonts.googleapis.com/css2?family=Ubuntu",
  //     style: {
  //       fontFamily: "Ubuntu",
  //     },
  //   },
  //   {
  //     label: "Ubuntu Condensed",
  //     value: "Ubuntu Condensed",
  //     url: "https://fonts.googleapis.com/css2?family=Ubuntu Condensed",
  //     style: {
  //       fontFamily: "Ubuntu Condensed",
  //     },
  //   },
  //   {
  //     label: "Ubuntu Mono",
  //     value: "Ubuntu Mono",
  //     url: "https://fonts.googleapis.com/css2?family=Ubuntu Mono",
  //     style: {
  //       fontFamily: "Ubuntu Mono",
  //     },
  //   },
  //   {
  //     label: "Ultra",
  //     value: "Ultra",
  //     url: "https://fonts.googleapis.com/css2?family=Ultra",
  //     style: {
  //       fontFamily: "Ultra",
  //     },
  //   },
  //   {
  //     label: "Uncial Antiqua",
  //     value: "Uncial Antiqua",
  //     url: "https://fonts.googleapis.com/css2?family=Uncial Antiqua",
  //     style: {
  //       fontFamily: "Uncial Antiqua",
  //     },
  //   },
  //   {
  //     label: "Underdog",
  //     value: "Underdog",
  //     url: "https://fonts.googleapis.com/css2?family=Underdog",
  //     style: {
  //       fontFamily: "Underdog",
  //     },
  //   },
  //   {
  //     label: "Unica One",
  //     value: "Unica One",
  //     url: "https://fonts.googleapis.com/css2?family=Unica One",
  //     style: {
  //       fontFamily: "Unica One",
  //     },
  //   },
  // {
  //     "label": "UnifrakturCook",
  //     "value": "UnifrakturCook",
  //     "url": "https://fonts.googleapis.com/css2?family=UnifrakturCook",
  //     "style": {
  //         "fontFamily": "UnifrakturCook"
  //     }
  // },
  {
    label: "UnifrakturMaguntia",
    value: "UnifrakturMaguntia",
    url: "https://fonts.googleapis.com/css2?family=UnifrakturMaguntia",
    style: {
      fontFamily: "UnifrakturMaguntia",
    },
  },
  //   {
  //     label: "Unkempt",
  //     value: "Unkempt",
  //     url: "https://fonts.googleapis.com/css2?family=Unkempt",
  //     style: {
  //       fontFamily: "Unkempt",
  //     },
  //   },
  //   {
  //     label: "Unlock",
  //     value: "Unlock",
  //     url: "https://fonts.googleapis.com/css2?family=Unlock",
  //     style: {
  //       fontFamily: "Unlock",
  //     },
  //   },
  //   {
  //     label: "Unna",
  //     value: "Unna",
  //     url: "https://fonts.googleapis.com/css2?family=Unna",
  //     style: {
  //       fontFamily: "Unna",
  //     },
  //   },
  //   {
  //     label: "Vampiro One",
  //     value: "Vampiro One",
  //     url: "https://fonts.googleapis.com/css2?family=Vampiro One",
  //     style: {
  //       fontFamily: "Vampiro One",
  //     },
  //   },
  //   {
  //     label: "Varela",
  //     value: "Varela",
  //     url: "https://fonts.googleapis.com/css2?family=Varela",
  //     style: {
  //       fontFamily: "Varela",
  //     },
  //   },
  //   {
  //     label: "Varela Round",
  //     value: "Varela Round",
  //     url: "https://fonts.googleapis.com/css2?family=Varela Round",
  //     style: {
  //       fontFamily: "Varela Round",
  //     },
  //   },
  //   {
  //     label: "Varta",
  //     value: "Varta",
  //     url: "https://fonts.googleapis.com/css2?family=Varta",
  //     style: {
  //       fontFamily: "Varta",
  //     },
  //   },
  //   {
  //     label: "Vast Shadow",
  //     value: "Vast Shadow",
  //     url: "https://fonts.googleapis.com/css2?family=Vast Shadow",
  //     style: {
  //       fontFamily: "Vast Shadow",
  //     },
  //   },
  //   {
  //     label: "Vesper Libre",
  //     value: "Vesper Libre",
  //     url: "https://fonts.googleapis.com/css2?family=Vesper Libre",
  //     style: {
  //       fontFamily: "Vesper Libre",
  //     },
  //   },
  //   {
  //     label: "Vibur",
  //     value: "Vibur",
  //     url: "https://fonts.googleapis.com/css2?family=Vibur",
  //     style: {
  //       fontFamily: "Vibur",
  //     },
  //   },
  //   {
  //     label: "Vidaloka",
  //     value: "Vidaloka",
  //     url: "https://fonts.googleapis.com/css2?family=Vidaloka",
  //     style: {
  //       fontFamily: "Vidaloka",
  //     },
  //   },
  //   {
  //     label: "Viga",
  //     value: "Viga",
  //     url: "https://fonts.googleapis.com/css2?family=Viga",
  //     style: {
  //       fontFamily: "Viga",
  //     },
  //   },
  {
    label: "Voces",
    value: "Voces",
    url: "https://fonts.googleapis.com/css2?family=Voces",
    style: {
      fontFamily: "Voces",
    },
  },
  //   {
  //     label: "Volkhov",
  //     value: "Volkhov",
  //     url: "https://fonts.googleapis.com/css2?family=Volkhov",
  //     style: {
  //       fontFamily: "Volkhov",
  //     },
  //   },
  //   {
  //     label: "Vollkorn",
  //     value: "Vollkorn",
  //     url: "https://fonts.googleapis.com/css2?family=Vollkorn",
  //     style: {
  //       fontFamily: "Vollkorn",
  //     },
  //   },
  {
    label: "Vollkorn SC",
    value: "Vollkorn SC",
    url: "https://fonts.googleapis.com/css2?family=Vollkorn SC",
    style: {
      fontFamily: "Vollkorn SC",
    },
  },
  //   {
  //     label: "Voltaire",
  //     value: "Voltaire",
  //     url: "https://fonts.googleapis.com/css2?family=Voltaire",
  //     style: {
  //       fontFamily: "Voltaire",
  //     },
  //   },
  //   {
  //     label: "VT323",
  //     value: "VT323",
  //     url: "https://fonts.googleapis.com/css2?family=VT323",
  //     style: {
  //       fontFamily: "VT323",
  //     },
  //   },
  //   {
  //     label: "Waiting for the Sunrise",
  //     value: "Waiting for the Sunrise",
  //     url: "https://fonts.googleapis.com/css2?family=Waiting for the Sunrise",
  //     style: {
  //       fontFamily: "Waiting for the Sunrise",
  //     },
  //   },
  //   {
  //     label: "Wallpoet",
  //     value: "Wallpoet",
  //     url: "https://fonts.googleapis.com/css2?family=Wallpoet",
  //     style: {
  //       fontFamily: "Wallpoet",
  //     },
  //   },
  //   {
  //     label: "Walter Turncoat",
  //     value: "Walter Turncoat",
  //     url: "https://fonts.googleapis.com/css2?family=Walter Turncoat",
  //     style: {
  //       fontFamily: "Walter Turncoat",
  //     },
  //   },
  //   {
  //     label: "Warnes",
  //     value: "Warnes",
  //     url: "https://fonts.googleapis.com/css2?family=Warnes",
  //     style: {
  //       fontFamily: "Warnes",
  //     },
  //   },
  //   {
  //     label: "Wellfleet",
  //     value: "Wellfleet",
  //     url: "https://fonts.googleapis.com/css2?family=Wellfleet",
  //     style: {
  //       fontFamily: "Wellfleet",
  //     },
  //   },
  //   {
  //     label: "Wendy One",
  //     value: "Wendy One",
  //     url: "https://fonts.googleapis.com/css2?family=Wendy One",
  //     style: {
  //       fontFamily: "Wendy One",
  //     },
  //   },
  //   {
  //     label: "Wire One",
  //     value: "Wire One",
  //     url: "https://fonts.googleapis.com/css2?family=Wire One",
  //     style: {
  //       fontFamily: "Wire One",
  //     },
  //   },
  //   {
  //     label: "Work Sans",
  //     value: "Work Sans",
  //     url: "https://fonts.googleapis.com/css2?family=Work Sans",
  //     style: {
  //       fontFamily: "Work Sans",
  //     },
  //   },
  // {
  //     "label": "Yaldevi Colombo",
  //     "value": "Yaldevi Colombo",
  //     "url": "https://fonts.googleapis.com/css2?family=Yaldevi Colombo",
  //     "style": {
  //         "fontFamily": "Yaldevi Colombo"
  //     }
  // },
  //   {
  //     label: "Yanone Kaffeesatz",
  //     value: "Yanone Kaffeesatz",
  //     url: "https://fonts.googleapis.com/css2?family=Yanone Kaffeesatz",
  //     style: {
  //       fontFamily: "Yanone Kaffeesatz",
  //     },
  //   },
  //   {
  //     label: "Yantramanav",
  //     value: "Yantramanav",
  //     url: "https://fonts.googleapis.com/css2?family=Yantramanav",
  //     style: {
  //       fontFamily: "Yantramanav",
  //     },
  //   },
  //   {
  //     label: "Yatra One",
  //     value: "Yatra One",
  //     url: "https://fonts.googleapis.com/css2?family=Yatra One",
  //     style: {
  //       fontFamily: "Yatra One",
  //     },
  //   },
  //   {
  //     label: "Yellowtail",
  //     value: "Yellowtail",
  //     url: "https://fonts.googleapis.com/css2?family=Yellowtail",
  //     style: {
  //       fontFamily: "Yellowtail",
  //     },
  //   },
  //   {
  //     label: "Yeon Sung",
  //     value: "Yeon Sung",
  //     url: "https://fonts.googleapis.com/css2?family=Yeon Sung",
  //     style: {
  //       fontFamily: "Yeon Sung",
  //     },
  //   },
  //   {
  //     label: "Yeseva One",
  //     value: "Yeseva One",
  //     url: "https://fonts.googleapis.com/css2?family=Yeseva One",
  //     style: {
  //       fontFamily: "Yeseva One",
  //     },
  //   },
  //   {
  //     label: "Yesteryear",
  //     value: "Yesteryear",
  //     url: "https://fonts.googleapis.com/css2?family=Yesteryear",
  //     style: {
  //       fontFamily: "Yesteryear",
  //     },
  //   },
  // {
  //     "label": "Yinmar",
  //     "value": "Yinmar",
  //     "url": "https://fonts.googleapis.com/css2?family=Yinmar",
  //     "style": {
  //         "fontFamily": "Yinmar"
  //     }
  // },
  //   {
  //     label: "Yrsa",
  //     value: "Yrsa",
  //     url: "https://fonts.googleapis.com/css2?family=Yrsa",
  //     style: {
  //       fontFamily: "Yrsa",
  //     },
  //   },
  //   {
  //     label: "ZCOOL KuaiLe",
  //     value: "ZCOOL KuaiLe",
  //     url: "https://fonts.googleapis.com/css2?family=ZCOOL KuaiLe",
  //     style: {
  //       fontFamily: "ZCOOL KuaiLe",
  //     },
  //   },
  //   {
  //     label: "ZCOOL QingKe HuangYou",
  //     value: "ZCOOL QingKe HuangYou",
  //     url: "https://fonts.googleapis.com/css2?family=ZCOOL QingKe HuangYou",
  //     style: {
  //       fontFamily: "ZCOOL QingKe HuangYou",
  //     },
  //   },
  //   {
  //     label: "ZCOOL XiaoWei",
  //     value: "ZCOOL XiaoWei",
  //     url: "https://fonts.googleapis.com/css2?family=ZCOOL XiaoWei",
  //     style: {
  //       fontFamily: "ZCOOL XiaoWei",
  //     },
  //   },
  //   {
  //     label: "Zeyada",
  //     value: "Zeyada",
  //     url: "https://fonts.googleapis.com/css2?family=Zeyada",
  //     style: {
  //       fontFamily: "Zeyada",
  //     },
  //   },
  //   {
  //     label: "Zhi Mang Xing",
  //     value: "Zhi Mang Xing",
  //     url: "https://fonts.googleapis.com/css2?family=Zhi Mang Xing",
  //     style: {
  //       fontFamily: "Zhi Mang Xing",
  //     },
  //   },
  // {
  //     "label": "ZhiMangXing",
  //     "value": "ZhiMangXing",
  //     "url": "https://fonts.googleapis.com/css2?family=ZhiMangXing",
  //     "style": {
  //         "fontFamily": "ZhiMangXing"
  //     }
  // },
  //   {
  //     label: "Zilla Slab Highlight",
  //     value: "Zilla Slab Highlight",
  //     url: "https://fonts.googleapis.com/css2?family=Zilla Slab Highlight",
  //     style: {
  //       fontFamily: "Zilla Slab Highlight",
  //     },
  //   },
];
