import React, { useEffect, useState } from 'react';

import { Modal as BootstrapModal } from "react-bootstrap";
import { Form, EmailInput, PasswordInput, Button } from "components";

import { 
    initFirebaseUI, handleAuthStateChange,
    handleUserSignIn, handleUserSignUp
} from "dataflow/services";

import { 
    isNullUndefined, 
    isObjectNotEmpty, getObjectPropertyValue, 
    isStringTrimNotEmpty, isFunction, setCookie, isPasswordValid, isEmailValid
} from 'helper/validations';

import "assets/scss/components/modals/userAuthenticationModal.scss";
import { useLocation } from "react-router-dom";
import { setIsUserAuthenticated } from 'dataflow/slices/website.slice';
import { useDispatch } from 'react-redux';

export function UserAuthenticationModal(props) {
    const {
        isVisible,
        isLogin,
        setIsLogin,

        modalProps,
        headerProps,
        bodyProps,
        
    } = props;

    // Validate Props
    let modalProps_validated = isObjectNotEmpty(modalProps)? modalProps:{};
    let headerProps_validated = isObjectNotEmpty(headerProps)? headerProps:{};
    let bodyProps_validated = isObjectNotEmpty(bodyProps)? bodyProps:{};

    // States
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [userDetails, setUserDetails] = useState({});
    const [isSubmissionAttempted, setIsSubmissionAttempted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const location = useLocation();

    function handleSubmission() {
        setIsSubmissionAttempted(true);
        setErrorMessage("")
        if (isStringTrimNotEmpty(email) &&
            isStringTrimNotEmpty(pwd)) {

            if (isLogin) handleUserSignIn(email, pwd, 
                // Success
                async (user) => {
                    
                    if (isFunction(props.onHide)) {props.onHide()};
                    
                    // Set cookies
                    let userInfo = getObjectPropertyValue(user, "user");
                    // let tokenResult = await userInfo.getIdTokenResult()
                    // let signInProvider = getObjectPropertyValue(tokenResult, "signInProvider");

                    let userId = getObjectPropertyValue(userInfo, "uid");
                    setCookie("uid", userId, { path: "/" });
                    // let name = getObjectPropertyValue(userInfo, "displayName");
                    // let email = getObjectPropertyValue(userInfo, "email");
                    // let isEmailVerified = getObjectPropertyValue(userInfo, "emailVerified");
                    // let phoneNumber = getObjectPropertyValue(userInfo, "phoneNumber");
                    // let profilePic = getObjectPropertyValue(userInfo, "photoURL");
                    
                    // let additionalUserInfo = getObjectPropertyValue(user, "additionalUserInfo");
                    // let isNewUser = getObjectPropertyValue(additionalUserInfo, "isNewUser");

                    

                    //login user
                    if (isFunction(props.onLoginSuccessful)){
                        props.onLoginSuccessful();
                    } 

                },
                // Failure
                (error) => {
                    setErrorMessage(error.message);
                });
            else{
                if(isPasswordValid(pwd) && isEmailValid(email)){
                    handleUserSignUp(email, pwd, 
                        // Success
                        async (user) => {
                            if (isFunction(props.onHide)) props.onHide();
                            
                            // Set cookies
                            let userInfo = getObjectPropertyValue(user, "user");
                            // let tokenResult = await userInfo.getIdTokenResult()
                            // let idToken = getObjectPropertyValue(tokenResult, "token");
                            // let expirationTime = getObjectPropertyValue(tokenResult, "expirationTime");
                            // let signInProvider = getObjectPropertyValue(tokenResult, "signInProvider");
        
                            // let refreshToken = getObjectPropertyValue(userInfo, "refreshToken");
                            // setCookie("rt", refreshToken, { path: "/", maxAge: 3600 });
                            let userId = getObjectPropertyValue(userInfo, "uid");
                            setCookie("uid", userId, { path: "/" });
                            // setCookie("idt", idToken, { path: "/", expires: new Date(expirationTime) });
                            // let name = getObjectPropertyValue(userInfo, "displayName");
                            // let email = getObjectPropertyValue(userInfo, "email");
                            // let isEmailVerified = getObjectPropertyValue(userInfo, "emailVerified");
                            // let phoneNumber = getObjectPropertyValue(userInfo, "phoneNumber");
                            // let profilePic = getObjectPropertyValue(userInfo, "photoURL");
                            
                            // let additionalUserInfo = getObjectPropertyValue(user, "additionalUserInfo");
                            // let isNewUser = getObjectPropertyValue(additionalUserInfo, "isNewUser");
        
                            //login user
                            if (isFunction(props.onLoginSuccessful)){
                                props.onLoginSuccessful();
                            } 
        
                
                        },
                        // Failure
                        (error) => {
                            setErrorMessage(error.message);
                        });
                }
            } 
        }
    }

    // Monitor Authentication state change
    const dispatch = useDispatch();
    useEffect(() => {
        handleAuthStateChange((isAuthenticated) => dispatch(setIsUserAuthenticated(isAuthenticated)));
    }, [])

    useEffect(() => {
        if (isVisible) initFirebaseUI(location?.pathname);
    }, [isVisible]);

    return (
        <BootstrapModal
            {...modalProps_validated}
            show={isVisible}
            onHide={props.onHide}
            centered
        >
            {/* Header */}
            <BootstrapModal.Header {...headerProps_validated} 
            >
                {isLogin? "Log Masuk": "Daftar Akaun"} 
                {isLogin? <p>Tidak Mempunyai Akaun? <span onClick={() => {setIsLogin(false);setErrorMessage("")}}>Daftar</span> Disini.</p> 
                : 
                <p> Sudah Mempunyai Akaun? <span onClick={() => {setIsLogin(true);setErrorMessage("")}}>Log Masuk</span> Disini.</p>}
            </BootstrapModal.Header>

            {/* Body */}
            <BootstrapModal.Body {...bodyProps_validated}>
                <Form onSubmit={handleSubmission}>
                    {/* Email */}
                    <EmailInput
                        value={email}
                        onChange={(newEmail) => setEmail(newEmail)}
                        isSubmissionAttempted={isSubmissionAttempted}
                    />

                    {/* Password */}
                    <PasswordInput
                        value={pwd}
                        onChange={(newPwd) => setPwd(newPwd)}
                        customInvalidation={isNullUndefined(userDetails)}

                        isLogin={isLogin}
                        isSubmissionAttempted={isSubmissionAttempted}
                    />
                    <span className={"errorMessage"}>{errorMessage}</span>

                    <div className={"d-flex justify-content-center"}>
                        Or
                    </div>

                    {/* Social Media */}
                    <div className={"d-flex justify-content-center"}>
                        <div id="firebaseui-auth-container"/>
                        <div id="loader">Loading...</div>
                    </div>

                    <div className={"row justify-content-center"}>
                        <div className={"col-6 col-sm-4 d-flex justify-content-center"}>
                            {/* Cancel */}
                            <Button
                                content={"Cancel"}
                                onClick={props.onHide}
                                decoration_unimportant
                            />
                        </div>

                        <div className={"col-6 col-sm-4 d-flex justify-content-center"}>
                            {/* Submit */}
                            <Button
                                content={isLogin? "Login": "Sign Up"}
                                type={"submit"}
                                decoration_isBrightPrimary
                            />
                        </div>
                    </div>
                </Form>
            </BootstrapModal.Body>
            <BootstrapModal.Footer>
                 <a href="/reset-kata-laluan">Terlupa kata laluan?</a>
            </BootstrapModal.Footer>
        </BootstrapModal>
    )
}