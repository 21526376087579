import React from "react";
import { describeArc } from "helper/functions/describeArc.js";

import "assets/scss/components/ecard/svgCircle.scss";

export function SVGCircle({ radius, color }) {
  return (
    <svg className="countdown__svg">
      <path
        fill="none"
        stroke={color}
        strokeWidth="4"
        d={describeArc(35, 35, 30, 0, radius)}
      />
    </svg>
  );
}
