import React from "react";

import { Button } from "react-bootstrap";
import DropdownItem from "./DropdownItem";
import { useCustomSelector } from "hooks";

import "assets/scss/components/ecard/dropdownMenu.scss";

function DropdownMenu({
  children,
  input,
  sendMessage,
  readMessage,
  check,
  checkBox,
}) {
  const { appInfo } = useCustomSelector("app");
  const theme = appInfo.themes;

  const border1 = {
    // border: `solid ${primaryColor}`,
    border: `1px solid ${theme.primaryColor}`,
  };

  return (
    <div className="custom__dropdown" style={border1}>
      <DropdownItem>
        <form className="custom__form" onSubmit={sendMessage}>
          <div className="custom__formText">
            <textarea
              placeholder="Isikan ucapan anda di sini..."
              value={input}
              onChange={readMessage}
              maxlength="200"
              required
            />
          </div>
          <div className="sahkan-ucapan ">
            <div className="custom__formCheckbox">
              <input
                className=""
                type="checkbox"
                checked={check}
                name="confirmed"
                required
                onChange={checkBox}
              />
              <label for="confirmed">Ucapan telah disemak</label>
              <br></br>
            </div>
            <Button
              id="submit__btn"
              className="text_btn"
              disabled={!input}
              type="submit"
              variant="primary"
              size="sm"
            >
              HANTAR
            </Button>
          </div>
        </form>
      </DropdownItem>
    </div>
  );
}

export default DropdownMenu;
