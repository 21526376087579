import Axios from "axios";
import { Button } from "components/buttons/Button";
import { Input } from "components/formInputs/Input";
import {
  isArrayEmpty,
  isArrayNotEmpty,
  isStringTrimEmpty,
  isStringTrimNotEmpty,
} from "helper/validations";
import React, { useEffect, useState } from "react";
import ContentExpander from "components/contentExpander/ContentExpander";

function FAQ(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [faqList, setFaqList] = useState();
  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState();
  const [selectedSectionIdx, setSelectedSectionIdx] = useState();

  // TESTING
  useEffect(() => {
    if (isStringTrimEmpty(searchTerm)) {
      setFaqList([
        {
          topic: "Daftar Akaun Baru",
          list: [
            {
              question: "Bagaimana untuk daftar akaun baru?",
              answer:
                'Anda boleh mendaftar akaun baru EkadTepi dengan memilih "daftar" di bahagian log masuk > daftar. Selain itu, anda juga boleh mendaftar akaun baru menggunakan gmail, facebook, atau twitter.',
            },
            {
              question: "Masalah ketika mendaftar?",
              answer:
                "Sekiranya anda mempunyai masalah ketika mendaftar, sila hubungi kami melalui pautan emel yang telah disediakan.",
            },
          ],
        },
        {
          topic: "Maklumat Diri",
          list: [
            {
              question: "Adakah maklumat diri perlu diisi?",
              answer:
                "Maklumat diri ini perlu diisi kerana ia bertujuan untuk memudahkan proses jual beli dan memudahkan kami untuk menghubungi anda.",
            },
            {
              question: "Adakah maklumat diri yang diisi selamat?",
              answer:
                "Maklumat diri yang anda berikan adalah untuk kegunaan kami sahaja dan tidak akan didedahkan kepada orang lain.",
            },
          ],
        },
        {
          topic: "Maklumat EkadTepi",
          list: [
            {
              question: "Apakah maksud EkadTepi pilihan?",
              answer:
                "EkadTepi pilihan ialah senarai tema EkadTepi yang telah anda pilih dari tema-tema EkadTepi.",
            },
            {
              question: "Apakah maksud warna lilitan pada EkadTepi?",
              answer:
                "EkadTepi yang bewarna kuning bermaksud EkadTepi ini masih dalam proses dan belum dibayar. EkadTepi bewarna hijau bermaksud EkadTepi ini telah aktif dan siap untuk digunakan. EkadTepi bewarna merah bermaksud EkadTepi ini telah tidak aktif dan tidak boleh digunakan. ",
            },
            {
              question: "Berapa lama tempoh aktif EkadTepi?",
              answer:
                "EkadTepi akan tamat dan tidak aktif selepas sebulan dari tarikh majlis diadakan.",
            },
          ],
        },
        {
          topic: "Maklumat fungsi RSVP",
          list: [
            {
              question: "Apakah tujuan RSVP?",
              answer:
                "Tujuan RSVP ialah untuk memudahkan tuan rumah mengehadkan jumlah tetamu untuk setiap sesi dan memudahkan tuan rumah membuat anggaran jumlah tetamu yang akan datang.",
            },
            {
              question: "Berapa jumlah RSVP dan tetamu yang boleh ditetapkan?",
              answer:
                "Jumlah RSVP dan tetamu yang hadir akan ditetapkan oleh tuan rumah sendiri.",
            },
          ],
        },
        {
          topic: "Maklumat Ucapan",
          list: [
            {
              question: "Apakah tujuan fungsi Ucapan?",
              answer:
                "Fungsi Ucapan ditambah pada EkadTepi dengan tujuan agar kita dapat mengumpulkan kata-kata dan ucapan selamat dari mereka yang menerima EkadTepi ini.",
            },
            {
              question: "Adakah ucapan boleh dipadamkan?",
              answer:
                "Ya. Sekiranya terdapat ucapan yang tidak sesuai, ucapan tersebut boleh dipadam oleh pemilik akaun.",
            },
          ],
        },
        {
          topic: "Maklumat Album",
          list: [
            {
              question: "Apakah tujuan fungsi Album?",
              answer:
                "Fungsi Album ditambah pada EkadTepi dengan tujuan agar tuan rumah dan semua tetamu yang hadir dapat berkongsi gambar mereka ketika berada di majlis ini.",
            },
            {
              question: "Adakah gambar boleh dipadamkan?",
              answer:
                "Ya. Sekiranya terdapat gambar yang tidak sesuai, gambar tersebut boleh dipadam oleh pemilik akaun.",
            },
          ],
        },
        {
          topic: "Ubahsuai EkadTepi",
          list: [
            {
              question: "Berapa kali EkadTepi boleh diubahsuai?",
              answer:
                "Kami tidak menetapkan had untuk anda mengubahsuai EkadTepi. Jadi, EkadTepi sentiasa boleh diubahsuai mengikut kemahuan dan keselesaan anda.",
            },
            {
              question: "Adakah EkadTepi boleh diubahsuai selepas pembayaran?",
              answer: "Ya. EkadTepi masih boleh diubahsuai selepas pembayaran.",
            },
            {
              question: "Apakah yang boleh diubahsuai dalam EkadTepi?",
              answer:
                "Buat masa ini, kami hanya menetapkan untuk anda mengubah font tulisan dan warna pada EkadTepi.",
            },
            {
              question:
                "Adakah EkadTepi yang telah diubahsuai perlu terus dibayar?",
              answer:
                "Tidak. Anda boleh simpan dahulu semua ubahsuai yang anda telah buat dan bayaran boleh dibuat kemudian apabila anda ingin mengaktifkan EkadTepi tersebut.",
            },
          ],
        },
        {
          topic: "Pembayaran",
          list: [
            {
              question: "Apakah cara pembayaran yang diterima?",
              answer:
                "Kami menerima pembayaran melalui online banking dan pembayaran melalui kad kredit/debit.",
            },
            {
              question: "Adakah pembayaran ini selamat?",
              answer:
                "Ya. Pembayaran anda sudah semestinya selamat kerana kami menggunakan perkhidmatan ToyyibPay yang terjamin keselamatannya.",
            },
            {
              question: "Adakah harga EkadTepi tetap dan sama?",
              answer:
                "Ya. Harga EkadTepi tetap dengan harga RM60 dan sama untuk semua tema.",
            },
          ],
        },
      ]);
    }
  }, [searchTerm]);

  // useEffect(() => {
  //     Axios.get("/")
  //         .then((response) => {
  //             setFaqList(response.data);
  //         })
  // }, []);

  function handleOnSubmit() {
    if (isStringTrimNotEmpty(searchTerm)) {
      let pattern = new RegExp(searchTerm, "gi");
      setFaqList(
        faqList.map((faq) => {
          let newList = faq.list.filter((qa) => {
            return (
              qa.question.search(pattern) !== -1 ||
              qa.answer.search(pattern) !== -1
            );
          });

          if (newList.length === 0) {
            return;
          } else {
            return { ...faq, list: [...newList] };
          }
        })
      );
    }
  }

  if (isArrayEmpty(faqList)) return "";

  return (
    <div className={"about-us__faq"}>
      {/* Header */}
      <div
        className={
          "faq__header d-flex flex-column justify-content-center align-items-center"
        }
      >
        {/* Title */}
        <div className={"header__title text-center"}>
          Frequently asked questions (faq)
        </div>

        {/* Search Questions */}
        <div className={"header__search-questions d-flex"}>
          <Input
            className={"search-questions__input-wrapper"}
            inputClassName={"search-questions__input"}
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder={"Tulis carian anda di sini"}
          />

          <Button
            className={"search-questions__submit"}
            onClick={handleOnSubmit}
            content={"Cari"}
          />
        </div>
      </div>

      {/* Details */}
      <div className="faq_box">
        <div className={"faq__details"}>
          {isArrayNotEmpty(faqList)
            ? faqList.map((details, sectionIdx) => {
                let topic = details?.topic;
                let list = details?.list;

                return (
                  <div key={sectionIdx} className={"details__section"}>
                    {/* Topic */}
                    <div className={"section__topic"}>{topic}</div>

                    {/* Questions/Answers */}
                    {isArrayNotEmpty(list)
                      ? list.map((details, idx) => {
                          let question = details?.question;
                          let answer = details?.answer;

                          let isAnswerVisible =
                            selectedQuestionIdx == idx &&
                            selectedSectionIdx == sectionIdx;

                          return (
                            <div key={idx}>
                              <ContentExpander
                                mainDisplayText={question}
                                expandedContent={answer}
                                onClick={() => {
                                  if (isAnswerVisible) {
                                    setSelectedQuestionIdx(undefined);
                                    setSelectedSectionIdx(undefined);
                                  } else {
                                    setSelectedQuestionIdx(idx);
                                    setSelectedSectionIdx(sectionIdx);
                                  }
                                }}
                                isExpanded={isAnswerVisible}
                                className={"section__question"}
                                mainDisplayTextClassName={"question__text"}
                                iconClassName={"question__expander"}
                                expanderContentClassNam={"section__answer"}
                              />
                            </div>
                          );
                        })
                      : ""}
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
