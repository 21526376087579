import React from "react";

import CardPreview from ".//sections/CardPreview";

import "assets/scss/modules/home/home.scss";
import { Highlight } from "./sections/Highlight";
import { WhyUs } from "./sections/WhyUs";
import { RegistrationSteps } from "./sections/RegistrationSteps";
import { LiveExamples } from "./sections/LiveExamples";
import { Attraction } from "./sections/Attraction";

export default function Home() {
  return (
    <div className={"home  d-flex flex-column align-items-center"}>
      <Highlight />

      <WhyUs />

      <LiveExamples />

      <CardPreview />

      <Attraction />

      <RegistrationSteps />
    </div>
  );
}
