import CreditCardInput from "components/formInputs/CreditCardInput";
import { Input } from "components/formInputs/Input";
import React from "react";

function CreditCard(props) {
  const {
    cardName,
    cardNumber,
    expirationDate,
    cvc,
    //FUNCTIONS
    setCardName,
    setCardNumber,
    setExpirationDate,
    setCVC,
    isUpdateAttempted,
    isDisabled,
  } = props;

  const checkCVCNumber = (value) => {
    if (value.match(/^[^0-9]+$/gi)) {
      return false;
    } else {
      return setCVC(value);
    }
  };

  const checkExpirationDate = (value) => {
    if (value.match(/^[^0-9]+$/gi)) {
      return false;
    } else {
      return setExpirationDate(value);
    }
  };

  const checkCardNumber = (value) => {
    if (value.match(/^[^0-9]+$/gi)) {
      return false;
    } else {
      return setCardNumber(value);
    }
  };

  const formatCardNumber = (value) => {
    if (value === undefined) {
      return value;
    }
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  };

  const formatExpirationDate = (value) => {
    if (value === undefined) {
      return value;
    }
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{2,4}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 2) {
      parts.push(match.substring(i, i + 2));
    }

    if (parts.length) {
      return parts.join(" / ");
    } else {
      return value;
    }
  };

  return (
    <div className={"credit-card"}>
      <CreditCardInput
        className={
          "credit-card__number d-flex flex-column justify-content-center"
        }
        labelClassName={"credit-card__number__label"}
        label={"Nombor Kad:"}
        inputClassName={"credit-card__number__input"}
        value={formatCardNumber(cardNumber)}
        onChange={checkCardNumber}
        isSubmissionAttempted={isUpdateAttempted}
        isDisabled={isDisabled}
      />

      <div
        className={
          "credit-card__info d-flex align-center-center justify-content-between"
        }
      >
        <Input
          className={
            "credit-card__expiration-date d-flex flex-column justify-content-center"
          }
          labelClassName={"credit-card__expiration-date__label"}
          label={"Tarikh Luput:"}
          value={formatExpirationDate(expirationDate)}
          onChange={checkExpirationDate}
          inputClassName={"credit-card__expiration-date__input"}
          isInvalid={!expirationDate && isUpdateAttempted}
          errorMsg={"Expiration Date is required"}
          isDisabled={isDisabled}
          inputType={"tel"}
          placeholder={"01 / 23"}
          maxLength={"7"}
        />

        <Input
          className={
            "credit-card__cvc d-flex flex-column justify-content-center"
          }
          labelClassName={"credit-card__cvc__label"}
          label={"CVV2/CVC2:"}
          value={cvc}
          onChange={checkCVCNumber}
          inputClassName={"credit-card__cvc__input"}
          isInvalid={!cvc && isUpdateAttempted}
          errorMsg={"CVC is required"}
          isDisabled={isDisabled}
          inputType={"tel"}
          placeholder={"XXX"}
          maxLength={"3"}
        />
      </div>

      {/* Nama Penuh */}
      <Input
        className={
          "credit-card__fullname d-flex flex-column justify-content-center"
        }
        labelClassName={"fullname__label"}
        label={"Nama Pemegang Kad:"}
        value={cardName}
        onChange={setCardName}
        inputClassName={"fullName__input"}
        isInvalid={!cardName && isUpdateAttempted}
        errorMsg={"Nama Penuh diperlukan"}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default CreditCard;
