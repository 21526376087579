import { createSlice } from '@reduxjs/toolkit'

export const cardCatalogSlice = createSlice({
  name: 'cardCatalog',
  initialState: {},
  reducers: {
    getCardCatalogList_requesting: (state) => {
      state.getCardCatalogList_requesting = true;
      state.getCardCatalogList_status = false;
      state.getCardCatalogList_errorMsg = undefined;
    },
    getCardCatalogList_success: (state, action) => {
      state.getCardCatalogList_requesting = false;
      state.getCardCatalogList_status = true;
      state.cardCatalogList = action.payload.data;
    },
    getCardCatalogList_failure: (state, action) => {
      state.getCardCatalogList_requesting = false;
      state.getCardCatalogList_errorMsg = action.payload;
    },
  },
});

export const { 
  getCardCatalogList_requesting, getCardCatalogList_success, getCardCatalogList_failure,
} = cardCatalogSlice.actions;

export default cardCatalogSlice.reducer;