import React from 'react';
import default_background from "assets/images/background.png";
import { isArrayNotEmpty } from 'helper/validations';

import { OverlayTrigger } from 'react-bootstrap';
import CustomButton from 'components/ecard/CustomButton';
import ModalComp from 'components/ecard/ModalComp';
import Message from 'components/ecard/Message';
import BackButton from 'components/ecard/BackButton';

export default function WishDisplay({
    titleStyle, messages, 
    renderPopover, isTooltipVisible,
    
    input, sendMessage, readMessage, check, checkBox,
    setModalShow, setTooltip,

    isModalVisible, isAddIconHidden
}) {
    return (
        <div className="general_background">
            <div className="wish_component d-flex flex-column">
                <div className="wish_header d-flex justify-content-between align-items-center">
                <div className="wish_header_back">
                    <BackButton />
                </div>
                <div className="wish_header_title text-center">
                    <div className="header2">
                    <h4 id="tajuk" style={titleStyle}>
                        UCAPAN
                    </h4>
                    </div>
                </div>
                <div className="wish_header_empty text-right"></div>
                </div>
            
                {/* content page */}
                <div className="wish__container m-auto">
                    

                    <div className="ucapan__timeline">
                        {isArrayNotEmpty(messages) && messages.map((message) => (
                            <Message
                                key={message?.id}
                                id={message?.id}
                                user={message?.data?.user}
                                relationship={message?.data?.relationship}
                                message={message?.data?.wish}
                                timestamp={message?.data?.timestamp}
                            />
                        ))}
                    </div>

                    {!isAddIconHidden && (
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderPopover}
                            show={messages?.length !== 0 ? false : isTooltipVisible}
                        >
                            <CustomButton
                                icon={"fas fa-plus fa-fw send__icon"}
                                input={input}
                                sendMessage={sendMessage}
                                readMessage={readMessage}
                                check={check}
                                checkBox={checkBox}
                                setModalShow={setModalShow}
                                setTooltip={setTooltip}
                            />
                        </OverlayTrigger>
                    )}

                    <div className="wishModalComp ">
                        <ModalComp
                            text={"Sila isikan maklumat anda:"}
                            survey={false}
                            show={isModalVisible}
                            onHide={() => setModalShow(false)}
                        />
                    </div>

                    
                </div>
            </div>
        </div>
    );
}