import React from 'react';

import { Input } from './Input';

import { isPasswordInvalid } from "helper/validations"; 



export function PasswordInput(props) {
    const {
        customLabel,
        value,
        onChange,
        isLogin,
        customInvalidation,
        isSubmissionAttempted
    } = props;

    let isInvalid = isPasswordInvalid(value);
    console.log(isInvalid)
    let errorMsg = (
        <>
            <div>At least 1 lowercase</div>
            <div>At least 1 uppercase</div>
            <div>At least 1 number</div>
            <div>At least 1 special character (!@#$%^&*)</div>
            <div>Must be minimum 8 characters</div>
        </>
    );

    if (isLogin) {
        isInvalid = customInvalidation;
        errorMsg = "Wrong Password";
    }

    return (
        <Input
            controlId={"passwordInput"}
            label={customLabel? customLabel : "Password"}
            placeholder={"Enter Password"}
            inputType={"password"}
            
            value={value}
            onChange={onChange}
    
            isInvalid={isInvalid && isSubmissionAttempted}
            errorMsg={errorMsg}
        />
    );
}