import React from 'react'

import { Input } from './Input';

function CreditCardInput(props) {
    const {
        className, inputClassName, labelClassName,
        value, label,
        onChange,
        isSubmissionAttempted,
        isDisabled,
    } = props;

    return (
        <Input
            className={className}
            controlId={"cardNumber"}
            labelClassName={labelClassName}
            label={label? label:"Card Number:"}
            placeholder={"1111-2222-3333-4444"}
            inputType={"tel"}
            maxLength={"19"}
            isDisabled={isDisabled}

            inputClassName={inputClassName}
            value={value}
            onChange={onChange}
            
            isInvalid={!value && isSubmissionAttempted}
            errorMsg={"Invalid Email"}
        />
    );
}

export default CreditCardInput
