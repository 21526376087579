import React from "react";
import { isArrayNotEmpty } from "helper/validations";

import BackButton from "components/ecard/BackButton";
import moment from "moment";
import { convertToDate, isDateValid } from "helper/validations/date";

export default function TentativeDisplay({
  bgOverlayStyle,

  titleStyle,

  tentatives,
  timeStyle,
  eventNameStyle,
}) {
  return (
    <div className="general_background">
      <div className="tentative_component d-flex flex-column">
        <div className="tentative_header d-flex justify-content-between align-items-center">
          <div className="tentative_header_back">
            <BackButton />
          </div>
          <div className="tentative_header_title text-center">
            <div className="header2">
              <h4 id="tajuk" style={titleStyle}>
                ATURCARA
              </h4>
            </div>
          </div>
          <div className="tentative_header_empty text-right"></div>
        </div>

        <div className="tentative__container m-auto">
          <div
            className={
              tentatives.length <= 8 ? "box_list_center" : "box_list_top"
            }
            style={{ ...bgOverlayStyle, borderRadius: "30px" }}
          >
            {isArrayNotEmpty(tentatives) &&
              tentatives.map((list, index) => (
                <div id="list_aturcara" key={index}>
                  <div className="aturcara__time" style={timeStyle}>
                    {isDateValid(list?.time) &&
                      moment(convertToDate(list?.time))
                        .locale(navigator?.language)
                        .format("hh:mm a")}
                  </div>
                  <div className="aturcara__aktiviti" style={eventNameStyle}>
                    <p className="aktivitinya">{list.activity}</p>
                  </div>
                </div>
              ))}
          </div>

        </div>
      </div>
    </div>
  );
}
