import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    booking: null,
  },
  reducers: {
    getbooking_requesting: (state) => {
      state.getbooking_requesting = true;
      state.getbooking_status = false;
      state.getbooking_errorMsg = undefined;
    },
    getbooking_success: (state, action) => {
        state.getbooking_requesting = false;
        state.getbooking_status = true;
        state.booking = action.payload;
    },
    getbooking_failure: (state, action) => {
        state.getbooking_requesting = false;
        state.getbooking_errorMsg = action.payload;
    },

  },
});

export const { getbooking_requesting, getbooking_success, getbooking_failure } = userSlice.actions;

export default userSlice.reducer;
