import React, { useEffect, useRef, useState } from "react";

import "assets/scss/components/order.scss";
import { Button } from "./buttons/Button";
import trash from "assets/images/trash-alt-regular.svg";
import copy from "assets/images/clone-regular.svg";
import pencil from "assets/images/pencil-alt-solid.svg";
import { firestoreDB } from "dataflow/services/firebase";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Card } from "./Card";
import { generateUniqueId, isNotNullUndefined } from "helper/validations";
import { useCustomSelector } from "hooks";
import { Input } from "./formInputs/Input";
import { Form } from "./formInputs/Form";
import { promiseInstance as Axios } from "dataflow/services/config";

import swal from "sweetalert";

export function Order(props) {
  const {
    id,
    status,
    draft,
    link,
    eventId,
    themesId,
    dateCreated,
    lastUpdated,
    name,
    paymentDate,
    amount,
  } = props;

  const [eventStatus, setEventStatus] = useState();
  const [themes, setThemes] = useState();
  const [home, setHome] = useState();
  const [cardName, setCardName] = useState(name);

  const [isUpdateAttempted, setIsUpdateAttempted] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const copyRef = useRef(null);
  const inputNameId = useRef(generateUniqueId("card-name"));

  const history = useHistory();
  let { path, url } = useRouteMatch();

  const { getuserCardList_requesting, userCardList } =
    useCustomSelector("userCard");

  useEffect(() => {
    let event = userCardList?.find((card) => card.id === eventId);

    if (event) {
      setThemes(event?.themes);
      setHome(event?.home);
      let eventTime = event?.timestamp?.toDate();
      let currentTime = new Date();
      if (currentTime - eventTime >= 0) {
        setEventStatus("Tamat");
      } else {
        setEventStatus("Aktif");
      }
    }
  }, [userCardList, eventId]);

  const deleteOrder = () => {
    swal({
      title: "Adakah anda pasti?",
      text: "Setelah dipadamkan, anda tidak akan mengembalikan draf ini.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      className: "delete-order__inquiry",
    }).then((willDelete) => {
      if (willDelete) {
        firestoreDB()
          .collection("orders")
          .doc(id)
          .delete()
          .then(() => {
            //in the future need to implement this with cloud functions
            firestoreDB()
              .collection("cards")
              .doc(eventId)
              .delete()
              .then(() => {
                console.log("Event: ", eventId, " deleted");

                swal("Poof! Draf anda telah dipadamkan.", {
                  icon: "success",
                  className: "delete-order__success",
                });
              })
              .catch((error) => {
                console.error("Error removing event: ", error);

                swal("Gagal memadam draf!", {
                  icon: "error",
                  className: "delete-order__failed",
                });
              });
          })
          .catch((error) => {
            console.error("Error removing order: ", error);

            swal("Gagal memadam draf!", {
              icon: "error",
              className: "delete-order__failed",
            });
          });
      } else {
        swal("Draf anda masih selamat");
      }
    });
  };

  const editCardName = () => {
    if (isDisabled) {
      setIsDisabled(false);

      // focus on the input
      window.setTimeout(() => {
        let inputEle = document.getElementById(inputNameId.current);
        if (inputEle) inputEle.focus();
      }, 0);
    } else {
      setIsDisabled(true);

      let body = { orderName: cardName, orderId: id };

      Axios.post("/ubahsuai-ekad/selesai", body)
        .then((response) => {
          console.log("Response: ", response);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
  };

  const copyToClipboard = (e) => {
    let copyText = copyRef.current.href;
    document.addEventListener(
      "copy",
      (event) => {
        event.clipboardData.setData("text/plain", copyText);
        event.preventDefault();
      },
      true
    );
    document.execCommand("copy");
  };

  return (
    <div
      className={
        "component-order d-flex align-items-center justify-content-center"
      }
    >
      {isNotNullUndefined(home) && isNotNullUndefined(themes) && (
        <a className="order-card" href={link && link} target={"_blank"}>
          <Card
            key={eventId}
            eCardUrl={link}
            height={"244px"}
            width={"180px"}
            userOrder
            eventId={eventId}
            theme={themes}
            homeData={home}
          />
        </a>
      )}

      <div className="order-details">
        <Form>
          <div
            className={
              "card-name__container d-flex justify-content-center align-items-center m-auto"
            }
          >
            <Input
              inputId={inputNameId.current}
              className={
                "card-name__input d-flex justify-content-center align-items-center"
              }
              labelClassName={"card-name__label"}
              label={"Nama Kad:"}
              value={cardName}
              onChange={setCardName}
              inputClassName={"card-name__input"}
              isInvalid={!name && isUpdateAttempted}
              errorMsg={"Nama Kad diperlukan"}
              isDisabled={isDisabled}
            />
            {/* {Edit Card Name} */}
            <Button
              className={"card-name__edit"}
              decoration_isBrightPrimary={isDisabled}
              decoration_isSecondary={!isDisabled}
              content={
                <img className={"edit__icon"} src={pencil} alt={"Edit"} />
              }
              onClick={editCardName}
            />
          </div>
        </Form>

        <p>
          <strong>Order ID:</strong> {id}
        </p>

        <p>
          <strong>Kemaskini:</strong>{" "}
          {lastUpdated?.toDate().toLocaleTimeString(navigator.language, {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </p>
        <p>
          <strong>Status:</strong> {status ? status : eventStatus}
        </p>
        <p>
          <strong>Link URL: </strong>
          {link ? (
            <a
              href={`${window.location.origin}${link}`}
              target={"_blank"}
              ref={copyRef}
            >
              {`${window.location.host}${link}`}
            </a>
          ) : (
            "N/A"
          )}
        </p>

        {!draft && (
          <>
            <p>
              <strong>Pembayaran:</strong>{" "}
              {paymentDate?.toDate().toLocaleTimeString(navigator.language, {
                year: "numeric",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              })}
            </p>

            <p>
              <strong>Jumlah:</strong> RM {amount}
            </p>
          </>
        )}

        {draft ? (
          <div className={"component-order__action d-flex flex-column w-100"}>
            <div
              className={
                "component-order__action-wrapper d-flex justify-content-between"
              }
            >
              {/* Edit */}
              <Button
                className={"action__edit "}
                content={"Ubahsuai"}
                onClick={() =>
                  history.push({
                    pathname: `/ubahsuai-ekadtepi/${id}/${eventId}`,
                    state: { completed: false },
                  })
                }
                decoration_isBrightInversePrimary
              />
              {/* Delete */}
              <Button
                className={"action__delete"}
                content={
                  <img className={"delete__icon"} src={trash} alt={"Delete"} />
                }
                onClick={deleteOrder}
                decoration_delete
              />
            </div>
            {/* Pay */}
            <Button
              className={"action__pay"}
              content={"Pembayaran"}
              onClick={() => history.push(`/pembayaran/${id}`)}
              decoration_isBrightPrimary
            />
          </div>
        ) : (
          <div className={"completed-order d-flex w-100"}>
            {/* Edit */}
            <Button
              className={"action__edit"}
              content={"Ubahsuai"}
              onClick={() =>
                history.push({
                  pathname: `/ubahsuai-ekadtepi/${id}/${eventId}`,
                  state: { completed: true },
                })}
              decoration_isBrightInversePrimary
              isDisabled={eventStatus === "Tamat" && true}
            />
            {/* Copy link */}
            <Button
              className={"action__copy"}
              content={
                <>
                  <div className="copy__id">Salin Link</div>
                  <img className={"copy__icon"} src={copy} alt={"Copy"} />
                </>
              }
              onClick={copyToClipboard}
              decoration_isBrightPrimary
            />
          </div>
        )}
      </div>
    </div>
  );
}
