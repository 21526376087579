import { isNotNullUndefined } from ".";
import { isStringTrimNotEmpty } from "./string";

export const validateNumber = {
    isNumber,
	isNumberValid
}

export function isNumber(val) {
	return (typeof val) === "number";
}

export function isNumberValid(val) {
	return isNumber(val) && isNotNullUndefined(val) && !isNaN(val);
}

export function isNumberOrStringValid(value) {
	return isNumberValid(value) || 
		(isStringTrimNotEmpty(value) && 
		(value.match(/^\d+\.?\d*/g) && value.match(/^\d+\.?\d*/g)[0].length === value.length) && 
		!isNaN(parseFloat(value)));
}

export function convertStringToNumber(val) {
	if (isNumberOrStringValid(val)) return parseFloat(val);
	return val;
}