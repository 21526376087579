import { ImgWithFallback } from 'components';
import MenuPageContainer from 'components/containers/MenuPageContainer';
import { useCustomSelector } from 'hooks';
import WishDisplay from 'modules/ECard/sections/wish/WishDisplay';
import React from 'react';

function useWishStep({
    cardThemeBgImage, content_pageTitleDetails,

    wish_wishDetails, wish_textDetails
}) {
    const { ecardEditDetails } = useCustomSelector("app");

    return {
        input_wishStep: (
            <div className={"inputs__wish-step"}>
                {/* Title */}
                <div className={"wish-step__title d-flex align-items-center"}>
                    Ucapan
                </div>

                {/* Description */}
                <div className={"wish-step__description"}>
                    Tidak perlu ubahsuai.
                </div>
            </div>
        ), 
        preview_wishStep: (
            <MenuPageContainer ecardEditDetails={ecardEditDetails}>
                <WishDisplay
                    
                    titleStyle={{
                        fontFamily: content_pageTitleDetails?.font,
                        color: content_pageTitleDetails?.fontColor
                    }}

                    isAddIconHidden

                    messages={[
                        {
                            "id": "KQSJvsEgtAK4HK0xjGQ8",
                            "data": {
                                "relationship": "Keluarga",
                                "user": "Aiman",
                                "wish": "tahniah guys! Semoga Allah berkati perkawinan korang bedua.",
                                "timestamp": {
                                    "seconds": 1635598126,
                                }
                            }
                        },
                        {
                            "id": "KQSJvsEgtAK4HK0xjGQ7",
                            "data": {
                                "relationship": "Jiran",
                                "user": "Aisyah",
                                "wish": "Congrats!",
                                "timestamp": {
                                    "seconds": 1635598000,
                                }
                            }
                        },
                        {
                            "id": "KQSJvsEgtAK4IK0xjGQ7",
                            "data": {
                                "relationship": "Rakan Universiti",
                                "user": "Johari",
                                "wish": "Mantap la bro! Aku dah agak dah muka hensem tu senang nak cari isteri haha",
                                "timestamp": {
                                    "seconds": 1635508100,
                                }
                            }
                        },
                        {
                            "id": "K4SJvsEgtAK4IK0xjGQ7",
                            "data": {
                                "relationship": "Lain-lain",
                                "user": "Siti",
                                "wish": "Alhamdulillah, akak dah kawin. Terima kasih menjemput saye. Tak sabar nak tengok wedding dress akak. hehehe",
                                "timestamp": {
                                    "seconds": 1635098100,
                                }
                            }
                        }
                    ]}

                    // textStyle={fontStyle1}
                    // messages={messages}
                    // iconStyle={iconStyle1}

                    // renderPopover={renderPopover}
                    // isTooltipVisible={tooltip}

                    // input={input}
                    // sendMessage={sendMessage}
                    // readMessage={readMessage}
                    // check={check}
                    // checkBox={checkBox}
                    // setModalShow={setModalShow}
                    // setTooltip={setTooltip}

                    // isModalVisible={modalShow}

                    // isMinusIconVisible={minus}
                    // onMinusIconClick={show}
                />
            </MenuPageContainer>
        )
    }
}

export default useWishStep;