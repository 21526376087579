import NavButton from 'components/ecard/NavButton';
import { isNotNull } from 'helper/validations';
import React, { useEffect, useRef } from 'react'

import menu_close from "assets/images/nav-icon-fill.svg";

function CoverPageContainer(props) {

    const {
        children, musicList, musicURL, isAvail, isMuted, muteUnMute, loadMusic, loadSelectedMusic, audioComp,
        
        app_details
    } = props;

    //need to add to update data
    useEffect(() => {
        loadMusic(app_details?.musicURL)
    }, [app_details])

    const audioRef = useRef(null);
    useEffect(() => {
        if (isAvail && isNotNull(audioRef?.current) && !audioRef?.current?.muted) {
            audioRef.current.pause();
            audioRef.current.load();
            audioRef.current.play();
        }
        
    });

    return (
        <div className={"coverPageContainer w-100 h-100"}>
                {children} 

                {isAvail && (
                    <audio ref={audioRef} playsInline muted={isMuted} loop preload="auto">
                    <source src={musicURL} type="audio/mpeg"></source>
                    </audio>
                )}

                <div className={"position-absolute d-flex flex-column justify-content-between align-items-center t-10"} style={{zIndex: "10", right: "5%", bottom: "8%"}}>
                    
                    {isAvail && (
                    <NavButton phase={isMuted} setPhase={muteUnMute}>
                        <i
                        style={!isMuted ? { color: "#47b0e2", fontSize: "30px" } : { color: "#ebebe4", fontSize: "30px" }}
                        className={
                            "icons fas " +
                            (isMuted ? "fa-volume-mute " : "fa-volume-down ")
                        }
                        ></i>
                        
                    </NavButton>
                    )}

                    <NavButton phase={false} >
                        <img src={menu_close} alt={"Menu Close"} className={"menu-close"} onClick={() => {}}  style={{height: "30px", width: "30px"}}/> 
                    </NavButton>
                </div>
            </div> 
    )
}

export default CoverPageContainer