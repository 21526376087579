import React, { useLayoutEffect, useState } from "react";

import "assets/scss/modules/payment/payment.scss";

import { Form } from "components/formInputs/Form";
import { Button } from "components/buttons/Button";
import { Input } from "components/formInputs/Input";
import { TelInput } from "components/inputs/TelInput";
import { EmailInput } from "components/formInputs/EmailInput";
import {
  getObjectPropertyValue,
  isArrayNotEmpty,
  isEmailValid,
  isStringNotEmpty,
  isUserLoggedIn,
} from "helper/validations";
import CreditCard from "./sections/CreditCard";
import OnlineBanking from "./sections/OnlineBanking";
import { useHistory, useParams } from "react-router-dom";
import { promiseInstance as Axios } from "dataflow/services/config";
import { useCustomSelector } from "hooks";

import { endpointUrls } from "helper/constants/endpointUrls";

function Payment() {
  // get latest user info from Redux store
  const { personalInfoObject } =
    useCustomSelector("personalInfo");

  const history = useHistory();
  const { id } = useParams();

  const [currentSelection, setCurrentSelection] = useState("fpx");
  const [amount, setAmount] = useState("60.00");
  const [isSubmissionAttempted, setIsSubmissionAttempted] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  //Credit Card
  const [cardName, setCardName] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [expirationDate, setExpirationDate] = useState();
  const [cvc, setCVC] = useState();

  //fpx
  const [selectedBankCode, setSelectedBankCode] = useState("TEST0021");
  const [bankList, setBankList] = useState([]);

  //get redux data objex value
  let nameValue = getObjectPropertyValue(personalInfoObject, "name", "");
  let phoneNumberValue = getObjectPropertyValue(
    personalInfoObject,
    "phoneNumber",
    ""
  );
  let cityValue = getObjectPropertyValue(personalInfoObject, "city", "");
  let addressValue = getObjectPropertyValue(personalInfoObject, "address", "");
  let postcodeValue = getObjectPropertyValue(
    personalInfoObject,
    "postcode",
    null
  );
  let stateValue = getObjectPropertyValue(personalInfoObject, "state", "");
  let countryValue = getObjectPropertyValue(personalInfoObject, "country", "");
  let emailValue = getObjectPropertyValue(personalInfoObject, "email", "");

  const [name, setName] = useState(nameValue);
  const [phone, setPhone] = useState(phoneNumberValue);
  const [fullPhone, setFullPhone] = useState(phoneNumberValue);
  const [address, setAddress] = useState(addressValue);
  const [city, setCity] = useState(cityValue);
  const [postcode, setPostcode] = useState(postcodeValue);
  const [state, setState] = useState(stateValue);
  const [country, setCountry] = useState(countryValue);
  const [email, setEmail] = useState(emailValue);

  let selectionList = [
    {
      header: "Kad",
      id: "card",
    },
    {
      header: "Online",
      id: "fpx",
    },
  ];

  let display = "";
  switch (currentSelection) {
    case "fpx":
      display = (
        <OnlineBanking
          bankList={bankList}
          selectedBankCode={selectedBankCode}
          setSelectedBankCode={setSelectedBankCode}
        />
      );
      break;
    case "card":
      display = (
        <CreditCard
          cardName={cardName}
          cardNumber={cardNumber}
          expirationDate={expirationDate}
          cvc={cvc}
          setCardName={setCardName}
          setCardNumber={setCardNumber}
          setExpirationDate={setExpirationDate}
          setCVC={setCVC}
          isSubmissionAttempted={isSubmissionAttempted}
          isDisabled={isDisabled}
        />
      );
      break;
    default:
      display = (
        <OnlineBanking
          bankList={bankList}
          selectedBankCode={selectedBankCode}
          setSelectedBankCode={setSelectedBankCode}
        />
      );
      break;
  }

  const changePhone = (newNumber, fullNumber) => {
    setPhone(newNumber);
    setFullPhone(fullNumber); //set full phone number with country code
    console.log(newNumber, nameValue);
  };

  useLayoutEffect(() => {
    if (!isUserLoggedIn()) {
      history.replace("/utama");
    } else {
      Axios.get(endpointUrls.getBankList)
        .then((res) => {
          setBankList(res.data);
        })
        .catch((error) => {});
    }
  }, []);

  const handlePayment = () => {
    setIsSubmissionAttempted(true);

    if (
      isStringNotEmpty(name) &&
      isStringNotEmpty(fullPhone) &&
      isStringNotEmpty(email) &&
      isEmailValid(email) &&
      isStringNotEmpty(address) &&
      isStringNotEmpty(city) &&
      isStringNotEmpty(postcode) &&
      isStringNotEmpty(state) &&
      isStringNotEmpty(country)
    ) {
      //disable page
      //show loading UI Trigger

      if (currentSelection === "fpx") {
        let body = {
          name: name,
          phone: fullPhone,
          email: email,
          bankId: selectedBankCode,
          orderId: id,
        };
        Axios.post(endpointUrls.postOnlinePayment, body)
          .then((response) => {
            history.push({
              pathname: "/loading",
              state: { data: response.data },
            });
          })
          .catch((error) => {
            setIsSubmissionAttempted(false);
          });
      } else {
      }
    }
  };

  return (
    <div
      className={
        "payment container d-flex flex-column align-items-center justify-content-center"
      }
    >
      {/* Page Title */}
      <div className={"payment__page-title text-center"}>
        <span>
          Pembayaran <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      <Form className={"payment__form container"}>
        <div
          className={
            "payment__amount d-flex justify-content-between align-items-center"
          }
        >
          <span className="total-amount">JUMLAH</span>
          <span className="the-amount">RM{amount}</span>
        </div>

        <div className={"payment__billing-info"}>
          {/* <div className={"billing-info__title"}>
            <p>Maklumat Pembayaran:</p>
          </div> */}
          <div className="payment-id">
            <span className="payment-for-order ">
              <span className="pay-order-number">Order ID: </span>
              <span className="pay-order-id"> {id}</span>
            </span>
          </div>

          {/* Nama Penuh */}
          <Input
            className={
              "billing-info__fullname d-flex flex-column justify-content-center "
            }
            labelClassName={"fullname__label"}
            label={"Nama Penuh:"}
            value={name}
            onChange={setName}
            inputClassName={"fullName__input"}
            isInvalid={!name && isSubmissionAttempted}
            errorMsg={"Nama Penuh diperlukan"}
            isDisabled={isDisabled}
          />

          <div className="tel-and-email d-flex justify-content-center align-items-center">
            <TelInput
              className={
                "billing-info__phone d-flex flex-column justify-content-center"
              }
              id={"phone"}
              labelClassName={"phone__label"}
              label={"No. Telefon:"}
              value={phone}
              onChange={changePhone}
              inputClassName={"Phone__input"}
              isSubmissionAttempted={!phone && isSubmissionAttempted}
              errorMsg={"Phone No. is required"}
              isDisabled={isDisabled}
            />

            <EmailInput
              className={
                "billing-info__email d-flex flex-column justify-content-center"
              }
              labelClassName={"email__label"}
              label={"Emel:"}
              inputClassName={"email__input"}
              value={email}
              onChange={setEmail}
              isSubmissionAttempted={isSubmissionAttempted}
            />
          </div>

          <div className="payment-info__address1 d-flex align-items-center">
            <Input
              className={
                "address1__street d-flex flex-column justify-content-center"
              }
              labelClassName={"street__label"}
              label={"Alamat Rumah:"}
              value={address}
              onChange={setAddress}
              inputClassName={"street__input"}
              isInvalid={!address && isSubmissionAttempted}
              errorMsg={"Address is required"}
              isDisabled={isDisabled}
            />

            <Input
              className={
                "address1__city d-flex flex-column justify-content-center"
              }
              labelClassName={"city__label"}
              label={"Bandar:"}
              value={city}
              onChange={setCity}
              inputClassName={"Bandar__input"}
              isInvalid={!city && isSubmissionAttempted}
              errorMsg={"City is required"}
              isDisabled={isDisabled}
            />
          </div>
          <div className={"personal-info__address2 d-flex align-items-center"}>
            <Input
              className={
                "address2__postcode d-flex flex-column justify-content-center"
              }
              labelClassName={"postcode__label"}
              label={"Poskod:"}
              value={postcode}
              onChange={setPostcode}
              inputClassName={"postcode__input"}
              isInvalid={!postcode && isSubmissionAttempted}
              errorMsg={"Postcode is required"}
              isDisabled={isDisabled}
            />

            <Input
              as={"select"}
              className={
                "address2__state d-flex flex-column justify-content-center"
              }
              labelClassName={"state__label"}
              label={"Negeri:"}
              value={state}
              onChange={setState}
              inputClassName={"state__input"}
              isInvalid={!state && isSubmissionAttempted}
              errorMsg={"State is required"}
              isDisabled={isDisabled}
            />

            <Input
              as={"select"}
              className={
                "address2__origin d-flex flex-column justify-content-center"
              }
              labelClassName={"origin__label"}
              label={"Negara:"}
              value={country}
              onChange={setCountry}
              inputClassName={"origin__input"}
              isInvalid={!country && isSubmissionAttempted}
              errorMsg={"Country is required"}
              isDisabled={isDisabled}
            />
          </div>
        </div>

        <div className={"payment__method"}>
          <div
            className={
              "payment__method-selection d-flex align-items-center justify-content-between"
            }
          >
            <span>Cara Pembayaran:</span>
            <div
              className={
                "payment__method-selection-list d-flex align-items-center "
              }
            >
              {isArrayNotEmpty(selectionList) &&
                selectionList.map((info, index) => {
                  let header = info?.header;
                  let id = info?.id;
                  let isSelected = currentSelection === id;

                  return (
                    <div
                      key={id}
                      className={"selections__item-wrapper text-center"}
                    >
                      <div
                        className={
                          "selections__item " +
                          `${id} ` +
                          (isSelected
                            ? "selection__item_selected "
                            : "selection__item_unselected")
                        }
                        onClick={() => setCurrentSelection(id)}
                      >
                        {/* Header */}
                        <div className={"item__header"}>{header}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* Display */}
          <div className={"payment__method-display"}>{display}</div>
        </div>

        <div className={"payment-button w-100 d-flex justify-content-center"}>
          <Button
            className={"payment__button-back"}
            content={"Kembali"}
            onClick={() => history.goBack()}
            decoration_isBrightInversePrimary
          />

          <Button
            className={"payment__button-pay"}
            content={"Pembayaran"}
            onClick={handlePayment}
            decoration_isBrightPrimary
          />
        </div>
      </Form>
    </div>
  );
}

export default Payment;
