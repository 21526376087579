import { isNumberValid, isStringTrimNotEmpty } from 'helper/validations';
import Swal from 'sweetalert2';


export function Toaster(props) {
    const { type, msg, position, timer, 
            isTimerProgressBarHidden, isTimerStop_onMouseEnter_NotRequired } = props;

    // Validate Props
    let type_formatted = "";
    if (isStringTrimNotEmpty(type)) type_formatted = type;
    let msg_formatted = "";
    if (isStringTrimNotEmpty(msg)) msg_formatted = msg;
    let position_formatted = "top-end";
    if (isStringTrimNotEmpty(position)) position_formatted = position;
    let timer_formatted = 3000;
    if (isNumberValid(timer)) timer_formatted = timer;

    const toaster = Swal.mixin({
        toast: true,
        position: position_formatted,
        showConfirmButton: false,
        timer: timer_formatted,
        timerProgressBar: !isTimerProgressBarHidden,
        onOpen: (toast) => {
            if (!isTimerStop_onMouseEnter_NotRequired) {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        }
    });
      
    toaster.fire({
        icon: type_formatted,
        title: msg_formatted
    })

    return toaster;
}