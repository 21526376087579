import React from 'react'

import "assets/scss/components/ecard/backdrop.scss";

function Backdrop({show}) {
  return (
    <div className={"backdropBackground " + "primary " + (show ? "show" : "fade")}>

    </div>
  )
}

export default Backdrop