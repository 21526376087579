import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useCustomSelector } from "hooks";

import "assets/scss/components/ecard/calendarModal.scss";

function CalendarModal(props) {

  const {
    booking
  } = useCustomSelector("booking");

  const {
		appInfo
	} = useCustomSelector("app");
  const home = appInfo.home;
  const details = appInfo.details;

  let hrefA = encodeURI(
    "data:text/calendar;charset=utf8," +
      [
        "BEGIN:VCALENDAR",
        "VERSION:2.0",
        "BEGIN:VEVENT",
        "URL:" + document.URL,
        "DTSTART:" +
          (booking.start
            ?.toDate()
            .toISOString()
            .replace(/-|:|\.\d\d\d/g, "") || ""),
        "DTEND:" +
          (booking.end
            .toDate()
            .toISOString()
            .replace(/-|:|\.\d\d\d/g, "") || ""),
        "SUMMARY:" +
          (home.event + " " + home.bridegroomName + " dan " + home.brideName ||
            ""),
        "DESCRIPTION:" + (booking.session || ""),
        "LOCATION:" + (details.place || ""),
        "END:VEVENT",
        "END:VCALENDAR",
      ].join("\n")
  );

  let hrefG = encodeURI(
    [
      "https://www.google.com/calendar/render",
      "?action=TEMPLATE",
      "&text=" +
        (home.event + " " + home.bridegroomName + " dan " + home.brideName ||
          ""),
      "&dates=" +
        (booking.start
          ?.toDate()
          .toISOString()
          .replace(/-|:|\.\d\d\d/g, "") || ""),
      "/" +
        (booking.end
          .toDate()
          .toISOString()
          .replace(/-|:|\.\d\d\d/g, "") || ""),
      "&details=" + (booking.session || ""),
      "&location=" + (details.place || ""),
      "&sprop=&sprop=name:",
    ].join("")
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={"static"}
      centered
      className="calendarmodal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Peringatan</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Adakah anda mahu menambahkannya ke kalendar anda?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button type="submit" variant="primary" size="sm">
          <a target="blank" href={hrefA}>
            <i class="fab fa-apple"></i>
            <span> Apple Calendar</span>
          </a>
        </Button>

        <Button type="submit" variant="primary" size="sm">
          <a target="blank" href={hrefG}>
            <i class="fab fa-google"></i>
            <span> Google Calendar</span>
          </a>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CalendarModal;
