import moment from "moment";
import { isFunction, isNumberValid } from ".";
import { isNotNullUndefined } from "./nullUndefined";
import hijriMoment from "moment-hijri";

export function isDateValid(date) {
  return (
    isNotNullUndefined(date) &&
    (moment(date).isValid() || isTimestampObject(date))
  );
}

export function isTimestampObject(date) {
  return isNumberValid(date?.nanoseconds) || isNumberValid(date?.seconds);
}

export function convertTimestampToDate(date) {
  if (isTimestampObject(date)) {
    if (isFunction(date?.toDate)) return date.toDate();
    else return new Date(date?.seconds * 1000);
  }
  return undefined;
}
export function convertToDate(date) {
  let date_validated = convertTimestampToDate(date);
  if (date_validated) {
    return date_validated;
  } else if (isDateValid(date)) {
    // Do checking for other kinds of format
    return new Date(date);
  }
  return date;
}

export function getMalayIslamicDateString(date) {
  let islamicMoment = hijriMoment(date); //.add(-1, "day");
  let day = islamicMoment.iDate();
  let year = islamicMoment.iYear();
  let month = islamicMoment.iMonth();

  const malayIslamicMonths = [
    "Muharam",
    "Safar",
    "Rabiulawal",
    "Rabiulakhir",
    "Jamadilawal",
    "Jamadilakhir",
    "Rejab",
    "Syaaban",
    "Ramadan",
    "Syawal",
    "Zulkaedah",
    "Zulhijah",
  ];

  return day + " " + malayIslamicMonths[month] + ", " + year;
}
