import useAppServices from "dataflow/services/app.service";
import {
  convertStringToNumber,
  isArrayNotEmpty,
  isDeepArrayComparisonEqual,
  isDeepObjectComparisonEqual,
  isNotNullUndefined,
  isNullUndefined,
} from "helper/validations";
import { useLocation, useHistory } from "react-router";
import { useCustomSelector } from "hooks";
import moment from "moment";
import { convertToDate } from "helper/validations/date";

export function useSubmission({
  app_details,
  app_init_details,

  cover_details,
  cover_init_details,
  cover_styles,
  cover_init_styles,

  content_pageTitleDetails,
  content_init_pageTitleDetails,
  content_countdownDetails,
  content_init_countdownDetails,
  content_textIconDetails,
  content_init_textIconDetails,

  contact_contactInfoDetails,
  contact_init_contactInfoDetails,
  contact_contactInfoDetailsFull,
  contact_init_contactInfoDetailsFull,
  contact_textDetails,
  contact_init_textDetails,
  contact_iconBorderDetails,
  contact_init_iconBorderDetails,

  event_details,
  event_init_details,
  event_textDetails,
  event_init_textDetails,

  tentative_tentativeDetailsList,
  tentative_init_tentativeDetailsList,
  tentative_textDetails,
  tentative_init_textDetails,

  location_locationDetails,
  location_init_locationDetails,
  location_iconDetails,
  location_init_iconDetails,
  location_borderDetails,
  location_init_borderDetails,

  rsvp_rsvpDetailsList,
  rsvp_init_rsvpDetailsList,
  rsvp_textDetails,
  rsvp_init_textDetails,
}) {
  const { updateEcard, updateRSVPSessions, deleteRSVPSessions } =
    useAppServices();
  const location = useLocation();
  const pathname = location?.pathname;
  const pathList = pathname?.split("/");
  const ecardId = pathList[pathList?.length - 1];
  const orderId = pathList[pathList?.length - 2];

  const { ecardEditDetails } = useCustomSelector("app");

  function onSave() {
    // Get rsvp to be added
    let rsvpToBeAdded = [],
      rsvpToBeUpdated = [];
    if (isArrayNotEmpty(rsvp_rsvpDetailsList)) {
      rsvp_rsvpDetailsList.map((obj, idx) => {
        let startDate = new Date(
          moment(convertToDate(cover_details?.date)).toDate().toDateString() +
            " " +
            moment(convertToDate(obj?.startTime)).toDate().toTimeString()
        );
        let endDate = new Date(
          moment(convertToDate(cover_details?.date)).toDate().toDateString() +
            " " +
            moment(convertToDate(obj?.endTime)).toDate().toTimeString()
        );

        if (isNullUndefined(obj?.sessionId)) {
          rsvpToBeAdded.push({
            title: isNotNullUndefined(obj?.sessionTitle)
              ? obj?.sessionTitle
              : "Sesi " + (idx + 1),
            total: convertStringToNumber(obj?.totalVisitors),
            timestart: obj?.startTime
              ? moment(convertToDate(startDate)).toDate()
              : new Date(),
            timeend: obj?.startTime
              ? moment(convertToDate(endDate)).toDate()
              : new Date(),
            session: convertStringToNumber(obj?.totalVisitors),
            unlimited: obj?.unlimited ? obj?.unlimited : false,
          });
        } else {
          rsvpToBeUpdated.push({
            id: obj?.sessionId,
            title: obj?.sessionTitle,
            total: convertStringToNumber(obj?.totalVisitors),
            timestart: obj?.startTime
              ? moment(convertToDate(startDate)).toDate()
              : new Date(),
            timeend: obj?.startTime
              ? moment(convertToDate(endDate)).toDate()
              : new Date(),
            increment:
              convertStringToNumber(obj?.totalVisitors) -
              convertStringToNumber(obj?.visitorCount),
            unlimited: obj?.unlimited ? obj?.unlimited : false,
          });
        }
      });
    }

    // Get rsvp to be deleted
    let rsvpToBeDeleted = [];
    if (isArrayNotEmpty(rsvp_init_rsvpDetailsList)) {
      rsvp_init_rsvpDetailsList.map((obj) => {
        let id = obj?.sessionId;
        let matchDetails = rsvp_rsvpDetailsList.find(
          (obj) => obj?.sessionId == id
        );

        if (!matchDetails) {
          rsvpToBeDeleted.push({
            id: obj?.sessionId,
          });
        }
      });
    }

    let data = {
      orderId,

      themes: {
        ...ecardEditDetails?.themes,

        homeFontFamily1: cover_styles?.eventNameFont,
        homeColor1: cover_styles?.eventNameFontColor,
        homeFontFamily2: cover_styles?.bridegroomNameFont,
        homeColor2: cover_styles?.bridegroomNameFontColor,
        homeFontFamily3: cover_styles?.andSymbolFont,
        homeColor3: cover_styles?.andSymbolFontColor,
        homeFontFamily4: cover_styles?.brideNameFont,
        homeColor4: cover_styles?.brideNameFontColor,
        homeFontFamily5: cover_styles?.dateFont,
        homeColor5: cover_styles?.dateFontColor,
        homeBgOpacity: cover_styles?.homeBgOpacity,

        contentFontFamily1: content_pageTitleDetails?.font,
        contentColor1: content_pageTitleDetails?.fontColor,

        contentFontFamily2: content_countdownDetails?.font,
        contentColor2: content_countdownDetails?.fontColor,
        contentColor3: content_countdownDetails?.circleColor,

        contentFontFamily3: content_textIconDetails?.font,
        contentColor4: content_textIconDetails?.fontColor,
        contentColor5: content_textIconDetails?.iconColor,

        contactFontFamily1: contact_textDetails?.font,
        contactColor1: contact_textDetails?.fontColor,
        contactColor2: contact_iconBorderDetails?.borderColor,

        detailsFontFamily1: event_textDetails?.celebratorFont,
        detailsColor1: event_textDetails?.celebratorFontColor,
        detailsFontFamily2: event_textDetails?.coupleFont,
        detailsColor2: event_textDetails?.coupleFontColor,
        detailsFontFamily3: event_textDetails?.dateFont,
        detailsColor3: event_textDetails?.dateFontColor,
        detailsFontFamily4: event_textDetails?.locationFont,
        detailsColor4: event_textDetails?.locationFontColor,
        detailsFontFamily5: event_textDetails?.otherTextFont,
        detailsColor5: event_textDetails?.otherTextFontColor,
        detailsFontFamily6: event_textDetails?.prayerFont,
        detailsColor6: event_textDetails?.prayerFontColor,
        detailsBgOpacity: event_textDetails?.detailsBgOpacity,

        tentativeFontFamily1: tentative_textDetails?.timeFont,
        tentativeColor1: tentative_textDetails?.timeColorFont,
        tentativeFontFamily2: tentative_textDetails?.activityFont,
        tentativeColor2: tentative_textDetails?.activityFontColor,

        locationColor2: location_iconDetails?.iconColor,
        locationFontFamily1: location_iconDetails?.fontIcon,
        locationColor3: location_iconDetails?.iconFontColor,
        locationColor1: location_borderDetails?.color,

        rsvpFontFamily1: rsvp_textDetails?.sessionTitleFont,
        rsvpColor1: rsvp_textDetails?.sessionTitleColor,
        rsvpFontFamily2: rsvp_textDetails?.timeFont,
        rsvpColor2: rsvp_textDetails?.timeColor,
        rsvpFontFamily3: rsvp_textDetails?.totalFont,
        rsvpColor3: rsvp_textDetails?.totalColor,
        // rsvpFontFamily3: rsvp_textDetails?.totalAttendeesFont,
        // rsvpColor3: rsvp_textDetails?.totalAttendeesColor,

        rsvpColor4: rsvp_textDetails?.buttonFonColor,
        rsvpColor5: rsvp_textDetails?.buttonBgColor,
        rsvpFontFamily4: rsvp_textDetails?.buttonFont,
      },
      data: {
        details: {
          ...ecardEditDetails?.details,

          name1: event_details?.celebrator1,
          name2: event_details?.celebrator2,
          spouse1: event_details?.couple1,
          spouse2: event_details?.couple2,
          date1: cover_details?.date,
          place: event_details?.eventLocation,
          dua: event_details?.prayer,
        },
        home: {
          ...ecardEditDetails?.home,

          event: cover_details?.eventName,
          bridegroomName: cover_details?.bridegroomName,
          brideName: cover_details?.brideName,
          date: cover_details?.date,
        },
        contact: contact_contactInfoDetails,
        tentative: tentative_tentativeDetailsList,
        location: {
          ...ecardEditDetails?.location,

          place: location_locationDetails?.eventLocation,
        },
        // Note: Below RSVP will only Add. It will not update or delete
        rsvp: rsvpToBeAdded,
        timestamp: cover_details?.date,
        music: app_details?.music,
        musicURL: app_details?.musicURL,
      },
    };

    // update entire ecard except for certain rsvp data
    updateEcard(data);

    // manually update rsvp
    if (isArrayNotEmpty(rsvpToBeUpdated)) {
      rsvpToBeUpdated.map((data) => {
        const { id, title, total, timestart, timeend, increment, unlimited } =
          data;

        updateRSVPSessions(
          ecardEditDetails?.colId,
          ecardId,
          id,
          { title, total, timestart, timeend, unlimited },
          increment
        );
      });
    }

    // manually delete rsvp
    if (isArrayNotEmpty(rsvpToBeDeleted)) {
      rsvpToBeDeleted.map((data) => {
        deleteRSVPSessions(ecardEditDetails?.colId, ecardId, data?.id);
      });
    }
  }

  function isAnyInputChanged() {
    return !(
      isDeepObjectComparisonEqual(cover_details, cover_init_details) &&
      isDeepObjectComparisonEqual(cover_styles, cover_init_styles) &&
      isDeepObjectComparisonEqual(
        content_pageTitleDetails,
        content_init_pageTitleDetails
      ) &&
      isDeepObjectComparisonEqual(
        content_countdownDetails,
        content_init_countdownDetails
      ) &&
      isDeepObjectComparisonEqual(
        content_textIconDetails,
        content_init_textIconDetails
      ) &&
      isDeepObjectComparisonEqual(
        contact_contactInfoDetails,
        contact_init_contactInfoDetails
      ) &&
      isDeepObjectComparisonEqual(
        contact_textDetails,
        contact_init_textDetails
      ) &&
      isDeepObjectComparisonEqual(
        contact_iconBorderDetails,
        contact_init_iconBorderDetails
      ) &&
      isDeepObjectComparisonEqual(event_details, event_init_details) &&
      isDeepObjectComparisonEqual(event_textDetails, event_init_textDetails) &&
      isDeepArrayComparisonEqual(
        tentative_tentativeDetailsList,
        tentative_init_tentativeDetailsList
      ) &&
      isDeepObjectComparisonEqual(
        tentative_textDetails,
        tentative_init_textDetails
      ) &&
      isDeepObjectComparisonEqual(
        location_locationDetails,
        location_init_locationDetails
      ) &&
      isDeepObjectComparisonEqual(
        location_iconDetails,
        location_init_iconDetails
      ) &&
      isDeepObjectComparisonEqual(
        location_borderDetails,
        location_init_borderDetails
      ) &&
      isDeepArrayComparisonEqual(
        rsvp_rsvpDetailsList,
        rsvp_init_rsvpDetailsList
      ) &&
      isDeepObjectComparisonEqual(rsvp_textDetails, rsvp_init_textDetails)
    );
  }

  return {
    onSave,
    isAnyInputChanged,
  };
}
