import React, { useState } from "react";
import { Input } from "components/formInputs/Input";
import { TextAreaInput } from "components/formInputs/TextAreaInput";
import { Form } from "components/formInputs/Form";
import { EmailInput } from "components/formInputs/EmailInput";
import { Button } from "components/buttons/Button";
import { promiseInstance as Axios } from "dataflow/services/config";
import { isEmailValid } from "helper/validations";
import { endpointUrls } from "helper/constants/endpointUrls";

import email_svg from "assets/images/unDraw-email.svg";

import swal from "sweetalert";

export default function SendEmail(props) {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [title, setTitle] = useState();
  const [msg, setMsg] = useState();
  const [isSubmissionAttempted, setIsSubmissionAttempted] = useState();

  function onSubmit() {
    setIsSubmissionAttempted(true);

    if (isEmailValid(email) && email && name && title && msg) {
      let body = {
        email,
        name,
        title,
        msg,
      };

      swal({
        title: "Penghantaran Email Dalam Proses",
        icon: "info",
        button: false,
        timer: 2000,
        className: "send-email__start",
      });

      Axios.post(endpointUrls.postContactUsEmail, body)
        .then((response) => {
          swal.close();
          swal({
            title: "Berjaya Dihantar!",
            icon: "success",
            button: false,
            timer: 2000,
            className: "send-email__success",
          });

          setName();
          setEmail();
          setTitle();
          setMsg();
        })
        .catch((error) => {
          swal({
            title: "Gagal Menghantar Emel!",
            icon: "error",
            button: false,
            timer: 2000,
            className: "send-email__failed",
          });

          setName();
          setEmail();
          setTitle();
          setMsg();
        });
    } else {
      setIsSubmissionAttempted(true);
    }
  }

  return (
    <div
      className={
        "about-us__send-email d-flex align-items-center justify-content-around"
      }
    >
      <Form>
        {/* Name */}
        <Input
          className={"send-email__name d-flex align-items-center"}
          labelClassName={"name__label"}
          label={"Nama:"}
          inputClassName={"name__input"}
          value={name}
          onChange={setName}
          isInvalid={!name && isSubmissionAttempted}
          errorMsg={"Name is required"}
        />

        {/* Email */}
        <EmailInput
          className={"send-email__email d-flex align-items-center"}
          labelClassName={"email__label"}
          label={"Email:"}
          inputClassName={"email__input"}
          value={email}
          onChange={setEmail}
          isSubmissionAttempted={isSubmissionAttempted}
        />

        {/* Title */}
        <Input
          className={"send-email__title d-flex align-items-center"}
          labelClassName={"title__label"}
          label={"Tajuk:"}
          inputClassName={"title__input"}
          value={title}
          onChange={setTitle}
          // isInvalid={!title && isSubmissionAttempted}
          errorMsg={"Title is required"}
        />

        {/* Message */}
        <TextAreaInput
          className={"send-email__msg d-flex"}
          labelClassName={"msg__label"}
          label={"Mesej:"}
          inputClassName={"msg__input"}
          value={msg}
          onChange={setMsg}
          // isInvalid={!msg && isSubmissionAttempted}
        />

        {/* Submit */}
        <div className={"w-100 d-flex justify-content-end"}>
          <Button
            className={"send-email__submit"}
            content={"Hantar"}
            onClick={onSubmit}
            decoration_isBrightPrimary
          />
        </div>
      </Form>
      <div className="email-svg">
        <img className={"email-svg-icon"} src={email_svg} alt={"Cart"} />
      </div>
    </div>
  );
}
