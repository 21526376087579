export const validateNullUndefined = {
    isNotNull,
	isNull, 
	isNotUndefined,
	isUndefined, 
	isNotNullUndefined,
	isNullUndefined,
}

export function isNotNull(val) {
	return (val !== null);
}

export function isNull(val) {
	return (val == null);
}

export function isNotUndefined(val) {
	return (typeof val !== "undefined");
}

export function isUndefined(val) {
	return (typeof val == "undefined");
}

export function isNotNullUndefined(val) {
	return (isNotNull(val) && isNotUndefined(val));
}

export function isNullUndefined(val) {
	return (isNull(val) || isUndefined(val));
}