import React, { useEffect, useState } from "react";
import WishList from "components/WishList";

import { useCustomSelector } from "hooks";

import { Route, Switch, useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Card } from "components/Card";
import { Button } from "components/buttons/Button";
import { isArrayNotEmpty } from "helper/validations";

function Wish({ setCurrentSelection }) {
  let { path, url } = useRouteMatch();

  const { userCardList } =
    useCustomSelector("userCard");

  const [cards, setCards] = useState([]);

  useEffect(() => {
    let events = userCardList?.filter(
      (card, index) => card.eventStatus == "publish"
    );
    setCards(events);
  }, []);

  return (
    <div className={"display__wish-info"}>
      {/* Page Title */}
      <div
        className={
          "wish-info__header d-flex align-items-center justify-content-start"
        }
      >
        <p className={"header__title"}>1. Pilih Ekadtepi</p>
      </div>

      {/* Page Content */}
      <div
        className={
          "wish-info__content d-flex flex-column justify-content-center align-items-center w-100"
        }
      >
        {/* List of Ecard */}
        {isArrayNotEmpty(cards)? (
          <div className={"wish-info__content-ecard  d-flex overflow-auto"}>
            {cards?.map((card) => (
              <div key={card.id} className={"content-ecard__wrapper"}>
                <Link to={`${url}/${card.id}`}>
                  <Card
                    key={card.id}
                    eCardUrl={`${url}/${card.id}`}
                    height={"244px"}
                    width={"180px"}
                    userOrder
                    eventId={card.id}
                    theme={card.themes}
                    homeData={card.home}
                  />
                </Link>
              </div>
            ))}
          </div>
        ):(
          <div className={"missing-ecard"}>
            <span className={"missing-ecard__msg"}>Tiada 'Live EkadTepi' dijumpa. Sila teruskan pembayaran dalam 'Draf EkadTepi' anda.</span>
            <Button
              className={"missing-ecard__action"}
              content={"Draf EkadTepi"}
              onClick={() => setCurrentSelection("card")}
              decoration_isBrightPrimary
            />
          </div>
        )}

        {/*wish viewer*/}
        <div
          className={
            "content-ecard__header d-flex flex-column align-items-center justify-content-start"
          }
        >
          <p className={"header__title"}>Senarai Ucapan</p>
          <p className={"wish-list-total"}>Jumlah ucapan diterima:</p>
        </div>

        <div
          className={
            "content-ecard__wishes overflow-auto d-flex flex-column justify-content-start"
          }
        >
          <Switch>
            <Route exact path={path}>
              <div className="content-ecard__wishes-notification d-flex justify-content-center align-items-center">
                <h4>Sila pilih EkadTepi</h4>
              </div>
            </Route>
            <Route path={`${path}/:eventId`}>
              <WishList />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Wish;
