import { isFunction } from "helper/validations";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

export default function useOnPageExit({
    blockingCondition, onBlockFunc
}) {
    const [destinationPathname, setDestinationPathname] = useState();
    const unblock = useRef();
    const history = useHistory();
    
    useEffect(() => {
        unblock.current = history.block((props) => {
            if (blockingCondition) {
                onBlockFunc({ ...props, unblock, history, setDestinationPathname });
                return false;
            }
            else return true;
        });
    })

    useEffect(() => {
        if (destinationPathname) {
            if (isFunction(unblock?.current)) unblock.current();
            history.push(destinationPathname);
        }
    }, [destinationPathname])
}