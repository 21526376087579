import React, { useEffect, useState } from "react";
import default_background from "assets/images/background.png";
import useAppServices from "dataflow/services/app.service";
import { useCustomSelector } from "hooks";
import { isArrayNotEmpty, isObjectNotEmpty } from "helper/validations";
import { useLocation } from "react-router";

export default function useInitValues({
  setApp_details,

  setCover_details,
  setCover_styles,

  setContent_pageTitleDetails,
  setContent_countdownDetails,
  setContent_textIconDetails,

  setContact_contactInfoDetails,
  setContact_contactInfoDetailsFull,
  setContact_textDetails,
  setContact_iconBorderDetails,

  setEvent_details,
  setEvent_textDetails,

  setTentative_tentativeDetailsList,
  setTentative_textDetails,

  setLocation_locationDetails,
  setLocation_iconDetails,
  setLocation_borderDetails,

  setRsvp_rsvpDetailsList,
  setRsvp_textDetails,
}) {
  // App
  const [app_init_details, setApp_init_details] = useState();

  // Cover Page
  const [cover_init_details, setCover_init_details] = useState();
  const [cover_init_styles, setCover_init_styles] = useState({
    eventNameFont: "initial",
    eventNameFontColor: "initial",
    bridegroomNameFont: "initial",
    bridegroomNameFontColor: "initial",
    andSymbolFont: "initial",
    andSymbolFontColor: "initial",
    brideNameFont: "initial",
    brideNameFontColor: "initial",
    dateFont: "initial",
    dateFontColor: "initial",
    homeBgOpacity: "initial",
  });

  // Content Page
  const [content_init_pageTitleDetails, setContent_init_pageTitleDetails] =
    useState({
      font: "initial",
      fontColor: "initial",
    });
  const [content_init_countdownDetails, setContent_init_countdownDetails] =
    useState({
      font: "initial",
      fontColor: "initial",
      circleColor: "initial",
    });
  const [content_init_textIconDetails, setContent_init_textIconDetails] =
    useState({
      font: "initial",
      fontColor: "initial",
      iconColor: "initial",
    });

  // Contact Page
  const [contact_init_contactInfoDetails, setContact_init_contactInfoDetails] =
    useState();
  const [
    contact_init_contactInfoDetailsFull,
    setContact_init_contactInfoDetailsFull,
  ] = useState();
  const [contact_init_textDetails, setContact_init_textDetails] = useState({
    font: "initial",
    fontColor: "initial",
  });
  const [contact_init_iconBorderDetails, setContact_init_iconBorderDetails] =
    useState({
      borderColor: "initial",
    });

  // Event Page
  const [event_init_details, setEvent_init_details] = useState();
  const [event_init_textDetails, setEvent_init_textDetails] = useState({
    celebratorFont: "initial",
    celebratorFontColor: "initial",
    coupleFont: "initial",
    coupleFontColor: "initial",
    dateFont: "initial",
    dateFontColor: "initial",
    locationFont: "initial",
    locationFontColor: "initial",
    otherTextFont: "initial",
    otherTextFontColor: "initial",
    prayerFont: "initial",
    prayerFontColor: "initial",
    detailsBgOpacity: "initial",
  });

  // Tentative Page
  const [
    tentative_init_tentativeDetailsList,
    setTentative_init_tentativeDetailsList,
  ] = useState([{}]);
  const [tentative_init_textDetails, setTentative_init_textDetails] = useState({
    timeFont: "initial",
    timeColorFont: "initial",
    activityFont: "initial",
    activityFontColor: "initial",
  });
  const [tentative_init_borderBgDetails, setTentative_init_borderBgDetails] =
    useState({
      bgColor: "initial",
      borderColor: "initial",
    });

  // Location Page
  const [location_init_locationDetails, setLocation_init_locationDetails] =
    useState();
  const [location_init_iconDetails, setLocation_init_iconDetails] = useState({
    iconColor: "initial",
    fontIcon: "initial",
    iconFontColor: "initial",
  });
  const [location_init_borderDetails, setLocation_init_borderDetails] =
    useState({ color: "initial" });

  // RSVP Page
  const [rsvp_init_rsvpDetailsList, setRsvp_init_rsvpDetailsList] = useState();
  const [rsvp_init_textDetails, setRsvp_init_textDetails] = useState({
    titleFont: "initial",
    titleColor: "initial",
    timeFont: "initial",
    timeColor: "initial",
    totalFont: "initial",
    totalColor: "initial",
  });

  // Get Card info & set initial values
  const { getEcardEditDetails, getRSVPSessions, rsvpSessions } =
    useAppServices();
  const { ecardEditDetails, updateEcard_status } = useCustomSelector("app");

  const location = useLocation();
  const pathname = location?.pathname;
  const pathList = pathname?.split("/");
  const ecardId = pathList[pathList?.length - 1];

  const { isUserAuthenticated } = useCustomSelector("website");
  useEffect(() => {
    if (isUserAuthenticated) getEcardEditDetails(ecardId);
  }, [isUserAuthenticated]);
  useEffect(() => {
    if (updateEcard_status) getEcardEditDetails(ecardId);
  }, [updateEcard_status]);
  useEffect(() => {
    if (isArrayNotEmpty(rsvpSessions)) {
      let rsvpDetailsList = rsvpSessions.map((obj) => {
        return {
          sessionId: obj?.id,
          sessionTitle: obj?.session?.title,
          startTime: obj?.session?.timestart,
          endTime: obj?.session?.timeend,
          totalVisitors: obj?.session?.total,
          visitorCount: obj?.session?.total,
          unlimited: obj?.session?.unlimited,
        };
      });

      setRsvp_init_rsvpDetailsList(rsvpDetailsList);
      setRsvp_rsvpDetailsList(rsvpDetailsList);
    }
  }, [rsvpSessions]);
  useEffect(() => {
    if (isObjectNotEmpty(ecardEditDetails)) {
      // Values
      let appDetails = {
        music: ecardEditDetails?.music,
        musicURL: ecardEditDetails?.musicURL,
      };

      let coverDetails = {
        eventName: ecardEditDetails?.home?.event,
        bridegroomName: ecardEditDetails?.home?.bridegroomName,
        brideName: ecardEditDetails?.home?.brideName,
        date: ecardEditDetails?.home?.date
          ? ecardEditDetails.home.date
          : ecardEditDetails?.details?.date1,
        music: ecardEditDetails?.music ? ecardEditDetails?.music : false,
        musicURL: ecardEditDetails?.musicURL,
      };
      let coverStyles = {
        eventNameFont: ecardEditDetails?.themes?.homeFontFamily1,
        eventNameFontColor: ecardEditDetails?.themes?.homeColor1,
        bridegroomNameFont: ecardEditDetails?.themes?.homeFontFamily2,
        bridegroomNameFontColor: ecardEditDetails?.themes?.homeColor2,
        andSymbolFont: ecardEditDetails?.themes?.homeFontFamily3,
        andSymbolFontColor: ecardEditDetails?.themes?.homeColor3,
        brideNameFont: ecardEditDetails?.themes?.homeFontFamily4,
        brideNameFontColor: ecardEditDetails?.themes?.homeColor4,
        dateFont: ecardEditDetails?.themes?.homeFontFamily5,
        dateFontColor: ecardEditDetails?.themes?.homeColor5,
        homeBgOpacity: ecardEditDetails?.themes?.homeBgOpacity,
      };

      let contentPageTitleDetails = {
        font: ecardEditDetails?.themes?.contentFontFamily1,
        fontColor: ecardEditDetails?.themes?.contentColor1,
      };
      let contentCountdownDetails = {
        font: ecardEditDetails?.themes?.contentFontFamily2,
        fontColor: ecardEditDetails?.themes?.contentColor2,
        circleColor: ecardEditDetails?.themes?.contentColor3,
      };
      let contentTextIconDetails = {
        font: ecardEditDetails?.themes?.contentFontFamily3,
        fontColor: ecardEditDetails?.themes?.contentColor4,
        iconColor: ecardEditDetails?.themes?.contentColor5,
      };

      let contactInfoDetails = ecardEditDetails.contact;
      let contactInfoDetailsFull = ecardEditDetails.contact;
      let contactTextDetails = {
        font: ecardEditDetails?.themes?.contactFontFamily1,
        fontColor: ecardEditDetails?.themes?.contactColor1,
      };
      let contactIconBorderDetails = {
        borderColor: ecardEditDetails?.themes?.contactColor2,
      };

      let eventDetails = {
        celebrator1: ecardEditDetails?.details?.name1,
        celebrator2: ecardEditDetails?.details?.name2,
        couple1: ecardEditDetails?.details?.spouse1,
        couple2: ecardEditDetails?.details?.spouse2,
        eventDate: ecardEditDetails?.details?.date1
          ? ecardEditDetails.details.date1
          : ecardEditDetails?.home?.date,
        eventLocation: ecardEditDetails?.details?.place
          ? ecardEditDetails.details.place
          : ecardEditDetails?.location?.place,
        prayer: ecardEditDetails?.details?.dua,
      };
      let eventTextDetails = {
        celebratorFont: ecardEditDetails?.themes?.detailsFontFamily1,
        celebratorFontColor: ecardEditDetails?.themes?.detailsColor1,
        coupleFont: ecardEditDetails?.themes?.detailsFontFamily2,
        coupleFontColor: ecardEditDetails?.themes?.detailsColor2,
        dateFont: ecardEditDetails?.themes?.detailsFontFamily3,
        dateFontColor: ecardEditDetails?.themes?.detailsColor3,
        locationFont: ecardEditDetails?.themes?.detailsFontFamily4,
        locationFontColor: ecardEditDetails?.themes?.detailsColor4,
        otherTextFont: ecardEditDetails?.themes?.detailsFontFamily5,
        otherTextFontColor: ecardEditDetails?.themes?.detailsColor5,
        prayerFont: ecardEditDetails?.themes?.detailsFontFamily6,
        prayerFontColor: ecardEditDetails?.themes?.detailsColor6,
        detailsBgOpacity: ecardEditDetails?.themes?.detailsBgOpacity,
      };

      let tentativeDetailsList = ecardEditDetails?.tentative;
      let tentativeTextDetails = {
        timeFont: ecardEditDetails?.themes?.tentativeFontFamily1,
        timeColorFont: ecardEditDetails?.themes?.tentativeColor1,
        activityFont: ecardEditDetails?.themes?.tentativeFontFamily2,
        activityFontColor: ecardEditDetails?.themes?.tentativeColor2,
      };
      // let tentativeBorderBgDetails = {
      //     bgColor: ecardEditDetails?.themes?.,
      //     borderColor: ecardEditDetails?.themes?.,
      // }

      let locationDetails = {
        eventLocation: ecardEditDetails?.location?.place
          ? ecardEditDetails.location.place
          : ecardEditDetails?.details?.place,
      };
      let locationIconDetails = {
        iconColor: ecardEditDetails?.themes?.locationColor2,
        fontIcon: ecardEditDetails?.themes?.locationFontFamily1,
        iconFontColor: ecardEditDetails?.themes?.locationColor3,
      };
      let locationBorderDetails = {
        color: ecardEditDetails?.themes?.locationColor1,
      };

      let rsvpTextDetails = {
        titleFont: ecardEditDetails?.themes?.contentFontFamily1,
        titleColor: ecardEditDetails?.themes?.contentColor1,
        sessionTitleFont: ecardEditDetails?.themes?.rsvpFontFamily1,
        sessionTitleColor: ecardEditDetails?.themes?.rsvpColor1,
        timeFont: ecardEditDetails?.themes?.rsvpFontFamily2,
        timeColor: ecardEditDetails?.themes?.rsvpColor2,
        totalFont: ecardEditDetails?.themes?.rsvpFontFamily3,
        totalColor: ecardEditDetails?.themes?.rsvpColor3,
        buttonFonColor: ecardEditDetails?.themes?.rsvpColor4,
        buttonBgColor: ecardEditDetails?.themes?.rsvpColor5,
        buttonFont: ecardEditDetails?.themes?.rsvpFontFamily4,
      };

      // Init values
      setApp_init_details(appDetails);

      setCover_init_details(coverDetails);
      setCover_init_styles(coverStyles);

      setContent_init_pageTitleDetails(contentPageTitleDetails);
      setContent_init_countdownDetails(contentCountdownDetails);
      setContent_init_textIconDetails(contentTextIconDetails);

      setContact_init_contactInfoDetails(contactInfoDetails);
      setContact_init_contactInfoDetailsFull(contactInfoDetailsFull);
      setContact_init_textDetails(contactTextDetails);
      setContact_init_iconBorderDetails(contactIconBorderDetails);

      setEvent_init_details(eventDetails);
      setEvent_init_textDetails(eventTextDetails);

      setTentative_init_tentativeDetailsList(tentativeDetailsList);
      setTentative_init_textDetails(tentativeTextDetails);
      // setTentative_init_borderBgDetails(tentativeBorderBgDetails);

      setLocation_init_locationDetails(locationDetails);
      setLocation_init_iconDetails(locationIconDetails);
      setLocation_init_borderDetails(locationBorderDetails);

      setRsvp_init_textDetails(rsvpTextDetails);

      // Current values
      setApp_details(appDetails);

      setCover_details(coverDetails);
      setCover_styles(coverStyles);

      setContent_pageTitleDetails(contentPageTitleDetails);
      setContent_countdownDetails(contentCountdownDetails);
      setContent_textIconDetails(contentTextIconDetails);

      setContact_contactInfoDetails(contactInfoDetails);
      setContact_contactInfoDetailsFull(contactInfoDetailsFull);
      setContact_textDetails(contactTextDetails);
      setContact_iconBorderDetails(contactIconBorderDetails);

      setEvent_details(eventDetails);
      setEvent_textDetails(eventTextDetails);

      setTentative_tentativeDetailsList(tentativeDetailsList);
      setTentative_textDetails(tentativeTextDetails);
      // setTentative_borderBgDetails(tentativeBorderBgDetails);

      setLocation_locationDetails(locationDetails);
      setLocation_iconDetails(locationIconDetails);
      setLocation_borderDetails(locationBorderDetails);

      setRsvp_textDetails(rsvpTextDetails);

      getRSVPSessions(ecardEditDetails?.colId, ecardEditDetails?.eventId);
    }
  }, [ecardEditDetails]);

  return {
    rsvpSessions,

    app_init_details,
    setApp_init_details,

    cover_init_details,
    setCover_init_details,
    cover_init_styles,
    setCover_init_styles,

    content_init_pageTitleDetails,
    setContent_init_pageTitleDetails,
    content_init_countdownDetails,
    setContent_init_countdownDetails,
    content_init_textIconDetails,
    setContent_init_textIconDetails,

    contact_init_contactInfoDetails,
    setContact_init_contactInfoDetails,
    contact_init_contactInfoDetailsFull,
    setContact_init_contactInfoDetailsFull,
    contact_init_textDetails,
    setContact_init_textDetails,
    contact_init_iconBorderDetails,
    setContact_init_iconBorderDetails,

    event_init_details,
    setEvent_init_details,
    event_init_textDetails,
    setEvent_init_textDetails,

    tentative_init_tentativeDetailsList,
    setTentative_init_tentativeDetailsList,
    tentative_init_textDetails,
    setTentative_init_textDetails,
    tentative_init_borderBgDetails,
    setTentative_init_borderBgDetails,

    location_init_locationDetails,
    setLocation_init_locationDetails,
    location_init_iconDetails,
    setLocation_init_iconDetails,
    location_init_borderDetails,
    setLocation_init_borderDetails,

    rsvp_init_rsvpDetailsList,
    setRsvp_init_rsvpDetailsList,
    rsvp_init_textDetails,
    setRsvp_init_textDetails,
  };
}
