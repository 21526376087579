import React from 'react';
import default_background from "assets/images/background.png";

import MapContainer from '../MapContainer';
import BackButton from 'components/ecard/BackButton';

export default function LocationDisplay({
    titleStyle, mapInfo, 
    mapContainerStyle,
    iconStyles, iconContainerStyle, iconFontStyles
}) {
    return (
        <div className="general_background">
            <div className="location_component d-flex flex-column">
                <div className="location_header d-flex justify-content-between align-items-center">
                    <div className="location_header_back">
                        <BackButton />
                    </div>
                <div className="location_header_title text-center">
                    <div className="header2">
                        <h4 id="tajuk" style={titleStyle}>
                            LOKASI
                        </h4>
                    </div>
                </div>

                <div className="location_header_empty text-right"></div>
                </div>
    
                <div className="location__container m-auto">

                    <MapContainer
                        // lat={isNotNullUndefined(mapInfo?.coordinate._latitude)  ? mapInfo?.coordinate._latitude : ""}
                        // long={isNotNullUndefined(mapInfo?.coordinate._longitude)  ? mapInfo?.coordinate._longitude : ""}
                        place={mapInfo.place}
                        mapContainerStyle={mapContainerStyle}
                        iconStyles={iconStyles}
                        iconContainerStyle={iconContainerStyle}
                        iconFontStyles={iconFontStyles}
                    />

                </div>
            </div>
        </div>
    );
}