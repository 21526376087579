import React from "react";

import { Button } from "./buttons/Button";

import { useCardPreview } from "hooks";
import {
  isUserLoggedIn,
  isFunction,
  getObjectPropertyValue
} from "helper/validations";

import ic_cart from "assets/images/shopping_bag_solid.svg";

import "assets/scss/components/card.scss";
import { promiseInstance as Axios } from "dataflow/services/config";

import swal from "sweetalert";
import moment from "moment";
import 'moment/locale/ms'
import {
  convertToDate,
  isDateValid,
} from "helper/validations/date";
import { ImgWithFallback } from "./image";
import { endpointUrls } from "helper/constants/endpointUrls";

export function Card(props) {
  const {
    eCardUrl, //ecard url for live preview
    name, //title for themes

    userOrder, //if card display in user dashboard
    height, //custom height
    width, //custom width

    themeId, //theme Id
    theme, //card themes
    homeData, //custom data for home preview

    setIsAuthModalVisible,
  } = props;

  const { modalDisplay, openPreview } = useCardPreview({
    eCardUrl
  });

  console.log(eCardUrl)

  function handleAddToCart() {
    if (isUserLoggedIn()) {
      // Add to cart

      Axios.post(`${endpointUrls.postAddCardtoCart}${themeId}`)
        .then((response) => {
          swal({
            title: "Success!",
            text: "ECard ditambah ke dalam draf anda.",
            icon: "success",
            button: false,
            timer: 2000,
            className: "added-to-cart__success",
          });
          console.log(response);
        })
        .catch((error) => {
          swal({
            title: "Gagal!",
            text: "Gagal menambah ECard ke dalam draf anda.",
            icon: "error",
            button: false,
            timer: 2000,
            className: "added-to-cart__failed",
          });
          console.log(error);
        });
    } else if (isFunction(setIsAuthModalVisible)) {
      setIsAuthModalVisible(true);
    }
  }

  let brideName = getObjectPropertyValue(homeData, "brideName");
  let groomName = getObjectPropertyValue(homeData, "bridegroomName");
  let date = getObjectPropertyValue(homeData, "date");
  let event = getObjectPropertyValue(homeData, "event");
  let imageSrc = getObjectPropertyValue(theme, "background");
  let OptimizedImageSrc = getObjectPropertyValue(theme, "backgroundOptimized");

  const style1 = {
    color: getObjectPropertyValue(theme, "homeColor1"),
    fontFamily: getObjectPropertyValue(theme, "homeFontFamily1"),
  };

  const style2 = {
    color: getObjectPropertyValue(theme, "homeColor2"),
    fontFamily: getObjectPropertyValue(theme, "homeFontFamily2"),
  };

  const style3 = {
    color: getObjectPropertyValue(theme, "homeColor3"),
    fontFamily: getObjectPropertyValue(theme, "homeFontFamily3"),
  };

  const style4 = {
    color: getObjectPropertyValue(theme, "homeColor4"),
    fontFamily: getObjectPropertyValue(theme, "homeFontFamily4"),
  };

  const style5 = {
    color: getObjectPropertyValue(theme, "homeColor5"),
    fontFamily: getObjectPropertyValue(theme, "homeFontFamily5"),
  };

  const background1 = {
    background: getObjectPropertyValue(theme, "backgroundColor1"),
  };

  return (
    <>
      {modalDisplay}

      <div className={"component-card"}>
        <div className={"component-card-wrapper"}>
          {/* Image */}
          <div className={"component-card__image-wrapper text-center"}>
            <ImgWithFallback
              className={"component-card__image"}
              style={{
                height: height,
                width: width,
              }}
              src={OptimizedImageSrc}
              fallback={imageSrc}
            /> 
            <span className={"component-card__name-price_tag " +
              (userOrder ? "componenent-card__name-price_tag__hidden" : "")}>RM60</span>

            {/* {Homepage preview} */}
            <div className={"component-card__homepage"} style={background1}>
              <div className="homepage-title">
                <h3 className="home__text3" style={style1}>
                  {event ? event : "Walimatul Urus"}
                </h3>
              </div>

              <div className="hompage-name">
                <h1 className="home__text1" style={style2}>
                  {groomName ? groomName : "Adam"}
                </h1>
                <h1 className="home__text1" style={style3}>
                  &
                </h1>
                <h1 className="home__text1" style={style4}>
                  {brideName ? brideName : "Hawa"}
                </h1>
              </div>

              <div className="hompage-date">
                <h3 className="home__text3" style={style5}>
                  {isDateValid(date)
                    ? moment(convertToDate(date))
                        .locale('ms')
                        .format("DD MMM, YYYY")
                    : moment(new Date("18 JUNE 2022")).locale('ms')
                    .format("DD MMM, YYYY")}
                </h3>
              </div>
            </div>

            {/* Action */}
            <div
              className={
                "component-card__action " +
                (userOrder ? "componenent-card__action__hidden" : "")
              }
            >
              <div className={"component-card__action-wrapper d-flex"}>
                {/* Preview */}
                <Button
                  className={"action__preview flex-fill"}
                  content={"Contoh"}
                  onClick={openPreview}
                  decoration_isBrightInversePrimary
                />

                {/* Add to Cart */}
                <Button
                  className={"action__add-to-cart"}
                  content={
                    <img
                      className={"add-to-cart__icon"}
                      src={ic_cart}
                      alt={"Cart"}
                    />
                  }
                  onClick={handleAddToCart}
                  decoration_isBrightPrimary
                />
              </div>
            </div>
          </div>

          {/* Name */}
          <div
            className={
              "component-card__name text-center " +
              (userOrder ? "component-card__name__hidden" : "")
            }
          >
            {name}{" "}
          </div>
        </div>
      </div>
    </>
  );
}
