import { 
	isNotNullUndefined, isStringTrimNotEmpty,
	isArray, deepCloneArray, isDeepArrayComparisonEqual, 
} from ".";



export const objectValidations = {
	isObject,
	isObjectValid,
	isObjectEmpty,
	isObjectNotEmpty,
	getObjectLength,
	hasObjectProperty,
	getObjectPropertyValue,

	cloneObject,
	deepCloneObject,
	isDeepObjectComparisonEqual
}

export function isObject(obj) {
	return (typeof obj === "object");
}

export function isObjectValid(obj) {
	return (isNotNullUndefined(obj) && isObject(obj));
}
export function validateObject(obj) {
	return (isObjectValid(obj)? obj: {});
}

export function isObjectEmpty(obj) {
	return (isObjectValid(obj) && Object.keys(obj).length === 0);
}

export function isObjectNotEmpty(obj) {
	return (isObjectValid(obj) && Object.keys(obj).length > 0);
}

export function getObjectLength(obj) {
	if (isObjectValid(obj)) return Object.keys(obj).length;
	return undefined;
}

export function hasObjectProperty(obj, propName) {
	return (isObjectNotEmpty(obj) && isStringTrimNotEmpty(propName) && 
        isNotNullUndefined(obj[propName]));
}

export function getObjectPropertyValue(obj, propName, defaultVal) {
	if (hasObjectProperty(obj, propName)) return obj[propName];
	if (isNotNullUndefined(defaultVal)) return defaultVal;
	return undefined;
}

export function cloneObject(obj) {
	if (isObjectValid(obj)) return Object.assign({}, obj);
	return {};
}

export function deepCloneObject(obj) {
	if (isObjectNotEmpty(obj)) {

        let newObj = {};
		for (let key in obj) {

			if (obj.hasOwnProperty(key)) {
                if (isArray(obj[key])) {

					newObj[key] = deepCloneArray(obj[key]);
				}
                else if (isObject(obj[key]) &&
                        isNotNullUndefined(obj[key])) {

					newObj[key] = deepCloneObject(obj[key]);
				}
				else newObj[key] = obj[key];
			}
        }
        return newObj;
	}
	return obj;
}

export function isDeepObjectComparisonEqual(obj1, obj2) {
	// iterate the object
	if (isObject(obj1) && isObject(obj2)) {
		let isNonMatchFound = false;

		if (isObjectNotEmpty(obj1) && isObjectNotEmpty(obj2) &&
			getObjectLength(obj1) == getObjectLength(obj2)) {

			for (let property in obj1) {
				let val1 = obj1?.[property];
				let val2 = obj2?.[property];
	
				if (isArray(val1) && isArray(val2)) {
					isNonMatchFound = !isDeepArrayComparisonEqual(val1, val2);
					if (isNonMatchFound) break;
				}
				else if (isObject(val1) && isObject(val2)) {
					isNonMatchFound = !isDeepObjectComparisonEqual(val1, val2);
					if (isNonMatchFound) break;
				}
				else if (val1 !== val2) {
					isNonMatchFound = true; break;
				}
			}
		}
		else if (getObjectLength(obj1) != getObjectLength(obj2)) 
			isNonMatchFound = true;

		return !isNonMatchFound;
	}
	return obj1 === obj2;
}