import { createSlice } from '@reduxjs/toolkit';

export const cameraSlice = createSlice({
  name: 'camera',
  initialState: {
    
  },
  reducers: {
    setcameraMedia_requesting: (state) => {
      state.setcameraMedia_requesting = true;
      state.setcameraMedia_status = false;
      state.setcameraMedia_errorMsg = undefined;
    },
    setcameraMedia_success: (state, action) => {
      state.setcameraMedia_requesting = false;
      state.setcameraMedia_status = true;
      state.cameraMedia = action.payload;
    },
    setcameraMedia_failure: (state, action) => {
      state.setcameraMedia_requesting = false;
      state.setcameraMedia_errorMsg = action.payload;
    },
    resetcameraMedia_requesting: (state) => {
      state.resetcameraMedia_requesting = true;
      state.resetcameraMedia_status = false;
      state.resetcameraMedia_errorMsg = undefined;
    },
    resetcameraMedia_success: (state) => {
      state.resetcameraMedia_requesting = false;
      state.resetcameraMedia_status = true;
      state.cameraMedia = null;
    },
    resetcameraMedia_failure: (state, action) => {
      state.resetcameraMedia_requesting = false;
      state.resetcameraMedia_errorMsg = action.payload;
    },
  },
});
  
export const { setcameraMedia_requesting, setcameraMedia_success, setcameraMedia_failure, resetcameraMedia_requesting, resetcameraMedia_success, resetcameraMedia_failure } = cameraSlice.actions;
  
  
export default cameraSlice.reducer;