import React from "react";
import default_background from "assets/images/background.png";
import { isArrayNotEmpty } from "helper/validations";

import RsvpSession from "components/ecard/RsvpSession";
import BackButton from "components/ecard/BackButton";

export default function RsvpDisplay({
  titleStyle,

  sessions,
  colId,
  eventId,
  sessionProps = {},
}) {
  return (
    <div className="general_background">
      <div className="rsvp_component d-flex flex-column">
        <div className="rsvp_header d-flex justify-content-between align-items-center">
          <div className="rsvp_header_back">
            <BackButton />
          </div>
          <div className="rsvp_header_title text-center">
            <div className="header2">
              <h4 id="tajuk" style={titleStyle}>
                RSVP
              </h4>
            </div>
          </div>
          <div className="rsvp_header_empty text-right"></div>
        </div>

        <div className="rsvp__container m-auto">

          <div
            className={
              sessions.length <= 2
                ? "sesi__container_center"
                : "sesi__container_top"
            }
          >
            {/* get session and seat from database, login from store and api */}
            {isArrayNotEmpty(sessions) &&
              sessions.map(({ id, session, title }, index) => (
                <RsvpSession
                  title={session?.title}
                  seat={session?.session}
                  session={index + 1}
                  key={id}
                  id={id}
                  start={session?.timestart}
                  end={session?.timeend}
                  total={session?.total}
                  unlimited={session?.unlimited}
                  colId={colId}
                  cardId={eventId}
                  {...sessionProps}
                />
              ))}
          </div>

        </div>
      </div>
    </div>
  );
}
