import React, { useEffect, useState } from 'react';
import { deepCloneArray, getArrayLength, hexToFilterCSS, isArray, isArrayNotEmpty, isNotNullUndefined, isObjectEmpty, validateFunction } from 'helper/validations';
import InputContentExpander from 'components/contentExpander/InputContentExpander';
import RsvpDisplay from 'modules/ECard/sections/rsvp/RsvpDisplay';
import { useCustomSelector } from 'hooks';
import MenuPageContainer from 'components/containers/MenuPageContainer';

export default function useRSVPStep({
    fontList, loadFont,

    isResetRequired, currentStep,
    cardThemeBgImage, content_pageTitleDetails,

    rsvp_rsvpDetailsList = [], setRsvp_rsvpDetailsList: setRsvp_rsvpDetailsList_props,
    rsvp_textDetails = {}, setRsvp_textDetails: setRsvp_textDetails_props,

    rsvp_init_rsvpDetailsList, rsvp_init_textDetails
}) {
    // Validations
    let setRsvp_rsvpDetailsList = validateFunction(setRsvp_rsvpDetailsList_props);
    let setRsvp_textDetails = validateFunction(setRsvp_textDetails_props);

    const [currentVisibleContentPos, setCurrentVisibleContentPos] = useState();
    const { ecardEditDetails } = useCustomSelector("app");

    function reset() {
        setRsvp_rsvpDetailsList(rsvp_init_rsvpDetailsList);
        setRsvp_textDetails(rsvp_init_textDetails);
    }
    useEffect(() => {
        if (currentStep == "rsvp" && isResetRequired) reset();
    }, [isResetRequired]);

    let input_rsvpStep = (
        <div className={"inputs__rsvp-step"}>
            {/* Data */}
            <InputContentExpander
                mainDisplayText={"Maklumat RSVP"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 0) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(0);
                }}
                isExpanded={currentVisibleContentPos === 0}

                themeType={"darkGrey"}

                onContentReset={() => setRsvp_rsvpDetailsList(rsvp_init_rsvpDetailsList)}

                contentInputs={(isArrayNotEmpty(rsvp_rsvpDetailsList)? rsvp_rsvpDetailsList:[{ sessionTitle: "Sesi 1" }]).map((details, idx) => {
                    return {
                        onSectionDelete: setRsvp_rsvpDetailsList,
                        onSectionAdd: setRsvp_rsvpDetailsList,
                        list: rsvp_rsvpDetailsList,
                        isDeleteIconVisible: true,
                        isAddIconVisible: true,
                        inputs: [
                            {
                                header: "Sesi " + (idx + 1),
                                type: "text",
                                label: "Tajuk Sesi:",
                                value: isNotNullUndefined(details?.sessionTitle)? details.sessionTitle: "Sesi " + (idx + 1),
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    let newList = deepCloneArray(rsvp_rsvpDetailsList);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        sessionTitle: value
                                    }
                                    setRsvp_rsvpDetailsList(newList);
                                }
                            },
                            {
                                type: "datePicker",
                                label: "Waktu Mula:",
                                defaultValue: details?.startTime,
                                value: details?.startTime,
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    let newList = deepCloneArray(rsvp_rsvpDetailsList);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        startTime: value
                                    }
                                    setRsvp_rsvpDetailsList(newList);
                                },
                            },
                            {
                                type: "datePicker",
                                label: "Waktu Tamat:",
                                defaultValue: details?.endTime,
                                value: details?.endTime,
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    let newList = deepCloneArray(rsvp_rsvpDetailsList);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        endTime: value
                                    }
                                    setRsvp_rsvpDetailsList(newList);
                                },
                            },
                            {
                                type: "text",
                                label: "Jumlah Ditetapkan:",
                                defaultValue: details?.totalVisitors,
                                value: details?.totalVisitors,
                                onChange: (value) => {
                                    let newList = deepCloneArray(rsvp_rsvpDetailsList);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        totalVisitors: value
                                    }
                                    setRsvp_rsvpDetailsList(newList);
                                },
                                inputProps: {
                                    isDigitOnly: true
                                }
                            },
                            {
                                type: "switch",
                                label: "Jumlah Tidak Terhad",
                                id: idx,
                                defaultValue: details?.unlimited,
                                value: details?.unlimited,
                                onChange: (event) => {
                                    const checked = event.target.checked
                                    console.log(checked )

                                    let newList = deepCloneArray(rsvp_rsvpDetailsList);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        unlimited: checked
                                    }
                                    setRsvp_rsvpDetailsList(newList);
                                    
                                }
                            },
                        ]
                    };
                })}
            />

            {/* Font */}
            <InputContentExpander
                mainDisplayText={"Tulisan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 1) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(1);
                }}
                isExpanded={currentVisibleContentPos === 1}

                themeType={"blue"}
               
                onContentReset={() => setRsvp_textDetails(rsvp_init_textDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                header: "Tajuk Sesi",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: rsvp_textDetails?.sessionTitleFont },
                                value: rsvp_textDetails?.sessionTitleFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        sessionTitleFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna:",
                                defaultValue: rsvp_textDetails?.sessionTitleColor,
                                value: rsvp_textDetails?.sessionTitleColor,
                                onChange: (color) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        sessionTitleColor: color
                                    });
                                }
                            },

                            {
                                header: "Waktu",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: rsvp_textDetails?.timeFont },
                                value: rsvp_textDetails?.timeFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        timeFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna:",
                                defaultValue: rsvp_textDetails?.timeColor,
                                value: rsvp_textDetails?.timeColor,
                                onChange: (color) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        timeColor: color
                                    });
                                }
                            },

                            {
                                header: "Jumlah Kekosongan",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: rsvp_textDetails?.totalFont },
                                value: rsvp_textDetails?.totalFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        totalFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna:",
                                defaultValue: rsvp_textDetails?.totalColor,
                                value: rsvp_textDetails?.totalColor,
                                onChange: (color) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        totalColor: color
                                    });
                                }
                            }
                        ]
                    }
                ]}
            />

            {/* Attend Button */}
            <InputContentExpander
                mainDisplayText={"Butang Hadir"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 2) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(2);
                }}
                isExpanded={currentVisibleContentPos === 2}

                themeType={"blue"}
               
                onContentReset={() => setRsvp_textDetails(rsvp_init_textDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: rsvp_textDetails?.buttonFont },
                                value: rsvp_textDetails?.buttonFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        buttonFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: rsvp_textDetails?.buttonFonColor,
                                value: rsvp_textDetails?.buttonFonColor,
                                onChange: (color) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        buttonFonColor: color
                                    });
                                }
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Latar Belakang:",
                                defaultValue: rsvp_textDetails?.buttonBgColor,
                                value: rsvp_textDetails?.buttonBgColor,
                                onChange: (color) => {
                                    setRsvp_textDetails({
                                        ...rsvp_textDetails,
                                        buttonBgColor: color
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />
        </div>
    );

    let preview_rsvpStep = (
        <MenuPageContainer ecardEditDetails={ecardEditDetails}>
            <RsvpDisplay

                titleStyle={{
                    fontFamily: content_pageTitleDetails?.font,
                    color: content_pageTitleDetails?.fontColor
                }}

                sessions={
                    isArrayNotEmpty(rsvp_rsvpDetailsList)?
                    rsvp_rsvpDetailsList.map((details, idx) => {
                        return {
                            ...details,
                            session: {
                                title: isNotNullUndefined(details?.sessionTitle)? details.sessionTitle: "Sesi " + (idx + 1),
                                session: details?.totalVisitors,
                                timestart: details?.startTime,
                                timeend: details?.endTime,
                                total: details?.totalVisitors,
                                unlimited: details?.unlimited
                            } 
                        }
                    }): [{
                        session: {
                            title: "Sesi 1",
                        } 
                    }]
                }
                sessionProps={{
                    titleStyle: {
                        fontFamily: rsvp_textDetails?.sessionTitleFont,
                        color: rsvp_textDetails?.sessionTitleColor
                    },
                    timeStyle: {
                        fontFamily: rsvp_textDetails?.timeFont,
                        color: rsvp_textDetails?.timeColor
                    },
                    totalStyle: {
                        fontFamily: rsvp_textDetails?.totalFont,
                        color: rsvp_textDetails?.totalColor
                    },
                    isButtonDisabled: true,
                    buttonStyle: {
                        backgroundColor: rsvp_textDetails?.buttonBgColor,
                        fontFamily: rsvp_textDetails?.buttonFont,
                        color: rsvp_textDetails?.buttonFonColor,
                    }
                }}
            />
        </MenuPageContainer>
    );

    return {
        input_rsvpStep, preview_rsvpStep
    }
}