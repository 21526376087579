import React, { useState } from 'react';

import { Modal as BootstrapModal } from "react-bootstrap";
import { RadioInput, LiveModel } from "components";

import { isObjectNotEmpty } from "helper/validations";

import "assets/scss/components/modals/eCardPreview.scss";

export function ECardPreviewModal(props) {
    const {
        isModalVisible,
        eCardUrl,

        modalProps,
        headerProps,
        bodyProps,
    } = props;

    const [isMobile, setIsMobile] = useState(true);
    
    // Validate Props
    let modalProps_validated = isObjectNotEmpty(modalProps)? modalProps:{};
    let headerProps_validated = isObjectNotEmpty(headerProps)? headerProps:{};
    let bodyProps_validated = isObjectNotEmpty(bodyProps)? bodyProps:{};

    return (
        <BootstrapModal
            {...modalProps_validated}
            className={"e-card-preview-modal"}
            onHide={props.onHide}
            show={isModalVisible}
            centered
        >
            {/* Header */}
            <BootstrapModal.Header {...headerProps_validated} closeButton>
                Preview
            </BootstrapModal.Header>

            {/* Body */}
            <BootstrapModal.Body {...bodyProps_validated}>
                <div className={"e-card-preview-modal__body d-flex flex-column justify-content-center align-items-center"}>
                    <LiveModel
                        isMobile={isMobile}
                        url={eCardUrl}
                    />

                    {/* Device Toggle */}
                    <div className={"body__device-toggle d-flex align-items-center justify-content-center"}>
                        {/* Mobile UI Trigger */}
                        <RadioInput
                            label={"Mobile"}
                            groupName={"body__device-toggle"}
                            id={"device-toggle__mobile"}
                            isChecked={isMobile}
                            onChange={() => setIsMobile(!isMobile)}
                        />

                        {/* Tablet UI Trigger */}
                        <RadioInput
                            label={"Tablet"}
                            groupName={"body__device-toggle"}
                            id={"device-toggle__tablet"}
                            isChecked={!isMobile}
                            onChange={() => setIsMobile(!isMobile)}
                        />
                    </div>
                </div>
            </BootstrapModal.Body>
        </BootstrapModal>
    );
}