import { Form } from "components/formInputs/Form";
import { Input } from "components/formInputs/Input";
import { Button } from "components/buttons/Button";
import React, { useEffect, useState } from "react";
import defaultPhoto from "assets/images/user-solid.svg";
import { TelInput } from "components/inputs/TelInput";
import { useCustomSelector } from "hooks";
import {
  getObjectPropertyValue,
  isObjectNotEmpty,
  isStringNotEmpty,
} from "helper/validations";
import { firestoreDB, getCurrentUser } from "dataflow/services/firebase";
import { useDispatch } from "react-redux";
import { getpersonalInfoObject_success } from "dataflow/slices/personalInfo.slice";

function PersonalInfo(props) {
  // get latest user info from Redux store
  const { personalInfoObject } =
    useCustomSelector("personalInfo");

  const dispatch = useDispatch();

  const [username, setUsername] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [sex, setSex] = useState();
  const [city, setCity] = useState();
  const [age, setAge] = useState();
  const [address, setAddress] = useState();
  const [postcode, setPostcode] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [fullPhone, setFullPhone] = useState();

  const [isUpdateAttempted, setIsUpdateAttempted] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  //get curent user id from firebase auth
  let userId = getObjectPropertyValue(getCurrentUser(), "uid", "");

  //get redux data objex value
  let usernameValue = getObjectPropertyValue(
    personalInfoObject,
    "username",
    ""
  );
  let nameValue = getObjectPropertyValue(personalInfoObject, "name", "");
  let phoneNumberValue = getObjectPropertyValue(
    personalInfoObject,
    "phoneNumber",
    ""
  );
  let sexValue = getObjectPropertyValue(personalInfoObject, "sex", "");
  let cityValue = getObjectPropertyValue(personalInfoObject, "city", "");
  let ageValue = getObjectPropertyValue(personalInfoObject, "age", null);
  let addressValue = getObjectPropertyValue(personalInfoObject, "address", "");
  let postcodeValue = getObjectPropertyValue(
    personalInfoObject,
    "postcode",
    null
  );
  let stateValue = getObjectPropertyValue(personalInfoObject, "state", "");
  let countryValue = getObjectPropertyValue(personalInfoObject, "country", "");
  let emailValue = getObjectPropertyValue(personalInfoObject, "email", "");
  let photoValue = getObjectPropertyValue(personalInfoObject, "photoURL", null);

  //function to initialize input value with latest user info from Redux store
  const initializeInput = () => {
    setUsername(usernameValue);
    setName(nameValue);
    setPhone(phoneNumberValue);
    setSex(sexValue);
    setCity(cityValue);
    setAge(ageValue);
    setAddress(addressValue);
    setPostcode(postcodeValue);
    setState(stateValue);
    setCountry(countryValue);
    setFullPhone(phoneNumberValue);
  };

  // update input value with latest user info
  useEffect(() => {
    if (isObjectNotEmpty(personalInfoObject)) {
      initializeInput();
    }
  }, [personalInfoObject]);

  const changePhone = (newNumber, fullNumber) => {
    setPhone(newNumber);
    setFullPhone(fullNumber); //set full phone number with country code
  };

  const handleEdit = () => {
    setIsDisabled(!isDisabled);

    if (!isDisabled) {
      initializeInput(); //reset input
    }
  };

  const onUpdate = () => {
    setIsUpdateAttempted(true);
    let updatedData = {
      username: isStringNotEmpty(username) ? username : "",
      name: isStringNotEmpty(name) ? name : "",
      phoneNumber: isStringNotEmpty(fullPhone) ? fullPhone : "",
      sex: isStringNotEmpty(sex) ? sex : "",
      age: isStringNotEmpty(age) ? age : "",
      address: isStringNotEmpty(address) ? address : "",
      city: isStringNotEmpty(city) ? city : "",
      postcode: isStringNotEmpty(postcode) ? postcode : "",
      state: isStringNotEmpty(state) ? state : "",
      country: isStringNotEmpty(country) ? country : "",
    };

    //update save data to userInfo database
    let userInfoRef = firestoreDB().collection("users").doc(userId);
    userInfoRef
      .update(updatedData)
      .then(() => {
        console.log("Document successfully updated!");
        setIsDisabled(true);
        //merge and update redux store data
        dispatch(
          getpersonalInfoObject_success({
            ...personalInfoObject,
            ...updatedData,
          })
        ); //merge redux store data
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  return (
    <div className={"display__personal-info"}>
      <div className={"personal-info__header d-flex align-items-center"}>
        <img
          className={"header__photo"}
          src={photoValue ? photoValue : defaultPhoto}
          alt={"profile"}
        />

        <div
          className={"header__info d-flex flex-column justify-content-center"}
        >
          <p className={"header__info-name"}>{usernameValue}</p>
          <p className={"header__info-email"}>{emailValue}</p>
        </div>
      </div>

      <Form>
        {/* Nama Penuh */}
        <Input
          className={
            "personal-info__fullname d-flex flex-column justify-content-center"
          }
          labelClassName={"fullname__label"}
          label={"Nama Penuh:"}
          value={name}
          onChange={setName}
          inputClassName={"fullName__input"}
          isInvalid={!name && isUpdateAttempted}
          errorMsg={"Nama Penuh diperlukan"}
          isDisabled={isDisabled}
        />
        {/* Nama Panggilan */}
        <Input
          className={
            "personal-info__name d-flex flex-column justify-content-center"
          }
          labelClassName={"name__label"}
          label={"Nama Panggilan (paparan akaun):"}
          value={username}
          onChange={setUsername}
          inputClassName={"name__input"}
          isInvalid={!username && isUpdateAttempted}
          errorMsg={"Nama panggilan diperlukan"}
          maxLength={"20"}
          isDisabled={isDisabled}
        />

        {/* latar belakang */}
        <div className={"personal-info__background d-flex align-items-center"}>
          <TelInput
            className={
              "personal-info__phone d-flex flex-column justify-content-center"
            }
            id={"phone"}
            labelClassName={"phone__label"}
            label={"No. Telefon:"}
            value={phone}
            onChange={changePhone}
            inputClassName={"Phone__input"}
            isSubmissionAttempted={!phone && isUpdateAttempted}
            errorMsg={"Phone No. is required"}
            isDisabled={isDisabled}
          />

          <Input
            elementType={"select"}
            className={"sex__race d-flex flex-column justify-content-center"}
            labelClassName={"sex_label"}
            label={"Jantina:"}
            value={sex}
            onChange={setSex}
            optionList={["Lelaki", "Perempuan"]}
            inputClassName={"sex__input"}
            isInvalid={!sex && isUpdateAttempted}
            errorMsg={"sex is required"}
            isDisabled={isDisabled}
          />

          <Input
            className={
              "background__age d-flex flex-column justify-content-center"
            }
            labelClassName={"age__label"}
            label={"Umur:"}
            value={age}
            onChange={setAge}
            inputClassName={"age__input"}
            isInvalid={!age && isUpdateAttempted}
            errorMsg={"Age is required"}
            isDisabled={isDisabled}
          />
        </div>

        {/* Alamat 1 */}
        <div className="personal-info__address1 d-flex align-items-center">
          <Input
            className={
              "address1__street d-flex flex-column justify-content-center"
            }
            labelClassName={"street__label"}
            label={"Alamat Rumah:"}
            value={address}
            onChange={setAddress}
            inputClassName={"street__input"}
            isInvalid={!address && isUpdateAttempted}
            errorMsg={"Address is required"}
            isDisabled={isDisabled}
          />

          <Input
            className={
              "address1__city d-flex flex-column justify-content-center"
            }
            labelClassName={"city__label"}
            label={"Bandar:"}
            value={city}
            onChange={setCity}
            inputClassName={"Bandar__input"}
            isInvalid={!city && isUpdateAttempted}
            errorMsg={"City is required"}
            isDisabled={isDisabled}
          />
        </div>

        {/* Alamat 2 */}
        <div className={"personal-info__address2 d-flex align-items-center"}>
          <Input
            className={
              "address2__postcode d-flex flex-column justify-content-center"
            }
            labelClassName={"postcode__label"}
            label={"Poskod:"}
            value={postcode}
            onChange={setPostcode}
            inputClassName={"postcode__input"}
            isInvalid={!postcode && isUpdateAttempted}
            errorMsg={"Postcode is required"}
            isDisabled={isDisabled}
          />

          <Input
            as={"select"}
            className={
              "address2__state d-flex flex-column justify-content-center"
            }
            labelClassName={"state__label"}
            label={"Negeri:"}
            value={state}
            onChange={setState}
            inputClassName={"state__input"}
            isInvalid={!state && isUpdateAttempted}
            errorMsg={"State is required"}
            isDisabled={isDisabled}
          />

          <Input
            as={"select"}
            className={
              "address2__origin d-flex flex-column justify-content-center"
            }
            labelClassName={"origin__label"}
            label={"Negara:"}
            value={country}
            onChange={setCountry}
            inputClassName={"origin__input"}
            isInvalid={!country && isUpdateAttempted}
            errorMsg={"Country is required"}
            isDisabled={isDisabled}
          />
        </div>

        {/* Save and edit */}
        <div className={"w-100 d-flex justify-content-center"}>
          <Button
            className={"personal-info__edit"}
            decoration_isBrightPrimary={isDisabled}
            decoration_isSecondary={!isDisabled}
            content={isDisabled ? "Ubahsuai" : "Batal"}
            onClick={handleEdit}
          />

          <Button
            className={"personal-info__save"}
            content={"Simpan"}
            decoration_isBrightPrimary={!isDisabled}
            isDisabled={isDisabled}
            onClick={onUpdate}
          />
        </div>
      </Form>
    </div>
  );
}

export default PersonalInfo;
