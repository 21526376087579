import { fonts } from 'helper/constants/fonts';
import { isArrayNotEmpty } from 'helper/validations';
import { loadFileResourse } from 'helper/validations/dom';
import React, { useEffect, useRef } from 'react';

function useLoadFonts(props = {}) {
    const {
        loadFrequency = 100
    } = props;

    const maxLoadedPos = useRef(-1);
    
    useEffect(() => {
        for (let i = 0; i < loadFrequency; i++) {
            loadFont(i);
        }
    }, []); 

    function loadFont(listIndex) {
        if (listIndex > maxLoadedPos.current && listIndex <= fonts.length) {
            for (let i = (maxLoadedPos.current + 1); i < (maxLoadedPos.current + loadFrequency); i++) {
                let obj = fonts[i];
                let url = obj?.url;
                loadFileResourse(url);
            }

            maxLoadedPos.current += loadFrequency;
        }
    }

    function loadFontForSelectedFonts(fontList) {
        if (isArrayNotEmpty(fontList)) {
            fontList.map((fontName) => {
                let url = fonts.find((obj) => obj.value === fontName)?.url;

                if (url) loadFileResourse(url);
            })
        }
    }

    return {
        fontList: fonts, loadFont, loadFontForSelectedFonts
    }
}

export default useLoadFonts;