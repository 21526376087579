import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Carousel } from "react-bootstrap";
import "assets/scss/modules/ECard/ECard.scss";
import { setcarousel_success } from "dataflow/slices/app.slice";
import { useCustomSelector } from "hooks";

import Home from "./sections/home/Home";
import LoadingScreen from "./sections/LoadingScreen";
import TempData from "./sections/TempData";
import Details from "./sections/details/Details";
import useAppServices from "dataflow/services/app.service";
import useLoadFonts from "hooks/useLoadFonts";
import { isArrayNotEmpty, isStringTrimNotEmpty } from "helper/validations";

import menu_open from "assets/images/nav-icon-base.svg";
import menu_close from "assets/images/nav-icon-fill.svg";
import NavButton from "components/ecard/NavButton";
import MenuContainer from "./sections/MenuContainer";
import useLoadMusic from "hooks/useLoadMusic";

function ECard() {
  const [themes, setThemes] = useState(null);
  const [collectionId, setCollectionId] = useState(null);

  const { getAppInfo } = useAppServices();

  const dispatch = useDispatch();
  const activeSlide = useRef(null);
  const { id } = useParams();
  let { path, url } = useRouteMatch();
  let history = useHistory()
  
  const { carouselIndex, appInfo } = useCustomSelector("app");
  //control audio
  const {musicURL, isAvail, isMuted, muteUnMute, loadMusic} = useLoadMusic()

  useEffect(() => {
    let mounted = true;

    const getData = async () => {
      getAppInfo(id, url);
    };

    setTimeout(getData, 100);

    return () => {
      mounted = false;
    };
  }, [id, url]);

  // Load fonts found in ecard
  const { loadFontForSelectedFonts } = useLoadFonts();
  useEffect(() => {
    // get all the fonts needed from "themes"
    let fontList = [];
    let themes = appInfo?.themes;
    for (let propertyName in themes) {
      // find any property that has the word "fontFamily" in it
      if (propertyName.toLowerCase().indexOf("fontfamily") !== -1) {
        // get value of the font
        let fontName = themes[propertyName];
        if (isStringTrimNotEmpty(fontName)) fontList.push(fontName);
      }
    }

    if (isArrayNotEmpty(fontList)) {
      loadFontForSelectedFonts(fontList);
    }
  }, [appInfo?.themes]);

  useEffect(() => {
    if (appInfo) {
      setThemes(appInfo.themes);
      setCollectionId(appInfo?.colId);
      loadMusic(appInfo?.musicURL)
    }
  }, [appInfo]);

  const handleSelect = (selectedIndex, e) => {
    dispatch(setcarousel_success(selectedIndex));
  };

  //new addition
  
  //control animation
  const [isPhase, setIsPhase] = useState(true);

  const audioRef = useRef(null);
  useEffect(() => {
    if (isAvail) {
      audioRef.current.muted ? audioRef.current.pause() : audioRef.current.play();
    }
  });
  console.log(isAvail, musicURL, audioRef)
  //load nav button after image fully loaded
  const [isLoaded, setIsLoaded] = useState(false);

  //control menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="ecard m-auto position-relative">

      {isMenuOpen && <MenuContainer themes={themes} collectionId={collectionId} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}

      {isAvail && (
        <audio ref={audioRef} playsInline muted={isMuted} loop preload="auto">
          <source src={musicURL} type="audio/mpeg"></source>
        </audio>
      )}

      {isLoaded && (
        <div className="ecard__nav position-absolute d-flex flex-column justify-content-between align-items-center">
          {isAvail && (
            <NavButton phase={isMuted} setPhase={muteUnMute}>
              <i
                style={!isMuted ? { color: "#47b0e2" } : { color: "#ebebe4" }}
                className={
                  "icons fas " +
                  (isMuted ? "fa-volume-mute " : "fa-volume-down ")
                }
              ></i>
              
            </NavButton>
          )}

          <NavButton phase={isMenuOpen} setPhase={setIsMenuOpen}>
            <img src={(isMenuOpen ? menu_open : menu_close)} alt={(isMenuOpen ? "Menu Open": "Menu Close")} className={(isMenuOpen ? "menu-open": "menu-close")} onClick={() => history.push(`${url}`)}/> 
          </NavButton>
        </div>
      )}

      {!isLoaded && <LoadingScreen />}

      {themes && (
        <Carousel
          controls={false}
          wrap={false}
          keyboard={true}
          activeIndex={carouselIndex}
          onSelect={handleSelect}
          interval={null}
          style={isLoaded ? {} : { display: "none" }}
        >
          <Carousel.Item>
            <Home
              isPhase={isPhase}
              callback={handleSelect}
              setIsLoaded={setIsLoaded}
              setIsMuted={muteUnMute}
            />
          </Carousel.Item>

          <Carousel.Item>
            <Details/>
          </Carousel.Item>

        </Carousel>
      )}
    </div>
  );
}

export default ECard;
