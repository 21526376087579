import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import "assets/scss/components/contentExpander/contentExpander.scss";
import { isNotNullUndefined } from 'helper/validations';

export default function ContentExpander({ 
    className, iconClassName, mainDisplayTextClassName, expanderContentClassName,
    mainDisplayText, expandedContent, expanderContentStyle,
    isExpanded: isExpanded_fromParent,
    isEntireBarNotExpandable,
    themeType,

    // Function
    onClick = () => {}
}) {
    const [isExpanded, setIsExpanded] = useState();

    function handleOnClick(isNotEntireBar) {
        if (!isEntireBarNotExpandable || (isNotEntireBar && isEntireBarNotExpandable)) {
            setIsExpanded(!isExpanded);
            onClick();
        }
    }

    let isExpanded_validated = isNotNullUndefined(isExpanded_fromParent)? isExpanded_fromParent: isExpanded;

    let themeTypeClassName = "";
    switch (themeType) {
        case "blue": 
            themeTypeClassName = "component-content-expander_blue-theme"; 
            break;
        case "darkGrey": 
            themeTypeClassName = "component-content-expander_dark-grey-theme"; 
            break;
    }

    return (
        <div className={"component-content-expander " +
                (className? className + " ":"") +
                (themeTypeClassName? themeTypeClassName + " ":"")}>
            <div className={"component-content-expander__main-display d-flex align-items-center justify-content-between"}
                onClick={() => handleOnClick()}
            >
                <span className={"main-display__text " +
                        (mainDisplayTextClassName? mainDisplayTextClassName + " ":"")}>
                    {mainDisplayText}
                </span>

                {/* Expander */}
                <FontAwesomeIcon
                    className={"main-display__expander " + 
                        (iconClassName? iconClassName + " ":"")}
                    icon={isExpanded_validated? faMinus:faPlus}
                    onClick={() => handleOnClick(true)}
                />
            </div>

            {/* Expander Content */}
            <div className={"component-content-expander__content " + 
                    (expanderContentClassName? expanderContentClassName + " ":"") +
                    (isExpanded_validated? "":"component-content-expander_hide ")}
                style={expanderContentStyle}
            >
                {expandedContent}
            </div>
            
        </div>
    );
}