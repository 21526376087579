import { useCustomSelector } from 'hooks';
import GalleryDisplay from 'modules/ECard/sections/gallery/GalleryDisplay';
import React from 'react';

import album_sample_1 from "assets/images/updateEkad/album_sample_1.png";
import album_sample_2 from "assets/images/updateEkad/album_sample_2.png";
import album_sample_3 from "assets/images/updateEkad/album_sample_3.png";
import album_sample_4 from "assets/images/updateEkad/album_sample_4.png";
import album_sample_5 from "assets/images/updateEkad/album_sample_5.png";
import album_sample_6 from "assets/images/updateEkad/album_sample_6.png";
import album_sample_7 from "assets/images/updateEkad/album_sample_7.png";
import { ImgWithFallback } from 'components';
import MenuPageContainer from 'components/containers/MenuPageContainer';


function useGalleryStep({
    cardThemeBgImage, content_pageTitleDetails,

    gallery_galleryDetails, setGallery_galleryDetails,
    gallery_textDetails, setGallery_textDetails,
}) {
    const { ecardEditDetails } = useCustomSelector("app");

    return {
        input_galleryStep: (
            <div className={"inputs__gallery-step"}>
            {/* Title */}
            <div className={"gallery-step__title"}>
                Album
            </div>

            {/* Description */}
            <div className={"gallery-step__description"}>
                Tidak perlu ubahsuai.
            </div>
        </div>
        ),
        preview_galleryStep: (
            <MenuPageContainer ecardEditDetails={ecardEditDetails}>
                <GalleryDisplay
                    bgOverlayStyle={{
                        background: ecardEditDetails?.themes?.backgroundColor2,
                        borderColor: ecardEditDetails?.themes?.border && ecardEditDetails?.themes?.contentColor1
                    }}
                    
                    titleStyle={{
                        fontFamily: content_pageTitleDetails?.font,
                        color: content_pageTitleDetails?.fontColor
                    }}

                    mediaIconStyle={{
                        color: content_pageTitleDetails?.fontColor
                    }}

                    medias={[
                        {
                            "id": "B99MlE53Uw5e63yvsBbc",
                            "data": {
                                "mediaURL": album_sample_1,
                                "favorites": 2,
                                "eventId": "awr7ucFzj12mKItgmgtG",
                                "user": "Team EkadTepi",
                                "timestamp": {
                                    "seconds": 1635603304,
                                    "nanoseconds": 837000000
                                },
                                "mediaId": "9531a9c7-a4eb-ec1f-b54d-d72e47373849"
                            }
                        },
                        {
                            "id": "B99MlEr3Uw5e6JyvsBbc",
                            "data": {
                                "mediaURL": album_sample_2,
                                "favorites": 0,
                                "eventId": "awr7ucFzjTkmKItxmgtG",
                                "user": "Aiman",
                                "timestamp": {
                                    "seconds": 1635603304,
                                    "nanoseconds": 837000000
                                },
                                "message": "Pasangan Yang Perfect!",
                                "mediaId": "9531a9c7-a4eb-4c1f-b54d-d72e47073849"
                            }
                        },
                        {
                            "id": "B99MlEr3Uwde2JyvsBbc",
                            "data": {
                                "mediaURL": album_sample_3,
                                "favorites": 0,
                                "eventId": "awr7ucFzjTkmKItxmgtG",
                                "user": "Amirul",
                                "timestamp": {
                                    "seconds": 1635603304,
                                    "nanoseconds": 837000000
                                },
                                "message": "Wedding cake",
                                "mediaId": "9531a9c7-a4eb-4c1f-b54d-d72e47073849"
                            }
                        },
                        {
                            "id": "B99MlE53Uw5e6JyvsBbc",
                            "data": {
                                "mediaURL": album_sample_4,
                                "favorites": 2,
                                "eventId": "awr7ucFzjTkmKItgmgtG",
                                "user": "Team EkadTepi",
                                "timestamp": {
                                    "seconds": 1635603304,
                                    "nanoseconds": 837000000
                                },
                                "mediaId": "9531a9c7-a4eb-4c1f-b54d-d72e47773849"
                            }
                        },
                        {
                            "id": "B99MlEr3UwdefJyvsBbc",
                            "data": {
                                "mediaURL": album_sample_5,
                                "favorites": 0,
                                "eventId": "awr7ucFzjTkmKItxmgtG",
                                "user": "Khairul",
                                "timestamp": {
                                    "seconds": 1635603304,
                                    "nanoseconds": 837000000
                                },
                                "message": "Cincin Yang Tidak Boleh Dilupakan",
                                "mediaId": "9531a9c7-a4eb-4c1f-b54d-d72e47073849"
                            }
                        },
                        {
                            "id": "B99MlEr3Uw5efJyvsBbc",
                            "data": {
                                "mediaURL": album_sample_6,
                                "favorites": 0,
                                "eventId": "awr7ucFzjTkmKItxmgtG",
                                "user": "Nurul",
                                "timestamp": {
                                    "seconds": 1635603304,
                                    "nanoseconds": 837000000
                                },
                                "message": "Cantik Betul",
                                "mediaId": "9531a9c7-a4eb-4c1f-b54d-d72e47073849"
                            }
                        },
                        {
                            "id": "B99MlE53Uw5e67yvsBbc",
                            "data": {
                                "mediaURL": album_sample_7,
                                "favorites": 2,
                                "eventId": "awr7ucFzjT2mKItgmgtG",
                                "user": "Team EkadTepi",
                                "timestamp": {
                                    "seconds": 1635603304,
                                    "nanoseconds": 837000000
                                },
                                "mediaId": "9531a9c7-a4eb-ec1f-b54d-d72e47773849"
                            }
                        },
                    ]}

                    isAddImageIconHidden

                    // medias={medias}
                    // iconStyle={iconStyle1}
                />
            </MenuPageContainer>
        )
    }
}

export default useGalleryStep;