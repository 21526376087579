import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import BackButton from "components/ecard/BackButton";
import Media from "components/ecard/Media";
import { isArrayNotEmpty } from "helper/validations";

export default function GalleryDisplay({
  bgOverlayStyle,

  titleStyle,
  medias,
  mediaIconStyle,
  
  renderPopover,
  onTimelineAddClick = () => {},
  onMediaAddIconClick = () => {},
}) {
  return (
    <div className="general_background m-auto">
      <div className="gallery_component d-flex flex-column">
        <div className="gallery_header d-flex justify-content-between align-items-center">
          <div className="gallery_header_back ">
            <BackButton />
          </div>
          <div className="gallery_header_title text-center">
            <div className="header2">
              <h4 id="tajuk" style={titleStyle}>
                ALBUM
              </h4>
            </div>
          </div>
          <div className="gallery_header_add text-right">
            <div className="add__icon">
              <i
                className="fas fa-plus-circle icons"
                onClick={() => onMediaAddIconClick()}
                style={mediaIconStyle}
              ></i>
            </div>
          </div>
        </div>
        <div
          className="gallery__container"
          style={medias.length !== 0 ? bgOverlayStyle : null}
        >
          <div className="medias__timeline">
            {isArrayNotEmpty(medias) ? (
              medias.map((media) => (
                <Media
                  key={media?.id}
                  id={media?.id}
                  mediaURL={media?.data?.mediaURL}
                  message={media?.data?.message}
                  name={media?.data?.user}
                  favorites={media?.data?.favorites}
                />
              ))
            ) : (
              <div className="medias__timelineAdd">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderPopover}
                  show={true}
                >
                  <i
                    className="fas fa-plus-circle icons"
                    onClick={() => onTimelineAddClick()}
                    style={mediaIconStyle}
                  ></i>
                </OverlayTrigger>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
