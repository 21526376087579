import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import "assets/scss/modules/receipt/receipt.scss";
import Success from "./sections/Success";
import Pending from "./sections/Pending";
import Fail from "./sections/Fail";

function Receipt() {
  const location = useLocation();

  const [statusId, setStatusId] = useState("");
  const [billCode, setBillCode] = useState("");
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setStatusId(searchParams.get("status_id"));
    setBillCode(searchParams.get("billcode"));
    setOrderId(searchParams.get("order_id"));
  }, [location]);

  let display = "";
  switch (statusId) {
    case "1":
      display = <Success orderId={orderId} billCode={billCode} />;
      break;
    case "2":
      display = <Pending orderId={orderId} billCode={billCode} />;
      break;
    case "3":
      display = <Fail orderId={orderId} billCode={billCode} />;
      break;
    default:
      break;
  }

  return (
    <div className={"receipt d-flex flex-column align-items-center"}>
      {/* Page Title */}
      <div className={"receipt__page-title text-center"}>
        <span>
          Status Pembayaran <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>
      {/* Page Content */}
      {display}
      {/* Go to account button */}
    </div>
  );
}

export default Receipt;
