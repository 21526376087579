import React from "react";

import logo from "assets/images/logo.png";
import title from "assets/images/logo-title.png";

function Logo() {
  return (
    <div className="alllove">
      {/* <img className="logo__image" src={logo} alt="EkadTepi"/>

      <img className="logo__title" src={title} alt="Digital Invitation"/> */}
      <i className="love love1 fa fa-heart"></i>
      <i className="love love2 fa fa-heart"></i>
      <i className="love love3 fa fa-heart"></i>
      <i className="love love4 fa fa-heart"></i>
      <i className="love love5 fa fa-heart"></i>
      <h1>
        Raikan Cinta Bersama <br /> <span>EkadTepi</span>
      </h1>
    </div>
  );
}

export default Logo;
