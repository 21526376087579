import React from 'react';

import { Form as BootstrapForm } from 'react-bootstrap';

import { isFunction } from "helper/validations";



export function Form(props) {

    function handleOnSubmit() {
        if (isFunction(props.onSubmit))
            props.onSubmit();
    }

    return (
        <BootstrapForm
            onSubmit={(e) => {
                e.preventDefault();
                handleOnSubmit();
            }}
        >
            {props.children}
        </BootstrapForm>
    );
}