import axios from "axios";
import { isFunction, isObjectNotEmpty, isStringTrimNotEmpty, getCookieValue, getCookieAll, isObject, isNotNullUndefined, isCookieValid, isUserLoggedIn } from "helper/validations";
import { useDispatch } from "react-redux";
import { isConstructorDeclaration } from "typescript";
import { refreshIdToken } from ".";
import { deleteStorageFile } from "./firebase";

export const promiseInstance = axios.create({
    headers: {
        'content-type': "application/json",
        'appid': "",
    },
    baseURL: process.env.REACT_APP_API_URL
});

promiseInstance.interceptors.request.use(async (request) => {
    let token = "";
    if(isUserLoggedIn()){
        token = await refreshIdToken()
    }
    
    request.headers.Authorization = token ? `Bearer ${token}` : '';
    return request;  
});

export function useRequest() {
    const dispatch = useDispatch();

    function handleRequest(type, props) {
        let requestFunc = promiseInstance?.[type];

        const {
            url, requesting, success, failure, body, config, onSuccessData, 
            onSuccessExtend, onFailureExtend
        } = props;
        
        let secondParam = config;
        if (type == "post" || type == "put") {
            secondParam = body;
        }

        if (isFunction(requestFunc) && isStringTrimNotEmpty(url)) {
            if (isFunction(requesting)) dispatch(requesting());

            requestFunc(url, secondParam, config)
                .then((response) => {
                    // handle success
                    
                    if (isFunction(success)) 
                        dispatch(success({
                            data: response.data,
                            otherData: onSuccessData
                        }));

                    if (type == "delete" && response?.data?.fileName) {
                        deleteStorageFile(response?.data?.eventId, response?.data?.fileName);
                    }

                    if (isFunction(onSuccessExtend)) onSuccessExtend(response);
                })
                .catch((error) => {
                    // handle error
                    
                    if (isFunction(failure)) dispatch(failure(error?.message));
                    if (isFunction(onFailureExtend)) onFailureExtend(error?.message)
                })
                .finally(() => {
                    // always executed
                    
                });
        }
    }

    return {
        get: (props) => handleRequest("get", props),
        post: (props) => handleRequest("post", props),
        put: (props) => handleRequest("put", props),
        deleteReq: (props) => handleRequest("delete", props),
    }
}