import React, { useEffect, useState } from 'react';
import { hexToFilterCSS, validateFunction } from 'helper/validations';
import ContentDisplay from 'modules/ECard/sections/content/ContentDisplay';
import moment from 'moment';
import InputContentExpander from 'components/contentExpander/InputContentExpander';
import { isDateValid } from 'helper/validations/date';
import { useCustomSelector } from 'hooks';
import MenuPageContainer from 'components/containers/MenuPageContainer';


export default function useContentStep({
    fontList, loadFont,

    isResetRequired, currentStep,

    cover_details,

    content_init_pageTitleDetails, content_init_countdownDetails,
    content_init_textIconDetails,

    content_pageTitleDetails = {}, setContent_pageTitleDetails: setContent_pageTitleDetails_props,
    content_countdownDetails = {}, setContent_countdownDetails: setContent_countdownDetails_props,
    content_textIconDetails = {}, setContent_textIconDetails: setContent_textIconDetails_props,
}) {
    // Validations
    let setContent_pageTitleDetails = validateFunction(setContent_pageTitleDetails_props);
    let setContent_countdownDetails = validateFunction(setContent_countdownDetails_props);
    let setContent_textIconDetails = validateFunction(setContent_textIconDetails_props);

    const [currentVisibleContentPos, setCurrentVisibleContentPos] = useState();
    const { ecardEditDetails } = useCustomSelector("app");
    
    function reset() {
        setContent_pageTitleDetails(content_init_pageTitleDetails);
        setContent_countdownDetails(content_init_countdownDetails);
        setContent_textIconDetails(content_init_textIconDetails);
    }
    useEffect(() => {
        if (currentStep == "content" && isResetRequired) reset();
    }, [isResetRequired]);

    let input_contentStep = (
        <div className={"inputs__content-step"}>
            {/* Each Page Title */}
            <InputContentExpander
                mainDisplayText={"Tajuk Setiap Muka"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 0) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(0);
                }}
                isExpanded={currentVisibleContentPos === 0}

                themeType={"blue"}

                otherProps={{
                    expanderContentStyle: { overflow: "visible" }
                }}

                onContentReset={() => setContent_pageTitleDetails(content_init_pageTitleDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: content_pageTitleDetails?.font },
                                value: content_pageTitleDetails?.font,
                                onSelect: (value) => {
                                    setContent_pageTitleDetails({
                                        ...content_pageTitleDetails,
                                        font: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: content_pageTitleDetails?.fontColor,
                                value: content_pageTitleDetails?.fontColor,
                                onChange: (color) => {
                                    setContent_pageTitleDetails({
                                        ...content_pageTitleDetails,
                                        fontColor: color
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />

            {/* Time Left */}
            <InputContentExpander
                mainDisplayText={"Masa"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 1) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(1);
                }}
                isExpanded={currentVisibleContentPos === 1}

                themeType={"blue"}

                otherProps={{
                    expanderContentStyle: { overflow: "visible" }
                }}

                onContentReset={() => setContent_countdownDetails(content_init_countdownDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: content_countdownDetails?.font },
                                value: content_countdownDetails?.font,
                                onSelect: (value) => {
                                    setContent_countdownDetails({
                                        ...content_countdownDetails,
                                        font: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: content_countdownDetails?.fontColor,
                                value: content_countdownDetails?.fontColor,
                                onChange: (color) => {
                                    setContent_countdownDetails({
                                        ...content_countdownDetails,
                                        fontColor: color
                                    });
                                }
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Bulatan:",
                                defaultValue: content_countdownDetails?.circleColor,
                                value: content_countdownDetails?.circleColor,
                                onChange: (color) => {
                                    setContent_countdownDetails({
                                        ...content_countdownDetails,
                                        circleColor: color
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />

            {/* Icon Details */}
            <InputContentExpander
                mainDisplayText={"Ikon"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 2) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(2);
                }}
                isExpanded={currentVisibleContentPos === 2}

                themeType={"blue"}

                otherProps={{
                    expanderContentStyle: { overflow: "visible" }
                }}

                onContentReset={() => setContent_textIconDetails(content_init_textIconDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: content_textIconDetails?.font },
                                value: content_textIconDetails?.font,
                                onSelect: (value) => {
                                    setContent_textIconDetails({
                                        ...content_textIconDetails,
                                        font: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: content_textIconDetails?.fontColor,
                                value: content_textIconDetails?.fontColor,
                                onChange: (color) => {
                                    setContent_textIconDetails({
                                        ...content_textIconDetails,
                                        fontColor: color
                                    });
                                }
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Ikon:",
                                defaultValue: content_textIconDetails?.iconColor,
                                value: content_textIconDetails?.iconColor,
                                onChange: (color) => {
                                    setContent_textIconDetails({
                                        ...content_textIconDetails,
                                        iconColor: color
                                    });
                                }
                            }
                        ]
                    }
                ]}
            />
        </div>
    );

    let preview_contentStep = (
        <MenuPageContainer ecardEditDetails={ecardEditDetails}>
            <ContentDisplay
                bgOverlayStyle={{
                    background: ecardEditDetails?.themes?.backgroundColor2,
                    borderColor: ecardEditDetails?.themes?.border && ecardEditDetails?.themes?.contentColor1
                }}

                titleStyle={{
                    fontFamily: content_pageTitleDetails?.font,
                    color: content_pageTitleDetails?.fontColor
                }}
                
                countdownTextStyle={{
                    fontFamily: content_countdownDetails?.font,
                    color: content_countdownDetails?.fontColor,
                }}
                countdownBorderColor={content_countdownDetails?.circleColor}
                timer={isDateValid(cover_details?.date)? cover_details?.date: moment().month(moment().month() + 1).format()}
            
                iconStyle={{
                    color: content_textIconDetails?.iconColor
                }}
                iconTextStyle={{
                    fontFamily: content_textIconDetails?.font,
                    color: content_textIconDetails?.fontColor
                }}

            />
        </MenuPageContainer>
    );

    return {
        input_contentStep, preview_contentStep
    }
}