import React, { useEffect, useState } from "react";
import "assets/scss/modules/updateECard/updateECard.scss";
import { isArrayNotEmpty, isObjectNotEmpty, isFunction } from "helper/validations";
import { Button } from "components/buttons/Button";
import useHandleValues from "./hooks/useHandleValues";
import useStepContent from "./hooks/useStepContent";
import useInitValues from "./hooks/useInitValues";
import { Shimmer } from "components";
import { useCustomSelector } from "hooks";
import { useSubmission } from "./hooks/useSubmission";
import AlertModal from "components/notifications/AlertModal";
import useOnPageExit from "hooks/useOnPageExit";

export default function UpdateECard(props) {
  const [currentStep, setCurrentStep] = useState("cover");
  const [isResetRequired, setIsResetRequired] = useState();
  const { ecardEditDetails } = useCustomSelector("app");

  useEffect(() => {
    if (isResetRequired) setIsResetRequired(false);
  }, [isResetRequired]);

  let stepsDetails = [
    {
      id: "cover",
      text: "Depan",
    },
    {
      id: "eventDetails",
      text: "Butiran",
    },
    {
      id: "content",
      text: "Kandungan",
    },
    {
      id: "contact",
      text: "Hubungi",
    },
    {
      id: "tentative",
      text: "Aturcara",
    },
    {
      id: "location",
      text: "Lokasi",
    },
    {
      id: "rsvp",
      text: "RSVP",
    },
    {
      id: "wish",
      text: "Ucapan",
    },
    {
      id: "gallery",
      text: "Album",
    },
    // {
    //     id: "present",
    //     text: "Hadiah"
    // },
    {
      id: "complete",
      text: "Selesai",
    },
  ];

  const valuesObj = useHandleValues();

  const initValuesObj = useInitValues(valuesObj);

  const submissionObj = useSubmission({
    ...valuesObj,
    ...initValuesObj,
  });

  const { inputs, preview } = useStepContent({
    currentStep,
    isResetRequired,
    ...valuesObj,
    ...initValuesObj,
    ...submissionObj
  });

  let isSubmitActionsVisible = !(
    currentStep === "complete" ||
    currentStep === "gallery" ||
    currentStep === "wish"
  );

  useOnPageExit({
    blockingCondition: (isFunction(submissionObj?.isAnyInputChanged) && submissionObj.isAnyInputChanged()), 
    onBlockFunc: ({ pathname, unblock, setDestinationPathname }) => {
      AlertModal({
        title: "Kemaskini",
        text: "Anda mahu mengemaskinikan perubahan anda?",
        icon: "warning",
        confirmButtonText: "Simpan",
        showCancelButton: true,
        cancelButtonText: "Buang",
      })
      .then((result) => {
        if (result.isConfirmed){
          submissionObj.onSave();
        }

        setDestinationPathname(pathname);
      });
    }
  });

  return (
    <div className={"update-e-card d-flex flex-column align-items-center"}>
      {/* Title */}
      <div className={"update-e-card__page-title"}>
        <span>
          Ubahsuai <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* Navigation Steps */}
      <div
        className={
          "update-e-card__navigation-steps d-flex flex-wrap align-items-center justify-content-center"
        }
      >
        {isArrayNotEmpty(stepsDetails) &&
          stepsDetails.map((details, idx) => {
            let id = details?.id;
            let text = details?.text;
            let isCurrentStep = currentStep === id;

            return (
              <div key={idx} className={"navigation-steps__item-wrapper"}>
                <div
                  className={
                    "navigation-steps__item d-flex flex-column align-items-center justify-content-center "
                  }
                  onClick={() => setCurrentStep(id)}
                >
                  {/* Number */}
                  <div
                    className={
                      "item__number d-flex align-items-center justify-content-center " +
                      (isCurrentStep ? "item__number_current " : "")
                    }
                  >
                    {idx + 1}
                  </div>

                  {/* Name */}
                  <div className={"item__name"}>{text}</div>
                </div>
              </div>
            );
          })}
      </div>

      {/* Content */}
      <div className={"update-e-card__content d-flex " + (currentStep === "complete"? "flex-column-reverse flex-lg-row ":"")}>
        {/* User Inputs*/}
        <div className={"content__user-inputs"}>
          {/* Input Elements */}
          <div className={"user-inputs__inputs"}>
            {isObjectNotEmpty(ecardEditDetails) && initValuesObj?.rsvpSessions? inputs: (
              <div className={"inputs__shimmer"}>
                <Shimmer />
              </div>
            )}
          </div>

          {/* Submit */}
          {isSubmitActionsVisible && (
            <div
              className={
                "user-inputs__submit d-flex align-items-center justify-content-center "
              }
            >
              {/* Discard Changes */}
              <Button
                className={"submit__discard-changes"}
                content={"Asal"}
                onClick={() => {
                  AlertModal({
                    title: "Kembali ke Asal",
                    text: "Anda mahu set segala input di langkah ini kepada asal?",
                    icon: "warning",
                    confirmButtonText: "Teruskan",
                    showCancelButton: true,
                    cancelButtonText: "Batalkan",
                  })
                  .then((result) => {
                    if (result.isConfirmed){
                      setIsResetRequired(true);
                    }
                  });
                }}
                decoration_isBrightInversePrimary
              />

              {/* Save Changes */}
              <Button
                className={"submit__save-changes"}
                content={"Simpan"}
                onClick={submissionObj?.onSave}
                decoration_isBrightPrimary
              />
            </div>
          )}
        </div>

        {/* Preview */}
        <div className={"content__preview " + (currentStep === "complete"? "content__preview_no-shadow ":"")}>
          {isObjectNotEmpty(ecardEditDetails) && initValuesObj?.rsvpSessions? preview: (
            <div className={"preview__shimmer"}>
              <Shimmer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
