import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isUserWebsiteLogin: null,
    isProvider: false
};

export const websiteSlice = createSlice({
    name: 'website',
    initialState,
    reducers:{
        setIsUserWebsiteLoggedIn: (state, action) => {
            state.isUserWebsiteLogin = action.payload;
        },
        setIsProviderWebsiteLoggedIn: (state, action) => {
            state.isUserWebsiteLogin = action.payload;
            state.isProvider = true;
        },
        setIsUserAuthenticated: (state, action) => {
            state.isUserAuthenticated = action.payload;
        },
        resetIsUserWebsiteLoggedIn: (state) => initialState,
    }
})

export const {
    setIsUserWebsiteLoggedIn,
    setIsProviderWebsiteLoggedIn,
    resetIsUserWebsiteLoggedIn,
    setIsUserAuthenticated
} = websiteSlice.actions;

export default websiteSlice.reducer;