import React from 'react'

import "assets/scss/components/skeletons/skeletonElement.scss";

export function SkeletonElement({type}) {

    const classes = `skeleton ${type}`;

    return (
        <div className={classes}>
            
        </div>
    )
}

