import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "components/buttons/Button";

import { isUserLoggedIn } from "helper/validations";
import { useCustomSelector } from "hooks";
import { UserAuthenticationModal } from "components/modals/UserAuthenticationModal";
import { useDispatch } from "react-redux";
import { setIsUserWebsiteLoggedIn } from "dataflow/slices/website.slice";

function Success(props) {
  const { orderId } = props;

  let history = useHistory();
  const dispatch = useDispatch();

  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const [isAuthLogin, setIsAuthLogin] = useState(true);

  const { isUserWebsiteLogin } = useCustomSelector("website");

  const handleClick = () => {
    isUserWebsiteLogin
      ? history.push({
          pathname: "/akaun-saya",
          state: { selection: "card", selectionOrder: "complete" },
        })
      : setIsAuthModalVisible(true);
  };

  return (
    <div className="success  ">
      {/* Login/Sign Up Modals */}
      <UserAuthenticationModal
        isVisible={isAuthModalVisible}
        isLogin={isAuthLogin}
        setIsLogin={setIsAuthLogin}
        onHide={() => {
          setIsAuthModalVisible(false);
        }}
        onLoginSuccessful={() => {
          dispatch(setIsUserWebsiteLoggedIn(isUserLoggedIn()));
        }}
      />

      <div
        className={
          "success__body container d-flex flex-column align-items-center justify-content-center"
        }
      >
        <div className="success-icons  d-flex flex-column align-items-center justify-content-center">
          <i className={"far fa-check-circle success__body-icon icons"}></i>
          <h3 className={"success__body-headers text-center"}>
            Pembayaran Anda Berjaya!
          </h3>
        </div>

        <div className="suceess-details">
          <div
            className={
              "success__body-content  d-flex flex-column align-items-center justify-content-center"
            }
          >
            {/* <div className={"success__body-content-1 text-left"}>
              <p>Terima kasih kerana memilih EkadTepi.</p>
              <p>
                Kami mengucapkan tahniah kepada anda dan pasangan. Semoga majlis
                yang dinantikan berjalan dengan baik.
              </p>
            </div> */}

            <div className={"success__body-content-2 text-center"}>
              <div className="details-order2">
                <div className="first-id">Number Id: {orderId}</div>
                <div className="second-id">Jumlah Pembayaran: RM60.00</div>
                <div className="second-id">Cara Pembayaran: Online Banking</div>
                <div className="third-id">Emel: support@ekadtepi.com</div>
              </div>
            </div>
          </div>
        </div>
        {/* Go to account button */}
        <div className={"w-100 d-flex justify-content-center"}>
          <Button
            className={"receipt__account-button"}
            content={"Kembali ke akaun"}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

export default Success;
