import React from "react";

import "assets/scss/components/buttons/button.scss";

export function Button(props) {
  const {
    className,
    content,
    type,

    decoration_isBrightPrimary,
    decoration_isBrightInversePrimary,

    decoration_isDarkPrimary,
    decoration_isDarkInversePrimary,

    decoration_isSecondary,
    decoration_isInverseSecondary,

    decoration_unimportant,
    decoration_delete,
    decorationSuccess,

    decorationType,

    isDisabled,

    // Functions
    onClick = () => {},
  } = props;

  let decorationClassName = "";
  switch (decorationType) {
    case "transparentLightBg":
      decorationClassName = "component-button_transparent-light-bg";
      break;
    default: 
      decorationClassName = ""
  }

  return (
    <button
      type={type}
      className={
        "component-button " +
        "d-flex justify-content-center align-items-center " +
        (className ? className + " " : "") +
        (decoration_isBrightPrimary ? "component-button_bright-primary " : "") +
        (decoration_isBrightInversePrimary
          ? "component-button_bright-inverse-primary "
          : "") +
        (decoration_isDarkPrimary ? "component-button_dark-primary " : "") +
        (decoration_isDarkInversePrimary
          ? "component-button_dark-inverse-primary "
          : "") +
        (decoration_isSecondary ? "component-button_secondary " : "") +
        (decoration_isInverseSecondary
          ? "component-button_inverse-secondary "
          : "") +
        (decoration_unimportant ? "component-button_unimportant " : "") +
        (decoration_delete ? "component-button-delete " : "") +
        (decorationClassName ? decorationClassName + " " : "") +
        (decorationSuccess ? "component-button_success "  : "") +
        (isDisabled ? "component-button_disabled " : "")
      }
      onClick={onClick}
      disabled={isDisabled}
    >
      {content}
    </button>
  );
}
