import React from 'react';
import "assets/scss/components/contentExpander/inputContentExpander.scss";
import ContentExpander from './ContentExpander';
import { deepCloneArray, getArrayLength, isArrayEmpty, isArrayNotEmpty, validateObject, validateFunction } from 'helper/validations';
import { Dropdown } from 'components/Dropdown';
import { Input } from 'components/formInputs/Input';
import ColorPicker from 'components/inputs/ColorPicker';
import ic_delete from "assets/images/ic_delete.png";
import ic_add_more from "assets/images/ic_add_more.png";
import { DatePicker } from 'components/inputs/DatePicker';
import { TelInput } from 'components/inputs/TelInput';
import AlertModal from 'components/notifications/AlertModal';
import SliderInput from 'components/inputs/SliderInput';
import SwitchInput from 'components/inputs/SwitchInput';


export default function InputContentExpander({
    mainDisplayText, themeType,
    contentInputs,
    isExpanded,

	otherProps: otherProps__props,

    // Functions
    onMainDisplayClick: onMainDisplayClick_props, 
	onContentReset: onContentReset_props
}) {
	// Validate 
	let onMainDisplayClick = validateFunction(onMainDisplayClick_props);
	let onContentReset = validateFunction(onContentReset_props);
	let otherProps = validateObject(otherProps__props);

	function getInputDisplay(details, idx) {
		const {
			className, labelClassName, inputWrapperClassName, inputClassName,
			header, label, defaultValue, value, valueStyle,
			options, id="",

			isBottomInputRelated,
			
			onChange, onSelect, onLoadMenuItemData,
			inputProps: inputProps_props
		} = details;

		let inputProps = validateObject(inputProps_props);

		// types of inputs
		let input;
		switch (details?.type) {
			case "text":
				input = (
					<Input
						className={"item__text-input " + 
							(className? className + " ":"") +
							(isBottomInputRelated? "item__text-input_less-bottom-padding ":"")}
						labelClassName={"text-input__label " +
							(labelClassName? labelClassName + " ":"")}

						inputClassName={"text-input__input " +
							(inputClassName? inputClassName + " ":"")}
						
						label={label}
						value={value}
						onChange={onChange}

						isMarginBottomOptional isLabelAtTheSide

						{...inputProps}
					/>
				);
				break;
			case "dropdown":
				input = (
					<Dropdown
						className={"item__dropdown-input " +
							(className? className + " ":"") +
							(isBottomInputRelated? "item__dropdown-input_less-bottom-padding ":"")}
						labelClassName={"dropdown-input__label " +
							(labelClassName? labelClassName + " ":"")}
						wrapperClassName={"dropdown-input__input-wrapper " +
							(inputWrapperClassName? inputWrapperClassName + " ":"")}
						toggleClassName={"input-wrapper__toggler " +
							(inputClassName? inputClassName + " ":"")}

						toggleText={(
							<div style={valueStyle}>
								{value}
							</div>
						)}

						themeType={"light"}
						label={label}
						onLoadMenuItemData={onLoadMenuItemData}

						options={isArrayNotEmpty(options) && options.map((obj) => {
							return {
								...obj,
								style: { fontFamily: obj?.value },
								onClick: onSelect
							}
						})}
						isLabelOnLeftSide
						isArrowVisible
					/>
				);
				break;
			case "colorPicker":
				input = (
					<ColorPicker
						className={"item__color-picker " +
							(className? className + " ":"") +
							(isBottomInputRelated? "item__color-picker_less-bottom-padding ":"")}
						triggerWrapperClassName={"color-picker__trigger " +
							(inputClassName? inputClassName + " ":"")}

						defaultColor={defaultValue}
						color={value}

						label={label}
						onColorChange={onChange}
						isLabelOnLeftSide
					/>
				);
				break;
			case "datePicker":
				input = (
					<DatePicker
						className={"item__date-picker " +
							(className? className + " ":"") +
							(isBottomInputRelated? "item__date-picker_less-bottom-padding ":"")}
						labelClassName={"date-picker__label " +
							(labelClassName? labelClassName + " ":"")}
						inputClassName={"date-picker__input " +
							(inputClassName? inputClassName + " ":"")}

						label={label}
						dateFromParent={value}
						onChange={onChange}

						inputProps={{
							showTimeSelect: true,
							showTimeSelectOnly: true,
							timeIntervals: 5,
							timeCaption: "Time",
							dateFormat: "h:mm aa",
							...inputProps,
						}}
					/>
				);
				break;
			case "telInput":
				input = (
					<TelInput
						className={"item__phone-no d-flex flex-column " +
							(className? className + " ":"") +
							(isBottomInputRelated? "item__phone-no_less-bottom-padding ":"")}
						labelClassName={"phone-no__label " +
							(labelClassName? labelClassName + " ":"")}
						inputClassName={"phone-no__input " +
							(inputClassName? inputClassName + " ":"")}

						label={label}
						value={value}
						onChange={onChange}
						{...inputProps}
					/>
				);
				break;
			case "slider":
				input = (
					<SliderInput 
						className={"item__slider-input " + 
						(className? className + " ":"") +
						(isBottomInputRelated? "item__slider-input_less-bottom-padding ":"")}
						labelClassName={"slider-input__label " +
							(labelClassName? labelClassName + " ":"")}

						inputClassName={"slider-input__input " +
							(inputClassName? inputClassName + " ":"")}
						
						label={label}
						id={"opacity-slider"}

						defaultValue={defaultValue}
						value={value}
						onChange={onChange}

						{...inputProps}
					/>
				);
				break;
			case "switch":
			input = (
				<SwitchInput 
					className={"item__switch-input " + 
					(className? className + " ":"") +
					(isBottomInputRelated? "item__switch-input_less-bottom-padding ":"")}
					labelClassName={"switch-input__label " +
						(labelClassName? labelClassName + " ":"")}

					inputClassName={"switch-input__input " +
						(inputClassName? inputClassName + " ":"")}
					
					label={label}
					id={id}

					defaultValue={defaultValue}
					value={value}
					onChange={onChange}

					{...inputProps}
				/>
			);
			break;
			default:
				break;
		}

		return (
			<div key={idx} className={"input-wrapper__item"}>
				{/* Header */}
				{header && (
					<div className={"item__header"}>
						{header}
					</div>
				)}
				
				{/* Input */}
				{input}
			</div>
		)
	}

    return (
        <ContentExpander
            className={"component-input-content-expander"}
            mainDisplayTextClassName={"component-input-content-expander__main-display"}
            mainDisplayText={(
				<div className={"main-display__text-wrapper"}>
					{/* Text */}
					<span>{mainDisplayText}</span>

					{/* Reset */}
					<i className="fas fa-undo-alt"
						style={{ cursor: "pointer" }}
						onClick={(e)=> {
							e.stopPropagation();

							AlertModal({
								title: "Kembali ke Asal",
								text: "Anda mahu set segala input di seksyen ini kepada asal?",
								icon: "warning",
								confirmButtonText: "Teruskan",
								showCancelButton: true,
								cancelButtonText: "Batalkan",
							  })
							  .then((result) => {
								if (result.isConfirmed){
									onContentReset();
								}
							  });
						}}
					/>
				</div>
			)}
            themeType={themeType}

            iconClassName={"component-input-content-expander__expander-icon"}

            onClick={onMainDisplayClick}
            isExpanded={isExpanded}

            expanderContentClassName={"component-input-content-expander__content-inputs "}
            expandedContent={isArrayNotEmpty(contentInputs)? contentInputs.map((details, idx) => {
                const {
					className,

					inputs: inputs_props,
					list,
					
					onSectionDelete: onSectionDelete_props, 
					onSectionAdd: onSectionAdd_props,
					isDeleteIconVisible, isAddIconVisible
                } = details;

				// validation
				let onSectionDelete = validateFunction(onSectionDelete_props);
				let onSectionAdd = validateFunction(onSectionAdd_props);

				let inputs = [];
				if (isArrayNotEmpty(inputs_props)) {
					inputs = inputs_props.map((details, idx) => {
						return getInputDisplay(details, idx);
					});
				}

				return (
					<div key={idx} className={"content-details__outer-wrapper " +
							(className? className + " ":"")}>
						{/* Delete */}
						{isDeleteIconVisible && (getArrayLength(list) > 1) && (
							<div className={"outer-wrapper__delete-section"}>
								<img 
									className={"delete-section__icon"}
									src={ic_delete}
									onClick={() => {
										let newList = deepCloneArray(list);
										newList.splice(idx, 1);
										onSectionDelete(newList);
									}}
								/>
							</div>
						)}

						{/* Inputs */}
						<div className={"outer-wrapper__input-wrapper"}>
							{inputs}
						</div>

						{/* Add */}
						{isAddIconVisible && (
							<div className={"outer-wrapper__add-section"}>
								<img 
									className={"add-section__icon"}
									src={ic_add_more}
									onClick={() => {
										let newList = deepCloneArray(list);
										console.log(newList)
										if (isArrayEmpty(newList)) {
											newList.push({});
											newList.push({});
										}
										else newList.push({});
										
										onSectionAdd(newList);
									}}
								/>
							</div>
						)}

						{/* Divider */}
						<div className={"outer-wrapper__horizontal-line"} />
					</div>
				)
            }):""}

			{...otherProps}
        />
    );
}