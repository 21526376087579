import React from "react";
import { useCustomSelector } from "hooks";

function MapContainer({ 
  lat, long, place,

  mapContainerStyle, iconStyles, iconContainerStyle, iconFontStyles
}) {
  console.log(place)
  return (
    <div className="mapContainer">
      <div className="frameDiv">
        <iframe
          frameBorder="0"
          // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDK4eQD9zKKGQvGZ7_J7TVYMIt0wZ4LQeM
          //               &q=${lat},${long}`}
          // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDK4eQD9zKKGQvGZ7_J7TVYMIt0wZ4LQeM
          // &q=${place?.replace(" ", "+")}`}
          src={"https://www.google.com/maps?q=" + encodeURI(place) + "&output=embed"}
          allowFullScreen
          style={mapContainerStyle}
        ></iframe>
      </div>

      <div className="location__menu m-auto" style={iconContainerStyle}>
        <div className="lokasi__icon m-auto">
          <a
            // href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${long}&travelmode=driving&dir_action=navigate`}
            href={`https://www.google.com/maps/dir/?api=1&destination=${place?.replace(" ", "+")}&travelmode=driving&dir_action=navigate`}
            target="_blank"
          >
            <i className="fas fa-map-marked-alt gmap" style={iconStyles}></i>
          </a>
          <p className="d" style={iconFontStyles}>
            GMaps
          </p>
        </div>

        <div className="lokasi__icon m-auto">
          <a
            // href={`https://ul.waze.com/ul?ll=${lat}%2C${long}&navigate=yes`}
            href={`https://ul.waze.com/ul?q=${place?.replace(" ", "%20")}&navigate=yes`}
            target="_blank"
          >
            <i className="fab fa-waze waze" style={iconStyles}></i>
          </a>
          <p className="d" style={iconFontStyles}>
            Waze
          </p>
        </div>

        <div className="lokasi__icon m-auto">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=Attractions+${place?.replace(" ", "+")}`}
            target="_blank"
          >
            <i className="fas fa-map-pin explore" style={iconStyles}></i>
          </a>
          <p className="d" style={iconFontStyles}>
            Tarikan
          </p>
        </div>

        <div className="lokasi__icon m-auto">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=Hotels+${place?.replace(" ", "+")}`}
            target="_blank"
          >
            <i className="fas fa-hotel hotel" style={iconStyles}></i>
          </a>
          <p className="d" style={iconFontStyles}>
            Hotel
          </p>
        </div>
      </div>
    </div>
  );
}

export default MapContainer;
