import React from "react";

import "assets/scss/components/ecard/contactApp.scss";

function ContactApp({
  name, phoneNo, relationship, 
  borderStyle, nameStyle, relationshipStyle 
}) {
  return (
    <div className="person m-auto" style={borderStyle}>
      <div className="contact__name">
        <p className="nama__penyambut" style={nameStyle}>
          {name}
        </p>
        <p className="hubungan__penyambut" style={relationshipStyle}>
          {relationship}
        </p>
      </div>
      <div className="person__icon">
        <a className="ws" href={`https://wa.me/${phoneNo}`} target="_blank">
          <div className="icon_contact green">
            <i className="fab fa-whatsapp ws"></i>
          </div>
        </a>
        <a className="phone" href={`tel:+${phoneNo}`}>
          <div className="icon_contact blue">
            <i className="fas fa-phone phone"></i>
          </div>
        </a>
      </div>
    </div>
  );
}

export default ContactApp;
