import React, { useEffect, useState } from 'react';

import { hexToFilterCSS, validateFunction } from 'helper/validations';
import DetailsDisplay from 'modules/ECard/sections/details/DetailsDisplay';
import InputContentExpander from 'components/contentExpander/InputContentExpander';
import { useCustomSelector } from 'hooks';


export default function useEventStep({
    fontList, loadFont,

    isResetRequired, currentStep,
    content_pageTitleDetails,

    event_init_details, event_init_textDetails,

    event_details = {}, setEvent_details: setEvent_details_props,
    event_textDetails = {}, setEvent_textDetails: setEvent_textDetails_props,

    location_locationDetails, setLocation_locationDetails: setLocation_locationDetails_props,
    cover_details, setCover_details: setCover_details_props,
}) {
    // Validations
    let setEvent_details = validateFunction(setEvent_details_props);
    let setEvent_textDetails = validateFunction(setEvent_textDetails_props);
    let setLocation_locationDetails = validateFunction(setLocation_locationDetails_props);
    let setCover_details = validateFunction(setCover_details_props);

    const [currentVisibleContentPos, setCurrentVisibleContentPos] = useState();
    const { ecardEditDetails } = useCustomSelector("app");

    function reset() {
        setEvent_details(event_init_details);
        setEvent_textDetails(event_init_textDetails);
    }
    useEffect(() => {
        if (currentStep == "eventDetails" && isResetRequired) reset();
    }, [isResetRequired]);

    let input_eventDetailsStep = (
        <div className={"inputs__event-step"}>
            {/* Info */}
            <InputContentExpander
                mainDisplayText={"Maklumat Butiran"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 0) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(0);
                }}
                isExpanded={currentVisibleContentPos === 0}

                themeType={"darkGrey"}

                onContentReset={() => setEvent_details(event_init_details)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                header: "Nama Tuan Rumah",
                                type: "text",
                                label: "Nama 1:",
                                value: event_details?.celebrator1,
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    setEvent_details({
                                        ...event_details,
                                        celebrator1: value
                                    })
                                },
                            },
                            {
                                type: "text",
                                label: "Nama 2:",
                                value: event_details?.celebrator2,
                                onChange: (value) => {
                                    setEvent_details({
                                        ...event_details,
                                        celebrator2: value
                                    })
                                },
                            },
                            // {
                            //     header: "Nama jemputan",
                            //     type: "text",
                            //     label: "Nama:",
                            //     value: event_details?.invitee,
                            //     onChange: (value) => {
                            //         setEvent_details({
                            //             ...event_details,
                            //             invitee: value
                            //         })
                            //     },
                            // },
                            {
                                header: "Nama Pengantin",
                                type: "text",
                                label: "Nama 1:",
                                value: event_details?.couple1,
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    setEvent_details({
                                        ...event_details,
                                        couple1: value
                                    })
                                },
                            },
                            {
                                type: "text",
                                label: "Nama 2:",
                                value: event_details?.couple2,
                                onChange: (value) => {
                                    setEvent_details({
                                        ...event_details,
                                        couple2: value
                                    })
                                },
                            },
                            {
                                type: "datePicker",
                                label: "Tarikh:",
                                defaultValue: event_details?.eventDate,
                                value: event_details?.eventDate,
                                onChange: (value) => {
                                    setEvent_details({
                                        ...event_details,
                                        eventDate: value
                                    })

                                    // auto fill in cover step
                                    setCover_details({
                                        ...cover_details,
                                        date: value
                                    })
                                },
                                inputProps: {
                                    showTimeSelectOnly: false,
                                    showTimeSelect: false,
                                    dateFormat: "MMM dd, yyyy"
                                }
                            },
                            {
                                type: "text",
                                label: "Tempat:",
                                value: event_details?.eventLocation,
                                onChange: (value) => {
                                    setEvent_details({
                                        ...event_details,
                                        eventLocation: value
                                    });

                                    // auto fill in location step
                                    // setLocation_locationDetails({
                                    //     ...location_locationDetails,
                                    //     eventLocation: value
                                    // })
                                },
                            },
                            {
                                type: "text",
                                label: "Doa:",
                                value: event_details?.prayer,
                                onChange: (value) => {
                                    setEvent_details({
                                        ...event_details,
                                        prayer: value
                                    })
                                },
                            },
                        ]
                    }
                ]}
            />

            {/* Opacity Styles */}
            <InputContentExpander
                mainDisplayText={"Latar Belakang"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 1) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(1);
                }}
                isExpanded={currentVisibleContentPos === 1}

                themeType={"blue"}

                onContentReset={() => setEvent_textDetails(event_init_textDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "slider",
                                label: "Opacity:",
                                defaultValue: event_textDetails?.detailsBgOpacity * 100,
                                value: event_textDetails?.detailsBgOpacity * 100,
                                onChange: (event) => {
                                    console.log(event.target.value / 100)
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        detailsBgOpacity: event.target.value / 100
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />

            {/* Font Styles */}
            <InputContentExpander
                mainDisplayText={"Tulisan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 2) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(2);
                }}
                isExpanded={currentVisibleContentPos === 2}

                themeType={"blue"}

                onContentReset={() => setEvent_textDetails(event_init_textDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                header: "Nama Tuan Rumah",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: event_textDetails?.celebratorFont },
                                value: event_textDetails?.celebratorFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        celebratorFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: event_textDetails?.celebratorFontColor,
                                value: event_textDetails?.celebratorFontColor,
                                onChange: (color) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        celebratorFontColor: color
                                    });
                                }
                            },

                            // {
                            //     header: "Nama jemputan",
                            //     type: "dropdown",
                            //     label: "Fon:",
                            //     valueStyle: { fontFamily: event_textDetails?.inviteeFont },
                            //     value: event_textDetails?.inviteeFont,
                            //     isBottomInputRelated: true,
                            //     onSelect: (value) => {
                            //         setEvent_textDetails({
                            //             ...event_textDetails,
                            //             inviteeFont: value
                            //         });
                            //     },
                            //     onLoadMenuItemData: loadFont,
                            //     options: fontList
                            // },
                            // {
                            //     type: "colorPicker",
                            //     label: "Warna Fon:",
                            //     defaultValue: event_textDetails?.inviteeFontColor,
                            //     value: event_textDetails?.inviteeFontColor,
                            //     onChange: (color) => {
                            //         setEvent_textDetails({
                            //             ...event_textDetails,
                            //             inviteeFontColor: color
                            //         });
                            //     }
                            // },

                            {
                                header: "Nama Pengantin",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: event_textDetails?.coupleFont },
                                value: event_textDetails?.coupleFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        coupleFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: event_textDetails?.coupleFontColor,
                                value: event_textDetails?.coupleFontColor,
                                onChange: (color) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        coupleFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Tarikh",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: event_textDetails?.dateFont },
                                value: event_textDetails?.dateFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        dateFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: event_textDetails?.dateFontColor,
                                value: event_textDetails?.dateFontColor,
                                onChange: (color) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        dateFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Lokasi",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: event_textDetails?.locationFont },
                                value: event_textDetails?.locationFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        locationFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: event_textDetails?.locationFontColor,
                                value: event_textDetails?.locationFontColor,
                                onChange: (color) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        locationFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Doa",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: event_textDetails?.prayerFont },
                                value: event_textDetails?.prayerFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        prayerFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: event_textDetails?.prayerFontColor,
                                value: event_textDetails?.prayerFontColor,
                                onChange: (color) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        prayerFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Tulisan lain",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: event_textDetails?.otherTextFont },
                                value: event_textDetails?.otherTextFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        otherTextFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: event_textDetails?.otherTextFontColor,
                                value: event_textDetails?.otherTextFontColor,
                                onChange: (color) => {
                                    setEvent_textDetails({
                                        ...event_textDetails,
                                        otherTextFontColor: color
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />
        </div>
    );

    let preview_eventDetailsStep = (
        <DetailsDisplay
            bgImg={ecardEditDetails?.themes?.backgroundOptimized}
            fallbackImg={ecardEditDetails?.themes?.background}
            bgOverlayStyle={{
                background: ecardEditDetails?.themes?.backgroundColor2,
                borderColor: ecardEditDetails?.themes?.border && ecardEditDetails?.themes?.contentColor1
            }}
            opacityStyle={{opacity: event_textDetails?.detailsBgOpacity}}

            titleStyle={{
                fontFamily: content_pageTitleDetails?.font,
                color: content_pageTitleDetails?.fontColor
            }}

            // Other text
            textStyle={{
                fontFamily: event_textDetails?.otherTextFont,
                color: event_textDetails?.otherTextFontColor
            }}

            name1={event_details?.celebrator1}
            name2={event_details?.celebrator2}
            celebratorStyle={{
                fontFamily: event_textDetails?.celebratorFont,
                color: event_textDetails?.celebratorFontColor
            }}

            invitee={event_details?.invitee}
            inviteeStyle={{
                fontFamily: event_textDetails?.inviteeFont,
                color: event_textDetails?.inviteeFontColor
            }}

            spouse1={event_details?.couple1}
            spouse2={event_details?.couple2}
            spouseStyle={{
                fontFamily: event_textDetails?.coupleFont,
                color: event_textDetails?.coupleFontColor
            }}

            date1={event_details?.eventDate}
            date2={event_details?.eventDate}
            dateStyle={{
                fontFamily: event_textDetails?.dateFont,
                color: event_textDetails?.dateFontColor,
            }}

            place={event_details?.eventLocation}
            placeStyle={{
                fontFamily: event_textDetails?.locationFont,
                color: event_textDetails?.locationFontColor,
            }}

            prayerText={event_details.prayer}
            prayerStyle={{
                fontFamily: event_textDetails?.prayerFont,
                color: event_textDetails?.prayerFontColor
            }}
        />
    );

    return {
        input_eventDetailsStep, preview_eventDetailsStep
    }
}