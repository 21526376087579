import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {},
  reducers: {
    getcardDesignPreviewList_requesting: (state) => {
      state.getcardDesignPreviewList_requesting = true;
      state.getcardDesignPreviewList_status = false;
      state.getcardDesignPreviewList_errorMsg = undefined;
    },
    getcardDesignPreviewList_success: (state, action) => {
      state.getcardDesignPreviewList_requesting = false;
      state.getcardDesignPreviewList_status = true;
      // state.cardDesignPreviewList = action.payload.data;

      let themes = [
        {
          typeId: "3",
          type: "simple",
          title: "Simple 1",
          description: "abstract and simple design",
          theme: {
            thumbnail:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fsimple%2Foptimize-simple%201%20OP.png?alt=media&token=4440577a-3433-4508-bf57-360735d96dfb",
            background:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fsimple%2Foptimize-simple%201%20OP.png?alt=media&token=4440577a-3433-4508-bf57-360735d96dfb",
            backgroundOptimized:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fsimple%2Fthumbnails-simple%201.webp?alt=media&token=4f0d369f-3120-4048-a160-b58ce1c00be6",
            backgroundColor1: "rgba(0, 0, 0, 0)",
            backgroundColor2: "rgba(255, 255, 255, 0.7)",
            border: true,
            homeFontFamily1: "Tangerine",
            homeFontFamily2: "Vollkorn SC",
            homeFontFamily3: "Vollkorn SC",
            homeFontFamily4: "Vollkorn SC",
            homeFontFamily5: "Voces",
            contentFontFamily1: "Poppins",
            contentFontFamily2: "Poppins",
            contentFontFamily3: "Poppins",
            contactFontFamily1: "Poppins",
            detailsFontFamily1: "Poppins",
            detailsFontFamily2: "Poppins",
            detailsFontFamily3: "Poppins",
            detailsFontFamily4: "Poppins",
            tentativeFontFamily1: "Poppins",
            tentativeFontFamily2: "Poppins",
            locationFontFamily1: "Poppins",
            rsvpFontFamily1: "Poppins",
            rsvpFontFamily2: "Poppins",
            rsvpFontFamily3: "Poppins",
            rsvpFontFamily4: "Poppins",
            wishFontFamily1: "Poppins",
            wishFontFamily2: "Poppins",
            wishFontFamily3: "Poppins",
            homeColor1: "#3C3638",
            homeColor2: "#3C3638",
            homeColor3: "#3C3638",
            homeColor4: "#3C3638",
            homeColor5: "#3C3638",
            contentColor1: "#3C3638",
            contentColor2: "#3C3638",
            contentColor3: "#3C3638",
            contentColor4: "#3C3638",
            contentColor5: "#3C3638",
            contactColor1: "#3C3638",
            contactColor2: "#3C3638",
            contactColor3: "#3C3638",
            detailsColor1: "#3C3638",
            detailsColor2: "#3C3638",
            detailsColor3: "#3C3638",
            detailsColor4: "#3C3638",
            tentativeColor1: "#3C3638",
            tentativeColor2: "#3C3638",
            locationColor1: "#3C3638",
            locationColor2: "#3C3638",
            rsvpColor1: "#3C3638",
            rsvpColor2: "#3C3638",
            rsvpColor3: "#3C3638",
            rsvpColor4: "#3C3638",
            wishColor1: "#3C3638",
            wishColor2: "#3C3638",
            wishColor3: "#3C3638",
            contactFontFamily2: "Poppins",
            contactColor4: "#3C3638",
            locationColor3: "#3C3638",
            locationColor4: "rgba(255, 255, 255, 0.9)",
            rsvpColor5: "#3C3638",
            detailsColor5: "#3C3638",
            detailsFontFamily5: "Poppins",
          },
        },
        {
          typeId: "4",
          type: "classic",
          title: "Klasik 1",
          description: "classic and retro design",
          theme: {
            thumbnail:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fvintage%2Foptimize-vintage%201%20OP.png?alt=media&token=f7a5aee3-5dce-414a-959a-5b6e8631cd10",
            background:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fvintage%2Foptimize-vintage%201%20OP.png?alt=media&token=f7a5aee3-5dce-414a-959a-5b6e8631cd10",
            backgroundOptimized:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fvintage%2Fthumbnails-vintage%201.webp?alt=media&token=e6bfcb55-2bb3-4035-900c-c39d9e5a0a0f",
            backgroundColor1: "rgba(0, 0, 0, 0)",
            backgroundColor2: "rgba(0, 0, 0, 0)",
            border: false,
            homeFontFamily1: "Tangerine",
            homeFontFamily2: "Vollkorn SC",
            homeFontFamily3: "Vollkorn SC",
            homeFontFamily4: "Vollkorn SC",
            homeFontFamily5: "Voces",
            contentFontFamily1: "Poppins",
            contentFontFamily2: "Poppins",
            contentFontFamily3: "Poppins",
            contactFontFamily1: "Poppins",
            detailsFontFamily1: "Poppins",
            detailsFontFamily2: "Poppins",
            detailsFontFamily3: "Poppins",
            detailsFontFamily4: "Poppins",
            tentativeFontFamily1: "Poppins",
            tentativeFontFamily2: "Poppins",
            locationFontFamily1: "Poppins",
            rsvpFontFamily1: "Poppins",
            rsvpFontFamily2: "Poppins",
            rsvpFontFamily3: "Poppins",
            rsvpFontFamily4: "Poppins",
            wishFontFamily1: "Poppins",
            wishFontFamily2: "Poppins",
            wishFontFamily3: "Poppins",
            homeColor1: "#F3F3F3",
            homeColor2: "#F3F3F3",
            homeColor3: "#F3F3F3",
            homeColor4: "#F3F3F3",
            homeColor5: "#F3F3F3",
            contentColor1: "#F3F3F3",
            contentColor2: "#F3F3F3",
            contentColor3: "#F3F3F3",
            contentColor4: "#F3F3F3",
            contentColor5: "#F3F3F3",
            contactColor1: "#F3F3F3",
            contactColor2: "#F3F3F3",
            contactColor3: "#F3F3F3",
            detailsColor1: "#F3F3F3",
            detailsColor2: "#F3F3F3",
            detailsColor3: "#F3F3F3",
            detailsColor4: "#F3F3F3",
            tentativeColor1: "#F3F3F3",
            tentativeColor2: "#F3F3F3",
            locationColor1: "#F3F3F3",
            locationColor2: "#F3F3F3",
            rsvpColor1: "#F3F3F3",
            rsvpColor2: "#F3F3F3",
            rsvpColor3: "#F3F3F3",
            rsvpColor4: "#F3F3F3",
            wishColor1: "#F3F3F3",
            wishColor2: "#F3F3F3",
            wishColor3: "#F3F3F3",
            contactFontFamily2: "Poppins",
            contactColor4: "#F3F3F3",
            locationColor3: "#F3F3F3",
            locationColor4: "rgba(255, 255, 255, 0.9)",
            rsvpColor5: "#F3F3F3",
            detailsColor5: "#F3F3F3",
            detailsFontFamily5: "Poppins",
          },
        },
        {
          typeId: "2",
          type: "floral",
          title: "Floral 11",
          description: "flowers and petals",
          theme: {
            thumbnail:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Ffloral%2Foptimize-floral%2011%20OP.png?alt=media&token=ded365d8-5cce-46ec-90a8-49143d3ef715",
            background:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Ffloral%2Foptimize-floral%2011%20OP.png?alt=media&token=ded365d8-5cce-46ec-90a8-49143d3ef715",
            backgroundOptimized:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Ffloral%2Fthumbnails-floral%2011.webp?alt=media&token=98f77eec-02ed-4db4-9f9c-8e018b100fbb",
            backgroundColor1: "rgba(0, 0, 0, 0)",
            backgroundColor2: "rgba(255, 255, 255, 0.7)",
            border: true,
            homeFontFamily1: "Tangerine",
            homeFontFamily2: "Vollkorn SC",
            homeFontFamily3: "Vollkorn SC",
            homeFontFamily4: "Vollkorn SC",
            homeFontFamily5: "Voces",
            contentFontFamily1: "Poppins",
            contentFontFamily2: "Poppins",
            contentFontFamily3: "Poppins",
            contactFontFamily1: "Poppins",
            detailsFontFamily1: "Poppins",
            detailsFontFamily2: "Poppins",
            detailsFontFamily3: "Poppins",
            detailsFontFamily4: "Poppins",
            tentativeFontFamily1: "Poppins",
            tentativeFontFamily2: "Poppins",
            locationFontFamily1: "Poppins",
            rsvpFontFamily1: "Poppins",
            rsvpFontFamily2: "Poppins",
            rsvpFontFamily3: "Poppins",
            rsvpFontFamily4: "Poppins",
            wishFontFamily1: "Poppins",
            wishFontFamily2: "Poppins",
            wishFontFamily3: "Poppins",
            homeColor1: "#D16261",
            homeColor2: "#D16261",
            homeColor3: "#D16261",
            homeColor4: "#D16261",
            homeColor5: "#D16261",
            contentColor1: "#D16261",
            contentColor2: "#D16261",
            contentColor3: "#D16261",
            contentColor4: "#D16261",
            contentColor5: "#D16261",
            contactColor1: "#D16261",
            contactColor2: "#D16261",
            contactColor3: "#D16261",
            detailsColor1: "#D16261",
            detailsColor2: "#D16261",
            detailsColor3: "#D16261",
            detailsColor4: "#D16261",
            tentativeColor1: "#D16261",
            tentativeColor2: "#D16261",
            locationColor1: "#D16261",
            locationColor2: "#D16261",
            rsvpColor1: "#D16261",
            rsvpColor2: "#D16261",
            rsvpColor3: "#D16261",
            rsvpColor4: "#D16261",
            wishColor1: "#D16261",
            wishColor2: "#D16261",
            wishColor3: "#D16261",
            contactFontFamily2: "Poppins",
            contactColor4: "#D16261",
            locationColor3: "#D16261",
            locationColor4: "rgba(255, 255, 255, 0.9)",
            rsvpColor5: "#D16261",
            detailsColor5: "#D16261",
            detailsFontFamily5: "Poppins",
          },
        },
        {
          typeId: "1",
          type: "islamic",
          title: "Islamik 1",
          description: "cultural and religious decoration",
          theme: {
            thumbnail:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fislamic%2Foptimize-islamic%201%20OP.png?alt=media&token=bbd87e0c-735f-4cec-81c7-2145e9016435",
            background:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fislamic%2Foptimize-islamic%201%20OP.png?alt=media&token=bbd87e0c-735f-4cec-81c7-2145e9016435",
            backgroundOptimized:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fislamic%2Fthumbnails-islamic%201.webp?alt=media&token=b6350db7-6a4f-42ed-968b-98891a820cb7",
            backgroundColor1: "rgba(0, 0, 0, 0)",
            backgroundColor2: "rgba(0, 0, 0, 0)",
            border: false,
            homeFontFamily1: "Tangerine",
            homeFontFamily2: "Vollkorn SC",
            homeFontFamily3: "Vollkorn SC",
            homeFontFamily4: "Vollkorn SC",
            homeFontFamily5: "Voces",
            contentFontFamily1: "Poppins",
            contentFontFamily2: "Poppins",
            contentFontFamily3: "Poppins",
            contactFontFamily1: "Poppins",
            detailsFontFamily1: "Poppins",
            detailsFontFamily2: "Poppins",
            detailsFontFamily3: "Poppins",
            detailsFontFamily4: "Poppins",
            tentativeFontFamily1: "Poppins",
            tentativeFontFamily2: "Poppins",
            locationFontFamily1: "Poppins",
            rsvpFontFamily1: "Poppins",
            rsvpFontFamily2: "Poppins",
            rsvpFontFamily3: "Poppins",
            rsvpFontFamily4: "Poppins",
            wishFontFamily1: "Poppins",
            wishFontFamily2: "Poppins",
            wishFontFamily3: "Poppins",
            homeColor1: "#DAA520",
            homeColor2: "#DAA520",
            homeColor3: "#DAA520",
            homeColor4: "#DAA520",
            homeColor5: "#DAA520",
            contentColor1: "#DAA520",
            contentColor2: "#DAA520",
            contentColor3: "#DAA520",
            contentColor4: "#DAA520",
            contentColor5: "#DAA520",
            contactColor1: "#DAA520",
            contactColor2: "#DAA520",
            contactColor3: "#DAA520",
            detailsColor1: "#DAA520",
            detailsColor2: "#DAA520",
            detailsColor3: "#DAA520",
            detailsColor4: "#DAA520",
            tentativeColor1: "#DAA520",
            tentativeColor2: "#DAA520",
            locationColor1: "#DAA520",
            locationColor2: "#DAA520",
            rsvpColor1: "#DAA520",
            rsvpColor2: "#DAA520",
            rsvpColor3: "#DAA520",
            rsvpColor4: "#DAA520",
            wishColor1: "#DAA520",
            wishColor2: "#DAA520",
            wishColor3: "#DAA520",
            contactFontFamily2: "Poppins",
            contactColor4: "#DAA520",
            locationColor3: "#DAA520",
            locationColor4: "rgba(255, 255, 255, 0.9)",
            rsvpColor5: "#DAA520",
            detailsColor5: "#DAA520",
            detailsFontFamily5: "Poppins",
          },
        },
        {
          typeId: "5",
          type: "creation",
          title: "Karya 6",
          description: "night sky",
          theme: {
            backgroundOptimized:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fkarya%2F12-8-22%20Creation%20(6).webp?alt=media&token=261035d6-1f08-4cf9-b6a2-fb872adcb496",
            wishFontFamily3: "Poppins",
            rsvpFontFamily2: "Amarante",
            background:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fkarya%2F12-8-22%20Creation%20(6).png?alt=media&token=783c6534-5039-46f3-b06d-1b1fb2c87e55",
            backgroundColor1: "rgba(0, 0, 0, 0)",
            locationColor1: "#272940",
            homeColor5: "#ffffff",
            locationColor2: "#272940",
            detailsColor6: "#ffffff",
            backgroundColor2: "rgba(0, 0, 0, 0)",
            homeColor4: "#ffffff",
            rsvpColor1: "#272940",
            homeFontFamily3: "Dancing Script",
            rsvpFontFamily4: "Abril Fatface",
            contentFontFamily2: "Aladin",
            thumbnail:
              "https://firebasestorage.googleapis.com/v0/b/e-card-website.appspot.com/o/themes%2Fkarya%2F12-8-22%20Creation%20(6).png?alt=media&token=783c6534-5039-46f3-b06d-1b1fb2c87e55",
            homeFontFamily2: "Spirax",
            wishColor2: "#272940",
            tentativeFontFamily2: "Amaranth",
            contentColor1: "#272940",
            rsvpColor3: "#272940",
            detailsColor3: "#ffffff",
            detailsColor2: "#ffffff",
            contentColor4: "#272940",
            locationColor3: "#272940",
            rsvpColor5: "#272940",
            detailsColor5: "#ffffff",
            detailsFontFamily3: "Amarante",
            border: false,
            contentColor2: "#272940",
            tentativeColor2: "#272940",
            detailsColor1: "#ffffff",
            contentColor5: "#272940",
            homeFontFamily5: "Cookie",
            homeColor1: "#ffffff",
            detailsColor4: "#ffffff",
            contactFontFamily1: "Amarante",
            homeFontFamily4: "Spirax",
            rsvpColor2: "#272940",
            contentFontFamily3: "Aladin",
            wishFontFamily2: "Poppins",
            rsvpFontFamily3: "Amaranth",
            homeFontFamily1: "Cookie",
            wishColor3: "#272940",
            contactColor2: "#272940",
            rsvpColor4: "#FFFFFF",
            detailsFontFamily1: "Amarante",
            wishFontFamily1: "Poppins",
            contentFontFamily1: "Almendra",
            homeColor2: "#ffffff",
            contactFontFamily2: "Poppins",
            contactColor1: "#272940",
            locationFontFamily1: "Aladin",
            detailsFontFamily4: "Amarante",
            rsvpFontFamily1: "Amarante",
            tentativeColor1: "#272940",
            contentColor3: "#272940",
            detailsFontFamily2: "Amarante",
            detailsFontFamily5: "Aladin",
            detailsFontFamily6: "Aladin",
            homeColor3: "#ffffff",
            locationColor4: "rgba(255, 255, 255, 0.9)",
            tentativeFontFamily1: "Amarante",
            wishColor1: "#272940",
          },
          color: "",
          rating: [],
          popularity: 0,
        },
      ];

      state.cardDesignPreviewList = themes.map((theme) => {
        return {
          id: theme.title,
          theme: theme.theme,
          title: theme.title,
          type: theme.type,
          typeId: theme.typeId,
        };
      });
    },
    getcardDesignPreviewList_failure: (state, action) => {
      state.getcardDesignPreviewList_requesting = false;
      state.getcardDesignPreviewList_errorMsg = action.payload;
    },
  },
});

export const {
  getcardDesignPreviewList_requesting,
  getcardDesignPreviewList_success,
  getcardDesignPreviewList_failure,
} = homeSlice.actions;

export default homeSlice.reducer;
