import React, { useState, useEffect, useRef } from "react";

import { UserAuthenticationModal } from "components";
import ThemeList from "./sections/ThemeList";

import "assets/scss/modules/cardCatalog.scss";
import Filters from "./sections/Filters";
import CardList from "./sections/CardList";
import { isCookieValid, isUserLoggedIn } from "helper/validations";
import { useDispatch } from "react-redux";
import { setIsUserWebsiteLoggedIn } from "dataflow/slices/website.slice";

function CardCatalog(props) {
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const [isAuthLogin, setIsAuthLogin] = useState(true);

  const dispatch = useDispatch();

  return (
    <div className={"card-catalog container"}>
      {/* Login/Sign Up Modals */}
      <UserAuthenticationModal
        isVisible={isAuthModalVisible}
        isLogin={isAuthLogin}
        setIsLogin={setIsAuthLogin}
        onHide={() => {
          setIsAuthModalVisible(false);
          setIsAuthLogin(true)
        }}
        onLoginSuccessful={() => {
          dispatch(setIsUserWebsiteLoggedIn(isUserLoggedIn()));
        }}
      />

      {/* Page Title */}
      <div className={"card-catalog__title text-center"}>
        <span>
          Tema-Tema <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* List of Themes */}
      <div className={"d-flex align-items-center justify-content-center"}>
        <ThemeList />
      </div>

      {/* Horizontal Divider */}
      <div className={"d-flex align-items-center justify-content-center"}>
        <div className={"card-catalog__horizontal-divider"} />
      </div>

      {/* Filters */}
      <div className={"d-flex  justify-content-center align-items-center"}>
        <Filters />
      </div>

      {/* Card List */}
      <div className={"d-flex flex-wrap justify-content-center"}>
        <CardList setIsAuthModalVisible={setIsAuthModalVisible} />
      </div>
    </div>
  );
}

export default CardCatalog;
