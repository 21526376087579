import React, { useEffect, useState } from "react";

import "assets/scss/components/media.scss";
import { useParams } from "react-router";
import { firestoreDB } from "dataflow/services";
import { isStringNotEmpty } from "helper/validations";
import cross from "assets/images/times-solid.svg";
import { Button } from "./buttons/Button";
import useAccountDetailsService from "dataflow/services/accountDetails.services";

function Media() {
  let { eventId } = useParams();
  const { deleteMedia } = useAccountDetailsService();

  const [medias, setMedias] = useState([]);

  useEffect(() => {
    let unsubscribe;
    if (isStringNotEmpty(eventId)) {
      console.log(eventId);
      let albumCollectionRef = firestoreDB().collection("albums");
      unsubscribe = albumCollectionRef
        .where("eventId", "==", eventId)
        .orderBy("timestamp", "desc")
        .onSnapshot(
          (querySnapshot) => {
            setMedias(
              querySnapshot.docs.map((doc) => {
                return {
                  id: doc.id,
                  ...doc.data(),
                };
              })
            );
          },
          (error) => {}
        );
    }

    return () => {
      return unsubscribe;
    };
  }, [eventId]);

  return (
    <div className={"album__media-list w-100 row justify-content-center"}>
      {medias &&
        medias?.map((media) => (
          <div
            key={media.id}
            className={"media-list__element d-flex flex-column"}
          >
            <div className="media__source">
              <img
                src={media.mediaURL}
                alt="event moment"
                height={"180px"}
                width={"180px"}
              />
            </div>

            <div className="media__description d-flex align-items-center justify-content-between">
              <div className="media__info">
                <div className="media__headerAuthor">
                  <strong>{media.user}</strong>
                </div>
                <div className="media__infoCaption">{media.message}</div>
              </div>

              {/* Delete button */}
              <Button
                className={"action__delete"}
                content={
                  <img className={"delete__icon"} src={cross} alt={"Delete"} />
                }
                onClick={() =>
                  deleteMedia({
                    mediaId: media.id,
                    fileName: media.mediaId,
                    eventId,
                  })
                }
                decoration_delete
              />
            </div>
          </div>
        ))}
    </div>
  );
}

export default Media;
