import React from "react";
import { SVGCircle } from "components";
import { mapNumber } from "helper/functions/mapNumber.js";

import Countdown from "react-countdown";

function Countdown2({ timer, countdownBorderColor, countdownTextStyle }) {
  //renderer function for react-coundown module
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const daysRadius = mapNumber(days, 365, 0, 0, 360);
    const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
    const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
    const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

    const Clock = () => (
      <div className="countdown__wrapper m-auto">
        <div className="countdown__item m-auto">
          <SVGCircle radius={daysRadius} color={countdownBorderColor} />
          <span className="countdown__clock" style={countdownTextStyle}>
            {days}
          </span>
          <span className="countdown__timer" style={countdownTextStyle}>
            Hari
          </span>
        </div>

        <div className="countdown__item m-auto">
          <SVGCircle radius={hoursRadius} color={countdownBorderColor} />
          <span className="countdown__clock" style={countdownTextStyle}>
            {hours}
          </span>
          <span className="countdown__timer" style={countdownTextStyle}>
            Jam
          </span>
        </div>

        <div className="countdown__item m-auto">
          <SVGCircle radius={minutesRadius} color={countdownBorderColor} />
          <span className="countdown__clock" style={countdownTextStyle}>
            {minutes}
          </span>
          <span className="countdown__timer" style={countdownTextStyle}>
            Minit
          </span>
        </div>

        <div className="countdown__item m-auto">
          <SVGCircle radius={secondsRadius} color={countdownBorderColor} />
          <span className="countdown__clock" style={countdownTextStyle}>
            {seconds}
          </span>
          <span className="countdown__timer" style={countdownTextStyle}>
            Saat
          </span>
        </div>
      </div>
    );

    // ISSUE: Props passed are never used.
    //        Because props are not used, if statement is unnecessary at this point.
    if (completed) {
      return (
        <Clock
          daysRadius={0}
          hoursRadius={hoursRadius}
          minutesRadius={minutesRadius}
          secondsRadius={secondsRadius}
          // color={theme.primaryColor}
          countdownBorderColor={countdownBorderColor}
        />
      );
    } else {
      return (
        <Clock
          daysRadius={daysRadius}
          hoursRadius={hoursRadius}
          minutesRadius={minutesRadius}
          secondsRadius={secondsRadius}
          // color={theme?.primaryColor}
          countdownBorderColor={countdownBorderColor}
        />
      );
    }
  };

  return (
    <div className="countdown m-auto">
      <Countdown date={new Date(timer)} renderer={renderer} />
    </div>
  );
}

export default Countdown2;
