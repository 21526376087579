import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";

import "assets/scss/components/session.scss";
import { firestoreDB } from "dataflow/services";
import {
  isArrayNotEmpty,
  isNotNullUndefined,
  isObjectNotEmpty,
} from "helper/validations";

function Session() {
  let { eventId } = useParams();

  const [sessions, setSessions] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [booklist, setBooklist] = useState([])

  useEffect(() => {
    let eventRef = firestoreDB().collection("cards").doc(eventId);
    let unsubscribe = eventRef
      .collection("rsvp")
      .orderBy("timeend", "asc")
      .onSnapshot(
        (querySnapshot) => {
          setSessions(
            querySnapshot.docs.map((doc) => {
              return {
                id: doc.id,
                ...doc.data(),
              };
            })
          );
        },
        (error) => {}
      );

    return () => {
      return unsubscribe;
    };
  }, [eventId]);

  const displayName = async (id) => {
    setSelectedId(id);

    let booklistRef = firestoreDB().collection("cards").doc(eventId).collection("rsvp").doc(id).collection("booklist")
    let booklistSnapshot = await booklistRef.get()
    if(!booklistSnapshot.empty){
      let booklist = booklistSnapshot.docs.map((book) => {
        return{
          id: book.id,
          name: book.data().name,
          pax: book.data().pax,
          phone: book.data().phone,
          relationship: book.data().relationship,
        }
      })
      setBooklist(booklist)
    }else{
      setBooklist(null)
    }
    
  };

  return (
    <div
      className={
        "rsvp-session w-100 d-flex flex-column align-items-center justify-content-start"
      }
    >
      <div className="rsvp-session-ecard-id">
        <strong>EkadTepi ID: </strong>
        {eventId}
      </div>
      <div className={"session__container row"}>
        {sessions &&
          sessions.map((session, index) => {
            return (
              <div
                key={session.id}
                className={
                  "session__element text-center col-sm-6 " +
                  (selectedId === session.id ? "session__selectedId" : "")
                }
                onClick={() => displayName(session?.id)}
              >
                <p className="sesi-section">Sesi {index + 1}</p>
                <span className="sesi-time">
                  {session?.timestart
                    ?.toDate()
                    .toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                  -{" "}
                  {session?.timeend
                    ?.toDate()
                    .toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                </span>
                <p className="sesi-attendance">
                  {session?.total - session?.session} / {session?.total} hadir
                </p>
              </div>
            );
          })}
      </div>

      <div
        className={
          "name-list__header d-flex flex-column align-items-center justify-content-start"
        }
      >
        <p className={"header__title"}>Senarai kehadiran</p>
        <p className={"name-list-attendance-total"}>Jumlah kehadiran:</p>
      </div>

      <div className={"name-list__container overflow-auto row"}>
        {isNotNullUndefined(booklist) &&
          isObjectNotEmpty(booklist) &&
          booklist.map((book) => (
            <div
              className={
                "name-list__element col-sm-6 d-flex align-items-center justify-content-between"
              }
            >
              <div className="name-list__element-name d-flex flex-column align-items-start justify-content-center">
                <div className="attendance-name">{book?.name}</div>
                <span className="attendance-phone">
                  {book?.phone}
                </span>
              </div>
              <div className="name-list__element-number d-flex flex-column align-items-center justify-content-center">
                <div className="attendance-number">
                  {book?.pax}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Session;
