import React, { useEffect, useState } from 'react';
import { deepCloneArray, isArrayNotEmpty, validateFunction } from 'helper/validations';
import TentativeDisplay from 'modules/ECard/sections/tentative/TentativeDisplay';
import InputContentExpander from 'components/contentExpander/InputContentExpander';
import { useCustomSelector } from 'hooks';
import moment from 'moment';
import MenuPageContainer from 'components/containers/MenuPageContainer';


export default function useTentativeStep({
    fontList, loadFont,

    isResetRequired, currentStep,
    cardThemeBgImage, content_pageTitleDetails,

    cover_details,

    // This requires that if the item in the list is not empty, then 
    // both time and activity are required, otherwise remove the item
    tentative_init_tentativeDetailsList, tentative_tentativeDetailsList = {},

    setTentative_tentativeDetailsList: setTentative_tentativeDetailsList_props,
    tentative_init_textDetails, tentative_textDetails = {}, setTentative_textDetails: setTentative_textDetails_props,
    tentative_borderBgDetails = {}, setTentative_borderBgDetails: setTentative_borderBgDetails_props,
}) {
    // Validations
    let setTentative_tentativeDetailsList = validateFunction(setTentative_tentativeDetailsList_props);
    let setTentative_textDetails = validateFunction(setTentative_textDetails_props);
    let setTentative_borderBgDetails = validateFunction(setTentative_borderBgDetails_props);

    const [currentVisibleContentPos, setCurrentVisibleContentPos] = useState();
    const { ecardEditDetails } = useCustomSelector("app");

    function reset() {
        setTentative_tentativeDetailsList(tentative_init_tentativeDetailsList);
        setTentative_textDetails(tentative_init_textDetails);
        // setTentative_borderBgDetails(tentative_init_textDetails);
    }
    useEffect(() => {
        if (currentStep == "tentative" && isResetRequired) reset();
    }, [isResetRequired]);

    let input_tentativeStep = (
        <div className={"inputs__tentative-step"}>
            {/* Tentative Info */}
            <InputContentExpander
                mainDisplayText={"Maklumat Aturcara"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 0) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(0);
                }}
                isExpanded={currentVisibleContentPos === 0}

                themeType={"darkGrey"}

                onContentReset={() => setTentative_tentativeDetailsList(tentative_init_tentativeDetailsList)}
               
                contentInputs={(isArrayNotEmpty(tentative_tentativeDetailsList)? tentative_tentativeDetailsList:[""]).map((details, idx) => {
                    return {
                        onSectionDelete: setTentative_tentativeDetailsList,
                        onSectionAdd: setTentative_tentativeDetailsList,
                        list: tentative_tentativeDetailsList,
                        isDeleteIconVisible: true,
                        isAddIconVisible: true,
                        inputs: [
                            {
                                type: "datePicker",
                                label: "Waktu Mula:",
                                defaultValue: details?.time,
                                value: details?.time,
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    // use time from this input but use date from the cover page date
                                    let dateFromInput_moment = moment(value);
                                    let coverDate_moment = moment(cover_details?.date);
                                    coverDate_moment.hour(dateFromInput_moment.hour())
                                        .minute(dateFromInput_moment.minute())
                                        .second(dateFromInput_moment.second());

                                    let newList = deepCloneArray(tentative_tentativeDetailsList);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        time: coverDate_moment.format()
                                    }
                                    setTentative_tentativeDetailsList(newList);
                                },
                            },
                            {
                                type: "text",
                                label: "Aktiviti:",
                                value: details?.activity,
                                isBottomInputRelated: true,
                                onChange: (value) => {
                                    let newList = deepCloneArray(tentative_tentativeDetailsList);
                                    newList[idx] = {
                                        ...(newList[idx]? newList[idx]:{}),
                                        activity: value
                                    }
                                    setTentative_tentativeDetailsList(newList);
                                }
                            },
                        ]
                    };
                })}
            />

            {/* Font Style */}
            <InputContentExpander
                mainDisplayText={"Tulisan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 1) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(1);
                }}
                isExpanded={currentVisibleContentPos === 1}

                themeType={"blue"}
                
                onContentReset={() => setTentative_textDetails(tentative_init_textDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                header: "Masa",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: tentative_textDetails?.timeFont },
                                value: tentative_textDetails?.timeFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setTentative_textDetails({
                                        ...tentative_textDetails,
                                        timeFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: tentative_textDetails?.timeColorFont,
                                value: tentative_textDetails?.timeColorFont,
                                onChange: (color) => {
                                    setTentative_textDetails({
                                        ...tentative_textDetails,
                                        timeColorFont: color
                                    });
                                }
                            },

                            {
                                header: "Aktiviti",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: tentative_textDetails?.activityFont },
                                value: tentative_textDetails?.activityFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setTentative_textDetails({
                                        ...tentative_textDetails,
                                        activityFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: tentative_textDetails?.activityFontColor,
                                value: tentative_textDetails?.activityFontColor,
                                onChange: (color) => {
                                    setTentative_textDetails({
                                        ...tentative_textDetails,
                                        activityFontColor: color
                                    });
                                }
                            }
                        ]
                    }
                ]}
            />

            {/* Background & Border Styles */}
            {/* <InputContentExpander
                mainDisplayText={"Latar & Sempadan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 2) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(2);
                }}
                isExpanded={currentVisibleContentPos === 2}

                themeType={"blue"}

                onContentReset={() => setTentative_borderBgDetails(tentative_init_textDetails)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                header: "Masa",
                                type: "dropdown",
                                label: "Warna Latar:",
                                valueStyle: { fontFamily: tentative_borderBgDetails?.bgColor },
                                value: tentative_borderBgDetails?.bgColor,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setTentative_borderBgDetails({
                                        ...tentative_borderBgDetails,
                                        bgColor: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Sempadan:",
                                defaultValue: tentative_borderBgDetails?.borderColor,
                                value: tentative_borderBgDetails?.borderColor,
                                onChange: (color) => {
                                    setTentative_borderBgDetails({
                                        ...tentative_borderBgDetails,
                                        borderColor: color
                                    });
                                }
                            }
                        ]
                    }
                ]}
            /> */}
        </div>
    );

    let preview_tentativeStep = (
        <MenuPageContainer ecardEditDetails={ecardEditDetails}>
            <TentativeDisplay

                bgOverlayStyle={{
                    background: ecardEditDetails?.themes?.backgroundColor2,
                    borderColor: ecardEditDetails?.themes?.border && ecardEditDetails?.themes?.contentColor1
                }}
                
                titleStyle={{
                    fontFamily: content_pageTitleDetails?.font,
                    color: content_pageTitleDetails?.fontColor
                }}

                tentatives={
                    isArrayNotEmpty(tentative_tentativeDetailsList) && 
                    tentative_tentativeDetailsList.map((details) => {
                        return {
                            time: details?.time,
                            activity: details?.activity
                        };
                    }
                )}
                timeStyle={{
                    fontFamily: tentative_textDetails?.timeFont,
                    color: tentative_textDetails?.timeColorFont
                }}
                eventNameStyle={{
                    fontFamily: tentative_textDetails?.activityFont,
                    color: tentative_textDetails?.activityFontColor
                }}
            />
        </MenuPageContainer>
    );

    return {
        input_tentativeStep, preview_tentativeStep
    }
}