import {
  setappInfo_requesting,
  setappInfo_success,
  setappInfo_failure,
  updateEcard_requesting,
  updateEcard_success,
  updateEcard_failure,
  getEcardEditDetails_requesting,
  getEcardEditDetails_success,
  getEcardEditDetails_failure,
} from "dataflow/slices/app.slice";
import { useState } from "react";
import { firestoreDB } from ".";
import firebase from "firebase/app";
import "firebase/firestore"; // for cloud firestore

import { useRequest } from "./config";
import { Toaster } from "components/notifications/Toaster";

import { endpointUrls } from "helper/constants/endpointUrls";

export default function useAppServices() {
  const { get, post } = useRequest();
  const [rsvpSessions, setRSVPSessions] = useState();

  function getAppInfo(id, url) {
    get({
      url: `${endpointUrls.getCardData}?id=${id}`,
      requesting: setappInfo_requesting,
      success: setappInfo_success,
      failure: setappInfo_failure,
      onSuccessData: {
        eventId: id,
        rootURL: url,
      },
    });
  }

  function getEcardEditDetails(id, url) {
    get({
      url: `${endpointUrls.editCardData}?id=${id}`,
      requesting: getEcardEditDetails_requesting,
      success: getEcardEditDetails_success,
      failure: getEcardEditDetails_failure,
      onSuccessData: {
        eventId: id,
        rootURL: url,
      },
    });
  }

  function getRSVPSessions(collectionId, cardId) {
    firestoreDB()
      .collection(collectionId)
      .doc(cardId)
      .collection("rsvp")
      .orderBy("timeend", "asc")
      .onSnapshot((snapshot) =>
        setRSVPSessions(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            session: doc.data(),
          }))
        )
      );
  }

  function updateRSVPSessions(
    collectionId,
    cardId,
    sessionId,
    data,
    increment
  ) {
    firestoreDB()
      .collection(collectionId)
      .doc(cardId)
      .collection("rsvp")
      .doc(sessionId)
      .update({
        ...data,
        session: firebase.firestore.FieldValue.increment(increment),
      })
      .then(() => console.log("You successfully updated RSVP"))
      .catch((error) => console.log("Failed to update RSVP: ", error));
  }

  function deleteRSVPSessions(collectionId, cardId, sessionId) {
    firestoreDB()
      .collection(collectionId)
      .doc(cardId)
      .collection("rsvp")
      .doc(sessionId)
      .delete()
      .then(() => console.log("You successfully deleted RSVP"))
      .catch((error) => console.log("Failed to delete RSVP: ", error));
  }

  function updateEcard(body) {
    post({
      url: endpointUrls.updateCardData,
      requesting: updateEcard_requesting,
      success: updateEcard_success,
      onSuccessExtend: () => {
        Toaster({
          type: "success",
          msg: "Berjaya mengemaskini eCard",
        });
      },
      failure: updateEcard_failure,
      onFailureExtend: (msg) => {
        Toaster({
          type: "error",
          msg,
        });
      },
      body,
    });
  }

  return {
    getAppInfo,
    getEcardEditDetails,
    getRSVPSessions,
    updateRSVPSessions,
    deleteRSVPSessions,
    rsvpSessions,
    updateEcard,
  };
}
