import React from "react";

import mobile_frame from "assets/images/IphoneX_mockUp.png";
import tablet_frame from "assets/images/tablet_mockup_frame.png";

import "assets/scss/components/liveModel.scss";

export function LiveModel(props) {
  const { className, isMobile, url } = props;

  // Note: using iPhone 6/7/8 Plus dimensions
  let deviceHeight = 400;
  let deviceWidth = 204;
  let deviceFrameSrc = mobile_frame;
  if (!isMobile) {
    // Note: using iPad Pro dimensions
    deviceHeight = 287;
    deviceWidth = 231;
    deviceFrameSrc = tablet_frame;
  }

  console.log(url)

  return (
    <div
      className={
        "live-model " +
        (className ? className + " " : "") +
        (!isMobile ? "live-model_handle-tablet-offset " : "")
      }
    >
      <div className={"live-model__wrapper"}>
        {/* Device Frame */}
        <img
          className={
            "live-model__frame " +
            (!isMobile ? "live-model__frame_tablet-dimensions " : "")
          }
          src={deviceFrameSrc}
          alt={"Device Frame"}
        />

        {/* Website */}
        <iframe
          title={"EkadTepi WebApp"}
          className={
            "live-model__website " +
            (!isMobile ? "live-model__website_tablet-dimensions " : "")
          }
          src={url}
          width={deviceWidth}
          height={deviceHeight}
        />
      </div>
    </div>
  );
}
