import React from 'react'

import "assets/scss/components/inputs/sliderInput.scss";
import { validateFunction } from 'helper/validations';

function SliderInput({
  className, labelClassName, inputClassName,

  label, id,

  value,
  isMarginBottomOptional, isLabelAtTheSide,
  isDisabled,


  // Functions
  onChange: onChange_props,
}) {

  let onChange = validateFunction(onChange_props);

  return (
    <div className={"component-slider-input d-flex " + (className? className + " ":"")}>

      <label for={id} className={"component-slider-input__label " + (labelClassName? labelClassName + " ":"")}>
        {label}
      </label>

      <input className={"component-slider-input__input " + (inputClassName? inputClassName + " ":"")} type="range"
        id={id} value={value}
        onChange={(event) => onChange(event)}
        disabled={isDisabled}
      ></input>
      
    </div>
  )
}

export default SliderInput