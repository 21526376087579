import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const userOrderSlice = createSlice({
    name: 'userOrder',
    initialState,
    reducers: {
        getuserOrderList_requesting: (state) => {
            state.getuserOrderList_requesting = true;
            state.getuserOrderList_status = false;
            state.getuserOrderList_errorMsg = undefined;
        },
        getuserOrderList_success: (state, action) => {
            state.getuserOrderList_requesting = false;
            state.getuserOrderList_status = true;
            state.userOrderList = action.payload;
        },
        getuserOrderList_failure: (state, action) => {
            state.getuserOrderList_requesting = false;
            state.getuserOrderList_errorMsg = action.payload;
        },
        resetuserOrderList: (state) => initialState,
    }
})

export const {
    getuserOrderList_requesting,
    getuserOrderList_success,
    getuserOrderList_failure,
    resetuserOrderList
} = userOrderSlice.actions;

export default userOrderSlice.reducer;

