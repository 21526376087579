import { firestoreDB } from "dataflow/services";
import { isStringNotEmpty } from "helper/validations";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "assets/scss/components/wishList.scss";

import cross from "assets/images/times-solid.svg";
import { Button } from "./buttons/Button";
import useAccountDetailsService from "dataflow/services/accountDetails.services";

function WishList(props) {
  let { eventId } = useParams();
  const { deleteWish } = useAccountDetailsService();

  const [wishes, setWishes] = useState([]);
  const [isSelected, setIsSelected] = useState();

  useEffect(() => {
    let unsubscribe;
    if (isStringNotEmpty(eventId)) {
      console.log(eventId);
      let eventRef = firestoreDB().collection("cards").doc(eventId);
      unsubscribe = eventRef
        .collection("wishes")
        .orderBy("timestamp", "desc")
        .onSnapshot(
          (querySnapshot) => {
            setWishes(
              querySnapshot.docs.map((doc) => {
                return {
                  id: doc.id,
                  ...doc.data(),
                };
              })
            );
          },
          (error) => {}
        );
    }

    return () => {
      return unsubscribe;
    };
  }, [eventId]);

  return (
    <div className={"wish__wish-list"}>
      {wishes &&
        wishes.map((wish) => (
          <div
            key={wish.id}
            className={
              "wish-list__element d-flex align-items-center " +
              (isSelected === wish.id && "disabled")
            }
          >
            <div className="wisher-list">
              <div className="wish-list__element-name">{wish.user}</div>
              <div className="wish-list__element-relay">
                ({wish.relationship})
              </div>
            </div>
            <div className="wish-list__element-wishtime d-flex flex-column">
              <span className="wish-wish">{wish.wish}</span>
              <span className="wish-time">
                {wish.timestamp
                  ?.toDate()
                  .toLocaleTimeString(navigator.language, {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
              </span>
            </div>
            {/* Delete button */}
            <Button
              className={"action__delete"}
              content={
                <img className={"delete__icon"} src={cross} alt={"Delete"} />
              }
              onClick={() => {
                //need cardId and wishId
                setIsSelected(wish.id);
                deleteWish({ wishId: wish.id, eventId });
                // let wishRef = firestoreDB()
                //   .collection("cards")
                //   .doc(eventId)
                //   .collection("wishes")
                //   .doc(id);
                // wishRef
                //   .delete()
                //   .then(() => {
                //     console.log("Wish successfully deleted!");
                //   })
                //   .catch((error) => {
                //     console.error("Error removing wish: ", error);
                //   });
              }}
              decoration_delete
            />
          </div>
        ))}
    </div>
  );
}

export default WishList;
