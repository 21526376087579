import {
  getQueries,
  isArrayNotEmpty,
  isStringTrimNotEmpty,
  getReplacedPathQuery,
  getAddedPathQuery,
  getRemovedPathQuery,
} from "helper/validations";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "components";

import ic_triangle from "assets/images/ic_triangle.png";

export default function Filters(props) {

  const history = useHistory();
  const location = useLocation();
  let paramsObj = getQueries(location);
  let color = paramsObj?.["warna"];
  let filters = paramsObj?.["tapis"];

  // Convert to array if only 1 value
  let filters_formatted = [];
  if (isStringTrimNotEmpty(filters)) {
    filters_formatted.push({
      type: "tapis",
      name: filters,
    })
  } else if (isArrayNotEmpty(filters)) {
    let filtersArray = filters.map((filterName) => {
      return {
        type: "tapis",
        name: filterName,
      };
    });
    filters_formatted = filters_formatted.concat(filtersArray);
  }
  if (isStringTrimNotEmpty(color)) {
    filters_formatted.push({
      type: "warna",
      name: color,
    });
  }

  // Create the selected filter UI
  let selectedFiltersUI = [];
  if (isArrayNotEmpty(filters_formatted)) {
    selectedFiltersUI = filters_formatted.map((filterObj, idx) => {
      let type = filterObj?.type;
      let name = filterObj?.name;

      return (
        <div key={idx} className={"selected-filters__selection"}>
          <div
            className={"selection__wrapper"}
            onClick={() =>
              history.push(getRemovedPathQuery(location, type, name))
            }
          >
            <span className={"selection__text"}>{name}</span>
            <span className={"selection__remove"}>-</span>
          </div>
        </div>
      );
    });
  }

  let filterOptions = []; // ["Terbaru", "Popular", "Menarik"]; // Temporarily hidden since we only have very few users.
  let filterSelectionUI = [];
  if (isArrayNotEmpty(filterOptions)) {
    filterSelectionUI = filterOptions.map((filterName, idx) => {
      return (
        <div key={idx} className={"bottom__item"}>
          <div
            className={
              "item__wrapper d-flex align-items-center justify-content-center"
            }
            onClick={() => {
              history.push(getAddedPathQuery(location, "tapis", filterName));
            }}
          >
            <span className={"item__text"}>{filterName}</span>
            <span className={"item__add"}>+</span>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={"card-catalog__filters"}>
      {/* Top */}
      <div
        className={
          "filters__top d-flex align-items-center justify-content-center"
        }
      >
        {/* Label */}
        <div className={"top__label"}>Tapis:</div>

        {/* Selected Filters */}
        <div className={"top__selected-filters"}>
          <div
            className={"selected-filters__wrapper d-flex align-items-center"}
          >
            {selectedFiltersUI}
          </div>
        </div>

        {/* Color Selection */}
        <Dropdown
          toggleClassName={"top__color-selection"}
          toggleText={
            <div
              className={
                "color-selection__toggle d-flex align-items-center justify-content-center"
              }
            >
              <span className={"toggle__text"}>Warna</span>
              <img
                className={"toggle__icon"}
                src={ic_triangle}
                alt={"Dropdown arrow"}
              />
            </div>
          }
          options={[
            {
              label: "Hijau",
              value: "Hijau",
              onClick: (value) => {
                history.push(getReplacedPathQuery(location, "warna", "Hijau"));
              },
            },
            {
              label: "Pink",
              value: "Pink",
              onClick: (value) => {
                history.push(getReplacedPathQuery(location, "warna", "Pink"));
              },
            },
            {
              label: "Merah",
              value: "Merah",
              onClick: (value) => {
                history.push(getReplacedPathQuery(location, "warna", "Merah"));
              },
            },
            {
              label: "Ungu",
              value: "Ungu",
              onClick: (value) => {
                history.push(getReplacedPathQuery(location, "warna", "Ungu"));
              },
            },
            {
              label: "Gelap",
              value: "Gelap",
              onClick: (value) => {
                history.push(getReplacedPathQuery(location, "warna", "Gelap"));
              },
            },
            {
              label: "Coklat",
              value: "Coklat",
              onClick: (value) => {
                history.push(getReplacedPathQuery(location, "warna", "Coklat"));
              },
            },
            {
              label: "Biru",
              value: "Biru",
              onClick: (value) => {
                history.push(getReplacedPathQuery(location, "warna", "Biru"));
              },
            },
          ]}
        />
      </div>

      {/* Bottom */}
      <div
        className={
          "filters__bottom d-flex align-items-center justify-content-center"
        }
      >
        {filterSelectionUI}
      </div>
    </div>
  );
}
