import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { UserAuthenticationModal } from "components";

import {
  isStringEqual_caseSensitive,
  removeAllCookies,
  isArrayNotEmpty,
  getCookieValue,
  isUserLoggedIn,
} from "helper/validations";

import card_logo from "assets/images/card_logo2.png";
import bag_cart from "assets/images/shopping_bag_solid.svg";

import "assets/scss/modules/navigations/topNavigationBar.scss";
import { firestoreDB, handleUserSignOut } from "dataflow/services";
import { useCustomSelector } from "hooks";
import { useDispatch } from "react-redux";
import {
  getuserOrderList_success,
  resetuserOrderList,
} from "dataflow/slices/userOrder.slice";
import {
  getpersonalInfoObject_success,
  resetpersonalInfoObject,
} from "dataflow/slices/personalInfo.slice";
import { resetuserCardList } from "dataflow/slices/userCard.slice";
import {
  resetIsUserWebsiteLoggedIn,
  setIsProviderWebsiteLoggedIn,
  setIsUserWebsiteLoggedIn,
} from "dataflow/slices/website.slice";

function TopNavigationBar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  let pathname = history?.location?.pathname;

  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const [isAuthLogin, setIsAuthLogin] = useState(true);

  let isHome = isStringEqual_caseSensitive(pathname, "/utama");
  let isTheme = isStringEqual_caseSensitive(pathname, "/contoh-tema");
  let isContactUs = isStringEqual_caseSensitive(pathname, "/hubungi-kami");

  if (getCookieValue("provider")) {
    dispatch(setIsProviderWebsiteLoggedIn(isUserLoggedIn()));
  } else {
    dispatch(setIsUserWebsiteLoggedIn(isUserLoggedIn()));
  }

  const { isUserWebsiteLogin } = useCustomSelector("website");

  const { personalInfoObject } = useCustomSelector("personalInfo");

  const { userOrderList } = useCustomSelector("userOrder");

  let filtered = userOrderList?.filter(
    (order, index) => order.orderStatus === "created"
  );

  const handelLogout = async () => {
    await handleUserSignOut();
    removeAllCookies();
    dispatch(resetuserOrderList());
    dispatch(resetpersonalInfoObject());
    dispatch(resetuserCardList());
    dispatch(resetIsUserWebsiteLoggedIn());
    history.replace("/utama");
  };

  // Get data for the user info
  useLayoutEffect(() => {
    (async () => {
      const userId = getCookieValue("uid", true);
      if (isUserLoggedIn()) {
        if (!personalInfoObject) {
          let userInfoRef = firestoreDB().collection("users").doc(userId);
          userInfoRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                dispatch(getpersonalInfoObject_success(doc.data()));
              } else {
                console.log("No such document!");
              }
            })
            .catch((error) => {
              console.log("Error getting document:");
            });
        }

        if (!userOrderList) {
          let orderCollectionRef = firestoreDB().collection("orders");
          orderCollectionRef
            .where("userId", "==", userId)
            .orderBy("lastUpdated", "desc")
            .onSnapshot(
              (querySnapshot) => {
                if (querySnapshot.empty) {
                  console.log("No documents found");
                  dispatch(resetuserOrderList());
                } else {
                  dispatch(
                    getuserOrderList_success(
                      querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                      }))
                    )
                  );
                }
              },
              (error) => {
                console.log("Error getting documents: ");
              }
            );
        }
      }
    })();
  }, [isUserWebsiteLogin]);

  return (
    <div className={"top-navigation-bar"}>
      {/* Login/Sign Up Modals */}
      <UserAuthenticationModal
        isVisible={isAuthModalVisible}
        isLogin={isAuthLogin}
        setIsLogin={setIsAuthLogin}
        onHide={() => {
          setIsAuthModalVisible(false);
          setIsAuthLogin(true);
        }}
        onLoginSuccessful={() => {
          dispatch(setIsUserWebsiteLoggedIn(isUserLoggedIn()));
        }}
      />

      {/* Top Section */}
      <div
        className={
          "top-navigation-bar__top-section d-flex justify-content-between align-items-center"
        }
      >
        {/* Left Segment */}
        <div className={"top-section__left-segment"}></div>

        {/* Middle Segment */}
        <div className={"top-section__middle-segment"}>
          {/* Logo */}
          <div
            className={"middle-segment__logo"}
            onClick={() => history.push("/")}
          >
            <div className={"logo__top-text"}>EKADTEPI</div>
            <div className={"logo__bottom-text"}>KAD JEMPUTAN DIGITAL</div>
            {/* <img
              className={"logo__card-icon"}
              src={card_logo}
              alt={"Card Icon"}
            /> */}
          </div>
        </div>

        {/* Right Segment */}
        <div
          className={"top-section__right-segment d-flex justify-content-end"}
        >
          {/* Cart */}
          <div className={"right-segment__cart-wrapper"}>
            <div
              className="shopping-cart"
              onClick={() => {
                isUserWebsiteLogin
                  ? history.push({
                      pathname: "/akaun-saya",
                      state: { selection: "card" },
                    })
                  : setIsAuthModalVisible(true);
              }}
            >
              <img
                className={"right-segment__cart"}
                src={bag_cart}
                alt={"Cart Icon"}
              />
              {isArrayNotEmpty(filtered) && (
                <div className={"right-segment__cart-value"}>
                  {filtered?.length}
                </div>
              )}
            </div>
          </div>

          {/* Account */}
          <div className="right-segment__account-box d-flex">
            <div
              className={"right-segment__account"}
              onClick={() => {
                {
                  isUserWebsiteLogin
                    ? history.push("/akaun-saya")
                    : setIsAuthModalVisible(true);
                }
              }}
            >
              {isUserWebsiteLogin ? "myAkaun" : "Log Masuk"}
            </div>

            {isUserWebsiteLogin && (
              <div className={"right-segment__logOut"} onClick={handelLogout}>
                Log Keluar
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div
        className={
          "top-navigation-bar__bottom-section d-flex justify-content-around align-items-center"
        }
      >
        {/* Empty space left side */}
        <div className="bottom-section__empty-left"></div>
        {/* Main */}
        <div
          className={
            "bottom-section__main " +
            (isHome ? "bottom-section_underline " : "")
          }
          onClick={() => history.push("/utama")}
        >
          Utama
        </div>

        {/* Theme */}
        <div
          className={
            "bottom-section__theme " +
            (isTheme ? "bottom-section_underline " : "")
          }
          onClick={() => history.push("/contoh-tema")}
        >
          Contoh Tema
        </div>

        {/* Contact Us */}
        <div
          className={
            "bottom-section__contact-us " +
            (isContactUs ? "bottom-section_underline " : "")
          }
          onClick={() => history.push("/hubungi-kami")}
        >
          Hubungi Kami
        </div>
        {/* Empty space right side */}
        <div className="bottom-section__empty-right"></div>
      </div>
    </div>
  );
}

export default TopNavigationBar;
