import InputContentExpander from 'components/contentExpander/InputContentExpander';
import { validateFunction } from 'helper/validations';
import { useCustomSelector } from 'hooks';
import HomeDisplay from 'modules/ECard/sections/home/HomeDisplay';
import React, { useEffect, useState } from 'react';

import CoverPageContainer from 'components/containers/CoverPageContainer';

function useCoverStep({
    fontList, loadFont,

    musicList, musicURL, isAvail, isMuted, muteUnMute, loadMusic, loadSelectedMusic, audioComp,

    isResetRequired, currentStep,

    cover_init_details, cover_init_styles,
    app_init_details,

    cover_details = {}, setCover_details: setCover_details_props,
    cover_styles = {}, setCover_styles: setCover_styles_props,
    app_details = {}, setApp_details: setApp_details_props,
    event_details, setEvent_details: setEvent_details_props
}) {
    // Validations
    let setCover_details = validateFunction(setCover_details_props);
    let setCover_styles = validateFunction(setCover_styles_props);
    let setApp_details = validateFunction(setApp_details_props);
    let setEvent_details = validateFunction(setEvent_details_props);

    const [currentVisibleContentPos, setCurrentVisibleContentPos] = useState();
    const { ecardEditDetails } = useCustomSelector("app");

    function reset() {
        setCover_details(cover_init_details);
        setCover_styles(cover_init_styles);
        setApp_details(app_init_details);
    }

    useEffect(() => {
        if (currentStep == "cover" && isResetRequired) reset();
    }, [isResetRequired]);

    let input_coverStep = (
        <div className={"inputs__cover-step"}>
            {/* Info */}
            <InputContentExpander
                mainDisplayText={"Maklumat Depan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 0) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(0);
                }}
                isExpanded={currentVisibleContentPos === 0}

                themeType={"darkGrey"}

                onContentReset={() => setCover_details(cover_init_details)}

                contentInputs={[
                    {
                        onContentReset: reset,
                        inputs: [
                            {
                                type: "text",
                                label: "Nama Majlis:",
                                value: cover_details?.eventName,
                                onChange: (value) => {
                                    setCover_details({
                                        ...cover_details,
                                        eventName: value
                                    })
                                },
                            },
                            {
                                type: "text",
                                label: "Name Pengantin Lelaki:",
                                value: cover_details?.bridegroomName,
                                onChange: (value) => {
                                    setCover_details({
                                        ...cover_details,
                                        bridegroomName: value
                                    })
                                },
                            },
                            {
                                type: "text",
                                label: "Name Pengantin Perempuan",
                                value: cover_details?.brideName,
                                onChange: (value) => {
                                    setCover_details({
                                        ...cover_details,
                                        brideName: value
                                    })
                                },
                            },
                            {
                                type: "datePicker",
                                label: "Tarikh:",
                                defaultValue: cover_details?.date,
                                value: cover_details?.date,
                                onChange: (value) => {
                                    setCover_details({
                                        ...cover_details,
                                        date: value
                                    })

                                    // auto fill in event step
                                    setEvent_details({
                                        ...event_details,
                                        eventDate: value
                                    });
                                },
                                inputProps: {
                                    showTimeSelectOnly: false,
                                    showTimeSelect: false,
                                    dateFormat: "MMM dd, yyyy"
                                }
                            },
                        ]
                    }
                ]}
            />

<InputContentExpander
                mainDisplayText={"Lagu"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 1) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(1);
                }}
                isExpanded={currentVisibleContentPos === 1}

                themeType={"darkGrey"}

                otherProps={{
                    expanderContentStyle: { overflow: "visible" }
                }}

                onContentReset={() => setCover_details(cover_init_details)}

                contentInputs={[
                    {
                        onContentReset: reset, //need to change
                        inputs: [
                            {
                                type: "dropdown",
                                label: "Lagu Pilihan:",
                                value: app_details?.music ? app_details?.music : "Tiada",
                                onSelect: (value) => {
                                    setApp_details({
                                        ...app_details,
                                        music: value,
                                        musicURL: loadSelectedMusic(value).url,
                                    });
                                    loadMusic(loadSelectedMusic(value).url)
                                },
                                options: musicList
                            },   
                        ]
                    }
                ]}
            />

            {/* Opacity Styles */}
            <InputContentExpander
                mainDisplayText={"Latar Belakang"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 2) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(2);
                }}
                isExpanded={currentVisibleContentPos === 2}

                themeType={"blue"}

                onContentReset={() => setCover_styles(cover_init_styles)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                type: "slider",
                                label: "Opacity:",
                                defaultValue: cover_styles?.homeBgOpacity * 100,
                                value: cover_styles?.homeBgOpacity * 100,
                                onChange: (event) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        homeBgOpacity: event.target.value / 100
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />

            {/* Font Styles */}
            <InputContentExpander
                mainDisplayText={"Tulisan"}
                onMainDisplayClick={() => {
                    if (currentVisibleContentPos === 3) setCurrentVisibleContentPos(undefined);
                    else setCurrentVisibleContentPos(3);
                }}
                isExpanded={currentVisibleContentPos === 3}

                themeType={"blue"}

                onContentReset={() => setCover_styles(cover_init_styles)}

                contentInputs={[
                    {
                        inputs: [
                            {
                                header: "Event Name",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: cover_styles?.eventNameFont },
                                value: cover_styles?.eventNameFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        eventNameFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: cover_styles?.eventNameFontColor,
                                value: cover_styles?.eventNameFontColor,
                                onChange: (color) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        eventNameFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Name Pengantin Lelaki",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: cover_styles?.bridegroomNameFont },
                                value: cover_styles?.bridegroomNameFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        bridegroomNameFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: cover_styles?.bridegroomNameFontColor,
                                value: cover_styles?.bridegroomNameFontColor,
                                onChange: (color) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        bridegroomNameFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Simbol Dan",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: cover_styles?.andSymbolFont },
                                value: cover_styles?.andSymbolFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        andSymbolFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: cover_styles?.andSymbolFontColor,
                                value: cover_styles?.andSymbolFontColor,
                                onChange: (color) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        andSymbolFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Name Pengantin Perempuan",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: cover_styles?.brideNameFont },
                                value: cover_styles?.brideNameFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        brideNameFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: cover_styles?.brideNameFontColor,
                                value: cover_styles?.brideNameFontColor,
                                onChange: (color) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        brideNameFontColor: color
                                    });
                                }
                            },

                            {
                                header: "Date",
                                type: "dropdown",
                                label: "Fon:",
                                valueStyle: { fontFamily: cover_styles?.dateFont },
                                value: cover_styles?.dateFont,
                                isBottomInputRelated: true,
                                onSelect: (value) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        dateFont: value
                                    });
                                },
                                onLoadMenuItemData: loadFont,
                                options: fontList
                            },
                            {
                                type: "colorPicker",
                                label: "Warna Fon:",
                                defaultValue: cover_styles?.dateFontColor,
                                value: cover_styles?.dateFontColor,
                                onChange: (color) => {
                                    setCover_styles({
                                        ...cover_styles,
                                        dateFontColor: color
                                    });
                                }
                            },
                        ]
                    }
                ]}
            />
        </div>
    );

    //need to add to update data
    const transitionDuration = 500;

    const transitionDelay = 100;

    const defaultOpacityStyle = {
        transition: `opacity ${transitionDuration}ms cubic-bezier(0.4, 0, 1, 1) ${transitionDelay}ms`,
        opacity: 1,
    }

    const opacityStyles = {
        entering: { opacity: 1 },
        entered:  { opacity: cover_styles?.homeBgOpacity },
        exiting:  { opacity: cover_styles?.homeBgOpacity },
        exited:  { opacity: cover_styles?.homeBgOpacity }
        };
  
    let preview_coverStep = (
        <CoverPageContainer musicList={musicList} musicURL={musicURL} isAvail={isAvail} isMuted={isMuted} muteUnMute={muteUnMute} loadMusic={loadMusic} loadSelectedMusic={loadSelectedMusic} app_details={app_details} audioComp={audioComp}>
            <HomeDisplay
                bgImg={ecardEditDetails?.themes?.backgroundOptimized}
                fallbackImg={ecardEditDetails?.themes?.background}
                bgOverlayStyle={{
                    background: ecardEditDetails?.themes?.backgroundColor1,
                }}
                opacityStyles={opacityStyles} //need to add
                defaultOpacityStyle={defaultOpacityStyle}
                transitionDuration={transitionDuration}
        
                titleTxt={cover_details?.eventName}
                titleStyle={{
                    fontFamily: cover_styles?.eventNameFont,
                    color: cover_styles?.eventNameFontColor
                }}
        
                bridegroomStyle={{
                    fontFamily: cover_styles?.bridegroomNameFont,
                    color: cover_styles?.bridegroomNameFontColor
                }}
                andSymbolStyle={{
                    fontFamily: cover_styles?.andSymbolFont,
                    color: cover_styles?.andSymbolFontColor
                }}
                brideStyle={{
                    fontFamily: cover_styles?.brideNameFont,
                    color: cover_styles?.brideNameFontColor
                }}


                bridegroomName={cover_details?.bridegroomName}
                brideName={cover_details?.brideName}
        
                dateStyle={{
                    fontFamily: cover_styles?.dateFont,
                    color: cover_styles?.dateFontColor
                }}
                dateTxt={cover_details?.date}

            /> 
        </CoverPageContainer>
    )

    return {
        input_coverStep, preview_coverStep
    }
}

export default useCoverStep;