import React from "react";
import { useCustomSelector } from "hooks";
import LocationDisplay from "./LocationDisplay";
import { hexToFilterCSS } from "helper/validations";

function Location() {

  const { appInfo } = useCustomSelector("app");
  const mapInfo = appInfo.location;
  const theme = appInfo.themes;

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1
  }

  const mapContainerStyle = {
    borderColor: theme.locationColor1
  }

  const iconTitleStyle = {
    color: theme.locationColor3,
    fontFamily: theme.locationFontFamily1,
  };

  const iconContainerStyle = {
    backgroundColor: theme.locationColor4,
  };

  const iconStyle = {
    filter: hexToFilterCSS(theme.locationColor2),
  };

  return (
    <LocationDisplay
      titleStyle={titleStyle}

      mapContainerStyle={mapContainerStyle}
      iconStyles={iconStyle}
      iconContainerStyle={iconContainerStyle}
      iconFontStyles={iconTitleStyle}

      mapInfo={{ 
        ...mapInfo, 
        place: mapInfo?.place? mapInfo.place: appInfo?.details?.place 
      }}
    />
  );
}

export default Location;
