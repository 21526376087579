import { Button } from "components/buttons/Button";
import AlertModal from "components/notifications/AlertModal";
import HomeDisplay from "modules/ECard/sections/home/HomeDisplay";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { isFunction } from "helper/validations";
import { useCustomSelector } from "hooks";

import CoverPageContainer from "components/containers/CoverPageContainer";

function useCompleteStep({
  cardThemeBgImage,

  cover_details,
  cover_styles,
  app_details,

  musicList, musicURL, isAvail, isMuted, muteUnMute, loadMusic, loadSelectedMusic,

  isAnyInputChanged, onSave,
}) {
  const history = useHistory();
  const location = useLocation();
  const pathname = location?.pathname;
  const pathList = pathname?.split("/");
  const orderId = pathList[pathList?.length - 2];
  const completed = location?.state?.completed;

  const { ecardEditDetails } = useCustomSelector("app");

  //need to add to update data
  const transitionDuration = 500;

  const transitionDelay = 100;

  const defaultOpacityStyle = {
      transition: `opacity ${transitionDuration}ms cubic-bezier(0.4, 0, 1, 1) ${transitionDelay}ms`,
      opacity: 1,
  }

  const opacityStyles = {
      entering: { opacity: 1 },
      entered:  { opacity: cover_styles?.homeBgOpacity },
      exiting:  { opacity: cover_styles?.homeBgOpacity },
      exited:  { opacity: cover_styles?.homeBgOpacity }
      };

  return {
    preview: (
      <div className={"preview__complete-step"}>
        <CoverPageContainer musicList={musicList} musicURL={musicURL} isAvail={isAvail} isMuted={isMuted} muteUnMute={muteUnMute} loadMusic={loadMusic} loadSelectedMusic={loadSelectedMusic} app_details={app_details}>
          <HomeDisplay
            bgImg={ecardEditDetails?.themes?.backgroundOptimized}
            fallbackImg={ecardEditDetails?.themes?.background}
            bgOverlayStyle={{
                background: ecardEditDetails?.themes?.backgroundColor1,
            }}
            opacityStyles={opacityStyles} //need to add
            defaultOpacityStyle={defaultOpacityStyle}
            transitionDuration={transitionDuration}

            titleTxt={cover_details?.eventName}
            titleStyle={{
                fontFamily: cover_styles?.eventNameFont,
                color: cover_styles?.eventNameFontColor
            }}
    
            bridegroomStyle={{
                fontFamily: cover_styles?.bridegroomNameFont,
                color: cover_styles?.bridegroomNameFontColor
            }}
            andSymbolStyle={{
                fontFamily: cover_styles?.andSymbolFont,
                color: cover_styles?.andSymbolFontColor
            }}
            brideStyle={{
                fontFamily: cover_styles?.brideNameFont,
                color: cover_styles?.brideNameFontColor
            }}


            bridegroomName={cover_details?.bridegroomName}
            brideName={cover_details?.brideName}
    
            dateStyle={{
                fontFamily: cover_styles?.dateFont,
                color: cover_styles?.dateFontColor
            }}
            dateTxt={cover_details?.date}
          /> 
        </CoverPageContainer>
      </div>
    ),
    actions: (
      <div className={"actions__complete-step"}>
        <Button
          className={"complete-step__back"}
          content={"Kembali ke akaun"}
          onClick={() => {
            if (isFunction(isAnyInputChanged) && isAnyInputChanged()) {
              AlertModal({
                title: "Kemaskini",
                text: "Anda mahu mengemaskinikan perubahan anda?",
                icon: "warning",
                confirmButtonText: "Simpan",
                showCancelButton: true,
                cancelButtonText: "Buang",
              })
              .then((result) => {
                if (result.isConfirmed){
                  onSave();
                }

                history.push("/akaun-saya");
              });
            } else history.push("/akaun-saya");
          }}
          decoration_isBrightInversePrimary
        />

        {!completed && <Button
          className={"complete-step__pay"}
          content={"Pembayaran"}
          onClick={() => history.push(`/pembayaran/${orderId}`)}
          decoration_isBrightPrimary
        />}
      </div>
    ),
  };
}

export default useCompleteStep;
