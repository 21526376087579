import React from "react";

import { Avatar } from "@material-ui/core";
import ReactTimeago from "react-timeago";
import { Fade } from "react-reveal";

import "assets/scss/components/ecard/message.scss";
import { convertToDate } from "helper/validations/date";

function Message({ id, timestamp, message, user, relationship }) {
  console.log(timestamp)
  console.log(convertToDate(timestamp))
  return (
    <Fade right>
      <div className="message">
        <div className="message__info">
          <div className="message__header d-flex  align-items-center">
            <Avatar
              alt={user}
              src={`https://avatars.dicebear.com/api/identicon/${user}.svg`}
              className="message__headerAvatar"
            />
            <div className="message__headerInfo">
              <div className="message__from">
                <b>{user}</b>
              </div>
              <div className="message__relationship">
                <b>{relationship ? relationship : "Lain-lain"}</b>
              </div>
            </div>
          </div>
          <div className="message__text">
            <p>{message}</p>
          </div>
          <div className="message__timestamp">
            <div>
              <ReactTimeago
                date={convertToDate(timestamp)?.toUTCString()}
                live
              />
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

export default Message;
