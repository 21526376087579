import React from 'react';

import "assets/scss/components/inputs/radioInput.scss";



export function RadioInput(props) {
    const {
        labelClassName,
        label,
        groupName, id,
        value,
        isChecked,
        isDisabled
    } = props;

    return (
        <div className="component-radio-input d-flex align-items-center">
            <input className="component-radio-input__input" type="radio" 
                name={groupName} 
                id={id} value={value}
                onChange={props.onChange}
                checked={isChecked}
                disabled={isDisabled}
            />

            {/* Label */}
            <label htmlFor={id}
                className={"component-radio-input__label " +
                    (labelClassName? labelClassName + " ":"")}
            >
                {label}
            </label>
        </div>
    );
}