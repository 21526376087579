import Backdrop from 'components/ecard/Backdrop';
import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Camera from './Camera';
import Contact from './contact/Contact';
import Content from './content/Content'
import Gallery from './gallery/Gallery';
import Location from './location/Location';
import Preview from './Preview';
import Rsvp from './rsvp/Rsvp';
import Tentative from './tentative/Tentative';
import Wish from './wish/Wish';

function MenuContainer({themes, collectionId, isMenuOpen, setIsMenuOpen}) {

  let { path, url } = useRouteMatch();

  return (
    <>
      <Backdrop show={isMenuOpen}/>
      <div className={"menu-container"}>
          {/* contoh */}
    
        <Switch>
          <Route path={`${path}/preview`}>{themes && <Preview />}</Route>

          <Route path={`${path}/gallery`}>{themes && <Gallery />}</Route>

          <Route path={`${path}/camera`}>{themes && <Camera />}</Route>

          <Route path={`${path}/aturcara`}>{themes && <Tentative />}
          </Route>

          <Route path={`${path}/lokasi`}>{themes && <Location/>}</Route>

          <Route path={`${path}/rsvp`}>
            {themes && <Rsvp colId={collectionId} />}
          </Route>

          <Route path={`${path}/ucapan`}>
            {themes && <Wish colId={collectionId} />}
          </Route>

          <Route path={`${path}/hubungi`}>
            {themes && <Contact colId={collectionId} />}
          </Route>

          <Route path={`${path}`}>
            {themes && <Content colId={collectionId} setIsMenuOpen={setIsMenuOpen}/>}
          </Route>

        </Switch>
      </div>
      
    </>
    
  )
}

export default MenuContainer