import React, { useEffect } from 'react';

import { useCustomSelector } from "hooks";

import { Card } from 'components';
import { getObjectPropertyValue, getQueries, isArrayNotEmpty, isNullUndefined, isStringTrimNotEmpty } from 'helper/validations';
import { SkeletonCardList } from "components";
import useCardCatalogServices from 'dataflow/services/cardCatalog.services';
import { useLocation } from 'react-router';

export default function CardList(props) {

    const { setIsAuthModalVisible } = props;

    const location = useLocation();
    let paramsObj = getQueries(location);
    let color = paramsObj?.["warna"];
    let filters = paramsObj?.["tapis"];
    let themeId = paramsObj?.["tema"];
    
    const {
        cardCatalogList
    } = useCustomSelector("cardCatalog");

    const { getCardCatalogList } = useCardCatalogServices();

    useEffect(() => {
        if (isNullUndefined(cardCatalogList)) {
            getCardCatalogList();
        }
    }, []);

    let catalogListDisplay = [];
    if (isArrayNotEmpty(cardCatalogList)) {

        // Convert to array if only 1 value
        let filters_formatted = [];
        if (isStringTrimNotEmpty(filters)) {
            filters_formatted.push({
            type: "tapis",
            name: filters,
            })
        } else if (isArrayNotEmpty(filters)) {
            let filtersArray = filters.map((filterName) => {
            return {
                type: "tapis",
                name: filterName,
            };
            });
            filters_formatted = filters_formatted.concat(filtersArray);
        }
        if (isStringTrimNotEmpty(color)) {
            filters_formatted.push({
            type: "warna",
            name: color,
            });
        }

        let filteredTheme = cardCatalogList.filter((cardDetails, index) => {
            const eCardTypeId = getObjectPropertyValue(cardDetails, "typeId");
            if (isStringTrimNotEmpty(themeId) && themeId !== '0') {
                return themeId === eCardTypeId;
            }
            return true;
        })

        let filtered = filteredTheme.filter((cardDetails, index) => {

            const eCardDateAdded = new Date(getObjectPropertyValue(cardDetails, "dateAdded"));
            const eCardRating = getObjectPropertyValue(cardDetails, "rating");
            const eCardPopularity = getObjectPropertyValue(cardDetails, "popularity");
            const eCardColor = getObjectPropertyValue(cardDetails, "color");

            const sum = eCardRating.reduce((a, b) => a + b, 0);
            const avg = (sum / eCardRating.length) || 0;

            let msDay = 60*60*24*1000;
            let currentTime = new Date();
            
            //change color name
            return filters_formatted.every((filter) => {
                return  (filter.name === 'Popular' && eCardPopularity >= 5) || (filter.name === 'Menarik' && avg >= 0) || (filter.name === 'Terbaru' && Math.floor((currentTime - eCardDateAdded)/msDay) <= 30 && Math.floor((currentTime - eCardDateAdded)/msDay) >= 0) || (filter.type === 'warna' && filter.name.toLowerCase() === eCardColor) 
            })
        })

        if(filtered.length === 0){
            filtered = filteredTheme
        }

        catalogListDisplay = filtered.map((cardDetails, index) => {
            const id= getObjectPropertyValue(cardDetails, "id");
            const theme = getObjectPropertyValue(cardDetails, "theme");
            const name = getObjectPropertyValue(cardDetails, "title");
            const eCardUrl = getObjectPropertyValue(cardDetails, "exampleEvent");
            

            return (
                <Card 
                    key={id}
                    eCardUrl={`/card/${eCardUrl}`}
                    name={name}
                    setIsAuthModalVisible={setIsAuthModalVisible}
                    themeId={id}

                    theme={theme}
                />
            )
        })
    }else{
        catalogListDisplay = [1,2,3,4,5,6,7,8,9,10].map((n, index) => {
            return(
                <SkeletonCardList key={index} />
            )
        })
        
    }

    return catalogListDisplay;
}