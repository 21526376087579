import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCustomSelector } from "hooks";
import { setcarousel_success } from "dataflow/slices/app.slice";

import "assets/scss/components/ecard/backButton.scss";

function BackButton() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { appInfo, ecardEditDetails } = useCustomSelector("app");
  const rootURL = appInfo?.rootURL;
  const theme = appInfo?.themes ? appInfo?.themes : ecardEditDetails?.themes;

  const iconStyle1 = {
    color: theme?.contentColor1,
  };

  const goBack = () => {
    dispatch(setcarousel_success(1));
    history.push(rootURL);
  };

  return (
    <div className="backButtonBackground d-flex justify-content-center align-items-center">
      <i
        className="fas fa-arrow-left arrow__icon icons"
        onClick={goBack}
        style={iconStyle1}
      ></i>
    </div>
  );
}

export default BackButton;
