import { 
	isNumberValid, isNotNullUndefined, 
	isObject, cloneObject, deepCloneObject, isDeepObjectComparisonEqual
} from ".";

export const validateArray = {
    isArray,
	isArrayEmpty,
	isArrayNotEmpty,
	getArrayLength,
	isArrayLengthEqual,
	hasArrayValue,
	cloneArray,
	isComparisonOfBothArraysEqual,
	isComparisonOfBothArraysNotEqual,
	isElementInArray,
	cloneArrayOfObject,
	deepCloneArray,
	isDeepArrayComparisonEqual
}


export function isArray(val) {
	return Array.isArray(val);
}

export function isArrayEmpty(array) {
	return (isArray(array) && array.length == 0);
}

export function isArrayNotEmpty(array) {
	return (isArray(array) && array.length > 0);
}

export function getArrayLength(array, defaultValue) {
	if (isArray(array)) return array.length;
	if (isNumberValid(defaultValue)) return defaultValue;
	return undefined;
}

export function isArrayLengthEqual(array, equalValue) {
	let arrayLength = getArrayLength(array);

	if (!isNumberValid(arrayLength) || 
		!isNumberValid(equalValue)) 
		return undefined;

	return arrayLength == equalValue;
}

export function hasArrayValue(array, value) {
	return (isArrayNotEmpty(array) && array.includes(value));
}

export function cloneArray(array) {
	if (isArray(array)) return array.slice();
	return [];
}

export function isComparisonOfBothArraysEqual(array1, array2) {
	return JSON.stringify(array1) == JSON.stringify(array2);
}

export function isComparisonOfBothArraysNotEqual(array1, array2) {
	return JSON.stringify(array1) != JSON.stringify(array2);
}

export function isElementInArray(val, elementToFind) {
	if (isArrayNotEmpty(val) && isNotNullUndefined(elementToFind)) {
		return (~val.indexOf(elementToFind));
	}
	return false;
}

export function cloneArrayOfObject(val) {
	let array_new = [];

	if (isArray(val)) {
		val.map((valEntry)=>{ array_new.push(cloneObject(valEntry)); });
	}

	return array_new;
}

export function deepCloneArray(array) {
	if (isArrayNotEmpty(array)) {

		let newArray = array.map((element) => {
			if (isArray(element)) {
				return deepCloneArray(element);
			}
			else if (isObject(element) &&
                    isNotNullUndefined(element)) {
				return deepCloneObject(element);
			}
			else return element;
		});

        return newArray;
	}
	return [];
}

export function isDeepArrayComparisonEqual(arr1, arr2) {
	// iterate the array
	if (isArray(arr1) && isArray(arr2)) {
		let isNonMatchFound = false;

		if (isArrayNotEmpty(arr1) && isArrayNotEmpty(arr2) &&
			getArrayLength(arr1) == getArrayLength(arr2)) {

			for (let i = 0; i < arr1.length; i++) {
				let val1 = arr1[i];
				let val2 = arr2[i];
	
				if (isArray(val1) && isArray(val2)) {
					isNonMatchFound = !isDeepArrayComparisonEqual(val1, val2);
				}
				else if (isObject(val1) && isObject(val2)) {
					isNonMatchFound = !isDeepObjectComparisonEqual(val1, val2);
				}
				else if (val1 !== val2) {
					isNonMatchFound = true;
				}
			}
		}
		else if (getArrayLength(arr1) != getArrayLength(arr2)) {
			isNonMatchFound = true;
		}

		return !isNonMatchFound;
	}
	return arr1 === arr2;
}