import React, { useState, useEffect } from "react";
import { musics } from "helper/constants/musics";
import { isStringNotEmpty, isNotNullUndefined } from "helper/validations";

function useLoadMusic(props = {}) {
  const [musicURL, setMusicURL] = useState("");
  const [isAvail, setIsAvail] = useState(false);
  const [isMuted, setIsMuted] = useState(true);

  function loadMusic(musicURL) {
    if (isNotNullUndefined(musicURL) && isStringNotEmpty(musicURL)) {
      setMusicURL(musicURL);
      setIsAvail(true);
    } else {
      setMusicURL(musicURL);
      setIsAvail(false);
    }
  }

  function loadSelectedMusic(value) {
    let music = musics.filter((music) => {
      return music.value === value;
    });
    return music[0];
  }

  function muteUnMute() {
    setIsMuted(!isMuted);
  }

  return {
    musicList: musics,
    musicURL,
    isAvail,
    isMuted,
    muteUnMute,
    loadMusic,
    loadSelectedMusic,
  };
}

export default useLoadMusic;
