import React from "react";
import "assets/scss/modules/footer/footer.scss";
import facebook_logo from "assets/images/facebook-square-brands.svg";
// import twitter_logo from "assets/images/twitter-square-brands.svg";
import insta_logo from "assets/images/instagram-square-brands.svg";
import email_logo from "assets/images/envelope-square-solid.svg";

function Footer(props) {
  return (
    <div className={"footer d-flex flex-column justify-content-center"}>
      <div
        className={
          "footer-contact-us d-flex flex-column justify-content-center align-items-center"
        }
      >
        <div className={"footer-contact-us-title"}>Hubungi Kami :</div>
        <div className="footer-contact-us-icons">
          <a href="https://www.facebook.com/Ekad-Tepi-105124631441865" target="_blank" rel="noopener noreferrer">
            <img className={"logo"} src={facebook_logo} alt="facebook" />
          </a>
          {/* <a href="">
            <img className={"logo"} src={twitter_logo} alt="twitter" />
          </a> */}
          <a href="https://www.instagram.com/ekadtepimy/" target="_blank">
            <img className={"logo"} src={insta_logo} alt="instagram" />
          </a>
          <a href={`http://` + window?.location?.host + `/hubungi-kami`}>
            <img className={"logo"} src={email_logo} alt="email" />
          </a>
        </div>
        
      </div>
      <div className={"footer-break-line"}></div>
      <div className={"footer-others-details"}>
        {" "}
        Hakcipta Terpelihara © 2020 By Inv Tech
      </div>
    </div>
  );
}

export default Footer;
