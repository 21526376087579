import React, { useEffect, useRef } from "react";

import laptop_banner_webp from "assets/images/Laptop Banner-resize weppy.webp";
import laptop_banner from "assets/images/Laptop Banner-resize.png";

import mobile_banner_webp from "assets/images/New Mobile Banner-resize.webp";
import mobile_banner from "assets/images/New Mobile Banner-resize.png";

import { ImgWithFallback } from "components";
import useWindowSize from "hooks/useWindowSize";


export function Highlight(props) {

  const {width, height} = useWindowSize()
  console.log(width)

  return (
    <div
      className={
        "home__highlight d-flex align-items-center justify-content-center"
      }
    >
      <ImgWithFallback className={"highlight__bg"} src={width > 480 ? laptop_banner_webp : mobile_banner_webp} fallback={width > "480" ? laptop_banner : mobile_banner} />
    </div>
  );
}
