import {
  getObjectPropertyValue,
  getQueries,
  isArrayNotEmpty,
} from "helper/validations";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function ThemeList(props) {

  let history = useHistory();

  let location = useLocation();
  let paramsObj = getQueries(location);
  let themeId = paramsObj?.["tema"]? paramsObj?.["tema"]:0;

  let themeList = [
    "Semua",
    "Tema Islamik",
    "Tema Bunga",
    "Tema Simple",
    "Tema Klasik",
    "Tema Karya",
  ];
  let themeListDisplay = [];
  if (isArrayNotEmpty(themeList)) {
    themeListDisplay = themeList.map((themeName, idx) => {
      let isSelected = themeId == idx;

      return (
        <div key={idx} className={"theme-list__item"}>
          <div
            className={
              "item__wrapper d-flex align-items-center justify-content-center " +
              (isSelected ? "theme-list__item_selected " : "")
            }
            onClick={() =>
              history.push(
                location?.pathname ? location?.pathname + "?tema=" + idx : "/"
              )
            }
          >
            <span className={"item__name"}>{themeName}</span>
          </div>
        </div>
      );
    });
  }

  return (
    <div
      className={
        "card-catalog__theme-list d-flex flex-wrap justify-content-center align-items-center text-center"
      }
    >
      {themeListDisplay}
    </div>
  );
}
