import { isArrayNotEmpty } from "helper/validations";
import React, { useEffect, useState } from "react";

import { Order } from "components/Order";
import { useCustomSelector } from "hooks";
import { useHistory, useLocation } from "react-router";
import { Button } from "components/buttons/Button";

function Cards(props) {

  const location = useLocation();
  const history = useHistory();

  // current tab selection
  const [currentSelection, setCurrentSelection] = useState(location.state?.selectionOrder ? location?.state?.selectionOrder : "draft");

  const {
    getuserOrderList_requesting,
    userOrderList
  } = useCustomSelector("userOrder")

  let selectionList = [
    {
      header: "Draf EkadTepi",
      id: "draft",
    },
    {
      header: "Live EkadTepi",
      id: "complete",
    },
  ];

  let display = "";
  switch (currentSelection) {
    case "draft":
      {
        let filtered = userOrderList?.filter(
          (order, index) => order.orderStatus == "created"
        );
        display = isArrayNotEmpty(filtered)? 
          filtered.map((order, index) => (
            <Order
              key={order.id}
              id={order.id}
              eventId={order.eventId}
              themesId={order.themesId}
              dateCreated={order.dateCreated}
              status={"Sila buat pembayaran"}
              draft={true}
              name={order.orderName}
              lastUpdated={order.lastUpdated}
            />
          )
        ): (
          <div className={"missing-ecard"}>
            <span className={"missing-ecard__msg"}>Tiada EkadTepi dijumpa. Sila pilih dari tema EkadTepi kami.</span>
            <Button
              className={"missing-ecard__action"}
              content={"Tema EkadTepi"}
              onClick={() => history.push("contoh-tema")}
              decoration_isBrightPrimary
            />
          </div>
        );
      }
      break;
    case "complete":
      {
        let filtered = userOrderList?.filter(
          (order, index) => order.orderStatus == "completed"
        );
        display = isArrayNotEmpty(filtered)? 
          filtered.map((order, index) => (
            <Order
              key={order.id}
              id={order.id}
              eventId={order.eventId}
              themesId={order.themesId}
              dateCreated={order.dateCreated}
              draft={false}
              link={`/card/${order.eventId}`}
              name={order.orderName}
              lastUpdated={order.lastUpdated}
              paymentDate={order.payment.time}
              amount={order.payment.amount}
            />
          )
        ): (
          <div className={"missing-ecard"}>
            <span className={"missing-ecard__msg"}>Tiada EkadTepi dijumpa. Sila teruskan pembayaran dalam 'Draf EkadTepi' anda.</span>
          </div>
        );
      }
      break;
  }

  return (
    <div className={"display__card-info"}>
      {/* Page Title */}
      <div
        className={
          "card-info__header d-flex align-items-center justify-content-center"
        }
      >
        <p className={"header__title"}>Senarai EkadTepi</p>
      </div>

      {/* Content */}
      <div
        className={
          "card-info__content d-flex flex-column justify-content-center align-items-center"
        }
      >
        <div className={"card-info__selections d-flex justify-content-center"}>
          {isArrayNotEmpty(selectionList) &&
            selectionList.map((info, idx) => {
              let header = info?.header;
              let id = info?.id;
              let isSelected = currentSelection === id;

              return (
                <div key={id} className={"selections__item-wrapper text-center "}>
                  <div
                    className={
                      "selections__item " +
                      (isSelected ? "selection__item_selected " : "")
                    }
                    onClick={() => setCurrentSelection(id)}
                  >
                    {/* Header */}
                    <div className={"item__header"}>{header}</div>
                  </div>
                </div>
              );
            })}
        </div>

        {/* Order Display */}
        <div
          className={
            "card-info__details d-flex flex-column align-items-center overflow-auto"
          }
        >
          {display}
        </div>
      </div>
    </div>
  );
}

export default Cards;
