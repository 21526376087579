import React, { useEffect, useState } from "react";
import { useCustomSelector } from "hooks";
import { useHistory } from "react-router-dom";
import { firestoreDB } from "dataflow/services/firebase";
import { Popover } from "react-bootstrap";
import GalleryDisplay from "./GalleryDisplay";

function Gallery() {
  const { appInfo } = useCustomSelector("app");
  const rootURL = appInfo.rootURL;
  const eventId = appInfo.eventId;
  const theme = appInfo.themes;

  let history = useHistory();

  const [medias, setMedias] = useState([]);

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1,
  };

  const iconStyle = {
    color: theme.contentColor1,
  };

  useEffect(() => {
    let albumsRef = firestoreDB().collection("albums");
    albumsRef
      .where("eventId", "==", eventId)
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) =>
        setMedias(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        )
      );
    return () => {};
  }, [eventId]);

  const background1 = {
    background: theme.backgroundColor,
  };

  const renderPopover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Muatnaik gambar pertama</Popover.Title>
      <Popover.Content>
        Kongsikan momen anda ketika berada di majlis bersama pengantin.
      </Popover.Content>
    </Popover>
  );

  console.log(medias)
  return (
    <GalleryDisplay
      bgOverlayStyle={background1}
      titleStyle={titleStyle}
      medias={medias}
      mediaIconStyle={iconStyle}
      renderPopover={renderPopover}
      onTimelineAddClick={() => history.push(`${rootURL}/camera`)}
      onMediaAddIconClick={() => history.push(`${rootURL}/camera`)}
    />
  );
}

export default Gallery;
