export const endpointUrls = {
  //app services
  getCardData: "/user/card",
  editCardData: "/user/card-edit",
  updateCardData: "/ubahsuai-ekad/selesai",

  //accountDetails services
  deleteCardWish: "/padam-ucapan",
  deleteCardMedia: "/padam-media",
  //signIn account
  getMyAccountData: "/akaun-saya", //change implementation direct connection (react -> firestore)
  updateMyAccountData: "/akaun-saya/update", //change implementation direct connection (react -> firestore)
  //forgotPassword.jsx
  resetPassword: "/reset-password",
  //need to check again
  verifyEmail: "/verify-email",

  //cardCatalog services
  getCardCatalog: "/contoh-tema",
  //Card.jsx
  postAddCardtoCart: "/contoh-tema/simpan-kad/",

  //homePage services
  getCardDesignPreviewList: "", //not implement yet

  //aboutUs page
  postContactUsEmail: "/hubungi-kami",

  //Payment.jsx
  getBankList: "/bank-fpx",
  postOnlinePayment: "/pembayaran-online",
  sendReceipt: "/resit",
};
