import React, { useState } from 'react';

import { ECardPreviewModal } from 'components';

import { isNotMobile, isMobile, isStringTrimNotEmpty } from 'helper/validations';



export function useCardPreview(props) {
    const {
        eCardUrl
    } = props;
    
    const [isModalVisible, setIsModalVisible] = useState(false);

    function openPreview() {
        if (isStringTrimNotEmpty(eCardUrl)) {
            if (isMobile()) {
                // Open in new tab
                window.open(eCardUrl, "_blank");
            }
            else {
                // open in modal
                setIsModalVisible(true);
            }
        }
    }


    let modalDisplay = "";
    if (isNotMobile() && isModalVisible &&
        isStringTrimNotEmpty(eCardUrl)) {
        
        modalDisplay = (
            <ECardPreviewModal
                isModalVisible={isModalVisible}
                eCardUrl={eCardUrl}
                onHide={() => setIsModalVisible(false)}
            />
        )
    }

    return {
        modalDisplay,
        openPreview
    }
}