import React, { useEffect, useRef, useState } from 'react';
import "assets/scss/components/inputs/colorPicker.scss";
import { SketchPicker } from 'react-color';
import { generateUniqueId, isStringTrimNotEmpty } from 'helper/validations';
import { Overlay, Tooltip } from 'react-bootstrap';

function ColorPicker({
    className, triggerWrapperClassName, triggerClassName, labelClassName,

    label, isLabelOnLeftSide,
    defaultColor, color,

    // Functions
    onTriggerClick = () => {},
    onColorChange = () => {}
}) {
    const triggerId = useRef(generateUniqueId("component-color-picker"));
    const colorPickerId = useRef(generateUniqueId("component-color-picker__color-picker"));
    const [isColorPickerVisible, setIsColorPickerVisible] = useState();
    const target = useRef();

    useEffect(() => {
        function handleOutsideClick(e) {
            let triggerEle = document.getElementById(triggerId.current);
            let colorPickerEle = document.getElementById(colorPickerId.current);

            if (triggerEle && colorPickerEle && !(triggerEle?.contains(e.target) || colorPickerEle.contains(e.target))) {
                setIsColorPickerVisible(false);
            }
        }
        window.addEventListener("click", handleOutsideClick);
        return () => window.removeEventListener("click", handleOutsideClick);
    })

    return (
        <div className={"component-color-picker " +
                (className? className + " ":"") +
                (isLabelOnLeftSide? "d-flex align-items-center ":"")}>
                
            {/* Label */}
            {isStringTrimNotEmpty(label) && (
                <div className={"component-color-picker__label " +
                        (labelClassName? labelClassName + " ":"")}>
                    {label}
                </div>
            )}

            <Overlay
                target={target.current}
                // onToggle={() => setIsColorPickerVisible(!isColorPickerVisible)}
                show={isColorPickerVisible}
                key={"popover-positioned"}
                // trigger={"click"}
                placement={"top"}
            >
                {(props) => (
                    <Tooltip className={"component-color-picker__tooltip"} id={colorPickerId.current} {...props}>
                        <SketchPicker 
                            className={"component-color-picker__color-component"}
                            presetColors={[{
                                color: defaultColor
                            }]}
                            color={color}
                            onChange={(value) => {
                                onColorChange(value?.hex)
                            }}
                            disableAlpha 
                        />
                    </Tooltip>
                )}
            </Overlay>

            {/* Trigger */}
            <div id={triggerId.current} ref={target} className={"component-color-picker__trigger-wrapper " +
                    (triggerWrapperClassName? triggerWrapperClassName + " ":"")}>
                <div className={"trigger-wrapper__trigger " +
                        (triggerClassName? triggerClassName + " ":"")}
                    style={{
                        backgroundColor: color
                    }}
                    onClick={() => {
                        onTriggerClick();
                        setIsColorPickerVisible(true);
                    }}
                />
            </div>
        </div>
    );
}

export default ColorPicker;