import React, { useEffect, useRef } from 'react'
import IntlTelInput from 'react-intl-tel-input';
import { generateUniqueId } from "helper/validations/string";

import "assets/scss/components/inputs/telInput.scss";

export function TelInput(props) {
    const {
        className, 
        
        id, labelClassName,
        label,
        
        
        inputClassName,
        onChange,
        value,
        isDisabled,
        isSubmissionAttempted,
        
        errorMsg,

        isLabelAtTheSide, isMarginBottomOptional
    } = props;

    return (
        <div className={className}> 
            <label htmlFor={id}
                className={"component-input__label " +
                    (labelClassName? labelClassName + " ":"") + 
                    (isMarginBottomOptional? "mb-0 ":"") +
                    (isLabelAtTheSide? "d-flex align-items-center ":"")}
            >
                {label}
            </label>

            <IntlTelInput
                fieldId={id? id: generateUniqueId("intl-tel-input")}
                containerClassName="intl-tel-input"
                inputClassName={"component-input__input " + (inputClassName? inputClassName + " ":"")}
                defaultCountry={"my"}
                formatOnInit
                value={value? value:""}
                onPhoneNumberChange={((isValid, newNumber, country, fullNumber) =>
                    onChange(newNumber, fullNumber))
                }
                disabled={isDisabled}
            />

        </div>
    )
}
