import React from "react";
import { useCustomSelector } from "hooks";

import ContactDisplay from "./ContactDisplay";

function Contact() {
  const { appInfo } = useCustomSelector("app");
  const contacts = appInfo.contact;
  const theme = appInfo.themes;

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1,
  };

  const background1 = {
    background: theme.backgroundColor2,
    border:
      theme.border &&
      `solid 3px ${theme.contentColor1}`,
  };

  const border1 = {
    border: `solid ${theme.contactColor2}`,
  };

  const fontStyle1 = {
    color: theme.contactColor1,
    fontFamily: theme.contactFontFamily1,
  };

  const fontStyle2 = {
    color: theme.contactColor2,
    fontFamily: theme.contactFontFamily2,
  };

  return (
    <ContactDisplay
      bgOverlayStyle={background1}

      titleStyle={titleStyle}
      contacts={contacts}
      
      borderStyle={border1}
      nameStyle={fontStyle1}
      relationshipStyle={fontStyle2}
    />
  );
}

export default Contact;
