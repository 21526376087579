import { useRequest } from "./config";
import { endpointUrls } from "helper/constants/endpointUrls";

export default function useAccountDetailsService() {
  const { deleteReq } = useRequest();

  function deleteWish({ wishId, eventId }) {
    deleteReq({
      url: `${endpointUrls.deleteCardWish}?eventId=${eventId}&wishId=${wishId}`,
    });
  }

  function deleteMedia({ mediaId, fileName, eventId }) {
    deleteReq({
      url: `${endpointUrls.deleteCardMedia}?mediaId=${mediaId}&fileName=${fileName}&eventId=${eventId}`,
    });
  }

  return {
    deleteWish,
    deleteMedia,
  };
}
