import React from 'react'
import { useLocation } from 'react-router-dom';
import InnerHTML from 'dangerously-set-html-content'

function Loading() {

    const location = useLocation();

    const html = location.state.data;

    return (
        <div className={"loading"}>
            <InnerHTML html={html}/>
            <div className={"loading__notice d-flex mx-auto my-auto p-2"}>
                <span className={"notice-message"}>PLEASE WAIT</span>
                <svg className={"notice-svgLoader"} viewBox="0 0 1024 1024" width="10em" height="10em">
                    <circle cx="100" cy="100" r="75" />
                </svg>
            </div> 
        </div> 
    )
}

export default Loading
