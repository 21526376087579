import React, { useState, useEffect } from "react";
import { firestoreDB, storage } from "dataflow/services/firebase";
import { useCustomSelector } from "hooks";

import RsvpDisplay from "./RsvpDisplay";

function Rsvp({ colId }) {
  const [sessions, setSessions] = useState([]);
  const { setappInfo_requesting, appInfo } = useCustomSelector("app");
  const eventId = appInfo.eventId;
  const theme = appInfo.themes;

  const titleStyle = {
    color: theme.contentColor1,
    fontFamily: theme.contentFontFamily1
  }

  const sessionTimeStyle = {
    color: theme.rsvpColor2,
    fontFamily: theme.rsvpFontFamily2,
  };

  const sessionTitleStyle = {
    color: theme.rsvpColor1,
    fontFamily: theme.rsvpFontFamily1,
  };

  const totalStyle = {
    color: theme.rsvpColor3,
    fontFamily: theme.rsvpFontFamily3,
  };

  const buttonStyle = {
    color: theme.rsvpColor4,
    backgroundColor: theme.rsvpColor5,
    fontFamily: theme.rsvpFontFamily4,
  };

  useEffect(() => {

    //update the reservation availibility in real time
    firestoreDB()
      .collection(colId)
      .doc(eventId)
      .collection("rsvp")
      .orderBy("timeend", "asc")
      .onSnapshot((snapshot) =>
        setSessions(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            session: doc.data(),
          }))
        )
      );
  }, []);

  

  return (
    <RsvpDisplay
      titleStyle={titleStyle}

      sessionProps={{
        titleStyle: sessionTitleStyle,
        timeStyle: sessionTimeStyle,
        totalStyle: totalStyle,
        buttonStyle: buttonStyle
      }}

      sessions={sessions}
      colId={colId}
      eventId={eventId}
    />
  );
}

export default Rsvp;
