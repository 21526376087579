import React, { useEffect, useState } from "react";

import "assets/scss/components/ecard/typewriter.scss";

function Typewriter({
  animation,
  delay = 180,
  callback,
  isPlaying = true, //set animation to play or pause
  blinker,
  phase = true, //whether text is static or in animation
  text = "",
}) {
  const [typing, setTyping] = useState("");

  useEffect(() => {
    let timeout = () => {};
    if (!phase) {
      setTyping(text);
    } else {
      timeout = () =>
        setTimeout(() => {
          if (isPlaying) {
            setTyping(text.slice(0, typing.length + 1));
          }
        }, delay);

      timeout();
      if (typing === text) {
        callback();
      }
    }

    return () => {
      if (phase) {
        clearTimeout(timeout());
      }
    };
  }, [typing, text, isPlaying]);

  return (
    <>
      {typing}
      <span className={"typewriter " + (phase && isPlaying ? "" : "")}></span>
    </>
  );
}

export default Typewriter;
