import React from 'react'

import "assets/scss/components/inputs/sliderInput.scss";
import { validateFunction, validateNullUndefined } from 'helper/validations';

function SwitchInput({
    className, labelClassName, inputClassName,

    label, id,

    value,
    isMarginBottomOptional, isLabelAtTheSide,
    isDisabled,


    // Functions
    onChange: onChange_props,
}) {

    let onChange = validateFunction(onChange_props);
  return (
    <div className={"custom-control custom-switch component-switch-input d-flex " + (className? className + " ":"")}>

      <input className={"custom-control-input component-switch-input__input " + (inputClassName? inputClassName + " ":"")} type="checkbox"
        id={id} checked={value}
        onChange={onChange}
        disabled={isDisabled}
      ></input>

    <label for={id} className={"custom-control-label component-switch-input__label " + (labelClassName? labelClassName + " ":"")}>
        {label}
    </label>

    </div>
  )
}

export default SwitchInput