import React from "react";
import default_background from "assets/images/background.png";
import moment from "moment/min/moment-with-locales";
import { convertToDate, isDateValid } from "helper/validations/date";
import Typewriter from "components/ecard/Typewriter";
import { Transition } from "react-transition-group";
import { ImgWithFallback } from "components";
import { isStringNotEmpty } from "helper/validations";

export default function HomeDisplay({
  bgImg,
  fallbackImg,
  bgOverlayStyle,
  opacityStyles,
  defaultOpacityStyle,
  transitionDuration,

  titleTxt,
  titleStyle,

  bridegroomStyle,
  andSymbolStyle,
  brideStyle,

  bridegroomName,
  brideName,

  dateStyle,
  dateTxt,

  isPhase = false,
  callback = () => {},

  play1 = false,
  play2 = false,
  play3 = false,
  play4 = false,
  play5 = false,

  setPlay1 = () => {},
  setPlay2 = () => {},
  setPlay3 = () => {},
  setPlay4 = () => {},
  setPlay5 = () => {},

  setIsLoaded = () => {},
  setIsMuted = () => {},
}) {
  return (
    <div className="general_background m-auto">
      <Transition in={true} appear={true} timeout={transitionDuration}>
        {(state) => (
          <ImgWithFallback
            style={{ ...defaultOpacityStyle, ...opacityStyles[state] }}
            src={bgImg}
            fallback={fallbackImg ? fallbackImg : default_background}
            onLoad={() => {
              setIsLoaded(true);
              // setIsMuted(false);
            }}
          />
        )}
      </Transition>

      <div className="white_background" style={bgOverlayStyle}>
        <div className="home__containerBody m-auto">
          <div className="home__containerBodyTitle">
            <h3 className="home__text3" style={titleStyle}>
              <Typewriter
                phase={isPhase}
                text={titleTxt}
                isPlaying={play1}
                callback={() => {
                  setPlay1(false);
                  setPlay2(true);
                }}
              />
            </h3>
          </div>

          <div className="home__containerBodyName">
            <h1 className="home__text1" style={bridegroomStyle}>
              <Typewriter
                phase={isPhase}
                text={bridegroomName}
                isPlaying={play2}
                callback={() => {
                  setPlay2(false);
                  setPlay3(true);
                }}
              />
            </h1>
            <h1 className="home__text1" style={andSymbolStyle}>
              <Typewriter
                phase={isPhase}
                text={(isStringNotEmpty(bridegroomName)  && isStringNotEmpty(brideName)) ? "&" : ""}
                isPlaying={play3}
                callback={() => {
                  setPlay3(false);
                  setPlay4(true);
                }}
              />
            </h1>
            <h1 className="home__text1" style={brideStyle}>
              <Typewriter
                phase={isPhase}
                text={brideName}
                isPlaying={play4}
                callback={() => {
                  setPlay4(false);
                  setPlay5(true);
                }}
              />
            </h1>
          </div>

          <div className="home__containerBodyDate typewriterIn">
            <h3 className="home__text3" style={dateStyle}>
              <Typewriter
                phase={isPhase}
                text={
                  isDateValid(dateTxt) &&
                  moment(convertToDate(dateTxt))
                    .locale("ms")
                    .format("DD MMM, YYYY")
                }
                isPlaying={play5}
                callback={() => {
                  setPlay5(false);
                  setTimeout(() => {
                    callback(1);
                  }, 2500);
                }}
              />
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
