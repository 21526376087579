import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "components/buttons/Button";

import { isUserLoggedIn } from "helper/validations";
import { UserAuthenticationModal } from "components/modals/UserAuthenticationModal";
import { useDispatch } from "react-redux";
import { useCustomSelector } from "hooks";
import { setIsUserWebsiteLoggedIn } from "dataflow/slices/website.slice";

function Pending(props) {

  let history = useHistory();
  const dispatch = useDispatch();

  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const [isAuthLogin, setIsAuthLogin] = useState(true);

  const { isUserWebsiteLogin } = useCustomSelector("website");

  const handleClick = () => {
    isUserWebsiteLogin
      ? history.push({
          pathname: "/akaun-saya",
          state: { selection: "card", selectionOrder: "complete" },
        })
      : setIsAuthModalVisible(true);
  };

  return (
    <div className="pending">
      {/* Login/Sign Up Modals */}
      <UserAuthenticationModal
        isVisible={isAuthModalVisible}
        isLogin={isAuthLogin}
        setIsLogin={setIsAuthLogin}
        onHide={() => {
          setIsAuthModalVisible(false);
        }}
        onLoginSuccessful={() => {
          dispatch(setIsUserWebsiteLoggedIn(isUserLoggedIn()));
        }}
      />

      <div
        className={
          "pending__body d-flex flex-column align-items-center justify-content-space-evenly"
        }
      >
        <div className="pending-icons  d-flex flex-column align-items-center justify-content-center">
          <i className={"fas fa-spinner pending__body-icon icons"}></i>
          <h3 className={"pending__body-headers text-center"}>
            Pembayaran Anda Diproses
          </h3>
        </div>

        <div className="pending-details">
          <div
            className={
              "pending__body-content d-flex flex-column align-items-center justify-content-center"
            }
          >
            <div className={"pending__body-content-1 text-center"}>
              <p>
                Pembayaran anda sedang diproses. Sekiranya terdapat masalah
                berkaitan pembayaran, sila emel kami untuk tindakan lanjut.
              </p>
            </div>

            {/* <div className={"pending__body-content-2"}>
              <p>Order Total: RM70.00</p>
              <p>Payment Method: Online Banking</p>
              <p>Order Number: {orderId}</p>
              <p>Email: support@ekadtepi.com</p>
            </div> */}
          </div>
        </div>
        {/* Go to account button */}
        <div className={"w-100 d-flex justify-content-center"}>
          <Button
            className={"receipt__account-button"}
            content={"Kembali ke akaun"}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}

export default Pending;
