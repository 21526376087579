import { configureStore } from '@reduxjs/toolkit';
import homeReducer from './home.slice';
import cardCatalogReducer from './cardCatalog.slice';
import personalInfoReducer from './personalInfo.slice';
import userOrderReducer from './userOrder.slice';
import userCardReducer from './userCard.slice';
import bookingReducer from './booking.slice';
import cameraReducer from './camera.slice';
import appReducer from './app.slice';
import websiteReducer from './website.slice';

const store = configureStore({
  reducer: {
    home: homeReducer,
    cardCatalog: cardCatalogReducer,
    personalInfo: personalInfoReducer,
    userOrder: userOrderReducer,
    userCard: userCardReducer,
    booking: bookingReducer,
    camera: cameraReducer,
    app: appReducer,
    website: websiteReducer,
  },
});

export default store;