import React from 'react'

export function ImgWithFallback({src, fallback, type='image/webp', ...delegated}) {

  return (
    <picture {...delegated}>
        <source srcSet={src} type={type}/>
        <img src={fallback} {...delegated}/>
    </picture>
  )
}

