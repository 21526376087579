import React from "react";

export function RegistrationSteps(props) {
  return (
    <div className={"home__steps-to-register container"}>
      {/* Header */}
      <div className={"steps-to-register__header text-center"}>
        <span>
          Cara dapatkan <span className="EkadTepi">EkadTepi</span>
        </span>
      </div>

      {/* Sub-Header */}
      <div
        className={
          "steps-to-register__sub-header d-flex justify-content-center"
        }
      >
        <p className="steps-to-register_text text-center">
          Hanya dengan 3 langkah mudah, anda mampu memiliki kad jemputan digital
          terbaik untuk majlis anda.
        </p>
      </div>

      {/* Steps */}
      <div
        className={
          "steps-to-register__steps d-flex align-items-center justify-content-center"
        }
      >
        {/* Step 1 */}
        <div
          className={
            "steps__one d-flex flex-column justify-content-center align-items-center"
          }
        >
          {/* Number */}
          <div className={"number"}>1.</div>

          {/* Description */}
          <div className={"description"}>
            Daftar masuk dan pilih tema yang anda berminat.
          </div>
        </div>

        {/* Step 2 */}
        <div
          className={
            "steps__two d-flex flex-column justify-content-center align-items-center"
          }
        >
          {/* Number */}
          <div className={"number"}>2.</div>

          {/* Description */}
          <div className={"description"}>
            Reka EkadTepi mengikut kreativiti anda.
          </div>
        </div>

        {/* Step 3 */}
        <div
          className={
            "steps__three d-flex flex-column justify-content-center align-items-center"
          }
        >
          {/* Number */}
          <div className={"number"}>3.</div>

          {/* Description */}
          <div className={"description"}>Proses pembayaran dan selesai.</div>
        </div>
      </div>
    </div>
  );
}
