import { createSlice } from '@reduxjs/toolkit';
import { isObject } from 'helper/validations';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    appInfo: null,
    carouselIndex: 0,
  },
  reducers: {
    setappInfo_requesting: (state) => {
      state.setappInfo_requesting = true;
      state.setappInfo_status = false;
      state.setappInfo_errorMsg = undefined;
    },
    setappInfo_success: (state, action) => {
      state.setappInfo_requesting = false;
      state.setappInfo_status = true;
      state.appInfo = {
        ...action.payload.data,
        ...(isObject(action.payload.otherData)? action.payload.otherData:{})
      };
    },
    setappInfo_failure: (state, action) => {
      state.setappInfo_requesting = false;
      state.setappInfo_errorMsg = action.payload;
    },

    getEcardEditDetails_requesting: (state) => {
      state.getEcardEditDetails_requesting = true;
      state.getEcardEditDetails_status = false;
      state.getEcardEditDetails_errorMsg = undefined;
    },
    getEcardEditDetails_success: (state, action) => {
      state.getEcardEditDetails_requesting = false;
      state.getEcardEditDetails_status = true;
      state.ecardEditDetails = {
        ...action.payload.data,
        ...(isObject(action.payload.otherData)? action.payload.otherData:{})
      };
    },
    getEcardEditDetails_failure: (state, action) => {
      state.getEcardEditDetails_requesting = false;
      state.getEcardEditDetails_errorMsg = action.payload;
    },

    setcarouselIndex_requesting: (state) => {
      state.setcarouselIndex_requesting = true;
      state.setcarouselIndex_status = false;
      state.setcarouselIndex_errorMsg = undefined;
    },
    setcarousel_success: (state, action) => {
      state.setcarouselIndex_requesting = false;
      state.setcarouselIndex_status = true;
      state.carouselIndex = action.payload;
    },
    setcarousel_failure: (state, action) => {
      state.setcarouselIndex_requesting = false;
      state.setcarouselIndex_errorMsg = action.payload;
    },

    updateEcard_requesting: (state) => {
      state.updateEcard_requesting = true;
      state.updateEcard_status = false;
      state.updateEcard_errorMsg = undefined;
    },
    updateEcard_success: (state, action) => {
      state.updateEcard_requesting = false;
      state.updateEcard_status = true;
    },
    updateEcard_failure: (state, action) => {
      state.updateEcard_requesting = false;
      state.updateEcard_errorMsg = action.payload;
    },
  },
});

export const { 
  setappInfo_requesting, setappInfo_success, setappInfo_failure, 
  getEcardEditDetails_requesting, getEcardEditDetails_success, getEcardEditDetails_failure, 
  setcarouselIndex_requesting, setcarousel_success, setcarousel_failure,
  updateEcard_requesting, updateEcard_success, updateEcard_failure,
} = appSlice.actions;

export default appSlice.reducer;
