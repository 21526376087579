import React from 'react'
import { SkeletonElement } from './SkeletonElement'
import { Shimmer } from './Shimmer';

import "assets/scss/components/skeletons/skeletonCardList.scss";

export function SkeletonCardList(props) {

    const { theme } = props;

    const themeClass = theme || 'light'

    return (
        <div className={`skeleton-wrapper ${themeClass}`}>
            <div className={'skeleton-card-list d-flex flex-column justify-content-center align-items-center'}>
                <SkeletonElement type={'thumbnail'} />
                <SkeletonElement type={'title'} />
            </div>
            <Shimmer />
        </div>
    )
}

