import { ImgWithFallback } from 'components/image';
import React from 'react'

function MenuPageContainer(props) {

    const {children, ecardEditDetails} = props;

    let menuContainerStyle = {
        zIndex: 11,
        flex: 1,
        position: "absolute",
        height: "85%",
        width: "80%",
        top: "50%",
        right: "50%",
        transform: "translate(50%, -50%)",
        borderRadius: "30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "#f6f6f6",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1), 0 12px 28px rgba(0, 0, 0, 0.2)",
    }
    console.log(ecardEditDetails)

    return (
        <div className={"general_background"}>

            <ImgWithFallback
                src={ecardEditDetails?.themes?.background}
                fallback={ecardEditDetails?.themes?.background}
            />
            <div className={"menu-container"} style={menuContainerStyle}>  
                {children}
            </div>
        </div>
    )
}

export default MenuPageContainer