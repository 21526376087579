import { Button } from "components/buttons/Button";
import { Card } from "components/Card";
import Session from "components/Session";
import { isArrayNotEmpty } from "helper/validations";
import { useCustomSelector } from "hooks";
import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

function RSVP({ setCurrentSelection }) {
  let { path, url } = useRouteMatch();

  console.log(url)

  const { userCardList } =
    useCustomSelector("userCard");

  const [cards, setCards] = useState([]);

  useEffect(() => {
    let events = userCardList?.filter(
      (card, index) => card.eventStatus === "publish"
    );
    setCards(events);
  }, []);

  return (
    <div className={"display__rsvp-info"}>
      {/* Page Title */}
      <div
        className={
          "rsvp-info__header d-flex align-items-center justify-content-start"
        }
      >
        <p className={"header__title"}> 1. Pilih Ekadtepi</p>
      </div>

      {/* Page Content */}
      <div
        className={
          "rsvp-info__content d-flex flex-column justify-content-center align-items-center w-100"
        }
      >
        {/* List of Ecard */}
        {isArrayNotEmpty(cards)? (
          <div className={"rsvp-info__content-ecard  d-flex overflow-auto"}>
            {cards.map((card) => (
              <div
                key={card.id}
                className={"content-ecard__wrapper text-align-center "}
              >
                <Link to={`${url}/${card.id}`}>
                  <Card
                    key={card.id}
                    eCardUrl={`${url}/${card.id}`}
                    height={"244px"}
                    width={"180px"}
                    userOrder
                    eventId={card.id}
                    theme={card.themes}
                    homeData={card.home}
                  />
                </Link>
              </div>
            ))}
          </div>
        ):(
          <div className={"missing-ecard"}>
            <span className={"missing-ecard__msg"}>Tiada 'Live EkadTepi' dijumpa. Sila teruskan pembayaran dalam 'Draf EkadTepi' anda.</span>
            <Button
              className={"missing-ecard__action"}
              content={"Draf EkadTepi"}
              onClick={() => setCurrentSelection("card")}
              decoration_isBrightPrimary
            />
          </div>
        )}
      </div>

      {/*Session and list viewer*/}
      <div
        className={
          "content-ecard__header d-flex align-items-center justify-content-start"
        }
      >
        <p className={"header__title"}> 2. Pilih Sesi</p>
      </div>

      <Switch>
        <Route exact path={path}>
          <div className="content-ecard__rsvp d-flex justify-content-center align-items-center">
            <div className="content-ecard__rsvp-notification d-flex justify-content-center align-items-center">
              <h4>Sila pilih EkadTepi</h4>
            </div>
          </div>
        </Route>
        <Route path={`${path}/:eventId`}>
          <Session />
        </Route>
      </Switch>
    </div>
  );
}

export default RSVP;
