import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const userCardSlice = createSlice({
    name: 'userCard',
    initialState,
    reducers: {
        getuserCardList_requesting: (state) => {
            state.getuserCardList_requesting = true;
            state.getuserCardList_status = false;
            state.getuserCardList_errorMsg = undefined;
        },
        getuserCardList_success: (state, action) => {
            state.getuserCardList_success = false;
            state.getuserCardList_status = true;
            state.userCardList = action.payload;
        },
        getuserCardList_failure: (state, action) => {
            state.getuserCardList_requesting = false;
            state.getuserCardList_errorMsg = action.payload;
        },
        resetuserCardList: (state) => initialState,
    }
})

export const {
    getuserCardList_requesting,
    getuserCardList_success,
    getuserCardList_failure,
    resetuserCardList
} = userCardSlice.actions;

export default userCardSlice.reducer;