import React from 'react';
import default_background from "assets/images/background.png";
import { validateFunction } from 'helper/validations';

import Countdown2 from '../Countdown2';

export default function ContentDisplay({
    bgOverlayStyle,

    titleStyle,
    
    timer,
    countdownBorderColor,
    countdownTextStyle,

    iconStyle, iconTextStyle,
    onIconClick: onIconClick_props,

}) {
    let onIconClick = validateFunction(onIconClick_props);

    return (
        <div className="general_background">
            
    
            <div className="content_component d-flex flex-column" style={bgOverlayStyle}>
                {/* content page */}
                <div className="container__upper">
                    <div className="content__containerTop">
                        <div className="header">
                            <h4 id="tajuk"
                                style={titleStyle}
                            >
                                Countdown 
                            </h4>
                        </div>

                        <Countdown2 
                            className="countdown" 
                            timer={timer} 
                            countdownBorderColor={countdownBorderColor}
                            countdownTextStyle={countdownTextStyle}
                        />
                    </div>
        
                    <div className="content__containerBody">
                        <div className="details__icon">
                            <i
                                style={iconStyle}
                                className=" fas fa-phone-square icons"
                                onClick={() => onIconClick("hubungi")}
                            ></i>
                            <p className="a" style={iconTextStyle}>
                                Hubungi
                            </p>
                        </div>
        
                        <div className="tentative__icon">
                            <i
                                style={iconStyle}
                                className="fas fa-list-alt icons"
                                onClick={() => onIconClick("aturcara")}
                            ></i>
                            <p className="a" style={iconTextStyle}>
                                Atur Cara
                            </p>
                        </div>
        
                        <div className="location__icon">
                            <i
                                style={iconStyle}
                                className="fas fa-map-signs icons"
                                onClick={() => onIconClick("lokasi")}
                            ></i>
                            <p className="a" style={iconTextStyle}>
                                Lokasi
                            </p>
                        </div>
        
                        <div className="RSVP__icon">
                            <i
                                style={iconStyle}
                                className="fas fa-couch icons"
                                onClick={() => onIconClick("rsvp")}
                            ></i>
                            <p className="a" style={iconTextStyle}>
                                RSVP
                            </p>
                        </div>
        
                        <div className="Wish__icon">
                            <i
                                style={iconStyle}
                                className="fas fa-comment-alt icons"
                                onClick={() => onIconClick("ucapan")}
                            ></i>
                            <p className="a" style={iconTextStyle}>
                                Ucapan
                            </p>
                        </div>
        
                        <div className="gift__icon">
                            <i
                                style={iconStyle}
                                className="fas fa-camera icons"
                                onClick={() => onIconClick("gallery")}
                            ></i>
                            <p className="a" style={iconTextStyle}>
                                Album
                            </p>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    );
}