import React from "react";

function OnlineBanking(props) {
  const {
    bankList,
    selectedBankCode,

    //functions
    setSelectedBankCode,
  } = props;

  return (
    <div className="banking-center d-flex align-items-center">
      <div className={"online-banking row overflow-auto"}>
        {bankList?.map((bank, index) => {
          let id = bank.CODE;
          let name = bank.NAME;
          let isSelected = id === selectedBankCode;
          let isOffline = name.match(/(Offline)/g) != null;

          return (
            <div
              key={id}
              className={
                "fpx-bank d-flex flex-column justify-content-center align-items-center " + (isOffline ? "bank-offline " : "") 
                + (isSelected ? "selection__bank-selected " : "") 
              }
              onClick={() => setSelectedBankCode(id)}
            >
              <img
                className={"fpx-bank__logo"}
                src={require(`assets/images/banks/${id}.png`)}
                alt={`${name}`}
              />
              <span className={"fpx-bank__name text-center"}>{name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OnlineBanking;
