import { getObjectPropertyValue } from ".";

export const validateFunc = {
    isFunction,
    getSelectorFunc
}

export function isFunction(value) {
    return typeof value === "function";
}

export function validateFunction(value, defaultValue) {
    return isFunction(value)? value: (defaultValue? defaultValue:() => {});
}

export function getSelectorFunc(reducerName) {
    return state => getObjectPropertyValue(state, reducerName);
}