import {
  getCardCatalogList_requesting,
  getCardCatalogList_success,
  getCardCatalogList_failure,
} from "dataflow/slices/cardCatalog.slice";

import { useRequest } from "./config";
import { endpointUrls } from "helper/constants/endpointUrls";

export default function useCardCatalogServices() {
  const { get } = useRequest();

  function getCardCatalogList() {
    get({
      url: endpointUrls.getCardCatalog,
      requesting: getCardCatalogList_requesting,
      success: getCardCatalogList_success,
      failure: getCardCatalogList_failure,
    });
  }

  return {
    getCardCatalogList,
  };
}
