import React from 'react'
import "assets/scss/components/ecard/navButton.scss";

function NavButton({children, phase = true, setPhase = () => {}}) {

  return (
    <div className={"nav-button " + (phase ? "nav-button_primary" : "nav-button_secondary")} onClick={() => setPhase(!phase)} >
        {children}
    </div>
)
}

export default NavButton