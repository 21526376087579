import React from "react";
import { useCustomSelector } from "hooks";
import ReactTimeago from "react-timeago";

import "assets/scss/components/ecard/media.scss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

function Media({ id, timestamp, mediaURL, message, name, favorites }) {
  const { appInfo } = useCustomSelector("app");
  const theme = appInfo?.themes;

  const iconStyle1 = {
    color: theme?.contentColor1,
  };

  const tempStyle = {
    color: theme?.contentColor1,
  };

  return (
    <div className="media">
      <div className="media__header">
        <div className="media__headerAuthor">Dari: {name}</div>

        {/* 
        // nanti bila dah ready uncomment
        <div className="media__headerSettings d-flex justfiy-content-center align-items-center">
          <FontAwesomeIcon icon={faEllipsisH} className="fas fa-ellipsis-h" />
        </div> */}
      </div>

      <div className="media__source">
        <img src={mediaURL} alt="image" />
      </div>

      <div className="media__info">
        <div className="media__infoCaption">{message}</div>
        <div className="media_timestamp">
          <ReactTimeago
            date={new Date(timestamp?.toDate()).toUTCString()}
            live
          />
        </div>

        {/* <div className="media__infoFavs">
                    <i className="far fa-heart"></i> {favorites} Likes
                </div> */}
      </div>
    </div>
  );
}

export default Media;
